<div>
    <!-- <h4 class="text-center p-b-40 p-t-20">
        Sure Thing! Please confirm the email address you would like us to send your quote
    </h4> -->
    <div class="row" style="justify-content: center;">
        <h4 class="card-title">
            <app-bot-message [message]="botMessage"></app-bot-message>
        </h4>
    </div>
    
    <div class="col-12">
        <form [formGroup]="emailQuoteForm">
            <h3 class="card-title text-center custom-title p-b-20"></h3>
            <div class="row form-group">
                <div class="col-md-4 offset-md-4">
                    <mat-form-field style="width: 100%">
                        <input matInput [autofocus]="true" (input)="keyEmail()" formControlName="email" type="text" placeholder="Your Email Address" />
                    </mat-form-field>
                    <p class="error-class mat-angular-error-text"
                        *ngIf="!emailQuoteForm.get('email').valid && isSubmitted">{{emailError}}</p>
                </div>
            </div>
        </form>
    </div>
    <div class="p-b-40 text-center">
        <button class="btn btn-info custom-button-info m-r-40" type="button"
            (click)="navigateToLink('quote-premium')"
            [ngStyle]="styling?.button?.buttonNegative">Back</button>
    
        <button class="btn btn-info custom-button-info" type="button" (click)="submitForm()"
        [ngStyle]="styling?.button?.buttonPositive">Email Quote</button>
    </div>
</div>

<!-- <div [innerHTML]="customerResource" class="text-center"></div>
<div class = "text-center">
    <button class="btn btn-info "
    (click)="navigateToLink('get-quote-stable')"
    [ngStyle]="styling?.button?.buttonMajorAction">Get Another Quote</button>
</div>   -->
