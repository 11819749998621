import { Component, OnInit, HostListener } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-recoop-quote-premium',
  templateUrl: './recoop-quote-premium.component.html',
  styleUrls: ['./recoop-quote-premium.component.scss']
})
export class RecoopQuotePremiumComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  displayValue: any;
  estimatedPremium: any;
  loading = false;
  progressNumber = 0;
  interval: any;
  tenant: any;
  coverageItemList: any;
  quoteObj: any;
  isSmallWindow = window.innerWidth <= 768;
  helpText: any;
  coverageDisclosure: any;
  pageID: any;
  premiumForm: any;
  pageMessage: any;
  botMessage = [
    'Please give me a moment.'
  ];
  selectedCoverage: any;
  rateQuoteData: any;
  mostPopularValue: any = '';
  agentID: any;

  constructor(private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private httpService: CommonApiService,
    private dataTransferService: DataTransferService,
    private notifyToast: NotifytoastService,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {

    this.quoteObj = this.quotedataService.getConfiguration();
    this.tenant = this.utilsService.getTenantFromWindow();
    if(this.quotedataService.getAgentLoginName() != null){
      this.getAgentUserByUsername();
    }
    else{
      this.getRateQuoteData();
      this.initForm();
      this.getConstrainedValues();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }


  initForm() {
    this.pageID = this.quoteObj.pageID
    this.pageMessage = (this.quoteObj.pageMessage.includes('<FirstName>')) ? this.quoteObj.pageMessage.replace('<FirstName>', this.quotedataService.getInsuredName().firstName) : this.quoteObj.pageMessage;
    this.helpText = this.quoteObj.helpText;
    this.coverageDisclosure = this.quoteObj.coverageDisclosure;
    this.premiumForm = this.formBuilder.group({
      premiumPerYear: ['', [Validators.required]],
      premiumPerMonth: ['', [Validators.required]],
      coverageValue: ['', [Validators.required]]
    });
    this.fromAPI();
    this.mostPopularValue = this.quoteObj.mostPopularValue
    if (!this.premiumForm.value.coverageValue){
      if(this.quotedataService.getRecoopCoverageValue() != null){
        this.calculatePremium(this.quotedataService.getRecoopCoverageValue());
      }
      else{
        this.calculatePremium(this.quoteObj.defaultValue);
      }
    } 
  }
  fromAPI() {
    if (this.quoteObj.conversation[this.pageID]) this.premiumForm.patchValue(this.quoteObj.conversation[this.pageID])
  }

  setStyles(coverage) {
    return ((coverage == this.premiumForm.value.coverageValue) ? this.styling.button.buttonCoverage.Select : this.styling.button.buttonCoverage.UnSelect);
  }

  getAgentUserByUsername(){
    const agentLoginName = this.quotedataService.getAgentLoginName();
    this.httpService
      .get(`getAgentUserByUsername?accountCode=${this.tenant}&agentUsername=${agentLoginName}`, null, true)
      .subscribe(
        (resp) => {
          if (resp.out === undefined) {
            this.submitForm('next', true)
          } else {
            this.agentID = resp.out.agentID;
            this.quotedataService.setAgentID(this.agentID);
            this.getRateQuoteData();
            this.initForm();
            this.getConstrainedValues();
            if(this.quotedataService.getRecoopCoverageValue() != null){
              this.submitForm('next');
            }
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  calculatePremium(coverage) {
    this.selectedCoverage = coverage;
    this.premiumForm.patchValue({
      coverageValue: coverage
    });

    this.loading = true;
    this.fakeLoader();
    this.rateQuoteData["coverageDisasterValue"] = coverage
    this.submitRateQuote(this.rateQuoteData).subscribe(
      (resp) => {
        if (resp === undefined) {
          this.loading = false
          console.log('No response returned');
        } else {
          let estimatedPremium = resp.rate.estimatedPremium;
          let estimatedPremiumTax = resp.rate.taxes;
          let monthlyFee = JSON.stringify(estimatedPremium / 12);
          this.premiumForm.patchValue({
            premiumPerYear: estimatedPremium
          });
          this.premiumForm.patchValue({
            premiumPerMonth: this.utilsService.convertNumberToIS(parseFloat(monthlyFee).toFixed(2))
          });
          console.log("estimated premium from edit coverage: " + estimatedPremium)
          this.quotedataService.setEstimatedPremium(estimatedPremium);
          this.quotedataService.setPremiumTax(estimatedPremiumTax);
          this.quotedataService.setPolicyFees(resp.rate.policyFees)
          this.loading = false;
        }
      });


  }

  getConstrainedValues() {
    const state = this.quotedataService.getAddressObj().stateCode;
    const policyForm = this.getPolicyForm();
    const coverageValueToConsider = this.quotedataService.getAcntConfig()["quote"]["coverageValueToConsider"]
    this.httpService
      .get(`getConstrainedValues?accountCode=${this.tenant}&policyForm=${policyForm}&state=${state}`, null, true)
      .subscribe(
        (resp) => {
          if (resp.out === undefined) {
            this.submitForm('next', true)
            // this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp?.root?.Envelope?.Body?.Fault?.faultstring);
          } else {
            let cov = resp.out.coveragesList.CoverageLOV;
            const covList = cov.map(coverage => {
              const container = {};
              container[coverage.name] = coverage.values.string;
              return container;
            })
            this.coverageItemList = covList[0][coverageValueToConsider];
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  submitRateQuote(data): Observable<any> {
    return this.httpService.post('rateQuote', data, false, true).pipe(
      map((resp) => {
        console.log("Rate Quote resp : " + JSON.stringify(resp));
        if(resp.root != undefined){
          if(resp.root.Envelope.Body.Fault.faultstring.includes('Moratorium is in effect for Recoop Disaster Insurance')){
          this.submitForm('moratorium',false)
          return false
          }
        }
        else if (resp.out === undefined) {
          // this.utilsService.confirmModalPopup(`${resp.root.Envelope.Body.Fault.faultstring}`)
          this.submitForm('next', true)
          return false
        } else if (parseInt(resp.out.rate.estimatedPremium) == 0){
          if(this.quoteObj.zeroPremiumUrl){
            window.open(this.quoteObj.zeroPremiumUrl, '_self')
            return false
          }else{
            this.utilsService.confirmModalPopup(`Premium Quote is zero`)
            return false
          }
        } else {
          this.loading = false;
          return resp.out;
        }
      })
    );
  }

  submitForm(userSelection, isRateQuoteError?) {
    let temp;
    if (isRateQuoteError) {
      temp = {
        input: 'Next',
        pageID: this.quoteObj.pageID,
        userInput: { 'rate_quote_error': true }
      }
    }
    else {
      if (userSelection == 'next') {
        temp = {
          input: 'Next',
          pageID: this.quoteObj.pageID,
          userInput: { 'rate_quote_error': false, ...this.premiumForm.value }
          // {
          //   coverageValue: this.selectedCoverage
          // }
        }
        if(this.quotedataService.getRecoopCoverageValue() != null){
            temp.userInput.defaultCoverage = true;
        }
        else{
          temp.userInput.defaultCoverage = false;
        }
      }
      else if (userSelection == 'back') {
        temp = { input: 'Back', pageID: this.quoteObj.pageID }
      }
      else if(userSelection == 'moratorium'){
        temp = {
          input: 'moratorium',
          pageID: this.quoteObj.pageID,
          userInput: { 'moratorium_error': true }
        }
      }
    }
    if (temp) {
      this.dataTransferService.sendData(temp)
    }
  }

  fakeLoader() {
    this.progressNumber = 0
    this.startTimer()
  }

  startTimer() {
    this.interval = window.setInterval(() => {
      if (this.progressNumber < 98) this.progressNumber++
      else if (!this.loading) this.stopTimer(100)
      else this.stopTimer(100)
    }, 200)
  }

  stopTimer(time) {
    window.clearInterval(this.interval)
    window.clearTimeout(this.interval)
  }
  getEffectiveDate() {
    let result = new Date();
    result.setDate(result.getDate() + 1);
    return result.toLocaleDateString('en-US');
  }
  getFormValue(formName, value) {
    if (this.quoteObj.conversation[formName]) {
      return this.quoteObj.conversation[formName][value]
    } else {
      return null
    }
  }
  getPolicyForm() {
    console.log(this.quotedataService.getAcntConfig()["quote"]["productDetails"]["policyForm"], this.quotedataService.getAcntConfig()["quote"])
    return this.quotedataService.getAcntConfig()["quote"]["productDetails"]["policyForm"];
  }
  getRateQuoteData() {
    if(!this.agentID){
      this.agentID = this.quotedataService.getMappingInfoObj().defaultAgentID
    }
    const currentSelectedAddress = this.quotedataService.getAddressObj().currentSelectedAddress
    const propertyAddress = {
      street:
        (currentSelectedAddress['street_number'] &&
          currentSelectedAddress['street_number']['long_name']) +
        ' ' +
        (currentSelectedAddress['route'] && currentSelectedAddress['route']['long_name']) +
        ' ' +
        this.getFormValue("property-location", "unitNumber"),
      city: currentSelectedAddress['city']['long_name'],
      state: currentSelectedAddress['state']['short_name'],
      zip:
        currentSelectedAddress['postal_code'] &&
        currentSelectedAddress['postal_code']['long_name'],
      latitude: this.quotedataService.getRiskAddressObj()["lat"],
      longitude: this.quotedataService.getRiskAddressObj()["lng"]
    }
    const insuranceType = this.quotedataService.getAcntConfig()["quote"]["productDetails"]["insuranceType"];
    const occupancyStatus = this.quotedataService.getAcntConfig()["quote"]["relation_to_property_options"][this.getFormValue('relation-to-property', 'relationToProperty')];
    const propertyUsage= this.getFormValue('residence-type', 'propertyUsage');

    const data = {
      firstName: this.quotedataService.getInsuredName().firstName,
      lastName: this.quotedataService.getInsuredName().lastName,
      accountCode: this.tenant,
      insuranceType: insuranceType,
      policyEffectiveDate: this.getEffectiveDate(),
      propertyAddress: propertyAddress,
      mailingAddress: propertyAddress,
      propertyOccupancyStatus: occupancyStatus,
      agentID: this.agentID,
      propertyUsage: propertyUsage
    }
    this.rateQuoteData = data;
  }


}
