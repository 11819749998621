import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges {
  date = environment.buildDate
  version = environment.buildVersion
  isSfi = true
  @Input()footerContent: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.checkSfi()
  }

  ngOnChanges(){
  }

  checkSfi(){
    let tenant = this.route.snapshot.params.tenant
    if(tenant.indexOf('sfi') > -1){
      this.isSfi = true
    } else{
      this.isSfi = false
    }
  }

  navTo(isLanding){
    if(isLanding){
      window.open("https://www.securityfirstflorida.com/", '_blank')
    } else{
      window.open("https://www.securityfirstflorida.com/contact-us?_ga=2.244235973.1512143232.1604434318-1069710922.1600780991", '_blank')
    }
  }

  navToPage(page){
    if(page == 'privacy'){
      window.open("https://www.securityfirstflorida.com/privacy-policy", '_blank')
    } else if(page == 'terms'){
      window.open("https://www.securityfirstflorida.com/terms-use", '_blank')
    }
  }

}
