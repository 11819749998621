import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'app-email-quoter',
  templateUrl: './email-quoter.component.html',
  styleUrls: ['./email-quoter.component.scss'],
  providers: [CommonApiService]
})
export class EmailQuoterComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  emailQuoteForm: any;
  emailError: any;
  tenant: any;
  isSubmitted = false;
  quoteID: any;
  agentDetails:any;
  buildingTypes: any;
  botMessage = "Sure Thing! Please confirm the email address you would like us to send your quote"
  defaultAgentID:any;
  // buildingTypes = ["Single Family House","Single Family Modular","Mobile/Manufactured/Park Model/RV","Townhouse","Duplex"]

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService
  ) { }

  ngOnInit(): void {
    this.tenant = this.utilsService.getTenantFromWindow()
    this.initForm()
    this.getEmail()
    this.keyEmail()
    this.quoteID = this.quotedataService.getQuoteID()
    this.buildingTypes = this.quotedataService.getBuildingObject().buildingTypes
    this.agentDetails =  this.quotedataService.getEmailQuote()
    console.log("quote ID from email quoter: " + this.quoteID)
    this.defaultAgentID = (this.quotedataService.getMappingInfoObj().defaultAgentID == undefined || this.quotedataService.getMappingInfoObj().defaultAgentID  == null )? '3245336' : this.quotedataService.getMappingInfoObj().defaultAgentID  
    console.log('default agent ID: ' + this.quotedataService.getMappingInfoObj().defaultAgentID);
  }

  initForm(){
    this.emailQuoteForm = this.formBuilder.group({
      email: ['', [Validators.required, ValidationService.emailValidator]]
    })
  }

  getEmail(){
    let email = this.getFormValue('Personal-Info' , 'email')
    this.emailQuoteForm.patchValue({email})
  }

  getFormValue(formName, value){
    const quoteObj = this.quotedataService.getConfiguration()
    if(quoteObj.conversation[formName]){
      return quoteObj.conversation[formName][value]
    } else{
      return null
    }
  }

  keyEmail(){
    const temp = this.emailQuoteForm.get('email')
    if(temp.errors){
      if (temp.errors.required) {
        this.emailError =  'Email is required'
      } else if (temp.errors.invalidEmailAddress) {
        this.emailError =  'Email is not valid.'
      }
    }
  }

  navigateToLink(page) {
    this.router.navigate([`${this.tenant}/get-quote/${page}`]);
  }

  submitForm(){
    this.isSubmitted = true
    if(this.emailQuoteForm.valid) {
      this.quotedataService.setEmailQuote(this.emailQuoteForm.value.email)
      this.emailQuote()
    }
  }

  emailQuote() {
    const data = this.quotedataService.getQuoteObjectStructure();
    data.email = this.emailQuoteForm.value.email
    data.agentID =  (this.agentDetails == undefined || this.agentDetails == null ) ? this.defaultAgentID  : this.agentDetails.agentID
    if (this.buildingTypes != null) {
      data.propertyStructureType = data.propertyStructureType === '' ? this.buildingTypes[0] : data.propertyStructureType;
    }
    this.sendEmail(this.emailQuoteData()).subscribe(
      (sendEmailResp) => {
    if (sendEmailResp === undefined) {
      console.log('No response for sending quote by email');
    } else {
      // this.getCustomerResource();
      this.navigateToLink('email-quote-confirmation')
      console.log("email quote response: " + JSON.stringify(sendEmailResp))
    }
  });
  }

  sendEmail(data) {
    return this.httpService.post('emailQuote', data, true, true).pipe(
      map(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error(
              'Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring
            );
            console.log(JSON.stringify(resp));
          } else {
            console.log('email quote' + JSON.stringify(resp.out));
            return resp.out;
          }
        }
        // ,
        // (error) => {
        //   this.notifyToast.error('Please Contact System Administrator.');
        // }
      )
    );
  }

  emailQuoteData() {
    const data = {
        "accountCode": this.tenant,
        "to": this.emailQuoteForm.value.email,
        "quoteID": this.quoteID,
        "firstName": this.getFormValue('Personal-Info' , 'firstName'),
        "lastName": this.getFormValue('Personal-Info' , 'lastName'),
        "phoneNumber": "(111)111-1111",
     }
    return data;
  }

}
