<form [formGroup]="roofBuiltForm" *ngIf="quoteObj?.pageID == 'roof-age'">
    <div class="form-group row">
        <div class="col-md-4 offset-md-4 text-center" [ngStyle]="styling?.quoterWizard?.quoteSubField2">        
            <mat-label style="width: 40%" [ngStyle]="styling?.quoterWizard?.quoteSubField2">Year </mat-label>      
            <mat-form-field [ngStyle]="styling?.quoterWizard?.quoteSubField2">
                <input matInput [autofocus]="true" formControlName="roofYear" type="text" numbersOnly maxlength="4"/>
            </mat-form-field>
            <!-- placeholder="Year Roof Built/Replaced *" style="width: 60%"  -->
            <p class="error-class mat-angular-error-text text-center" [ngStyle]="styling?.quoterWizard?.quoteSubField2" *ngIf="roofBuiltForm.get('roofYear').errors?.required && isSubmitted">
                {{'REQUIRED_FIELD' | translate: {value:'Year Roof Built/Replaced'} }}</p>
            <p class="error-class mat-angular-error-text text-center" [ngStyle]="styling?.quoterWizard?.quoteSubField2" *ngIf="roofBuiltForm.get('roofYear').errors?.min && isSubmitted">Year Roof Built/Replaced should be greater than 1969</p>
            <p class="error-class mat-angular-error-text text-center" [ngStyle]="styling?.quoterWizard?.quoteSubField2" *ngIf="roofBuiltForm.get('roofYear').errors?.max && isSubmitted">Year Roof Built/Replaced should be less than current year</p>
        </div>
    </div>
    <!-- <div class="form-group row">
        <div class="col-md-4 offset-md-4">
            <mat-label class="custom-title-info account-title" >Roof Material *</mat-label>
            <ng-select id="roofMaterials_6" [items]="roofMaterials" appendTo="body" [hideSelected]="true"
                placeholder="Roof Material" formControlName="roofMaterial">
            </ng-select>
            <p class="error-class" *ngIf="!roofBuiltForm.get('roofMaterial').valid && isSubmitted">
                Roof Material is required</p>
        </div>
    </div> -->
    <!-- <div class="col-12 form-group">
        <div  class="text-center p-b-40">
            <button class="btn btn-info custom-button-info m-r-20" *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button"
                (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
            <button class="btn btn-info custom-button-info" type="button"
                *ngIf="quoteObj?.buttonOptions?.includes('Next')"
                (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        </div>
    </div> -->
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>
    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
    <button class="btn btn-info custom-button-info m-r-10 m-b-20"
        *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
        [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    <button class="btn btn-info custom-button-info m-l-10 m-b-20"
        type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
        [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
</div>
</form>