<form [formGroup]="seasonalResidenceForm" *ngIf="quoteObj?.pageID == 'Seasonal-Residence'">

    <div class="col-12">
        <div class="col-md-4 offset-md-4 d-flex">
        
        <div class="text-center" style="width: 100%;">
            <input type="radio" value="false" id="notRented"
                formControlName="seasonalRent" class="radio-col-light-blue" />
            <label [ngStyle]="styling?.fieldValueCheckBox" for="notRented">No</label>
        </div>
        <div class="text-center" style="width: 100%;">
            <input type="radio" value="true" id="rented" formControlName="seasonalRent"
                class="radio-col-light-blue" />
            <label [ngStyle]="styling?.fieldValueCheckBox" for="rented">Yes</label>
        </div>
    </div>
    </div>
    <div class="col-12 form-group">
        <div  class="text-center p-b-40">
            <button class="btn btn-info custom-button-info m-r-20" *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button"
                (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
            <button class="btn btn-info custom-button-info" *ngIf="quoteObj?.buttonOptions?.includes('Next')" type="button"
                (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        </div>
    </div>
</form>