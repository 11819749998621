<div class="table-responsive" *ngIf="tableDataObj?.length; else alternateTable" style="overflow: hidden;">
    <table class="table">
        <thead class="custom-description" style="background-color: lightgray;">
            <tr>
                <th style="width: 10px;" *ngIf="accordionHeaders?.length"></th>
                <th class="text-center sfi-body-paragraph-s sfi-table-header" [ngStyle]="styling?.tableHeader" *ngFor="let tableHeader of tableHeaders" 
                [ngClass]="customTableActions?.alignment[tableHeader] ? customTableActions?.alignment[tableHeader] : 'text-center'">
                    {{tableHeader | translate}}
                    <ng-container *ngIf="customTableActions?.tooltip && customTableActions?.tooltip[tableHeader]">
                        <a class="fas fa-question-circle" [ngStyle]="styling?.toolTip"
                        [popoverTitle]="customTableActions?.tooltip[tableHeader]?.title"
                        placement="auto"
                        containerClass="custom-tooltip"
                        [adaptivePosition]="true" triggers="mouseenter:mouseleave"
                        [popover]="popover_content" container="body"></a>
                    </ng-container>
                    <ng-template #popover_content>
                        <div [innerHTML]="customTableActions?.tooltip[tableHeader]?.description  | safeHtml">
                        </div>
                    </ng-template>
                </th>
            </tr>
        </thead>
        <tbody class="custom-description">
            <ng-container *ngIf="tableDataObj !== null; else alternateContent">
                <ng-container *ngFor="let row of tableDataObj; let i = index; let j =count">
                    <tr [attr.data-target]="'#NumberIndex' + tableName + i" [id]="'#NumberIndex' + tableName + i" 
                    style="background: #f2f4f8;" data-toggle="collapse">
                        <td style="width: 10px;" *ngIf="accordionHeaders?.length"><a class="fas fa-chevron-right"></a></td>
                        <ng-container *ngFor="let tableHeader of tableHeaders">
                            <ng-container *ngIf="tableHeader != 'ACTIONS'">
                                <td class="text-nowrap custom-link text-center sfi-hyperlinks" [ngStyle]="styling?.hyperlink" *ngIf="customTableActions?.links.includes(tableHeader)">
                                    <a class="view-policy-button" (click)="callbackFn('link', row)" appendToBody=true>{{row[tableHeader] }}</a>
                                </td>
                                <td [ngClass]="customTableActions?.alignment[tableHeader] ? customTableActions?.alignment[tableHeader] : 'text-center'"
                                *ngIf="!customTableActions?.links.includes(tableHeader)" [ngStyle]="styling?.tableValue">{{row[tableHeader] }}</td>
                            </ng-container>
                            <ng-container *ngIf="tableHeader == 'ACTIONS'">
                                <td class="text-center">
                                    <ng-container *ngFor="let action of customTableActions?.actions">
                                        <a *ngIf="action == 'Delete'" [attr.disabled]="j == 1" [ngClass]="{'disabled-icon': (j == 1)}"
                                        popover="Remove Policy" container="body" triggers="mouseenter:mouseleave" 
                                        style="cursor: pointer;" class="fas fa-trash m-r-10" (click)="callbackFn('delete', row)"></a>
                                        <a *ngIf="action == 'Document'" style="cursor: pointer;"
                                                [ngStyle]="styling?.iconStyling"
                                                popover="Show Document" container="body" triggers="mouseenter:mouseleave" 
                                                class="fas fa-file-alt m-r-10" (click)="callbackFn('document', row)"></a>
                                                <!-- <a *ngIf="action == 'Upload'" style="cursor: pointer;"
                                                popover="Upload Document" container="body" triggers="mouseenter:mouseleave" 
                                                class="fas fa-cloud-upload-alt" (click)="callbackFn('upload', row)"></a> -->
                                            <ng-container *ngIf="action == 'Upload'">
                                                <label [attr.for]="'file-uploader-ccp-'+i" 
                                                class="fas fa-cloud-upload-alt" 
                                                triggers="mouseenter:mouseleave"  
                                                popover="Upload Document"
                                                [ngStyle]="styling?.iconStyling"
                                                style="cursor: pointer;"></label>
                                                <input type="file" [attr.id]="'file-uploader-ccp-'+i" style="display: none;" (click)="fileInputCCP.value = null" #fileInputCCP (change)="fileChange($event,'upload', row)" multiple="multiple" />
                                            </ng-container>
                                            <div *ngIf="action == 'Loading'" class="spinner-border spinner-border-sm "></div>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr *ngIf="accordionHeaders.length" [id]="'NumberIndex' + tableName +i" class="collapse"
                    style="box-shadow: rgba(103, 117, 124, .85) 0px -2px 6px 1px inset;">

                        <td [attr.colspan]="tableHeaders.length+1" style="font-size: 12px;">
                            <ng-container *ngFor="let accordionHeader of accordionHeaders">
                                <ng-container *ngIf="accordionHeader != 'ACTIONS'">
                                    <div class="row form-group">
                                        <div class="col-4 text-left" [ngStyle]="styling?.tableHeader">
                                            <div class="custom-title-info account-title">{{accordionHeader | translate}}</div>
                                        </div>
                                        <div class="col-8 text-left">
                                            <span class="custom-title-infoDetail account-description" [ngStyle]="styling?.tableValue">{{tableDataObj[i][accordionHeader]}}</span>
                                        </div>
                                    </div>
                                    <!-- <hr class="horizontal-row" /> -->
                                </ng-container>
                                <ng-container *ngIf="accordionHeader == 'ACTIONS'">
                                    <div class="row form-group">
                                        <div class="col-4" [ngStyle]="styling?.tableHeader">
                                            <div class="custom-title-info account-title">{{accordionHeader | translate}}</div>
                                        </div>
                                        <div class="col-8 text-left">
                                            <ng-container *ngFor="let action of customTableActions?.actions">
                                                <a *ngIf="action == 'Delete'" style="cursor: pointer;"  [attr.disabled]="j == 1" 
                                                [ngClass]="{'disabled-icon': (j == 1)}" [ngStyle]="styling?.iconStyling"
                                                popover="Remove Policy" container="body" triggers="mouseenter:mouseleave" 
                                                class="fas fa-trash m-r-10" (click)="callbackFn('delete', row)"></a>
                                                <a *ngIf="action == 'Document'" style="cursor: pointer;" [ngStyle]="styling?.iconStyling"
                                                popover="Show Document" container="body" triggers="mouseenter:mouseleave" 
                                                class="fas fa-file-alt m-r-10" (click)="callbackFn('document', row)"></a>
                                                <!-- <a *ngIf="action == 'Upload'" style="cursor: pointer;"
                                                popover="Upload Document" container="body" triggers="mouseenter:mouseleave" 
                                                class="fas fa-cloud-upload-alt" (click)="callbackFn('upload', row)"></a> -->
                                                <!-- <ng-container *ngIf="action == 'Upload'">
                                                    <label for="file-uploader-ccp" 
                                                    class="fas fa-cloud-upload-alt" 
                                                    triggers="mouseenter:mouseleave"  
                                                    popover="Upload Document"
                                                    style="cursor: pointer;"></label>
                                                    <input type="file" id="file-uploader-ccp" style="display: none;" />
                                                </ng-container> -->
                                                <ng-container *ngIf="action == 'Upload'">
                                                    <label [attr.for]="'file-uploader-ccp-'+i" 
                                                    class="fas fa-cloud-upload-alt" 
                                                    [ngStyle]="styling?.iconStyling"
                                                    triggers="mouseenter:mouseleave"  
                                                    popover="Upload Document"
                                                    style="cursor: pointer;"></label>
                                                    <input type="file" [attr.id]="'file-uploader-ccp-'+i" style="display: none;" (click)="fileInputCCP.value = null" #fileInputCCP (change)="fileChange($event, 'upload', row)" multiple="multiple" />
                                                </ng-container>
                                                <div *ngIf="action == 'Loading'" class="spinner-border spinner-border-sm "></div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <ng-template #alternateContent>
                <tr> 
                <td [attr.colspan]="tableDataObj?.length+1" class="text-center" [ngStyle]="styling?.tableValue">{{noDataAvailableTxt}}--{{noDataAvailableTxt? noDataAvailableTxt: 'NO_DATA_AVAILABLE' | translate}}</td>
                </tr>
            </ng-template>
        </tbody>
    </table>
</div>
<ng-template #alternateTable>
    <div class="col-12 text-center" style="background: rgb(242, 244, 248); padding: 30px 0px;">
        <h4 [ngStyle]="styling?.tableValue" translate>{{noDataAvailableTxt? noDataAvailableTxt: 'NO_DATA_AVAILABLE'}} </h4>
    </div>
</ng-template>