import { Directive, OnInit, HostListener, ElementRef, AfterViewInit, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UtilsService } from '../services/utils.service';


@Directive({
    selector: '[altImgTenants]'
})

export class AlternateImgTenantDirective implements AfterViewInit{
    @Input() src;
    constructor(private imageRef: ElementRef, private utilsService: UtilsService){
    }

    ngAfterViewInit(){
        const img = new Image()
        img.onload =() => {
            this.setImg(this.src)
        }

        img.onerror = () => {
            let tenant = this.utilsService.getTenantFromWindow()
            this.src = this.src.replace(tenant, "common")
            this.setImg(this.src)
        }
        img.src = this.src
    }

    private setImg(src){
        this.imageRef.nativeElement.setAttribute('src', src)
    }

}