import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'app-send-quote-to-agent',
  templateUrl: './send-quote-to-agent.component.html',
  styleUrls: ['./send-quote-to-agent.component.scss'],
  providers: [CommonApiService]
})
export class SendQuoteToAgentComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  emailQuoteAgentForm: any;
  agentDetails: any
  agentFirstName:any;
  agentLastName: any;
  emailError: any;
  tenant: any;
  quoteID: any;
  quoteObj: any;
  isSubmitted = false;
  buildingTypes: any;
  defaultAgentID:any;
  // buildingTypes = ["Single Family House","Single Family Modular","Mobile/Manufactured/Park Model/RV","Townhouse","Duplex"]

  constructor(
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private router: Router,
    private utilsService: UtilsService,
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.tenant = this.utilsService.getTenantFromWindow()
    this.agentDetails = this.quotedataService.getEmailQuote()
    this.initForm()
    this.setAgentDetails()
    this.buildingTypes = this.quotedataService.getBuildingObject().buildingTypes
    this.defaultAgentID = (this.quotedataService.getMappingInfoObj().defaultAgentID == undefined || this.quotedataService.getMappingInfoObj().defaultAgentID  == null )? '3245336' : this.quotedataService.getMappingInfoObj().defaultAgentID  
    console.log('default agent ID: ' + this.quotedataService.getMappingInfoObj().defaultAgentID);
    //this.quoteID = this.quotedataService.getQuoteID()
  }

  initForm(){
    this.emailQuoteAgentForm = this.formBuilder.group({
      ph_number: ['', [ Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.maxLength(14)]],
      countryCode: ['1', [ Validators.pattern('^[0-9]+$'), Validators.maxLength(3)]],
      message: [null],
      email: [null, [Validators.required,ValidationService.emailValidator]],
      preferredCommunicationType:['email',[Validators.required]]
    })
  }

  setAgentDetails(){
    this.agentFirstName = this.agentDetails && this.agentDetails.agentFirstName;
    this.agentLastName = this.agentDetails && this.agentDetails.agentLastName;
    
  }

  setCommunicationType(type){
    this.emailQuoteAgentForm.patchValue({
      preferredCommunicationType: type  
    });
    // this.emailQuoteAgentForm.updateValueAndValidity();
    if(type =="email"){
      this.emailQuoteAgentForm.get('email').setValidators([Validators.required, ValidationService.emailValidator])
      this.emailQuoteAgentForm.get('email').updateValueAndValidity();
      this.emailQuoteAgentForm.get('ph_number').setValidators([Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.maxLength(14)])
      this.emailQuoteAgentForm.get('ph_number').updateValueAndValidity();
      
    }
    else if(type =="phone"){
      this.emailQuoteAgentForm.get('ph_number').setValidators([Validators.required,Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.maxLength(14)])
      this.emailQuoteAgentForm.get('ph_number').updateValueAndValidity();
      this.emailQuoteAgentForm.get('email').clearValidators()
      this.emailQuoteAgentForm.get('email').updateValueAndValidity();
      
    }
    // this.emailQuoteAgentForm.updateValueAndValidity();
    console.log("communication type is set to",type)
  }

  keyEmail(){
    const temp = this.emailQuoteAgentForm.get('email')
    if(temp.errors){
      if (temp.errors.required) {
        this.emailError =  'Email is required'
      } else if (temp.errors.invalidEmailAddress) {
        this.emailError =  'Email is not valid.'
      }
    }
  }

  navigateToLink(page) {
    this.router.navigate([`${this.tenant}/get-quote/${page}`]);
  }
  
  emailQuoteAgent() {
    this.isSubmitted = true;
    if(!this.emailError) this.keyEmail()
    if(this.emailQuoteAgentForm.valid){
      const data = this.quotedataService.getQuoteObjectStructure();
      data.agentID = this.agentDetails ? this.agentDetails.agentID: this.defaultAgentID
      data.firstName =  this.quotedataService.getContactDetails().first_name
      data.lastName =  this.quotedataService.getContactDetails().last_name
      if (this.quotedataService.getContactDetails().dontContact  === '' && this.emailQuoteAgentForm.value.ph_number != '') {
        data.phoneNumber = {
          number: this.convertPhoneNumber(this.emailQuoteAgentForm.value.ph_number),
          type:'Cell',
        } 
      }
      console.log(JSON.stringify(data.phoneNumber))
      // add the default value of HomeBuildingType if there is no information collected from UI
      if (this.buildingTypes != null) {
        data.propertyStructureType = data.propertyStructureType === '' ? this.buildingTypes[0] : data.propertyStructureType;
      }
      this.submitQuote(data).subscribe(
        (resp) => {
        if (resp === undefined) {
          console.log('No response returned');
        } else {
          console.log("submit quote response: " + JSON.stringify(resp))
          this.quoteID = resp.quoteReferenceNumber;
          this.quotedataService.setQuoteID(this.quoteID)
          console.log("quote ID from send quote to an agent: " + this.quoteID)
            this.sendAgentEmail(this.emailAgentQuoteData()).subscribe(
              (sendEmailResp) => {
            if (sendEmailResp === undefined) {
              console.log('No response for sending quote by email');
            } else {
              // this.getCustomerResource();
              this.navigateToLink('agent-email-confirmation')
              this.notifyToast.success(sendEmailResp);
                  console.log("email quote response: " + JSON.stringify(sendEmailResp))
                }
          });
        }
      });
    }
      
  }

  convertPhoneNumber(phoneNum) {
    if (phoneNum != null) {
      return phoneNum.replace(/[-_()' ']/g, '')
    }
    return null;
  }

  submitQuote(requestSubmitQuoteData): Observable<any> {
    return this.httpService
      .post('submitQuote', requestSubmitQuoteData, true, true)
      .pipe(
        map(
          (resp) => {
            if (resp.out === undefined) {
              this.notifyToast.error(
                'Please Contact System Administrator.' +
                  '\n' +
                  resp.root.Envelope.Body.Fault.faultstring
              );
              console.log(JSON.stringify(resp));
            } else {
              console.log(
                'return value Submit Quote' + JSON.stringify(resp.out)
              );
              return resp.out;
            }
          }
          // ,
          // (error) => {
          //   this.notifyToast.error('Please Contact System Administrator.');
          // }
        )
      );
  }

  sendAgentEmail(data) {
    return this.httpService.post('emailQuoteToAgent', data, true, true).pipe(
      map(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error(
              'Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring
            );
            console.log(JSON.stringify(resp));
          } else {
            console.log('email quote' + JSON.stringify(resp.out));
            return resp.out;
          }
        }
        // ,
        // (error) => {
        //   this.notifyToast.error('Please Contact System Administrator.');
        // }
      )
    );
  }

  emailAgentQuoteData() {
    let message = (this.emailQuoteAgentForm.value.message == null)
      ? " " : ". Message from "+ this.getFormValue('Personal-Info' , 'firstName') + " " 
        + this.getFormValue('Personal-Info' , 'lastName') +": "+ this.emailQuoteAgentForm.value.message
    const data = {
      "accountCode": this.tenant,
      "to": this.agentDetails.agentEmail,
      "quoteID": this.quoteID,
      "agentFirstName": this.agentFirstName,
      "agentLastName": this.agentLastName,
      "insuredFirstName": this.getFormValue('Personal-Info' , 'firstName'),
      "insuredLastName":this.getFormValue('Personal-Info' , 'lastName'),
      "preferrredContact":this.emailQuoteAgentForm.value.preferredCommunicationType,
      "email": this.emailQuoteAgentForm.value.email,
      "phone": this.emailQuoteAgentForm.value.ph_number
    }
     console.log("Email agent quote data", data);
    return data;
  }

  getFormValue(formName, value){
    if(this.quoteObj.conversation[formName]){
      return this.quoteObj.conversation[formName][value]
    } else{
      return null
    }
  }

}
