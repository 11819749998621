import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-google-autocomplete',
  templateUrl: './google-autocomplete.component.html',
  styleUrls: ['./google-autocomplete.component.scss']
})
export class GoogleAutocompleteComponent implements OnInit {
  @Output() setAddress = new EventEmitter();
  @ViewChild('googleAddress') googleAddress: any;
  @Input() placeHolder: any;
  constructor() { }

  ngOnInit(): void {
  }

  getPlaceAutocomplete() {
    const autoComplete = new google.maps.places.Autocomplete(this.googleAddress.nativeElement,
      {
        componentRestrictions: {country: 'US'},
        types: ['geocode']
      });
      this.getPlace({})
      google.maps.event.addListener(autoComplete, 'place_changed', () => {
		console.log("place:", autoComplete);
        const currentPlace = autoComplete.getPlace()
        this.getPlace(currentPlace)
      })
  }

  getPlace(currentPlace){
    this.setAddress.emit(currentPlace)
  }

}
