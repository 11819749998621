import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-seasonal-residence',
  templateUrl: './seasonal-residence.component.html',
  styleUrls: ['./seasonal-residence.component.scss']
})
export class SeasonalResidenceComponent implements OnInit {
  seasonalResidenceForm: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any;
  
  
  constructor(
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.initForm()
    this.fromAPI()
  }

  initForm(){
    console.log("quote object:", this.quoteObj.buttons)
    this.seasonalResidenceForm = this.formBuilder.group({
      seasonalRent: ['true']
    });
  }

  fromAPI(){
    if(this.quoteObj.conversation && this.quoteObj.conversation['Seasonal-Residence']) 
      this.seasonalResidenceForm.patchValue(this.quoteObj.conversation['Seasonal-Residence'])
  }


  onClick(eve) {
    console.log("eve:", eve)
    this.seasonalResidenceForm.patchValue({
      seasonalRent : eve
    });
  }

  submitForm(userSelection){
    let temp;
    if(this.seasonalResidenceForm.valid && userSelection == 'next'){
        temp = { input: 'Next', pageID: 'Seasonal-Residence', userInput: this.seasonalResidenceForm.value }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'Seasonal-Residence' }
    }
    if(temp){
      this.dataTransferService.sendData(temp)
    }
  }

}
