<form [formGroup]="priorInsuranceForm" *ngIf="quoteObj?.pageID == 'prior-insurance'">

    <div class="col-12 p-b-20">
        <div class="col-md-4 offset-md-4 d-flex">
        
        <div class="text-center" style="width: 100%;">
            <input type="radio" value=false id="notPriorInsurance"
                formControlName="priorInsurance" class="radio-col-light-blue" />
            <label [ngStyle]="styling?.fieldValueCheckBox" for="notPriorInsurance"
            [ngStyle]="styling?.quoterWizard?.quoteSubTitle">No</label>
        </div>
        <div class="text-center" style="width: 100%;">
            <input type="radio" value=true id="priorInsurance" formControlName="priorInsurance"
                class="radio-col-light-blue" />
            <label [ngStyle]="styling?.fieldValueCheckBox" for="priorInsurance"
            [ngStyle]="styling?.quoterWizard?.quoteSubTitle">Yes</label>
        </div>
    </div>
    </div>
    <!-- <div class="col-12 form-group">
        <div  class="text-center p-b-40">
            <button class="btn btn-info custom-button-info m-r-20" *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button"
                (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
            <button class="btn btn-info custom-button-info" *ngIf="quoteObj?.buttonOptions?.includes('Next')" type="button"
                (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        </div>
    </div> -->
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>
    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
    <button class="btn btn-info custom-button-info m-r-10 m-b-20"
        *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
        [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    <button class="btn btn-info custom-button-info m-l-10 m-b-20"
        type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
        [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
</div>
</form>