import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-is-rental-form',
  templateUrl: './is-rental-form.component.html',
  styleUrls: ['./is-rental-form.component.scss']
})
export class IsRentalFormComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  // @Input() dataObj: any;
  // @Output() submitFormObj = new EventEmitter()
  quoteObj: any;
  isSmallWindow = window.innerWidth <= 768;
  isRentalForm: any;
  parentTenant: any;

  constructor(
    private formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) {
    let tenant = this.route.parent.parent.snapshot.params.tenant
    this.parentTenant = tenant.split('-')[0].toLowerCase()
   }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.isRentalForm = this.formBuilder.group({
      checkRental: ['Primary Residence']
    });
    this.fromAPI()
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }
  onClick(eve) {
    this.isRentalForm.patchValue({
      checkRental : eve
    });
  }

  fromAPI(){
    if(this.quoteObj.conversation && this.quoteObj.conversation['home-usage']) 
      this.isRentalForm.patchValue(this.quoteObj.conversation['home-usage'])
  }

  submitForm(userSelection){
    let temp;
    if(this.isRentalForm.valid && userSelection == 'next'){
        temp = { input: 'Next', pageID: 'home-usage', userInput: this.isRentalForm.value }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'home-usage' }
    }
    if(temp){
      // console.log(temp)
      // this.submitFormObj.emit(temp)
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve){
    if (this.isRentalForm.valid) {
      let temp = eve
      temp.userInput = this.isRentalForm.value
      temp.currentPageID = 'home-usage'
      this.dataTransferService.sendData(temp)
    }
  }

}
