import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from 'rxjs/operators'
import { CommonApiService } from "src/app/shared/services/common-api.service";
import { UtilsService } from "src/app/shared/services/utils.service";




@Injectable()
export class GoogleCoOrdinatesResolver implements Resolve<any>{
    constructor(private httpService: CommonApiService, private utilsService: UtilsService){}

    resolve(route: ActivatedRouteSnapshot): Observable<any>{
        const pageName = this.utilsService.getTenantFromWindow()
        
        return this.httpService.get(`getCoOrdinates?accountCode=${pageName}`, null, true).pipe(
            map(res => { return res }),
            catchError(error => this.handleError(error))
        )
    }

    handleError(error){
        return observableThrowError(error)
    }
}