<form [formGroup]="lawSuitForm">

    <div class="col-12">
        <div class="col-md-4 offset-md-4 d-flex"> 
            <div class="text-center" style="width: 100%;">
                <input type="radio" value=no id="notLawSuit"
                    formControlName="lawSuit" class="radio-col-light-blue" />
                <label [ngStyle]="styling?.fieldValueCheckBox" for="notLawSuit">No</label>
            </div>
            <div class="text-center" style="width: 100%;">
                <input type="radio" value=yes id="lawSuit" formControlName="lawSuit"
                    class="radio-col-light-blue" />
                <label [ngStyle]="styling?.fieldValueCheckBox" for="lawSuit">Yes</label>
            </div>
    </div>
    </div>

    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
    <button class="btn btn-info custom-button-info m-r-10 m-b-20"
        *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
        [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    <button class="btn btn-info custom-button-info m-l-10 m-b-20"
        type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
        [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
</div>
</form>