import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-house-type-form',
  templateUrl: './house-type-form.component.html',
  styleUrls: ['./house-type-form.component.scss']
})
export class HouseTypeFormComponent implements OnInit {
  houseTypeForm: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  // @Input() dataObj: any;
  // @Output() submitFormObj = new EventEmitter()
  quoteObj: any;
  StructureTypelist: any;
  isSmallWindow = window.innerWidth <= 768;

  constructor(
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.initForm()
    this.fromAPI()
    this.getMappingValues()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }
  
  initForm(){
    this.houseTypeForm = this.formBuilder.group({
      checkHouse: ['Single Family Home']
    });
  }

  getMappingValues() {
    this.StructureTypelist = this.quotedataService.getMappingInfoObj().homeStructureType
  }

  // onClick(eve) {
  //   this.houseTypeForm.patchValue({
  //     checkHouse : eve
  //   });
  // }

  fromAPI(){
    if(this.quoteObj.conversation && this.quoteObj.conversation['structure-type']) 
      this.houseTypeForm.patchValue(this.quoteObj.conversation['structure-type'])
  }

  submitForm(userSelection){
    let temp;
    if(this.houseTypeForm.valid && userSelection == 'next'){
        temp = { input: 'Next', pageID: 'structure-type', userInput: this.houseTypeForm.value }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'structure-type' }
    }
    if(temp){
      // console.log(temp)
      // this.submitFormObj.emit(temp)
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve){
    if (this.houseTypeForm.valid) {
      let temp = eve
      temp.userInput = this.houseTypeForm.value
      temp.currentPageID = 'structure-type'
      this.dataTransferService.sendData(temp)
    }
  }

}
