<form [formGroup]="houseRoofTypeForm" *ngIf="quoteObj?.pageID == 'roof-shape'">
    <div class="row p-t-10 p-b-10">
        <div class="col-lg-3 p-b-20" [ngClass]="{'col-mob-6' : isSmallWindow}">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center">
                    <label class="p-2" for="Gable">
                        <input type="radio" value="Gable" formControlName="roofType" id="Gable" class="radio-col-light-blue" />
                        <img [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_roof_gable.svg'" altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">{{'GABLE' | translate}}</p>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-3 p-b-20" [ngClass]="{'col-mob-6' : isSmallWindow}">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center">
                    <label class="p-2" for="Hip">
                        <input type="radio" value="Hip" formControlName="roofType" id="Hip" class="radio-col-light-blue" />
                        <img [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_roof_hip.svg'" altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">{{'HIP' | translate}}</p>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-3 p-b-20" [ngClass]="{'col-mob-6' : isSmallWindow}">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center">
                    <label class="p-2" for="Flat">
                        <input type="radio" value="Flat" formControlName="roofType" id="Flat" class="radio-col-light-blue" />
                        <img [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_roof_flat.svg'" altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">{{'FLAT' | translate}}</p>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-3 p-b-20" [ngClass]="{'col-mob-6' : isSmallWindow}">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center">
                    <label class="p-2" for="Other">
                        <input type="radio" value="Other" formControlName="roofType" id="Other" class="radio-col-light-blue" />
                        <img [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_roof_dontknow.svg'" altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">{{'NOT_SURE' | translate}}</p>
                </mat-card>
            </div>
        </div>
    </div>
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>
    <div class="text-center p-b-40" *ngIf="isSmallWindow">
            <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
                type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
            <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
                *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
        </div>
        <div class="text-center p-b-40" *ngIf="!isSmallWindow">
            <button class="btn btn-info custom-button-info m-r-10 m-b-20"
                *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
            <button class="btn btn-info custom-button-info m-l-10 m-b-20"
                type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        </div>
</form>