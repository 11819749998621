<div class="row">
    <div class="col-md-8 offset-md-2 p-b-40"> 
            <div [innerHTML]="coverageMessage" style="text-align:center;"  [ngStyle]="styling?.pageTitle"></div>
    </div>
</div>
<div class="col-12 form-group">
    <div class="text-center p-b-40">
        <button class="btn btn-info custom-button-info m-l-10 m-b-20" type="button"
            *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="navigateToNextPage('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{ this.next || 'NEXT' | translate}}</button>
    </div>
</div>
