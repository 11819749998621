<form [formGroup]="houseRoofMaterialForm" *ngIf="quoteObj?.pageID == 'roof-material'">
    <div class="form-group row">
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center">
                    <label for="roofMaterialsMapping.asphaltShingle.value">
                        <input type="radio" value="{{roofMaterialsMapping.asphaltShingle.value}}" formControlName="roofMaterial" id="roofMaterialsMapping.asphaltShingle.value" class="radio-col-light-blue" />
                        <img [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_roof_asphalt_shingle.svg'" altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">{{roofMaterialsMapping.asphaltShingle.displayValue}}</p>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20" >
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center">
                    <label for="roofMaterialsMapping.tile.value">
                        <input type="radio" value="{{roofMaterialsMapping.tile.value}}" formControlName="roofMaterial" id="roofMaterialsMapping.tile.value" class="radio-col-light-blue" />
                        <img [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_roof_tile.svg'" altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">{{roofMaterialsMapping.tile.displayValue}}</p>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center">
                    <label for="roofMaterialsMapping.metal.value">
                        <input type="radio" value="{{roofMaterialsMapping.metal.value}}" formControlName="roofMaterial" id="roofMaterialsMapping.metal.value" class="radio-col-light-blue" />
                        <img [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_roof_metal.svg'" altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">{{roofMaterialsMapping.metal.displayValue}}</p>
                </mat-card>
            </div>
        </div>
        <!-- <div class="col-lg-3 p-b-20" [ngClass]="{'col-mob-6' : isSmallWindow}">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center">
                    <label for="roofMaterialsMapping.other.value">
                        <input type="radio" value="{{roofMaterialsMapping.other.value}}" formControlName="roofMaterial" id="roofMaterialsMapping.other.value" class="radio-col-light-blue" />
                        <img [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_roof_other.svg'" altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">{{roofMaterialsMapping.other.displayValue}}</p>
                </mat-card>
            </div>
        </div> -->
    </div>
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>
    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
    <button class="btn btn-info custom-button-info m-r-10 m-b-20"
        *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
        [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    <button class="btn btn-info custom-button-info m-l-10 m-b-20"
        type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
        [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
</div>
</form>