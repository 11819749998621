<form [formGroup]="yesNoForm">

    <div class="col-12 m-b-20">
        <div class="col-md-4 offset-md-4 d-flex">
            <div class="text-center" style="width: 100%;">
                <input type="radio" value=true id="yes" formControlName="yesNo" class="radio-col-light-blue" />
                <label [ngStyle]="styling?.fieldValueCheckBox" for="yes"
                    [ngStyle]="styling?.quoterWizard?.quoteSubTitle">Yes</label>
            </div>
            <div class="text-center" style="width: 100%;">
                <input type="radio" value=false id="no" formControlName="yesNo" class="radio-col-light-blue" />
                <label [ngStyle]="styling?.fieldValueCheckBox" for="no"
                    [ngStyle]="styling?.quoterWizard?.quoteSubTitle">No</label>
            </div>

        </div>
    </div>

    <div [ngStyle]="styling?.quoterWizard?.helpText" style="text-align: center;"> {{this.helpText}} </div>

    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>

    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6" type="button"
            *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{ this.next || 'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-10 m-b-20" *ngIf="quoteObj?.buttonOptions?.includes('Back')"
            type="button" (click)="submitForm('back')" [ngStyle]="styling?.button?.buttonNegative">{{'BACK' |
            translate}}</button>
        <button class="btn btn-info custom-button-info m-l-10 m-b-20" type="button"
            *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{ this.next || 'NEXT' | translate}}</button>
    </div>

</form>