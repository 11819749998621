<div class="col-12 form-group" *ngIf="page.toLowerCase().includes('knock-out')">
    <div class="row">
        <div class="col-md-4 offset-md-4">
        </div>
    </div>
    <div *ngIf="hyperLink" style="text-align: center;">
        <div [innerHTML]="hyperLink | safeHtml"></div>
    </div>
    <div class="col-12 form-group" style="text-align: center;">
        <button class="btn btn-info custom-button-info m-b-20" *ngIf="quoteObj?.buttonOptions?.includes('phone')"
            [ngClass]="{'col-lg-6': isSmallWindow, 'm-r-20': !isSmallWindow}" type="button"
            (click)="navTo('phone','tel:'+ accountConfig.quote.quotePhone)"
            [ngStyle]="styling?.button?.buttonPositive">{{accountConfig.quote.quotePhone}}</button>

        <button class="btn btn-info custom-button-info m-b-20" *ngIf="quoteObj?.buttonOptions?.includes('quoteForm')"
            [ngClass]="{'col-lg-6': isSmallWindow}" type="button" (click)="navTo('form',accountConfig.quote.quoteForm)"
            [ngStyle]="styling?.button?.buttonPositive">{{'QUOTE_FORM' | translate}}</button>
        <div class="text-center p-b-40">
            <button class="btn btn-info custom-button-info" [ngClass]="{'col-lg-6': isSmallWindow}"
                *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
        </div>
    </div>


</div>