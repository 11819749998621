import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/internal/operators/map';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-payroll-deduct',
  templateUrl: './payroll-deduct.component.html',
  styleUrls: ['./payroll-deduct.component.scss']
})
export class PayrollDeductComponent implements OnInit {


  payRollDeductForm: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any;
  pageID: any;
  isSmallWindow = window.innerWidth <= 768;
  next: any;
  helpText: any;
  directPayText: any;
  payrollDeductText: any;
  tenant: any;
  policyNumber: any;
  documentPreference: any;
  paymentOption: any;
  propertyUsage: any;

  constructor(
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute,
    private http: CommonApiService,
    private notifyToast: NotifytoastService
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.tenant = this.utilsService.getTenantFromWindow();
    this.policyNumber = this.quotedataService.getQuoteID();
    this.documentPreference = this.quoteObj.documentPreference;
    this.initForm()
    this.getFromSharedData()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm() {
    this.pageID = this.quoteObj.pageID
    this.payRollDeductForm = this.formBuilder.group({
      payrollSelection: ["false"]
    });
    this.helpText = this.quoteObj.helpText;
    this.next = this.quoteObj.Next ? this.quoteObj.Next : null;
    this.directPayText = this.quoteObj.directPayText;
    this.payrollDeductText = this.quoteObj.payrollDeductText;
    this.propertyUsage= this.getFormValue('residence-type', 'propertyUsage');
    if (this.quoteObj && this.quoteObj.defaultReply) {
      this.payRollDeductForm.patchValue({
        payrollSelection: this.quoteObj.defaultReply
      })
    }
  }

  onClick(eve) {
    this.payRollDeductForm.patchValue({
      payrollSelection: eve
    });
  }

  getFormValue(formName, value) {
    if (this.quoteObj.conversation[formName]) {
      return this.quoteObj.conversation[formName][value]
    } else {
      return null
    }
  }

  
  bindPolicy() {
    let input = {};
    input = {
      "accountCode": this.tenant,
      "creditCard": false,
      "achAccount": false,
      "quoteNumber": this.policyNumber,
      "documentPreference": this.documentPreference,
      "paymentOption": {
        "paymentMethod": this.paymentOption.paymentMethod,
        "billType": this.paymentOption.billType,
        "billPlanIdentifier": this.paymentOption.billPlanIdentifier
      },
      "email": this.quotedataService.getEmail(),
      "phoneNumber": this.quotedataService.getPhoneNumber(),
      "fullName":this.quotedataService.getInsuredName().firstName + " " + this.quotedataService.getInsuredName().lastName
    }
    return this.http.post('bindPolicy', input, false, true)
      .pipe(
        map((resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp?.root?.Envelope?.Body?.Fault?.faultstring);
          } else {
            console.log("result from bind policy: " + JSON.stringify(resp))
            return resp.out;
          }
        },
          (error) => {
            this.notifyToast.error('Please Contact System Administrator.');
          }
        ));
  }

  getFromSharedData() {
    const data = {
      accountCode: this.tenant,
      billType: "MortgageBill",
      feeAmount: this.quotedataService.getPolicyFees(),
      feeType: "policyFee",
      policyNum: this.quotedataService.getQuoteID(),
      policyType: "NewBusiness",
      productIdentifier: this.quotedataService.getAcntConfig()["quote"]["productDetails"]["productIdentifier"],
      state: this.quotedataService.getAddressObj().stateCode,
      termStartDate: this.getEffectiveDate(),
      termPeriod: "12",
      termUnit: "Months",
      totalPremium: this.quotedataService.getEstimatedPremium()
    }
    this.http.post('getPaymentOptions', data, true, true)
      .subscribe(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp?.root?.Envelope?.Body?.Fault?.faultstring);
          } else {
            // Per requirement, payment plan will be monthly pay and payroll deduct
            for (let i = 0; i < resp.out.billPlans.BillPlan.length; i++) {
              if (resp.out.billPlans.BillPlan[i]["numberOfInstallments"] == 1) {
                this.paymentOption = resp.out.billPlans.BillPlan[i];
                console.log(this.paymentOption)
                break;
              }
            }
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }
  getEffectiveDate() {
    let result = new Date();
    result.setDate(result.getDate() + 1);
    return result.toLocaleDateString('en-US');
  }

  submitForm(userSelection) {
    let temp;
    if (this.payRollDeductForm.valid && userSelection == 'next') {
      temp = {
        input: 'Next',
        pageID: this.pageID,
        userInput: this.payRollDeductForm.value,
      };
      if (this.payRollDeductForm.value.payrollSelection === "true") {
        this.bindPolicy().subscribe(
          (resp) => {
            if (resp != undefined && resp.policyNumber != undefined) {
              this.quotedataService.setPolicyNumber(resp.policyNumber);
              if (temp) {
                this.dataTransferService.sendData(temp);
              }
            } else {
              this.notifyToast.error(
                'Please Contact System Administrator.' +
                '\n' +
                resp.root.Envelope.Body.Fault.faultstring
              );
            }

          });
      } else {
        if (temp) {
          this.dataTransferService.sendData(temp);
        }
      }
    } else if (userSelection == 'back') {
      temp = { input: 'Back', pageID: this.pageID };
      this.dataTransferService.sendData(temp);
    }
  }

  submitBackToSummary(eve) {
    if (this.payRollDeductForm.valid) {
      let temp = eve
      temp.userInput = this.payRollDeductForm.value
      temp.currentPageID = this.pageID;
      this.dataTransferService.sendData(temp)
    }
  }

}
