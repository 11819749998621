import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-knock-out',
  templateUrl: './knock-out.component.html',
  styleUrls: ['./knock-out.component.scss']
})

export class KnockOutComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any;
  page: any;
  accountConfig: any;
  isSmallWindow = window.innerWidth <= 768;
  hyperLink: any;

  constructor(
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.page = this.route.snapshot.routeConfig.path
  }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.accountConfig = this.quotedataService.getAcntConfig()
    this.hyperLink = this.quoteObj.hyperLink;
    if (this.quoteObj.navigateTo) {
      window.open(this.quoteObj.navigateTo, "_self");
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  navTo(type, endPoint) {
    if (type == 'phone') window.location.href = endPoint;
    else window.open(endPoint);

  }

  submitForm(userSelection) {
    let temp;
    if (userSelection == 'back') {
      temp = { input: 'Back', pageID: this.quoteObj.pageID }
    }
    if (temp) {
      this.dataTransferService.sendData(temp)
    }
  }

}