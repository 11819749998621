import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../../shared/services/utils.service';

@Component({
  selector: 'app-rental-term',
  templateUrl: './rental-term.component.html',
  styleUrls: ['./rental-term.component.scss']
})
export class RentalTermComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any
  rentalTermForm: any;
  isSmallWindow = window.innerWidth <= 768;
  parentTenant: any;
  rentalTermList: any;

  constructor(
    private formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.rentalTermList = this.quotedataService.getMappingInfoObj().rentalTermList;
    this.initForm()
    this.fromAPI()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm(){
    this.rentalTermForm = this.formBuilder.group({
      rentalTerm: ['Daily or Nightly']
    });
  }

  fromAPI(){
    if(this.quoteObj.conversation && this.quoteObj.conversation['rental-term']) 
      this.rentalTermForm.patchValue(this.quoteObj.conversation['rental-term'])
  }

  submitForm(userSelection){
    let temp;
    if(this.rentalTermForm.valid && userSelection == 'next'){
        temp = { input: 'Next', pageID: 'rental-term', userInput: this.rentalTermForm.value }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'rental-term' }
    }
    if(temp){
      // console.log(temp)
      // this.submitFormObj.emit(temp)
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve){
    if (this.rentalTermForm.valid) {
      let temp = eve
      temp.userInput = this.rentalTermForm.value
      temp.currentPageID = 'rental-term'
      this.dataTransferService.sendData(temp)
    }
  }

}
