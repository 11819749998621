import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-prior-loss',
  templateUrl: './prior-loss.component.html',
  styleUrls: ['./prior-loss.component.scss']
})
export class PriorLossComponent implements OnInit {
  priorLossForm: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any;
  isSmallWindow = window.innerWidth <= 768;
  
  constructor(
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.initForm()
    this.fromAPI()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm(){
    this.priorLossForm = this.formBuilder.group({
      priorLoss: ['false']
    });
  }

  fromAPI(){
    if(this.quoteObj.conversation && this.quoteObj.conversation['prior-loss']) 
      this.priorLossForm.patchValue(this.quoteObj.conversation['prior-loss'])
  }


  onClick(eve) {
    this.priorLossForm.patchValue({
      priorLoss : eve
    });
  }

  submitForm(userSelection){
    let temp;
    if(this.priorLossForm.valid && userSelection == 'next'){
        temp = { input: 'Next', pageID: 'prior-loss', userInput: this.priorLossForm.value }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'prior-loss' }
    }
    if(temp){
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve){
    if (this.priorLossForm.valid) {
      let temp = eve
      temp.userInput = this.priorLossForm.value
      temp.currentPageID = 'prior-loss'
      this.dataTransferService.sendData(temp)
    }
  }

}

