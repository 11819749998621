import { Component, EventEmitter, HostListener, Input, OnInit, Output, ɵConsole } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-house-built-form',
  templateUrl: './house-built-form.component.html',
  styleUrls: ['./house-built-form.component.scss']
})
export class HouseBuiltFormComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  houseBuiltForm: any;
  // @Input() dataObj: any;
  // @Output() submitFormObj = new EventEmitter()
  isSubmitted = false;
  //stories: any;
  quoteObj: any;
  maxYearError = ''
  isSmallWindow = window.innerWidth <= 768;
  // stories = ["1","2","3","4","5","6","7+"]
  
  constructor(
    private formBuilder: FormBuilder, 
    private router: Router, 
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
    ) {
      
    }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.initForm()
    this.fromAPI()
    //this.stories = this.quotedataService.getBuildingObject().stories
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }
  
  // Validators.max(100000),
  initForm(){
    this.houseBuiltForm = this.formBuilder.group({
      yearBuilt: [''],
      squareFeet: ['', [Validators.required,  Validators.min(1)]],
      
    })
  }

  fromAPI(){
    if(this.quoteObj.conversation && this.quoteObj.conversation['house-built']) 
      this.houseBuiltForm.patchValue(this.quoteObj.conversation['house-built'])
      this.updateValidator()
  }

  updateValidator(){
    let roofYear = this.getFormValue('Roof-Built' , 'roofYear')
    if(roofYear){
      this.houseBuiltForm.get('yearBuilt').setValidators([Validators.required, Validators.max(roofYear), Validators.min(1650)]);
      this.houseBuiltForm.get('yearBuilt').updateValueAndValidity()
      this.maxYearError = 'Year Built should be less than Roof Year'
    } else{
      this.houseBuiltForm.get('yearBuilt').setValidators([Validators.required,  Validators.min(1650), Validators.max((new Date()).getFullYear())]);
      this.houseBuiltForm.get('yearBuilt').updateValueAndValidity()
      this.maxYearError = 'Year Built should be less than Current Year'
    }
  }

  navigateToLink(page) {
    const tenant = this.utilsService.getTenantFromWindow()
    this.router.navigate([`${tenant}/${page}`]);
  }

  submitForm(userSelection){
    let temp;
    this.isSubmitted = true;
    if(this.houseBuiltForm.valid && userSelection == 'next'){
      temp = { 
        input: 'Next',
        pageID: 'house-built',
        userInput: this.houseBuiltForm.value
      }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'house-built' }
    }
    if(temp){
      // console.log(temp)
      // this.submitFormObj.emit(temp)
      this.dataTransferService.sendData(temp)
    }
  }

  getFormValue(formName, value){
    if(this.quoteObj.conversation[formName]){
      return this.quoteObj.conversation[formName][value]
    } else{
      return null
    }
  }

  submitBackToSummary(eve){
    this.isSubmitted = true;
    if (this.houseBuiltForm.valid) {
      let temp = eve
      temp.userInput = this.houseBuiltForm.value
      temp.currentPageID = 'house-built'
      this.dataTransferService.sendData(temp)
    }
  }

}
