import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-number-of-stories',
  templateUrl: './number-of-stories.component.html',
  styleUrls: ['./number-of-stories.component.scss']
})
export class NumberOfStoriesComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  numberOfStoriesForm: any;
  isSubmitted = false;
  quoteObj: any;
  
  stories: any;
  tenant: any;
  isSmallWindow = window.innerWidth <= 768;
  
  constructor(
    private formBuilder: FormBuilder, 
    private router: Router, 
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.stories = this.quotedataService.getMappingInfoObj().stories
    this.initForm()
    this.fromAPI()
    }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }


  initForm(){
    this.numberOfStoriesForm = this.formBuilder.group({
      story: ['1', [Validators.required]]
    })
  }

  fromAPI(){
    if(this.quoteObj?.conversation[this.quoteObj?.pageID]?.story) {
      if(this.stories.includes((this.quoteObj.conversation[this.quoteObj.pageID].story)))
      this.numberOfStoriesForm.patchValue({story: this.quoteObj.conversation[this.quoteObj.pageID].story})
    }}

  navigateToLink(page) {
    const tenant = this.utilsService.getTenantFromWindow()
    this.router.navigate([`${tenant}/${page}`]);
  }

  submitForm(userSelection){
    let temp;
    this.isSubmitted = true;
    if(this.numberOfStoriesForm.valid && userSelection == 'next'){
      temp = { 
        input: 'Next',
        pageID: this.quoteObj.pageID,
        userInput: this.numberOfStoriesForm.value
      }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: this.quoteObj.pageID }
    }
    if(temp){
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve){
    this.isSubmitted = true;
    if (this.numberOfStoriesForm.valid) {
      let temp = eve
      temp.userInput = this.numberOfStoriesForm.value
      temp.currentPageID = this.quoteObj.pageID
      this.dataTransferService.sendData(temp)
    }
  }

}