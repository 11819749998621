import { Component, OnInit } from '@angular/core';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { formatCurrency, DatePipe } from '@angular/common';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from "lodash";
import { LatestGetQuoteDemoService } from '../latest-get-quote-demo.service';

@Component({
  selector: 'app-quote-summary-spe',
  templateUrl: './quote-summary-spe.component.html',
  styleUrls: ['./quote-summary-spe.component.scss']
})
export class QuoteSummarySpeComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  page: any;
  quoteObj: any;
  progressNumber: any;
  interval: any;
  loading: any;
  insuranceTypeValue: any;
  tenant: any;
  isSubmitted: any;
  selectedAgentDetails: any;
  defaultAgentID: any;
  mapForCoverageNames: any;
  state: any;
  policyType: any;
  iscoverageCValue: any;
  summaryData: any;
  botMessage = [
    'Ready to Quote',
    'Please give me a moment while I find the best policy coverages and a price for you'
  ];

  constructor(
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService,
    private datePipe: DatePipe,
    private getQuoteService: LatestGetQuoteDemoService,
    private quoteDataShare: QuoteDataShare
  ) {
    this.page = this.route.snapshot.routeConfig.path
  }
  ngOnInit(): void {
    this.tenant = this.utilsService.getTenantFromWindow();
    this.quoteObj = this.quotedataService.getConfiguration();
    this.selectedAgentDetails = this.quotedataService.getEmailQuote();
    this.insuranceTypeValue = this.quotedataService.getAcntConfig()["quote"]["productDetails"]["insuranceType"];
    this.policyType = this.quotedataService.getAcntConfig()["quote"]["productDetails"]["policyForm"];
    this.defaultAgentID = (this.quotedataService.getMappingInfoObj().defaultAgentID == undefined || this.quotedataService.getMappingInfoObj().defaultAgentID == null) ? '3245336' : this.quotedataService.getMappingInfoObj().defaultAgentID
    this.state = this.quotedataService.getAddressObj().stateCode;
    this.iscoverageCValue = (this.quotedataService.getMappingInfoObj().coverageCOrCoverageCtoCoverageA == undefined || this.quotedataService.getMappingInfoObj().coverageCOrCoverageCtoCoverageA == null) ? false : this.quotedataService.getMappingInfoObj().coverageCOrCoverageCtoCoverageA.includes(this.policyType)
    const address = this.quotedataService.getRiskAddressObj();
    const unitNumber = this.getFormValue('property-location', 'unitNumber')
    const cleanseAddress = address.street + (unitNumber ? " " : "") + unitNumber + ", " + address.city + ", " + address.stateCode + ", " + address.zipCode;
    this.summaryData = {
      structureType: this.getFormValue('structure-type', 'checkHouse'),
      priorLoss: (this.getFormValue('prior-loss', 'priorLoss') == "false")? "No": this.getFormValue('number-of-claims', 'claims'),
      policyStartDate: new Date(this.getFormValue('policy-start-date', 'startDate')).toLocaleDateString('en-US'),
      address: cleanseAddress
    }

  }

  rateAQuoteObjectStructure() {
    const data = {
      accountCode: this.tenant,
      phoneNumber: {
        number: null,
        type: null,
      },
      insuranceType: this.insuranceTypeValue,
      policyEffectiveDate: new Date(this.getFormValue('policy-start-date', 'startDate')).toLocaleDateString('en-US'),
      propertyAddress: this.getPropAddress(),
      mailingAddress: this.getPropAddress(),
      propertyStructureType: this.getFormValue('structure-type', 'checkHouse'),
      coverageAValue: this.quotedataService.getMappingInfoObj().coverageDefaultValue[this.policyType]?.coverageAValue,
      agentID: (this.selectedAgentDetails == undefined || this.selectedAgentDetails == null) ? this.defaultAgentID : this.selectedAgentDetails.agentID
    };
    this.quotedataService.setEffectiveDate(data.policyEffectiveDate);
    this.quotedataService.setQuoteObjectStructure(data)
    console.log("rate quote request: " + JSON.stringify(data))
    return data;
  }

  getFormValue(formName, value) {
    if (this.quoteObj.conversation[formName]) {
      return this.quoteObj.conversation[formName][value]
    } else {
      return null
    }
  }

  redirectPageOnClick(navigateTo) {
    let temp = {
      "input": "navigateToPage",
      "pageID": navigateTo,
    }
    this.quoteDataShare.setSummaryPageBtnDisplay(true)
    this.dataTransferService.sendData(temp)
  }

  submitForm(userSelection) {
    let temp;
    if (userSelection == 'submit') this.calculatePremium()
    else if (userSelection == 'back') {
      temp = { input: 'Back', pageID: this.quoteObj.pageID, userInput: {} }
      this.dataTransferService.sendData(temp)
    }
  }

  fakeLoader() {
    this.progressNumber = 0
    this.startTimer()
  }

  startTimer() {
    this.interval = window.setInterval(() => {
      if (this.progressNumber < 98) this.progressNumber++
      else if (!this.loading) this.stopTimer(100)
      else this.stopTimer(100)
    }, 300)
  }

  stopTimer(time) {
    window.clearInterval(this.interval)
    window.clearTimeout(this.interval)
  }

  calculatePremium() {
    this.loading = true;
    this.fakeLoader()
    this.submitRateQuote(this.rateAQuoteObjectStructure()).subscribe(
      (resp) => {
        if (resp === undefined) {
          console.log('No response returned');
          //this.notifyToast.error('Please Contact System Administrator.');
        } else {
          this.isSubmitted = true;
          const basicResponse = resp;
          this.applyResponseToCoverageValues(basicResponse);
          const estimatedPremium = basicResponse.rate.estimatedPremium
          this.quotedataService.setEstimatedPremium(estimatedPremium);
          const estimatedPremiumTax = basicResponse.rate.taxes;
          this.quotedataService.setPremiumTax(estimatedPremiumTax);
          // this.quotedataService.setPolicyFees(resp.rate.policyFees)
          this.getListOfCoverageNamesMapping()
          console.log("rate quote response: " + JSON.stringify(resp));
        }
      });
  }
  getListOfCoverageNamesMapping() {
    this.httpService
      .get(`getListOfCoverageNamesMapping?accountCode=${this.tenant}`, null, true)
      .subscribe(
        (resp) => {
          if (resp === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          } else {
            this.mapForCoverageNames = this.getQuoteService.objectToMap(resp);
            this.setCoverageNames()
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }
  setCoverageNames() {
    let listOfCoveragesUnderState;
    if (this.mapForCoverageNames.has(this.state)) {
      listOfCoveragesUnderState = this.mapForCoverageNames.get(this.state);
      if (listOfCoveragesUnderState.has(this.policyType)) {
        let curCoverageNames = listOfCoveragesUnderState.get(this.policyType).get("Coverages");
        let curDeductibleNames = listOfCoveragesUnderState.get(this.policyType).get("Deductibles");
        let curInsuranceScore = listOfCoveragesUnderState.get(this.policyType).get("InsuranceScore");
        let curOptionalCoverages = listOfCoveragesUnderState.get(this.policyType).get("OptionalCoverages");
        let curDiscount = listOfCoveragesUnderState.get(this.policyType).get("Discount");
        let setCoverageNameObj = { curCoverageNames, curDeductibleNames, curInsuranceScore, curOptionalCoverages, curDiscount }
        this.quotedataService.setCoverageNameObj(setCoverageNameObj);
        const globalPolicyObj = {
          policyType: this.policyType,
          capacityStatus: 'Open',
        };
        this.quotedataService.setPolicyObjData(globalPolicyObj);
        let temp = {
          input: 'Next', pageID: this.quoteObj.pageID, userInput: {
            "capacity": 'true',
            "eligibility": 'true',
            "rate_quote_error" : false
          }
        }
        this.dataTransferService.sendData(temp)
      } else {
        console.log("No coverage names based on the current policy type.")
        this.notifyToast.error('Please Contact System Administrator.');
      }
    } else {
      console.log("No coverage names in this state: " + this.state);
      this.notifyToast.error('Please Contact System Administrator.');
    }
  }

  applyResponseToCoverageValues(response) {
    if (response != null) {
      const coverageFormObj = {
        coverageAValue: response.coverageAValue !== null ? formatCurrency(response.coverageAValue, "en_US", "$", "USD") : null,
        coverageBValue: response.coverageBValue,
        coverageCValue: this.iscoverageCValue ? response.recommendedPercentageOfCoverageCtoCoverageA : formatCurrency(response.coverageCValue, "en_US", "$", "USD"),
        coverageDValue: response.coverageDValue,
        coverageEValue: response.coverageEValue,
        coverageFValue: response.coverageFValue,
        aopDeductible: response.aopDeductible,
        deductibleAppliedForHurricaneCoverage: response.deductibleAppliedForHurricaneCoverage,
        waterDeductible: response.waterDeductible,
        coverageLValue: response.coverageLValue,
        coverageMValue: response.coverageMValue,
      }
      this.quotedataService.setCoverageObj(coverageFormObj)
    }
  }

  getPropAddress() {

    let currentSelectedAddress = this.quotedataService.getAddressObj().currentSelectedAddress
    const tempData = {
      street:
        (currentSelectedAddress['street_number'] &&
          currentSelectedAddress['street_number']['long_name']) +
        ' ' +
        (currentSelectedAddress['route'] && currentSelectedAddress['route']['long_name']) +
        ' ' +
        this.getFormValue('property-location', 'unitNumber'),
      city: currentSelectedAddress['city']['long_name'],
      stateCode: currentSelectedAddress['state']['short_name'],
      zip:
        currentSelectedAddress['postal_code'] &&
        currentSelectedAddress['postal_code']['long_name'],
      latitude: this.quotedataService.getRiskAddressObj()["lat"],
      longitude: this.quotedataService.getRiskAddressObj()["lng"]
    };
    return tempData;
  }

  submitRateQuote(data): Observable<any> {
    return this.httpService.post('rateQuote', data, false, true).pipe(
      map((resp) => {
        if (resp.out === undefined) {
          this.notifyToast.error(`${resp.root.Envelope.Body.Fault.faultstring}`);
          let temp = {
            input: 'Next',
            pageID: this.quoteObj.pageID,
            userInput: { 'rate_quote_error': true }
          }
          this.dataTransferService.sendData(temp)
          // if(this.stepper.selectedIndex === 10){
          // if (this.page == 'Quote-Summary') {
          //   this.loading = false
          //   this.utilsService.confirmModalPopup(`${resp.root.Envelope.Body.Fault.faultstring}`)
          //   // this.stepper.previous()
          // } else {
          //   this.notifyToast.error(
          //     'Please Contact System Administrator.' +
          //     '\n' +
          //     resp.root.Envelope.Body.Fault.faultstring
          //   );
          // }
        } else {
          return resp.out;
        }
      })
    );
  }

}
