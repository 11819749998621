<div class="col-12 form-group" *ngIf="!loading">
    <div class="row" style="justify-content: center;">
        <!-- <h4 class="col-md-4 offset-md-4 text-center p-b-40 p-t-20">
            Are you sure you want to submit this form?
        </h4> -->
        <h4 class="card-title">
            <app-bot-message [message]="botMessage[0]"></app-bot-message>
        </h4>
    </div>
    <div class="col-12 form-group">
        <div  class="text-center p-b-40">
            <button class="btn btn-info custom-button-info m-r-20" type="button"
                (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
            <button class="btn btn-info custom-button-info" type="button"
                (click)="submitForm('submit')"
                [ngStyle]="styling?.button?.buttonPositive">{{'SUBMIT' | translate}}</button>
        </div>
    </div>
</div>

<div class="col-12" *ngIf="loading">
    <div class="p-b-20">
        <!-- <div class="text-center">
            <div class="message-bot-display" [ngStyle]="styling?.card?.attributeValue">
                Please give me a moment while I find the best policy coverages and a price for you
            </div>
        </div> -->
        <div class="row" style="justify-content: center;">
            <h4 class="card-title">
                <app-bot-message [message]="botMessage[1]"></app-bot-message>
            </h4>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <div class="col-sm-8" style="margin-top: 100px;margin-bottom: 100px;">
            <div class="pipe-container">
                <div class="pipe-loader" [style.width.%]="progressNumber">
                    {{progressNumber}}%
                </div>
            </div>
        </div>
    </div>
</div>