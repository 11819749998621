import { throwError as observableThrowError, Observable } from 'rxjs'
import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map, catchError } from 'rxjs/operators'
import { SpinnerVisibilityService } from 'ng-http-loader';

import { environment } from '../../../environments/environment'
import { Router } from '@angular/router';
import { NotifytoastService } from './notificationToast.service';
import { resolvePtr } from 'dns';

// @Injectable()
export class HttpService {
  private actionBaseUrl;
  private http;
  private loader;
  private router;
  private baseURL;
  private notifyToast;
  constructor(http: HttpClient, loader: SpinnerVisibilityService,@Inject('') baseURL: String, router: Router, notifyToast: NotifytoastService) {
    this.http = http;
    this.loader = loader;
    this.baseURL = baseURL;
    this.router = router;
    this.notifyToast = notifyToast;
  }

  getBaseUrl(url) {
    // return this.actionBaseUrl + url;
    return this.baseURL + url;
  }

  public get(url: string, param: any, showLoader: boolean): Observable<any> {
    this.requestInterceptor()
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      // AccessKey: 'AKIARAZ4XIJUQVEW6XHS',
      // SecretKey: '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'
    })
    const options = {
      headers,
      params: param
    }
    return this.http.get(this.getBaseUrl(url), options).pipe(
      map((data: any) => {
        this.responseInterceptor()
        return data.data || data;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public put(url: string, data: any, showLoader: boolean): Observable<any> {
    this.requestInterceptor()
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      // AccessKey: 'AKIARAZ4XIJUQVEW6XHS',
      // SecretKey: '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',
    })
    const options = {
      headers
    }
    return this.http.put(this.getBaseUrl(url), data, options).pipe(
      map((resp: any) => {
        this.responseInterceptor()
        return resp.data || resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public post(url: string, data: any, showLoader: boolean, noNeedAuth?: boolean): Observable<any> {
    if(showLoader) this.requestInterceptor()
    let headers;
    let dataObj;
    if(!noNeedAuth){
      const username = data.username;
      const password = data.password;
      dataObj = null;
      headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: "Basic " + btoa(`${username}:${password}`)
      })
    } else{
      headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      })
      dataObj = data
    }
    const options = {
      headers
    }
    return this.http.post(this.getBaseUrl(url), dataObj, options).pipe(
      map((resp: any) => {
        if(showLoader) this.responseInterceptor()
        return resp.data || resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public privateGet(url: string, param: any, showLoader: boolean): Observable<any> {
    if(showLoader) {
      this.requestInterceptor()
    }
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      Authorization: "Basic " + btoa(this.getUsernameToken())
    })
    const options = {
      headers,
      params: param
    }
    return this.http.get(this.getBaseUrl(url), options).pipe(
      map((data: any) => {
        if(showLoader)this.responseInterceptor()
        return data.data || data;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public privatePut(url: string, data: any, showLoader: boolean): Observable<any> {
    this.requestInterceptor()    
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      Authorization: "Basic " + btoa(this.getUsernameToken())
    })
    const options = {
      headers
    }
    return this.http.put(this.getBaseUrl(url), data, options).pipe(
      map((resp: any) => {
        this.responseInterceptor()
        return resp.data || resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public privatePost(url: string, data: any, showLoader: boolean): Observable<any> {
    this.requestInterceptor()
    const token = sessionStorage.getItem('user') ? 'Bearer ' + JSON.parse(sessionStorage.getItem('user')).idToken.jwtToken : null
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      Authorization: "Basic " + btoa(this.getUsernameToken())
    })
    const options = {
      headers
    }
    return this.http.post(this.getBaseUrl(url), data, options).pipe(
      map((resp: any) => {
        this.responseInterceptor()
        return (resp && resp.data) ? resp.data : resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  
  public changePasswordPut(url: string, data: any, oldPwd: string, showLoader: boolean): Observable<any> {
    this.requestInterceptor()
    const username = sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')).username : null
    
    const headers = new HttpHeaders({
      Accept: 'application/json',
        'Content-Type': 'application/json',
      Authorization: "Basic " + btoa(username+":"+oldPwd)
    })
    const options = {
      headers
    }
    return this.http.put(this.getBaseUrl(url), data, options).pipe(
      map((resp: any) => {
        this.responseInterceptor()
        return resp.data || resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public privateDelete(url: string, data: any, showLoader: boolean): Observable<any> {
    this.requestInterceptor()
    const token = sessionStorage.getItem('user') ? 'Bearer ' + JSON.parse(sessionStorage.getItem('user')).idToken.jwtToken : null
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      Authorization: token
    })
    const options = {
      headers
    }
    return this.http.delete(this.getBaseUrl(url), options).pipe(
      map((resp: any) => {
        this.responseInterceptor()
        return resp.data || resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  getBaseRadiusURL(url){
    // return `https://gzyd0zy8mk.execute-api.us-east-1.amazonaws.com/test/default/` + url
    return environment.agentFindUrl + url
  }

  public getRadiusSearch(url: string, param: any, showLoader: boolean): Observable<any> {
    if(showLoader) this.requestInterceptor()
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      // AccessKey: 'AKIARAZ4XIJUQVEW6XHS',
      // SecretKey: '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'
    })
    const options = {
      headers,
      params: param
    }
    return this.http.get(this.getBaseRadiusURL(url), options).pipe(
      map((data: any) => {
        if(showLoader) this.responseInterceptor()
        return data.data || data;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public privateUpload(url: string, data: any, showLoader: boolean): Observable<any> {
    // this.requestInterceptor()
    const token = sessionStorage.getItem('user') ? 'Bearer ' + JSON.parse(sessionStorage.getItem('user')).idToken.jwtToken : null
    const headers = new HttpHeaders({
      
      'enctype': 'multipart/form-data',
      'Accept': 'application/json',
      Authorization: "Basic " + btoa(this.getUsernameToken())
    })
    const options = {
      headers
    }
    return this.http.post(this.getBaseUrl(url), data, options).pipe(
      map((resp: any) => {
        // this.responseInterceptor()
        return (resp && resp.data) ? resp.data : resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  private handleError(error: any) {
    this.responseInterceptor()
    const errMsg = error.message
      ? error.message
      : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error'
    if (error.status === 401) {
      sessionStorage.clear()
      this.router.navigate(['/login']).then(() => {window.location.reload()})
      this.notifyToast.error('Your Session is Expired.')
      return observableThrowError(errMsg)
    } else {
      // return observableThrowError(errMsg)
      return observableThrowError(error)
    }
  }



  private requestInterceptor(): void {	  
    this.loader.show();
  }

  private responseInterceptor(): void {
    /*
	setTimeout(() => {
      
      this.loader.hide();
    }, 700);
*/	
    this.loader.hide();
  }

  getUsernameToken(){
    const tokenDetails = sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')) : null
    return `${tokenDetails.username}:${tokenDetails.token}`
  }

  transactionPostOld(url, data){
    this.requestInterceptor()
    // const token = sessionStorage.getItem('user') ? 'Bearer ' + JSON.parse(sessionStorage.getItem('user')).idToken.jwtToken : null
    const headers = new HttpHeaders({
      // Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/xml',
      // 'Access-Control-Allow-Origin': '*',
      'responseType': 'text'
      // Authorization: "Basic " + btoa(this.getUsernameToken())
    })
    const options = {
      headers
    }
    return this.http.post(url, data, options).pipe(
      map((resp: any) => {
        this.responseInterceptor()
        return (resp && resp.data) ? resp.data : resp;
      }),
      catchError(error => this.handleError(error))
    )
  }

  transactionPost(url, data) :Observable<any>{
    this.requestInterceptor()
    let isActualTrustCommerce = url.indexOf("https://vault.trustcommerce.com/") > -1
    return new Observable(observer => {
      var myHeaders = new Headers();
      // myHeaders.append()  
      fetch(url, {
        mode: isActualTrustCommerce ? 'no-cors': 'cors',
        method: 'POST',
        headers: myHeaders,
        body: data,
        })
      .then(response => response.text())
      .then(result => {
        console.log(result, "Fetch Response Success")
        this.responseInterceptor()
        let res = typeof(result) != 'string' && result != "" ? JSON.parse(result) : result
        observer.next(res)
        observer.complete()
      })
      .catch(error => {
        console.log(error, "Fetch Response Fail")
        this.responseInterceptor()
        const errMsg = error.message
          ? error.message
          : error.status
            ? `${error.status} - ${error.statusText}`
            : 'Payment Unsuccessful'
        observer.error(errMsg)
      });
    })
  }

  // public tempPost(url: string, data: any, showLoader: boolean, noNeedAuth?: boolean): Observable<any> {
  //   if(showLoader) this.requestInterceptor()
  //   let headers;
  //   let dataObj;
  //   headers = new HttpHeaders({
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //   })
  //   dataObj = data
  //   const options = {
  //     headers
  //   }
  //   return this.http.post(this.getQuoteUrl(url), dataObj, options).pipe(
  //     map((resp: any) => {
  //       if(showLoader) this.responseInterceptor()
  //       return resp.data || resp;
  //     }
  //     ),
  //     catchError(error => this.handleError(error))
  //   )
  // }

  // getQuoteUrl(url){
  //   return this.baseURL + "getQuoteInfo"
  //   // return 'https://insurbot-webchatsrv-uat.hostedinsurance.com/CCP/get-a-quote/Text/en/message/' + url
  // // return "https://insuredportal-uat-mw.hostedinsurance.com/default/getQuoteInfo"
  // // return "http://localhost:8080/default/getQuoteInfo"
  // // return "https://insuredportal-uat-mw.hostedinsurance.com/default/getQuoteInfo?accountCode=sfi&input=&currentStep=Welcome-Page"
  // }
}
