import { CurrencyPipe, formatCurrency } from '@angular/common';
import { Quote } from '@angular/compiler';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { DisplayPremiumService } from './display-premium.service';

@Component({
  selector: 'app-display-premium',
  templateUrl: './display-premium.component.html',
  styleUrls: ['./display-premium.component.scss'],
  providers: [DisplayPremiumService, CurrencyPipe]
})
export class DisplayPremiumComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any;
  tenant: any;
  isSubmitted = false;
  isSmallWindow = window.innerWidth <= 768;

  displayValue: {
    yearlyPremium: any,
    monthlyPremium: any
  };

  coverageValues: any;
  editCoveragesObj: any;
  coverageItemList: any;
  selectedAgentDetails: any;

  curCoverageNames: any;
  curDeductibleNames: any;
  curInsuranceScore: any;
  curOptionalCoverages: any;
  curDiscount: any;

  currentSelectedAddress: any;
  policyType: any;

  loading = false;
  progressNumber = 0
  interval: any;

  coverageA = false;
  coverageB = false;
  coverageC = false;
  coverageD = false;
  coverageE = false;
  coverageEPremisesLiab = false;
  coverageF = false;
  coverageL = false;
  coverageM = false;
  hurricane = false;
  aop = false;
  waterDamage = false;
  floodCoverage = false;
  insuranceScore = false;

  coverageAFieldName: any;
  coverageADescription: any;
  coverageBFieldName: any;
  coverageBDescription: any;
  coverageCFieldName: any;
  coverageCDescription: any;
  coverageDFieldName: any;
  coverageDDescription: any;
  coverageEFieldName: any;
  coverageEDescription: any;
  coverageEPremisesLiabFieldName: any;
  coverageEPremisesLiabDescription: any;
  coverageFFieldName: any;
  coverageFDescription: any;
  coverageLFieldName: any;
  coverageMFieldName: any;
  hurricaneFieldName: any;
  hurricaneDescription: any;
  aopFieldName: any;
  aopDescription: any;
  waterDamageFieldName: any;
  waterDamageDescription: any;
  defaultAgentID: any;
  floodCoverageFieldName: any;
  floodCoverageDescription: any;
  floodCoverageFiledValue: any;
  discountDescription: any;
  discountTitle: any;
  optionalCoverageTitle: any;
  insuranceScoreTitle: any;
  insuranceScoreTitleValue: any;
  insuranceScoreDes: any;
  insuranceScoreDesSecond: any;
  insuranceScoreDecTooltip: any;
  insuranceScoreList: string[] = [];
  insuranceScoreObjList: any;

  structureType: any;
  insuranceTypeValue: any;

  iscoverageCValue: false;
  botMessage = [
    'Ready to Quote',
    'Please give me a moment while I find the best policy coverages and a price for you'
  ]

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private dataTransferService: DataTransferService,
    private quotedataService: QuoteDataShare,
    private notifyToast: NotifytoastService,
    private httpService: CommonApiService,
    private displayPremiumService: DisplayPremiumService
  ) { }

  ngOnInit(): void {
    this.initForm()
    this.quoteObj = this.quotedataService.getConfiguration()
    this.tenant = this.utilsService.getTenantFromWindow()
    this.policyType = this.quotedataService.getPolicyObjData().policyType
    this.getConstrainedValues()
    // this.coverageItemList = this.quotedataService.getCoverageItemList()
    this.insuranceTypeValue = (this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote == undefined || this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote == null) ?  this.policyType: this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote[this.policyType]
    this.curCoverageNames = this.quotedataService.getCoverageNameObj().curCoverageNames
    this.curDeductibleNames = this.quotedataService.getCoverageNameObj().curDeductibleNames
    this.curInsuranceScore = this.quotedataService.getCoverageNameObj().curInsuranceScore
    this.curOptionalCoverages = this.quotedataService.getCoverageNameObj().curOptionalCoverages
    this.curDiscount = this.quotedataService.getCoverageNameObj().curDiscount
    this.currentSelectedAddress = this.quotedataService.getAddressObj().currentSelectedAddress
    this.iscoverageCValue = (this.quotedataService.getMappingInfoObj().coverageCOrCoverageCtoCoverageA == undefined || this.quotedataService.getMappingInfoObj().coverageCOrCoverageCtoCoverageA == null) ?  false : this.quotedataService.getMappingInfoObj().coverageCOrCoverageCtoCoverageA.includes(this.policyType)

    this.convertStructureType();
    this.setPremium()
    this.setCoverageValue()
    this.setCoverageBasedOnPolicyType();
    this.selectedAgentDetails = this.quotedataService.getEmailQuote()
    this.defaultAgentID = (this.quotedataService.getMappingInfoObj().defaultAgentID == undefined || this.quotedataService.getMappingInfoObj().defaultAgentID == null) ? '3245336' : this.quotedataService.getMappingInfoObj().defaultAgentID
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  convertStructureType() {
    this.structureType = this.quotedataService.getMappingInfoObj().structureTypeConvertValueForRateQuote[this.getFormValue('structure-type', 'checkHouse')]
    // console.log(this.structureType)
  }

  setPremium() {
    let monthlyFee = JSON.stringify(this.quotedataService.getEstimatedPremium() / 12)
    this.displayValue = {
      yearlyPremium: this.utilsService.convertNumberToIS(this.quotedataService.getEstimatedPremium()),
      monthlyPremium: this.utilsService.convertNumberToIS(parseFloat(monthlyFee).toFixed(2))
    }
  }

  setCoverageValue() {
    this.editCoveragesObj = this.quotedataService.getCoverageObj()
    this.coverageValues.patchValue(this.editCoveragesObj)
  }

  initForm() {
    this.coverageValues = this.formBuilder.group({
      coverageAValue: [''],
      coverageBValue: [''],
      coverageCValue: [''],
      coverageDValue: [''],
      coverageEValue: [''],
      coverageEPremisesLiabValue: [''],
      coverageFValue: [''],
      aopDeductible: [''],
      deductibleAppliedForHurricaneCoverage: [''],
      waterDeductible: ['Limited'],
      coverageLValue: [''],
      coverageMValue: [''],
      includeFloodPremium: false,
      estimatedCreditScore: ['Average'],
      // seniorDiscount: false
    })
  }

  navigateToLink(page) {
    this.router.navigate([`${this.tenant}/get-quote/${page}`]);
  }

  submitForm(userSelection) {
    let temp;
    this.isSubmitted = true;
    //'quote-premium'
    if (userSelection == 'next') {
      this.finalizeQuote()
      temp = {
        input: 'Next',
        pageID: this.quoteObj.pageID,
        userInput: this.coverageValues.value
      }
    }

    else if (userSelection == 'back') {
      this.resetCoverageValuesAndQuoteID();
      temp = { input: 'Back', pageID: this.quoteObj.pageID }
    }
    if (temp) {
      this.dataTransferService.sendData(temp)
    }
  }

  resetCoverageValuesAndQuoteID() {
    const coverageFormObj = {
      coverageAValue: "",
      coverageBValue: "",
      coverageCValue: "",
      coverageDValue: "",
      coverageEValue: "",
      coverageEPremisesLiabValue: "",
      coverageFValue: "",
      aopDeductible: "",
      deductibleAppliedForHurricaneCoverage: "",
      waterDeductible: "",
      coverageLValue: "",
      coverageMValue: "",
      includeFloodPremium: 'false',
      estimatedCreditScore: 'Average'
    }
    this.quotedataService.setCoverageObj(coverageFormObj);
  }

  setCoverageBasedOnPolicyType() {
    if (this.curCoverageNames.has("coverageA")) {
      this.coverageAFieldName = this.curCoverageNames.get("coverageA");
      this.coverageADescription = this.curCoverageNames.get("coverageADescription");
      if (this.coverageValues.value.coverageAValue !== undefined
        && this.coverageValues.value.coverageAValue !== null) {
        this.coverageA = true;
      }
    }

    if (this.curCoverageNames.has("coverageB")) {
      this.coverageBFieldName = this.curCoverageNames.get("coverageB");
      this.coverageBDescription = this.curCoverageNames.get("coverageBDescription");
      if (this.coverageValues.value.coverageBValue !== undefined && this.coverageValues.value.coverageBValue !== null) {
        this.coverageB = true;
      }
    }

    if (this.curCoverageNames.has("coverageC")) {
      this.coverageCFieldName = this.curCoverageNames.get("coverageC");
      this.coverageCDescription = this.curCoverageNames.get("coverageCDescription");
      if (this.coverageValues.value.coverageCValue !== undefined && this.coverageValues.value.coverageCValue !== null) {
        this.coverageC = true;
      }
    }

    if (this.curCoverageNames.has("coverageD")) {
      this.coverageDFieldName = this.curCoverageNames.get("coverageD");
      this.coverageDDescription = this.curCoverageNames.get("coverageDDescription");
      if (this.coverageValues.value.coverageDValue !== undefined && this.coverageValues.value.coverageDValue !== null) {
        this.coverageD = true;
      }
    }

    if (this.curCoverageNames.has("coverageE")) {
      this.coverageEFieldName = this.curCoverageNames.get("coverageE");
      this.coverageEDescription = this.curCoverageNames.get("coverageEDescription");
      if (this.coverageValues.value.coverageEValue !== undefined && this.coverageValues.value.coverageEValue !== null) {
        this.coverageE = true;
      }
    }

    if (this.curCoverageNames.has("coverageEPremisesLiab")) {
      this.coverageEPremisesLiabFieldName = this.curCoverageNames.get("coverageEPremisesLiab");
      this.coverageEPremisesLiabDescription = this.curCoverageNames.get("coverageEPremisesLiabDescription");
      if (this.coverageValues.value.coverageEPremisesLiabValue !== undefined && this.coverageValues.value.coverageEPremisesLiabValue !== null) {
        this.coverageEPremisesLiab = true;
      }
    }

    if (this.curCoverageNames.has("coverageF")) {
      this.coverageFFieldName = this.curCoverageNames.get("coverageF");
      this.coverageFDescription = this.curCoverageNames.get("coverageFDescription");

      if (this.coverageValues.value.coverageFValue !== undefined && this.coverageValues.value.coverageFValue !== null) {
        this.coverageF = true;
      }
    }

    if (this.curCoverageNames.has("coverageL")) {
      this.coverageLFieldName = this.curCoverageNames.get("coverageL");
      if (this.coverageValues.value.coverageLValue !== undefined && this.coverageValues.value.coverageLValue !== null) {
        this.coverageL = true;
      }
    }

    if (this.curCoverageNames.has("coverageM")) {
      this.coverageMFieldName = this.curCoverageNames.get("coverageM");
      if (this.coverageValues.value.coverageMValue !== undefined && this.coverageValues.value.coverageMValue !== null) {
        this.coverageM = true;
      }
    }

    if (this.curDeductibleNames.has("hurricane")) {
      this.hurricaneFieldName = this.curDeductibleNames.get("hurricane");
      this.hurricaneDescription = this.curDeductibleNames.get("hurricaneDescription");
      if (this.coverageValues.value.deductibleAppliedForHurricaneCoverage !== undefined && this.coverageValues.value.deductibleAppliedForHurricaneCoverage !== null) {
        this.hurricane = true;
      }
    }

    if (this.curDeductibleNames.has("aop")) {
      this.aopFieldName = this.curDeductibleNames.get("aop");
      this.aopDescription = this.curDeductibleNames.get("aopDescription");
      if (this.coverageValues.value.aopDeductible !== undefined && this.coverageValues.value.aopDeductible !== null) {
        this.aop = true;
      }
    }

    if (this.curDeductibleNames.has("waterDamage")) {
      this.waterDamageFieldName = this.curDeductibleNames.get("waterDamage");
      this.waterDamageDescription = this.curDeductibleNames.get("waterDamageDescription");
      if (this.coverageValues.value.waterDeductible !== undefined && this.coverageValues.value.waterDeductible !== null) {
        this.waterDamage = true;
      }
    }
    if (this.curOptionalCoverages !== undefined
      && this.curOptionalCoverages !== null) {
      this.optionalCoverageTitle = this.curOptionalCoverages.get("titleDisplayValue");
      this.floodCoverageFieldName = this.curOptionalCoverages.get("fieldDisplayValue");
      this.floodCoverageDescription = this.curOptionalCoverages.get("description");
      this.floodCoverageFiledValue = this.curOptionalCoverages.get("fieldValue")
      // console.log("flood coverage: "+this.coverageValues.value.includeFloodPremium)
      if (this.coverageValues.value.includeFloodPremium !== undefined
        && this.coverageValues.value.includeFloodPremium !== null) {
        this.floodCoverage = true;
        // console.log("flood coverage: " + this.floodCoverage)
      }
    }

    if (this.curDiscount !== undefined
      && this.curDiscount !== null) {
      this.discountTitle = this.curDiscount.get("titleDisplayValue");
      this.discountDescription = this.curDiscount.get("description");
    }

    if (this.curInsuranceScore !== undefined
      && this.curInsuranceScore !== null) {
      this.insuranceScoreTitle = this.curInsuranceScore.get("titleDisplayValue");
      this.insuranceScoreTitleValue = this.curInsuranceScore.get("titleValue");
      this.insuranceScoreDes = this.curInsuranceScore.get("description");
      this.insuranceScoreDesSecond = this.curInsuranceScore.get("descriptionSecond");
      this.insuranceScoreDecTooltip = this.curInsuranceScore.get("descriptionSecondTooltip");
      this.insuranceScoreObjList = this.curInsuranceScore.get("displayValues");
      //console.log(this.insuranceScoreObjList)
      for (let item of this.insuranceScoreObjList) {
        if (item !== null) {
          // console.log(item[1].get("scoreValue"))
          this.insuranceScoreList.push(item[1].get("scoreValue"))
        }
      }
      if (this.coverageValues.value.estimatedCreditScore !== undefined
        && this.coverageValues.value.estimatedCreditScore !== null) {
        this.insuranceScore = true;
      }
    }
  }

  reCalculatePremium() {
    this.loading = true;
    this.fakeLoader()
    console.log("recalculate premium request: " + this.rateAQuoteObjectStructure())
    this.submitRateQuote(this.rateAQuoteObjectStructure()).subscribe(
      (resp) => {
        if (resp === undefined) {
          this.loading = false
          console.log('No response returned');
        } else {
          let estimatedPremium = resp.rate.estimatedPremium;
          let estimatedPremiumTax = resp.rate.taxes;
          console.log("estimated premium from edit coverage: " + estimatedPremium)
          this.displayValue.yearlyPremium = this.utilsService.convertNumberToIS(estimatedPremium)
          console.log("yearly premium: " + this.displayValue.yearlyPremium)
          let monthlyFee = JSON.stringify(estimatedPremium / 12)
          console.log("monthly fee: " + monthlyFee)
          this.displayValue.monthlyPremium = this.utilsService.convertNumberToIS(parseFloat(monthlyFee).toFixed(2))
          console.log("monthly premium: " + this.displayValue.monthlyPremium)
          this.quotedataService.setEstimatedPremium(estimatedPremium);
          this.quotedataService.setPremiumTax(estimatedPremiumTax);
          this.quotedataService.setPolicyFees(resp.rate.policyFees)
          this.applyResponseToCoverageValues(resp);
          this.loading = false;
        }
      });
  }

  finalizeQuote() {
    console.log("finalize quote request: " + this.rateAQuoteObjectStructure())
    this.submitRateQuote(this.rateAQuoteObjectStructure()).subscribe(
      (resp) => {
        if (resp === undefined) {
          console.log('No response returned');
        } else {
          let estimatedPremium = resp.rate.estimatedPremium;
          let estimatedPremiumTax = resp.rate.taxes;
          console.log("estimated premium from edit coverage: " + estimatedPremium)
          this.displayValue.yearlyPremium = this.utilsService.convertNumberToIS(estimatedPremium)
          console.log("yearly premium: " + this.displayValue.yearlyPremium)
          let monthlyFee = JSON.stringify(estimatedPremium / 12)
          console.log("monthly fee: " + monthlyFee)
          this.displayValue.monthlyPremium = this.utilsService.convertNumberToIS(parseFloat(monthlyFee).toFixed(2))
          console.log("monthly premium: " + this.displayValue.monthlyPremium)
          this.quotedataService.setPremiumTax(estimatedPremiumTax);
          this.quotedataService.setPolicyFees(resp.rate.policyFees)
          this.quotedataService.setEstimatedPremium(estimatedPremium);
          this.applyResponseToCoverageValues(resp);
        }
      });
  }

  submitRateQuote(data): Observable<any> {
    return this.httpService.post('rateQuote', data, false, true).pipe(
      map((resp) => {
        if (resp.out === undefined) {
          this.utilsService.confirmModalPopup(`${resp.root.Envelope.Body.Fault.faultstring}`)
        } else {
          console.log('return value Rate Quote' + JSON.stringify(resp.out));
          this.loading = false;
          return resp.out;
        }
      })
    );
  }


  applyResponseToCoverageValues(response) {
    if (response != null) {
      const coverageFormObj = {
        coverageAValue: formatCurrency(response.coverageAValue, "en_US", "$", "USD"),
        coverageBValue: response.coverageBValue,
        coverageCValue: this.iscoverageCValue ? response.recommendedPercentageOfCoverageCtoCoverageA : formatCurrency(response.coverageCValue, "en_US", "$", "USD"),
        coverageDValue: response.coverageDValue,
        coverageEValue: response.coverageEValue,
        coverageEPremisesLiabValue: response.coverageEPremisesLiab,
        coverageFValue: response.coverageFValue,
        aopDeductible: response.aopDeductible,
        deductibleAppliedForHurricaneCoverage: response.deductibleAppliedForHurricaneCoverage,
        waterDeductible: response.waterDeductible,
        coverageLValue: response.coverageLValue,
        coverageMValue: response.coverageMValue,
        includeFloodPremium: response.includeFloodPremium,
        estimatedCreditScore: response.estimatedCreditScore,
      }
      this.quotedataService.setCoverageObj(coverageFormObj)
      this.setCoverageValue();
    }
  }

  rateAQuoteObjectStructure() {
    let policyForm = this.policyType;
    const data = {
      accountCode: this.tenant,
      phoneNumber: {
        number: null,
        type: null,
      },
      // insuranceType: "SFI Florida DF3 CO",
      insuranceType: this.insuranceTypeValue,
        // this.quotedataService.getInsuranceTypePrefix() +
        // ' ' +
        // this.currentSelectedAddress['state']['long_name'] +
        // ' ' +
        // policyForm,
      policyEffectiveDate: new Date(this.getFormValue('policy-start-date', 'startDate')).toLocaleDateString('en-US'),
      propertyAddress: this.getPropAddress(),
      mailingAddress: this.getPropAddress(),
      propertyUsage: this.getPropertyUsage(),
      propertyOccupancyStatus: this.getOccupancyStatus(),
      propertyYearBuilt: this.getFormValue('house-built', 'yearBuilt'),
      propertySquareFootage: this.getFormValue('house-built', 'squareFeet'),
      propertyNumberOfStories: (this.getFormValue('number-of-stories', 'story')) ? this.getFormValue('number-of-stories', 'story') : '1',
      propertyConstructionType: this.getFormValue('structure-material', 'constructionType'),
      propertyStructureType: this.structureType,
      propertyRoofType: this.getFormValue('roof-shape', 'roofType'),
      propertyRoofYearLastRenovated: this.getFormValue('roof-age', 'roofYear'),
      propertyRoofMaterial: this.quotedataService.getRoofMaterial(),
      openingProtectionLevel: this.getFormValue('opening-protection', 'openingProtection') ? this.getFormValue('opening-protection', 'openingProtection') : "None",
      hasPriorInsurance: this.getFormValue('prior-insurance', 'priorInsurance'),
      coverageAValue: this.coverageValues.value.coverageAValue.replace(/[^0-9.]+/g, ""),
      coverageBValue: this.coverageValues.value.coverageBValue,
      coverageCValue: this.iscoverageCValue ? null : this.coverageValues.value.coverageCValue.replace(/[^0-9.]+/g, ""),
      RecommendedPercentageOfCoverageCtoCoverageA: this.iscoverageCValue ? this.coverageValues.value.coverageCValue : this.coverageValues.value.RecommendedPercentageOfCoverageCtoCoverageA,
      coverageDValue: this.coverageValues.value.coverageDValue,
      coverageEValue: this.coverageValues.value.coverageEValue,
      coverageEPremisesLiabValue: this.coverageValues.value.coverageEPremisesLiabValue,
      coverageFValue: this.coverageValues.value.coverageFValue,
      coverageLValue: this.coverageValues.value.coverageLValue,
      coverageMValue: this.coverageValues.value.coverageMValue,
      aopDeductible: this.coverageValues.value.aopDeductible,
      // seniorDiscount: this.coverageValues.value.seniorDiscount,
      deductibleAppliedForHurricaneCoverage: this.coverageValues.value.deductibleAppliedForHurricaneCoverage,
      waterDeductible: this.coverageValues.value.waterDeductible,
      includeFloodPremium: this.coverageValues.value.includeFloodPremium,
      estimatedCreditScore: this.coverageValues.value.estimatedCreditScore,
      agentID: (this.selectedAgentDetails == undefined || this.selectedAgentDetails == null) ? this.defaultAgentID : this.selectedAgentDetails.agentID,
      capacityStatus: this.quotedataService.getPolicyObjData()["capacityStatus"]
    };
    console.log(' request quote data: ' + JSON.stringify(data));
    this.quotedataService.setQuoteObjectStructure(data)
    return data;
  }

  getOccupancyStatus() {
    return (this.getFormValue('home-usage', 'checkRental') && (this.quotedataService.getMappingInfoObj().propertyOccupancyStatusMapping[this.getFormValue('home-usage', 'checkRental')])) ? this.quotedataService.getMappingInfoObj().propertyOccupancyStatusMapping[this.getFormValue('home-usage', 'checkRental')] : this.quotedataService.getMappingInfoObj().propertyOccupancyStatusMapping[this.getFormValue('relation-to-property', 'relationToProperty')]
  }
  getPropertyUsage() {
    const propertyUsageMapping = this.quotedataService.getMappingInfoObj().propertyUsageMapping;
    const relationToProperty = this.getFormValue('relation-to-property', 'relationToProperty');
    const homeUsage = this.getFormValue('home-usage', 'checkRental');
    return ((propertyUsageMapping[relationToProperty]) || (propertyUsageMapping[homeUsage]))
  }

  getFormValue(formName, value) {
    if (this.quoteObj.conversation[formName]) {
      return this.quoteObj.conversation[formName][value]
    } else {
      return null
    }
  }

  getPropAddress() {
    const tempData = {
      street:
        (this.currentSelectedAddress['street_number'] &&
          this.currentSelectedAddress['street_number']['long_name']) +
        ' ' +
        (this.currentSelectedAddress['route'] && this.currentSelectedAddress['route']['long_name']) +
        ' ' +
        this.getFormValue('property-location', 'unitNumber'),
      city: this.currentSelectedAddress['city']['long_name'],
      stateCode: this.currentSelectedAddress['state']['short_name'],
      zip:
        this.currentSelectedAddress['postal_code'] &&
        this.currentSelectedAddress['postal_code']['long_name'],
      latitude: this.quotedataService.getRiskAddressObj()["lat"],
      longitude: this.quotedataService.getRiskAddressObj()["lng"]
    };
    return tempData;
  }

  getConstrainedValues() {
    let state = this.quotedataService.getAddressObj().stateCode;
    let policyFormValue = this.quotedataService.getMappingInfoObj().policyTypeForGetConstrainedValue[this.policyType];
    this.httpService
      .get(`getConstrainedValues?accountCode=${this.tenant}&policyForm=${policyFormValue}&state=${state}`, null, true)
      .subscribe(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp?.root?.Envelope?.Body?.Fault?.faultstring);
          } else {
            this.coverageItemList = this.displayPremiumService.assignGetConstrainedValues(resp)
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  fakeLoader() {
    this.progressNumber = 0
    this.startTimer()
  }

  startTimer() {
    this.interval = window.setInterval(() => {
      if (this.progressNumber < 98) this.progressNumber++
      else if (!this.loading) this.stopTimer(100)
      else this.stopTimer(100)
    }, 200)
  }

  stopTimer(time) {
    window.clearInterval(this.interval)
    window.clearTimeout(this.interval)
  }
}

