<form [formGroup]="preEnrollmentForm">
    <div class="col-12 form-group">
        <ng-container *ngIf="this.accessUsingSSN">
            <div class="row">
                <div class="col-md-4 offset-md-4 text-center">
                    <mat-form-field style="width: 100%">
                        <input matInput [autofocus]="true" formControlName="lastFiveDigitsSSN" type="text" (input)="displayErrorMsg()"
                            placeholder="{{'LAST_FIVE_DIGITS_OF_SSN' | translate}} *" maxlength="5"/>
                    </mat-form-field>
                    <p class="error-class mat-angular-error-text"
                        *ngIf="!preEnrollmentForm.get('lastFiveDigitsSSN').valid && isSubmitted">{{ errorMsgSSN | translate: {value:'LAST_FIVE_DIGITS' | translate} }}</p>
                    <!-- <p class="error-class mat-angular-error-text"
                        *ngIf="preEnrollmentForm.get('lastFiveDigitsSSN').valid && isSubmitted">
                        {{'REQUIRED_FIELD' | translate: {value:'LAST_FIVE_DIGITS' | translate} }}</p>
                    <p class="error-class mat-angular-error-text"
                        *ngIf="preEnrollmentForm.get('lastFiveDigitsSSN').errors?.pattern && isSubmitted">
                        {{'LAST_FIVE_DIGITS_PATTERN_ERRMSG' | translate }}</p>
                    <p class="error-class mat-angular-error-text"
                        *ngIf="preEnrollmentForm.get('lastFiveDigitsSSN').errors?.minlength && isSubmitted">
                        {{'LAST_FIVE_DIGITS_PATTERN_ERRMSG' | translate }}</p> -->
                </div>
            </div>
    
    
            <div class="row" formGroupName="dateOfBirth">
                <div class="col-md-4 offset-md-4">
                    <label class="custom-title-info" style="width: 32%;" [ngStyle]="styling?.fieldPlaceHolder" >
                        {{'DOB' | translate}}* : 
                    </label>
                    <mat-form-field style="width: 32%;">
                        <input matInput numbersOnly class="custom-title-infoDetail account-description" 
                        formControlName="b_month" type="text" [ngStyle]="styling?.fieldValueInput" (input)="enterValue($event.target.value)"
                        placeholder="MM" maxlength="2"/>
                    </mat-form-field>
                    /
                    <mat-form-field style="width: 32%;padding-left: 1%;">
                        <input matInput numbersOnly class="custom-title-infoDetail account-description" 
                        formControlName="b_date" type="text"  [ngStyle]="styling?.fieldValueInput"
                            placeholder="DD" maxlength="2" #b_date_input/>
                    </mat-form-field>
                    <!-- <p class="error-class mat-angular-error-text" 
                        *ngIf="(preEnrollmentForm.get('dateOfBirth.b_month').hasError('requiredFieldImp') || preEnrollmentForm.get('dateOfBirth.b_date').hasError('requiredFieldImp')) && isSubmitted">
                        {{'REQUIRED_FIELD' | translate: {value:'Date of Birth'} }}</p> -->
                    <p class="error-class mat-angular-error-text" 
                        *ngIf="preEnrollmentForm.get('dateOfBirth.b_date').hasError('requiredFieldImp') && isSubmitted">
                        {{'REQUIRED_FIELD' | translate: {value:'DOB' | translate} }}</p>
                    <p class="error-class mat-angular-error-text" 
                        *ngIf="preEnrollmentForm.get('dateOfBirth.b_month').hasError('requiredFieldImp') && isSubmitted">
                        {{'REQUIRED_FIELD' | translate: {value:'MOB' | translate} }}</p>
                    <p class="error-class mat-angular-error-text" 
                        *ngIf="preEnrollmentForm.get('dateOfBirth.b_month').hasError('invalidDateMonth') && isSubmitted">
                        {{'INVALID_DOB_COMBINATION' | translate}}</p>
                </div>
            </div>
        </ng-container>

        <div class="col-12 form-group" *ngIf="this.accessUsingEmployeeID">
            <div class="row">
                <div class="col-md-4 offset-md-4 text-center">
                    <mat-form-field style="width: 100%">
                        <input matInput [autofocus]="true" formControlName="employeeID" type="text"
                            placeholder="{{'EMPLOYEE_ID' | translate}} *" maxlength="10" numbersOnly/>
                    </mat-form-field>
                    <p class="error-class mat-angular-error-text"
                        *ngIf="!preEnrollmentForm.get('employeeID').valid && isSubmitted">
                        {{'REQUIRED_FIELD' | translate: {value:'EMPLOYEE_ID' | translate} }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 form-group">
        <div class="text-center p-b-40">
            <button class="btn btn-info custom-button-info m-r-20" *ngIf="quoteObj?.buttonOptions?.includes('Back')"
                type="button" (click)="submitForm('back')" [ngStyle]="styling?.button?.buttonNegative">{{'BACK' |
                translate}}</button>
            <button class="btn btn-info custom-button-info" type="button"
                *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{this.next||'GET-QUOTE' | translate}}</button>
        </div>
    </div>
</form>