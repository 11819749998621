import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-premium-select-form',
  templateUrl: './premium-select-form.component.html',
  styleUrls: ['./premium-select-form.component.scss']
})
export class PremiumSelectFormComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  @Input() dataObj: any;
  @Output() submitFormObj = new EventEmitter()
  selectedPremium = 'basic'
  quoteObj: any;
  

  constructor(
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.fromAPI()
  }

  fromAPI(){
    const selPremiumVal = this.quoteObj.conversation && this.quoteObj.conversation['Coverage-Select']
    if(selPremiumVal && selPremiumVal.selectedPremium) 
      this.selectedPremium = selPremiumVal.selectedPremium
  }


  selectPremium(eve){
    this.selectedPremium = eve;
    console.log(eve)
  }

  submitForm(userSelection){
    let temp;
    if(userSelection == 'next'){
      temp = { 
        input: 'Next',
        userInput: { selectedPremium: this.selectedPremium },
        pageID: 'Coverage-Select'
      }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'Coverage-Select' }
    }
    if(temp){
      // console.log(temp)
      // this.submitFormObj.emit(temp)
      this.dataTransferService.sendData(temp)
    }
  }

}
