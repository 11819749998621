import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-welcome-form',
  templateUrl: './welcome-form.component.html',
  styleUrls: ['./welcome-form.component.scss']
})
export class WelcomeFormComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  // @Input() dataObj: any;
  quoteObj: any;
  
  // @Output() submitFormObj = new EventEmitter()
  
  constructor(
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
  }

  submitForm(userSelection){
    let temp;
    if(userSelection == 'next') temp = { input: 'Next', pageID: this.quoteObj.pageID, userInput: {} }

    // if(temp) this.submitFormObj.emit(temp)
    if(temp) this.dataTransferService.sendData(temp)
  }

}
