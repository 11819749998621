import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-additional-house-details-form',
  templateUrl: './additional-house-details-form.component.html',
  styleUrls: ['./additional-house-details-form.component.scss'],
})
export class AdditionalHouseDetailsFormComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  additionalHouseDetailsForm: any;
  constructionTypeMapping: any;
  isSubmitted = false;
  quoteObj: any;
  constructionTypeMessages: any;
  
  tenant: any;
  isSmallWindow = window.innerWidth <= 768;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) {
    this.tenant = this.route.parent.parent.snapshot.params.tenant;
  }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.constructionTypeMessages =
      this.quotedataService.getAcntConfig()['content_management']['quote'][
        'construction_type'
      ];
      this.constructionTypeMapping =
      this.quotedataService.getMappingInfoObj()?.constructionTypeMapping;
    this.initForm();
    this.fromAPI();
    }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm() {
    this.additionalHouseDetailsForm = this.formBuilder.group({
      constructionType: ['Masonry 100%', [Validators.required]],
    });
  }

  fromAPI() {
    if (this.quoteObj?.conversation['structure-material']?.constructionType) {
      let validConstructionTypes = Object.keys(this.constructionTypeMapping).filter(el => this.constructionTypeMapping[el]['value'] == this.quoteObj.conversation['structure-material'].constructionType)
      if(validConstructionTypes.length){
      this.additionalHouseDetailsForm.patchValue({
        constructionType:
          this.quoteObj.conversation['structure-material'].constructionType,
      });
    }
    }
  }

  navigateToLink(page) {
    const tenant = this.utilsService.getTenantFromWindow();
    this.router.navigate([`${tenant}/${page}`]);
  }

  submitForm(userSelection) {
    let temp;
    this.isSubmitted = true;
    if (this.additionalHouseDetailsForm.valid && userSelection == 'next') {
      temp = {
        input: 'Next',
        pageID: 'structure-material',
        userInput: this.additionalHouseDetailsForm.value,
      };
    } else if (userSelection == 'back') {
      temp = { input: 'Back', pageID: 'structure-material' };
    }
    if (temp) {
      this.dataTransferService.sendData(temp);
    }
  }

  submitBackToSummary(eve){
    this.isSubmitted = true;
    if (this.additionalHouseDetailsForm.valid) {
      let temp = eve
      temp.userInput = this.additionalHouseDetailsForm.value
      temp.currentPageID = 'structure-material'
      this.dataTransferService.sendData(temp)
    }
  }
}
