import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { MatInputModule } from '@angular/material/input';

import { NotFoundComponent } from './not-found/not-found.component';
import { FooterComponent } from './footer/footer.component';
import { SiteNotFoundComponent } from './site-not-found/site-not-found.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { PhoneDisplayDirective } from './pipes/phone-display.directive';
import { GoogleAutocompleteComponent } from './google-autocomplete/google-autocomplete.component';
import { NumbersOnlyDirective } from './pipes/numbers-only.directive';
import { CardNumberDirective } from './pipes/card-number.directive';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PermissionDeniedComponent } from './permission-denied/permission-denied.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CommonApiService } from './services/common-api.service';
import { AuthGuard, AuthService } from './services/auth.service';
import { UtilsService } from './services/utils.service';
import { HttpService } from './services/base.service';
import { InstallationBannerComponent } from './installation-banner/installation-banner.component';
import { UserWarningComponent } from './user-warning/user-warning.component';
import { AutoFocusDirective } from './pipes/autofocus.directive';
import { QuoteDataShare } from './services/quoteDataShare.service';
import { DataTransferService } from './services/dataTransfer.service';
import { AlternateImgTenantDirective } from './pipes/alternate-img-tenants.directive';
import { CurrencyOnlyDirective } from './pipes/currency-only.directive';
import { SetDefaultCustPicDirective } from './pipes/set-default-cust-pic.directive';
// import { PdfModalComponent } from './pdf-modal/pdf-modal.component';

export function httpTranslateLoader (http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/','.json')
}

@NgModule({
  declarations: [NotFoundComponent, FooterComponent, SiteNotFoundComponent, ConfirmModalComponent, SafeHtmlPipe, PhoneDisplayDirective, NumbersOnlyDirective, CurrencyOnlyDirective, CardNumberDirective, GoogleAutocompleteComponent, ErrorModalComponent, PermissionDeniedComponent, CustomTableComponent, InstallationBannerComponent, UserWarningComponent, AutoFocusDirective, AlternateImgTenantDirective, SetDefaultCustPicDirective],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    PopoverModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatInputModule
  ],
  exports: [FooterComponent, TranslateModule, HttpClientModule, NgHttpLoaderModule, SafeHtmlPipe, PhoneDisplayDirective, NumbersOnlyDirective, CurrencyOnlyDirective, CardNumberDirective, GoogleAutocompleteComponent, ErrorModalComponent, CustomTableComponent, InstallationBannerComponent, AutoFocusDirective, AlternateImgTenantDirective, SetDefaultCustPicDirective],
  providers: [BsModalService, QuoteDataShare , CommonApiService, Router, AuthService, Router, DataTransferService]
})
export class SharedModule { }
