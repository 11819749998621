<form [formGroup]="houseBuiltForm" *ngIf="quoteObj?.pageID == 'house-built'">
    <div class="form-group row">

        <div class="col-md-4 offset-md-4 text-center" [ngStyle]="styling?.quoterWizard?.quoteSubField2">
            <mat-form-field [ngStyle]="styling?.quoterWizard?.quoteSubField2">
                <input [ngStyle]="styling?.quoterWizard?.quoteSubField2" matInput [autofocus]="true"
                    formControlName="yearBuilt" type="text" placeholder="Year Built *" numbersOnly maxlength="4" />
            </mat-form-field>
            <p class="error-class mat-angular-error-text text-center"
                *ngIf="houseBuiltForm.get('yearBuilt').errors?.required && isSubmitted" style="text-align: center;"
                [ngStyle]="styling?.quoterWizard?.quoteSubField2">
                {{'REQUIRED_FIELD' | translate: {value:'Year Built'} }}</p>
            <p class="error-class mat-angular-error-text text-center"
                *ngIf="houseBuiltForm.get('yearBuilt').errors?.min && isSubmitted"
                [ngStyle]="styling?.quoterWizard?.quoteSubField2">Year Built should be greater than 1650</p>
            <p class="error-class mat-angular-error-text text-center"
                *ngIf="houseBuiltForm.get('yearBuilt').errors?.max && isSubmitted"
                [ngStyle]="styling?.quoterWizard?.quoteSubField2">{{maxYearError}}</p>

        </div>
    </div>
    <div class="form-group row">

        <div class="col-md-4 offset-md-4 text-center" [ngStyle]="styling?.quoterWizard?.quoteSubField2">
            <mat-form-field [ngStyle]="styling?.quoterWizard?.quoteSubField2">
                <input [ngStyle]="styling?.quoterWizard?.quoteSubField2" matInput formControlName="squareFeet"
                    type="text" placeholder="Square Feet *" numbersOnly />
            </mat-form-field>
            <p class="error-class mat-angular-error-text text-center"
                *ngIf="houseBuiltForm.get('squareFeet').errors?.required && isSubmitted"
                [ngStyle]="styling?.quoterWizard?.quoteSubField2">
                {{'REQUIRED_FIELD' | translate: {value:'Square Feet'} }}</p>
            <!-- <p class="error-class mat-angular-error-text text-center" *ngIf="houseBuiltForm.get('squareFeet').errors?.max && isSubmitted">Square Feet should be less than 100,000</p> -->
            <p class="error-class mat-angular-error-text text-center"
                *ngIf="houseBuiltForm.get('squareFeet').errors?.min && isSubmitted"
                [ngStyle]="styling?.quoterWizard?.quoteSubField2">Square Feet should be greater than 0</p>

        </div>
    </div>
    <!-- <div class="form-group row">
        <div class="w-100 d-flex justify-content-center">
            <div style="width: 180px;">
                <mat-label class="custom-title-info account-title">Number of Stories * </mat-label>
                <ng-select id="stories_7" [items]="stories" appendTo="body" [hideSelected]="true"
                    placeholder="Number of Stories" formControlName="story">
                </ng-select>
                <p class="error-class" *ngIf="!houseBuiltForm.get('story').valid && isSubmitted">
                    Number of stories is required</p>
            </div>
        </div>
    </div> -->

    <!-- <div class="col-12 form-group">
        <div  class="text-center p-b-40">
            <button class="btn btn-info custom-button-info m-r-20" *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button"
                (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
            <button class="btn btn-info custom-button-info" type="button"
                *ngIf="quoteObj?.buttonOptions?.includes('Next')"
                (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        </div>
    </div> -->
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>
    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6" type="button"
            *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-10 m-b-20" *ngIf="quoteObj?.buttonOptions?.includes('Back')"
            type="button" (click)="submitForm('back')" [ngStyle]="styling?.button?.buttonNegative">{{'BACK' |
            translate}}</button>
        <button class="btn btn-info custom-button-info m-l-10 m-b-20" type="button"
            *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
    </div>
</form>