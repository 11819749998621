import { Component, Inject } from '@angular/core';
import { environment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Spinkit } from 'ng-http-loader';
import { UtilsService } from './shared/services/utils.service';
import { AppService } from './app.service';
// const permissionKeys = require("./shared/services/permission-keys.json")
declare var gPushEvent;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  public spinkit = Spinkit;
  title = 'Instant Quote';
  // keysAvailable = this.utilsService.getItemfromSession('permissionKeys') ? this.utilsService.getItemfromSession('permissionKeys') : []
  constructor(
    private router: Router,
    private translate: TranslateService,
    private utilsService: UtilsService,
    private appService: AppService
  ) {
    this.navigateHashRoute() //not necessary
    this.appService.newGoogleAnalytics()
    this.setLanguage()
    const locationObj = window.location
    const location = locationObj.origin + '/' + this.utilsService.getTenantFromWindow() + '/'

    // if (location === environment.sfiUrl) {
    if (location.indexOf('/sfi') > -1) {
      require('style-loader!../assets/tenantStyle/sfi-style.scss');
    } else if (location === environment.upcUrl) {
      require('style-loader!../assets/tenantStyle/upc-style.scss');
    } else if (location.indexOf('/acme') > -1) {
      require('style-loader!../assets/tenantStyle/acme-style.scss');
    } else {
      require('style-loader!../assets/tenantStyle/default-style.scss');
    }
    // else {
    //   this.router.navigate(['sfi/new'])
    //   require('style-loader!../assets/scss/sfi-style.scss');
    // }
    // this.setTabTitle();  
    // this.setTabIcon(); 


    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gPushEvent({ page_path: event.urlAfterRedirects, page_url: event.url, id: event.id, event: 'pageView' });
      }
    });
  }

  navigateHashRoute() {
    const locn = window.location;
    //console.log("locn:", locn);

    let tenants = {
      "sfi": [
        "dev-quote.securityfirstflorida.com",
        // "localhost:4200",
        "co-dev-quote.securityfirstflorida.com"
      ],
      "sfi-prod": [
        "quote.securityfirstflorida.com",
        "co-quote.securityfirstflorida.com"
        // "localhost:4200"
      ]
    }

    Object.keys(tenants).some(resp => {
      if (tenants[resp].indexOf(locn.host) > -1 && !locn.href.includes(`/#/${resp}/`)) {
        if (locn.hash == "") {
          this.router.navigate([`/${resp}`]).then(() => window.location.reload())
        } else {
          var hashArray = locn.hash.split('/');
          hashArray = hashArray.filter(item => item !== '#');
          var skipLevels = '';
          for (let item in hashArray) {
            skipLevels = skipLevels + '../';
          }
          this.router.navigate([skipLevels, `${resp}`]).then(() => window.location.reload())
        }
      }
    })
  }

  setGoogleAnalytics() {
    let id = this.utilsService.getTagId()
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
  }

  setLanguage() {
    let presetLang;
    // const hasPermission = this.keysAvailable.includes(permissionKeys.LANGUAGE_SUPPORT);
    // if(hasPermission) presetLang = sessionStorage.getItem('language') ? JSON.parse(sessionStorage.getItem('language')).langCode : 'en';
    // else presetLang = 'en';
    presetLang = 'en'
    this.translate.setDefaultLang('en');
    let tenant = this.utilsService.getTenantFromWindow()
    let lang = this.utilsService.selLangFile(tenant, presetLang)
    this.translate.use(lang);
  }
}