import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PdfModalComponent } from 'src/app/shared/pdf-modal/pdf-modal.component';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { SendQuoteService } from '../send-quote/send-quote.service';

@Component({
  selector: 'app-quote-submission-confirmation',
  templateUrl: './quote-submission-confirmation.component.html',
  styleUrls: ['./quote-submission-confirmation.component.scss']
})
export class QuoteSubmissionConfirmationComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  isSmallWindow = window.innerWidth <= 768;
  quoteSubmissionConfirmationPage: {
    contentSummary: string,
    subtitle: string,
    title: string,
    person: Array<{
      content: string,
      personName: string,
      personImg: string,
      subtitle: string
    }>;
  }
  constructor(
    private utilsService: UtilsService,
    private quoteDataService: QuoteDataShare,
    private sendQuoteService: SendQuoteService,
    private modalService: BsModalService,
    private quotedataService: QuoteDataShare,
  ) { }

  ngOnInit(): void {
    this.quoteSubmissionConfirmationPage =
    this.quotedataService.getAcntConfig()['content_management']['quote_submission_confirmation_page'];
    this.quoteSubmissionConfirmationPage.title = this.quoteSubmissionConfirmationPage.title ? this.quoteSubmissionConfirmationPage.title : "TITLE_QUOTE_SUBMISSION_CONFIRMATION"
    this.quoteSubmissionConfirmationPage.subtitle = this.quoteSubmissionConfirmationPage.subtitle ? this.quoteSubmissionConfirmationPage.subtitle : "SUBTITLE_QUOTE_SUBMISSION_CONFIRMATION"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  printQuote(){
    let quoteID = this.quoteDataService.getQuoteID()
    let tenant = this.utilsService.getTenantFromWindow()
    let data = {
      "accountCode": tenant,
      "quoteID": quoteID
      // quoteID: "P009279773"
    }
    this.sendQuoteService.printQuote(data).subscribe(resp => {
      if(resp){
        let contentType = resp.contentType ? resp.contentType : 'application/pdf'
        var file = this.sendQuoteService.base64ToArrayBuffer(resp.contents)
        // console.log(file)
        var fileArrayBuffer = new Blob([file], {type: contentType})
        var fileURL = window.URL.createObjectURL(fileArrayBuffer)
        this.showModal(fileArrayBuffer, fileURL)
        // window.open(fileURL, '_blank')
      }
    })
  }
  
  showModal(file, fileURL){
    const modal = this.modalService.show(PdfModalComponent, {backdrop: 'static', class: 'modal-lg'});
    (<PdfModalComponent>modal.content).showConfirmationModal('<b>Quote Details</b>', file, `Close`, 'Download File');
    (<PdfModalComponent>modal.content).onClose.subscribe(resp => {
      if(resp){
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = 'File';
        link.click();
      }
    })
  }

}
