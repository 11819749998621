import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilsService } from '../../../shared/services/utils.service';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-coverage-details',
  templateUrl: './coverage-details.component.html',
  styleUrls: ['./coverage-details.component.scss']
})
export class CoverageDetailsComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  @Input() coverageValues: FormGroup;
  @Input() coverageForms: any;
  @Input() curCoverageNames: any;
  @Input() curDeductibleNames: any;
  @Input() curPolicyType: any;
  coverageA= false;
  coverageB= false;
  coverageC= false;
  coverageD= false;
  coverageE= false;
  CoverageDandE=false;
  coverageF= false;
  coverageL= false;
  coverageM= false;
  hurricane= false;
  aop=false;
  sinkhole=false;
  waterDamage=false;

  coverageAFieldName: any;
  coverageBFieldName: any;
  coverageCFieldName: any;
  coverageDFieldName: any;
  coverageEFieldName: any;
  coverageFFieldName: any;
  coverageDandEFieldName: any;
  coverageLFieldName: any;
  coverageMFieldName: any;
  hurricaneFieldName: any;
  aopFieldName: any;
  sinkholeFieldName: any;
  waterDamageFieldName: any;

  constructor(
    private utilsService: UtilsService,  
  ) { }

  ngOnInit(): void {
    this.setCoverageBasedOnPolicyType();
  }

  setCoverageBasedOnPolicyType(){ 
    if (this.curCoverageNames.has("coverageA")) {
      this.coverageAFieldName = this.curCoverageNames.get("coverageA");
      if (this.coverageValues.value.coverageAValue !== undefined && this.coverageValues.value.coverageAValue !== null){
        this.coverageA = true;
      }
    }

    if (this.curCoverageNames.has("coverageB")) {
      this.coverageBFieldName = this.curCoverageNames.get("coverageB");
      if (this.coverageValues.value.coverageBValue !== undefined && this.coverageValues.value.coverageBValue !== null){
        this.coverageB = true;
      }
    }

    if (this.curCoverageNames.has("coverageC")) {
      this.coverageCFieldName = this.curCoverageNames.get("coverageC");
      if (this.coverageValues.value.coverageCValue !== undefined && this.coverageValues.value.coverageCValue !== null){
        this.coverageC = true;
      }
    }

    if (this.curCoverageNames.has("coverageD")) {
      this.coverageDFieldName = this.curCoverageNames.get("coverageD");
      if (this.coverageValues.value.coverageDValue !== undefined && this.coverageValues.value.coverageDValue !== null){
        this.coverageD = true;
      }
    }

    if (this.curCoverageNames.has("coverageE")) {
      this.coverageEFieldName = this.curCoverageNames.get("coverageE");
      if (this.coverageValues.value.coverageEValue !== undefined && this.coverageValues.value.coverageEValue !== null){
        this.coverageE = true;
      }
    }

    if (this.curCoverageNames.has("coverageF")) {
      this.coverageFFieldName = this.curCoverageNames.get("coverageF");
      if (this.coverageValues.value.coverageFValue !== undefined && this.coverageValues.value.coverageFValue !== null){
        this.coverageF = true;
      }
    }

    if (this.curCoverageNames.has("CoverageDandE")) {
      this.coverageDandEFieldName = this.curCoverageNames.get("CoverageDandE");
      if (this.coverageValues.value.coverageDandEValue !== undefined && this.coverageValues.value.coverageDandEValue !== null){
        this.CoverageDandE = true;
      }
    }

    if (this.curCoverageNames.has("coverageL")) {
      this.coverageLFieldName = this.curCoverageNames.get("coverageL");
      if (this.coverageValues.value.coverageLValue !== undefined && this.coverageValues.value.coverageLValue !== null){
        this.coverageL = true;
      }
    }

    if (this.curCoverageNames.has("coverageM")) {
      this.coverageMFieldName = this.curCoverageNames.get("coverageM");
      if (this.coverageValues.value.coverageMValue !== undefined && this.coverageValues.value.coverageMValue !== null){
        this.coverageM = true;
      }
    }

    if (this.curDeductibleNames.has("hurricane")) {
      this.hurricaneFieldName = this.curDeductibleNames.get("hurricane");
      if (this.coverageValues.value.deductibleAppliedForHurricaneCoverage !== undefined && this.coverageValues.value.deductibleAppliedForHurricaneCoverage !== null){
        this.hurricane = true;
      }
    }

    if (this.curDeductibleNames.has("aop")) {
      this.aopFieldName = this.curDeductibleNames.get("aop");
      if (this.coverageValues.value.aopDeductible !== undefined && this.coverageValues.value.aopDeductible !== null){
        this.aop = true;
      }
    }

    if (this.curDeductibleNames.has("sinkhole")) {
      this.sinkholeFieldName = this.curDeductibleNames.get("sinkhole");
      if (this.coverageValues.value.sinkholeDeductible !== undefined && this.coverageValues.value.sinkholeDeductible !== null){
        this.aop = true;
      }
    }

    if (this.curDeductibleNames.has("waterDamage")) {
      this.waterDamageFieldName = this.curDeductibleNames.get("waterDamage");
      if (this.coverageValues.value.waterDeductible !== undefined && this.coverageValues.value.waterDeductible !== null){
        this.waterDamage = true;
      }
    }
  }

  hasCuCoverage(name) {
    console.log(this.curCoverageNames)
    return this.curCoverageNames.has(name);
  }
}
