import { Injectable } from "@angular/core";
import { QuoteDataShare } from "src/app/shared/services/quoteDataShare.service";

@Injectable({
    providedIn: 'root'
  })
  export class LatestGetQuoteDemoService {
  
  constructor(
    private quotedataService: QuoteDataShare
  ) { }
  
  objectToMap(obj: Object) {
    let m = new Map()
    for (let k of Object.keys(obj)) {
      if (obj[k] instanceof Object) {
            m.set(k, this.objectToMap(obj[k]))   
      }
        else {
            m.set(k, obj[k])
      }
    }
    return m
  }

  getFormValue(formName, value){
    const quoteObj = this.quotedataService.getConfiguration()
    if(quoteObj.conversation[formName]){
      return quoteObj.conversation[formName][value]
    } else{
      return null
    }
  }
    
}