<form [formGroup]="openingProtectionForm" *ngIf="quoteObj?.pageID == 'opening-protection'">
    <div class="row p-20">
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center"
                (click)="onClick('None')">
                    <label for="None">
                        <input type="radio" value="None" formControlName="openingProtection" id="None" class="radio-col-light-blue" />
                        <div [innerHTML]="openingProtectionMessages.None | safeHtml"></div>
                        </label>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center"
                (click)="onClick('Basic')">
                    <label for="Basic">
                        <input type="radio" value="Basic" formControlName="openingProtection" id="Basic" class="radio-col-light-blue" />
                        <div [innerHTML]="openingProtectionMessages.Basic | safeHtml"></div>
                    </label>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center"
                (click)="onClick('Hurricane')">
                    <label for="Hurricane">
                        <input type="radio" value="Hurricane" formControlName="openingProtection" id="Hurricane" class="radio-col-light-blue" />
                        <div [innerHTML]="openingProtectionMessages.Hurricane | safeHtml"></div>
                    </label>
                </mat-card>
            </div>
        </div>
    </div>
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>
    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
    <button class="btn btn-info custom-button-info m-r-10 m-b-20"
        *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
        [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    <button class="btn btn-info custom-button-info m-l-10 m-b-20"
        type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
        [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
</div>
</form>