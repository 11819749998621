<div class="row p-20" *ngIf="!loading">
    <div class="col-md-8 offset-md-2" style="justify-content: center;">
        <h4 class="card-title">
            <app-bot-message [message]="botMessage[0]" [subTitle]="summaryData?.address" [showImageFlag]="true"></app-bot-message>
        </h4>
    </div>
    
    <div class="col-md-8 offset-md-2">
        <div class="row">
            <div class="col-md-12 text-center p-b-30">
                <button class="btn btn-info custom-button-info" type="button"
                (click)="submitForm('submit')"
                [ngStyle]="styling?.button?.buttonPositive">{{'CALCULATE_QUOTE' | translate}}</button>
            </div>
        </div>
        <div style="color: #455a64;">
            <div class="row p-b-20" [ngStyle]="styling?.quoterWizard?.quoteTitle">
                <div class="col-md-12">
                    {{'ABOUT_YOUR_HOME' | translate}}
                </div>
            </div>
            <div class="row p-b-20">
                <div class="col-md-6 summary-info-title" [ngStyle]="styling?.quoterWizard?.quoteSubField4" (click)="redirectPageOnClick('relation-to-property')">
                    {{'RELATIONSHIP_TO_PROPERTY' | translate}}
                </div>
                <div class="col-md-6 text-md-right" [ngStyle]="styling?.quoterWizard?.quoteSubField5">
                    <!-- Owner Occupied -->
                    {{summaryData?.relationToProperty}}
                </div>
            </div>
            <div class="row p-b-20">
                <div class="col-md-6 summary-info-title" [ngStyle]="styling?.quoterWizard?.quoteSubField4" (click)="redirectPageOnClick('structure-type')">
                    {{'STRUCTURE_TYPE' | translate}}
                </div>
                <div class="col-md-6 text-md-right" [ngStyle]="styling?.quoterWizard?.quoteSubField5">
                    <!-- Single Family House -->
                    {{summaryData?.structureType}}
                </div>
            </div>
            <div class="row p-b-20" *ngIf="summaryData?.propertyUsage">
                <div class="col-md-6 summary-info-title" [ngStyle]="styling?.quoterWizard?.quoteSubField4" (click)="redirectPageOnClick('home-usage')">
                    {{'HOME_OCCUPANCY_USAGE' | translate}}
                </div>
                <div class="col-md-6 text-md-right" [ngStyle]="styling?.quoterWizard?.quoteSubField5">
                    <!-- Owner's Primary Residence -->
                    {{summaryData?.propertyUsage}}
                </div>
            </div>
            <div class="row p-b-20">
                <div class="col-md-6 summary-info-title" [ngStyle]="styling?.quoterWizard?.quoteSubField4" (click)="redirectPageOnClick('house-built')">
                    {{'YEAR_BUILT' | translate}}
                </div>
                <div class="col-md-6 text-md-right" [ngStyle]="styling?.quoterWizard?.quoteSubField5">
                    <!-- 2015 -->
                    {{summaryData?.propertyYearBuilt}}
                </div>
            </div>
            <div class="row p-b-20">
                <div class="col-md-6 summary-info-title" [ngStyle]="styling?.quoterWizard?.quoteSubField4" (click)="redirectPageOnClick('house-built')">
                    {{'SQUARE_FEET' | translate}}
                </div>
                <div class="col-md-6 text-md-right" [ngStyle]="styling?.quoterWizard?.quoteSubField5">
                    <!-- 2675 sq. ft. -->
                    {{summaryData?.propertySquareFootage}}
                </div>
            </div>
            <div class="row p-b-20">
                <div class="col-md-6 summary-info-title" [ngStyle]="styling?.quoterWizard?.quoteSubField4" (click)="redirectPageOnClick('roof-shape')">
                    {{'ROOF_SHAPE' | translate}}
                </div>
                <div class="col-md-6 text-md-right" [ngStyle]="styling?.quoterWizard?.quoteSubField5">
                    <!-- Gable -->
                    {{summaryData?.propertyRoofType}}
                </div>
            </div>
            <div class="row p-b-20">
                <div class="col-md-6 summary-info-title" [ngStyle]="styling?.quoterWizard?.quoteSubField4" (click)="redirectPageOnClick('roof-material')">
                    {{'ROOF_MATERIAL' | translate}}
                </div>
                <div class="col-md-6 text-md-right" [ngStyle]="styling?.quoterWizard?.quoteSubField5">
                    <!-- Asphalt Shingle -->
                    {{summaryData?.propertyRoofMaterial}}
                </div>
            </div>
            <div class="row p-b-20">
                <div class="col-md-6 summary-info-title" [ngStyle]="styling?.quoterWizard?.quoteSubField4" (click)="redirectPageOnClick('structure-material')">
                    {{'HOME_MATERIAL' | translate}}
                </div>
                <div class="col-md-6 text-md-right" [ngStyle]="styling?.quoterWizard?.quoteSubField5">
                    <!-- Masonry -->
                    {{summaryData?.propertyConstructionType}}
                </div>
            </div>
            <div class="row p-b-20" [ngStyle]="styling?.quoterWizard?.quoteSubField4" (click)="redirectPageOnClick('number-of-stories')" *ngIf="summaryData?.noOfStories">
                <div class="col-md-6 summary-info-title">
                    {{'NO_OF_STORIES' | translate}}
                </div>
                <div class="col-md-6 text-md-right" [ngStyle]="styling?.quoterWizard?.quoteSubField5">
                    <!-- 2 -->
                    {{summaryData?.noOfStories}}
                </div>
            </div>
            <div class="row p-b-20" *ngIf="summaryData?.openingProtectionLevel">
                <div class="col-md-6 summary-info-title" [ngStyle]="styling?.quoterWizard?.quoteSubField4" (click)="redirectPageOnClick('opening-protection')">
                    {{'OPENING_PROTECTION' | translate}}
                </div>
                <div class="col-md-6 text-md-right" [ngStyle]="styling?.quoterWizard?.quoteSubField5">
                    <!-- None -->
                    {{summaryData?.openingProtectionLevel}}
                </div>
            </div>
            <div class="row p-b-20" [ngStyle]="styling?.quoterWizard?.quoteSubTitle">
                <div class="col-md-12 text-center">
                    <button class="btn btn-info custom-button-info m-r-20" type="button"
                        (click)="submitForm('back')"
                        [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
                        <button class="btn btn-info custom-button-info" type="button"
                        (click)="submitForm('submit')"
                        [ngStyle]="styling?.button?.buttonPositive">{{'CALCULATE_QUOTE' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
    
</div> 

<div class="col-12" *ngIf="loading">
    <div class="p-b-20">
        <!-- <div class="text-center">
            <div class="message-bot-display" [ngStyle]="styling?.card?.attributeValue">
                Please give me a moment while I find the best policy coverages and a price for you
            </div>
        </div> -->
        <div class="row" style="justify-content: center;">
            <h4 class="card-title">
                <app-bot-message [message]="botMessage[1]" [showImageFlag]="true"></app-bot-message>
            </h4>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <div class="col-sm-8" style="margin-top: 100px;margin-bottom: 100px;">
            <div class="pipe-container">
                <div class="pipe-loader" [style.width.%]="progressNumber">
                    {{progressNumber}}%
                </div>
            </div>
        </div>
    </div>
</div>