import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { LocateMeService } from 'src/app/shared/services/locate-me.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { PaginationService } from 'src/app/shared/services/pagination.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';

@Component({
  selector: 'app-find-agent-in-gmap',
  templateUrl: './find-agent-in-gmap.component.html',
  styleUrls: ['./find-agent-in-gmap.component.scss'],
  providers: [CommonApiService, LocateMeService]
})
export class FindAgentInGmapComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  searchAgentForm: any;
  showLocation = false;
  riskAddress: any;
  coOrds = [];
  centerCoOrds: any;
  isDisabled = false;
  myLocation: { lat: any, lng: any }
  agentData: any;
  selectedId = '';
  gotRiskAddress = false
  tenant: any;
  searchRadiusList = [5, 10, 20, 30, 40, 50]
  pageObj: any = {}
  agentDataArray = []
  currentPage = 1
  findAgentMapFilter: any;

  urlProps = {
    url: 'assets/multiTenantImages/png/pin-black.png',
    scaledSize: { width: 35, height: 45 },
  };
  myUrlProps = {
    url: 'assets/multiTenantImages/png/pin-blue.png',
    scaledSize: { width: 35, height: 45 },
  };
  private subject: Subject<string> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    public utilsService: UtilsService,
    private httpService: CommonApiService,
    private http: CommonApiService,
    private notifyToast: NotifytoastService,
    private quotedataService: QuoteDataShare,
    private locateMeService: LocateMeService,
    private router: Router,
    private paginationService: PaginationService,
    private dataTransferService: DataTransferService,
  ) { }

  ngOnInit(): void {
    this.tenant = this.utilsService.getTenantFromWindow()
    this.initForm()
    this.getConfiguration();
    this.riskAddress = this.quotedataService.getRiskAddressObj()
    // console.log(this.riskAddress)
    // //remove code start
    //   this.riskAddress = {
    //     "city": "Mt Dora",
    //     "street": "1510 Overlook Drive",
    //     "zipCode": "32757",
    //     "stateCode": "FL",
    //     "lat": 28.8101984,
    //     "lng": -81.654769
    // }
    //     //remove code end
    this.subject.pipe(debounceTime(1000)).subscribe((resp) => {
      this.centerPositionChanged(resp);
    });
    if (this.riskAddress) {
      let data = this.getPincodesData()
      this.findAgents(data);
    }
  }

  getConfiguration(){
    const tenant = this.utilsService.getTenantFromWindow()
    this.http.get(`accountConfiguration?accountCode=${tenant}`, null, true).subscribe(resp => {
      if(resp.quote.find_agent_map_filter){
        this.findAgentMapFilter = resp.quote.find_agent_map_filter;
      }
      console.log("Find Agent Filter : " + this.findAgentMapFilter);
    })
  }

  getPincodesData() {
    let zipCode = []
    this.searchAgentForm.patchValue({ zipCode: this.riskAddress.zipCode, searchRadius: '' });
    zipCode.push(this.riskAddress.zipCode)
    let data = {
      accountCode: this.tenant,
      zipCode
    }
    return data
  }

  initForm() {
    this.searchAgentForm = this.formBuilder.group({
      zipCode: [null, [Validators.required]],
      searchRadius: [''],
    });
  }

  resetLocation() {
    this.showLocation = false
    let data = this.getPincodesData()
    this.findAgents(data);
  }

  setMyLocation() {
    let isClicked = true
    this.showLocation = true
    this.locateMeService.getMyLocation().subscribe((resp) => {
      this.myLocation = {
        lat: resp.coords.latitude,
        lng: resp.coords.longitude
      }
      if (isClicked) {
        isClicked = false
        this.findAgentsFromMap(false) //no agents found so skipping this part edge case scenarios need to be tested
      }
    });
    if (this.myLocation && this.myLocation.lat) {
      if (isClicked) {
        isClicked = false
        this.findAgentsFromMap(false) //no agents found so skipping this part edge case scenarios need to be tested
      }
    }
  }

  centerPositionChanged(coOdrsObj) {
    this.centerCoOrds = coOdrsObj;
  }

  navigateToGmap(agent) {
    if (agent) {
      const placeName = `${agent.agencyName}, ${agent.agencyLocationAddress.street}, ${agent.agencyLocationAddress.city}, ${agent.agencyLocationAddress.stateCode}, ${agent.agencyLocationAddress.zip}`
      let url = `https://www.google.com/maps/search/${placeName}`
      window.open(url, '_blank')
    }
  }

  reEnableButton() {
    this.isDisabled = true;
    setTimeout(() => {
      this.isDisabled = false;
    }, 1000);
  }

  findAgentsFromMap(isCenter?: boolean) {
    this.reEnableButton();
    let geoCoder = new google.maps.Geocoder();
    console.log(this.myLocation, this.centerCoOrds, "meaow")
    geoCoder.geocode({ location: isCenter ? this.centerCoOrds : this.myLocation }, (results, status) => {
      let foundResult = false;
      if (results.length) {
        // results[0].address_components.forEach((element) => {
        //   if (element.types.includes('postal_code')) {
        //     console.log(element.long_name);
        //     this.searchAgentForm.patchValue({ zipCode: element.long_name, searchRadius: '' });
        //     let data = {
        //       accountCode: this.tenant,
        //       zipCode: [element.long_name]
        //     }
        //     this.findAgents(data);
        //     foundResult = true;
        //   }
        // });
        // if (!foundResult)
        //   this.notifyToast.error(
        //     'Unable to pinpoint a location. Please try someother area or try entering zipcode'
        //   );
        let postalCodeObj = results.filter(elem => elem.types.includes("postal_code"))
        let streetAddObj = results.filter(elem => elem.types.includes("street_address"))
        let newPostalCodeObj = postalCodeObj.length ? postalCodeObj : streetAddObj
        if(newPostalCodeObj.length){
          let tempObj = newPostalCodeObj[0].address_components.filter(addressObj => addressObj.types.includes("postal_code"))
          if(tempObj.length){
            this.searchAgentForm.patchValue({ zipCode: tempObj[0].long_name, searchRadius: '' });
            let data = {
              accountCode: this.tenant,
              zipCode: [tempObj[0].long_name]
            }
            this.findAgents(data);
            foundResult = true;
          }
        }
        if (!foundResult)
          this.notifyToast.error(
            'Unable to pinpoint a location. Please try someother area or try entering zipcode'
          );

      }
    });
  }

  searchAgents() {
    this.reEnableButton();
    if (this.searchAgentForm.valid) {
      if (this.searchAgentForm.value.searchRadius) this.findAgentsWithinRadius(this.searchAgentForm.value)
      else {
        let tempData = {
          zipCode: [this.searchAgentForm.value.zipCode],
          accountCode: this.tenant
        };
        this.findAgents(tempData);
      }
    }
  }

  findAgentsWithinRadius(dataObj) {
    this.httpService.getRadiusSearch(
      `radiusPostalCodeLookup?countryCode=US&centerPostalCode=${dataObj.zipCode}&radiusInMiles=${dataObj.searchRadius}`, null, true).subscribe(resp => {
        let data = {
          accountCode: this.tenant,
          zipCode: resp.output
        }
        if(resp.output.length) this.findAgents(data, resp.output)
        else this.notifyToast.error("Unable to find Zip Codes near this locality. Please try again.")
      })
  }

  findAgents(searchObj, zipCodes?) {
    this.gotRiskAddress = true
    // console.log('finding agents for:', searchObj);
    console.log(searchObj.zipCode, searchObj.zipCode.length)
    // this.searchAgentForm.patchValue({ zipCode: searchObj.zipCode });
    let data;
    if (searchObj) {
      data = { ...searchObj };
    }
    // let url = `findAgents?accountCode=${this.tenant}`;
    // if (data && data.agencyName) url = `${url}&agencyName=${data.agencyName}`;
    // if (data && data.searchRadius)
    //   url = `${url}&searchRadius=${data.searchRadius}`;
    // if (data && data.zipCode) url = `${url}&zipCode=${data.zipCode}`;
    this.httpService.post('findAgents', searchObj, true, true).subscribe((resp) => {
      let filteredResp = resp
      if(searchObj && searchObj.zipCode.length && this.findAgentMapFilter == 'Agency'){
        let tempResp = resp.out.AgentDetails.filter(agentObj => searchObj.zipCode.indexOf(agentObj.agencyLocationAddress.zip) > -1)
        filteredResp = { out: {AgentDetails : tempResp} }
      } 
      else if(searchObj && searchObj.zipCode.length && this.findAgentMapFilter == 'Agent'){
        let tempResp = resp.out.AgentDetails.filter(agentObj => searchObj.zipCode.indexOf(agentObj.agentLocationAddress.zip) > -1)
        filteredResp = { out: {AgentDetails : tempResp} }
      } 
      this.gmapInitOnFindingAgents(filteredResp);
    });
  }

  gmapInitOnFindingAgents(resp) {
    let itemsInArray = 10
    if (resp.out) {
      this.agentData = [...resp.out.AgentDetails];
      this.agentDataArray = this.groupedArray(resp.out.AgentDetails)
      this.pageObj = this.paginationService.getPageDetails(this.agentData.length, 1, itemsInArray)
      this.currentPage = this.pageObj.currentPage
      this.getGeoCode(this.agentDataArray[0], 1)
      // resp.out.AgentDetails.forEach((response, index) => {
      //   let geoCoder = new google.maps.Geocoder();
      //   geoCoder.geocode(
      //     {
      //       address: `${response.agencyLocationAddress.street}, ${response.agencyLocationAddress.city}, ${response.agencyLocationAddress.stateCode}`,
      //     },
      //     (results, status) => {
      //       if (status == google.maps.GeocoderStatus.OK) {
      //         response.latFromGoogle = results[0].geometry.location.lat();
      //         response.lngFromGoogle = results[0].geometry.location.lng();
      //       }
      //       console.log(results, status, 'meaow')
      //     }
      //   );
      // });
    } else {
      this.agentData = [];
    }

  }

  groupedArray(agentData) {
    let seperatedRes = []
    while (agentData.length) {
      seperatedRes.push(agentData.splice(0, 10))
    }

    return seperatedRes;
  }

  getGeoCode(agentDetails, pageIndex) {
    agentDetails.forEach((response, index) => {
      if (!(response.latFromGoogle && response.lngFromGoogle) && this.findAgentMapFilter == 'Agency') {
        let geoCoder = new google.maps.Geocoder();
        geoCoder.geocode(
          {
            address: `${response.agencyLocationAddress.street}, ${response.agencyLocationAddress.city}, ${response.agencyLocationAddress.stateCode}`,
          },
          (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              response.latFromGoogle = results[0].geometry.location.lat();
              response.lngFromGoogle = results[0].geometry.location.lng();
              response.mileageMiles = this.calcDistance(response.latFromGoogle, response.lngFromGoogle)
            }
          }
        );
      }
      else if (!(response.latFromGoogle && response.lngFromGoogle) && this.findAgentMapFilter == 'Agent') {
        let geoCoder = new google.maps.Geocoder();
        geoCoder.geocode(
          {
            address: `${response.agentLocationAddress.street}, ${response.agentLocationAddress.city}, ${response.agentLocationAddress.stateCode}`,
          },
          (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              response.latFromGoogle = results[0].geometry.location.lat();
              response.lngFromGoogle = results[0].geometry.location.lng();
              response.mileageMiles = this.calcDistance(response.latFromGoogle, response.lngFromGoogle)
            }
          }
        );
      }
    });
  }

  calcDistance(agentLat, agentLng) {
    let mileageMeters = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(agentLat, agentLng), new google.maps.LatLng(this.riskAddress.lat, this.riskAddress.lng)
    )
    return Number(mileageMeters * 0.00062137).toFixed(2)
  }

  setPage(page, disabled?) {
    if (!disabled) {
      let itemsInArray = 10
      this.currentPage = page
      this.pageObj = this.paginationService.getPageDetails(this.agentData.length, page, itemsInArray)
      this.getGeoCode(this.agentDataArray[page - 1], 1)
    }
  }

  openWindow(id, infoWindow?) {
    if (infoWindow) {
      infoWindow.close()
    }
    this.selectedId = id;
    let element = document.getElementById(`Location_${id}`);
    element.scrollIntoView()
  }

  windowOpenCheck(id) {
    return this.selectedId == id;
  }

  closeInfo() {
    this.selectedId = null;
  }

  sendQuote(eve) {
    console.log("Selected agent details returned from the component:", eve);
    // this.quotedataService.setEmailQuote(eve)
    this.emailQuoteAgent(eve)
    // this.navigateToLink('quote-agent-submission')
  }

  navigateToLink(page) {
    this.router.navigate([`${this.tenant}/get-quote/${page}`]);
  }

  submitForm(userSelection) {
    let temp;
    if (userSelection == 'back') {
      temp = { input: 'Back', pageID: 'find-agent' }
    }
    if (temp) {
      this.dataTransferService.sendData(temp)
    }
  }


  // *** new update ***
  emailQuoteAgent(agentDetails) {
    // this.defaultAgentID = (this.quotedataService.getMappingInfoObj().defaultAgentID == undefined || this.quotedataService.getMappingInfoObj().defaultAgentID  == null )? '3245336' : this.quotedataService.getMappingInfoObj().defaultAgentID  
    const data = this.quotedataService.getQuoteObjectStructure();
    let contactDetails = this.quotedataService.getContactDetails()
    let buildingTypes = this.quotedataService.getBuildingObject().buildingTypes
    data.agentID = agentDetails ? agentDetails.agentID : '3245336'
    data.firstName = contactDetails.first_name
    data.lastName = contactDetails.last_name
    if (contactDetails.dontContact === '' && contactDetails.phone_no != '') {
      data.phoneNumber = {
        number: this.utilsService.convertPhoneNumber(contactDetails.phone_no),
        type: 'Cell',
      }
    }
    // console.log(JSON.stringify(data.phoneNumber))
    // add the default value of HomeBuildingType if there is no information collected from UI
    if (buildingTypes != null) {
      data.propertyStructureType = data.propertyStructureType === '' ? buildingTypes[0] : data.propertyStructureType;
    }
    this.submitQuote(data).subscribe(
      (resp) => {
        if (resp === undefined) {
          console.log('No response returned');
        } else {
          console.log("submit quote response: " + JSON.stringify(resp))
          let quoteID = resp.quoteReferenceNumber;
          this.quotedataService.setQuoteID(quoteID)
          let collectiveData = {
            quoteID, contactDetails, agentDetails
          }
          // console.log("quote ID from send quote to an agent: " + quoteID)
          this.sendAgentEmail(collectiveData).subscribe((sendEmailResp) => {
            if (sendEmailResp === undefined) {
              console.log('No response for sending quote by email');
            } else {
              const data = {
                "accountCode": this.tenant,
                "to": this.quotedataService.getContactDetails().email,
                "quoteID": quoteID,
                "firstName": this.quotedataService.getContactDetails().first_name,
                "lastName": this.quotedataService.getContactDetails().last_name,
                "phoneNumber": this.quotedataService.getContactDetails().phone_no
              }
              this.sendEmail(data).subscribe(
                (sendEmailResp) => {
                  if (sendEmailResp === undefined) {
                    console.log('No response for sending quote by email');
                  } else {

                    this.navigateToLink('quote-agent-submission')
                    this.notifyToast.success(sendEmailResp);
                    console.log("email quote response: " + JSON.stringify(sendEmailResp))
                  }
                });
            }
          });
        }
      });

  }

  sendEmail(data) {
    return this.httpService.post('emailQuote', data, true, true).pipe(map(resp => {
      if (resp.out === undefined) {
        this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
        console.log(JSON.stringify(resp));
      } else {
        console.log('email quote' + JSON.stringify(resp.out));
        return resp.out;
      }
    }, (error) => {
      this.notifyToast.error('Please Contact System Administrator.');
    })
    );
  }

  submitQuote(requestSubmitQuoteData): Observable<any> {
    return this.httpService.post('submitQuote', requestSubmitQuoteData, true, true).pipe(
      map((resp) => {
        if (resp.out === undefined) {
          this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          console.log(JSON.stringify(resp));
        } else {
          console.log('return value Submit Quote' + JSON.stringify(resp.out));
          return resp.out;
        }
      }
        // ,
        // (error) => {
        //   this.notifyToast.error('Please Contact System Administrator.');
        // }
      )
    );
  }

  sendAgentEmail(collectiveData) {
    let data = this.emailAgentQuoteData(collectiveData)
    return this.httpService.post('emailQuoteToAgent', data, true, true).pipe(
      map((resp) => {
        if (resp.out === undefined) {
          this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          console.log(JSON.stringify(resp));
        } else {
          console.log('email quote' + JSON.stringify(resp.out));
          return resp.out;
        }
      }
        // ,
        // (error) => {
        //   this.notifyToast.error('Please Contact System Administrator.');
        // }
      )
    );
  }

  emailAgentQuoteData(collectionData) {
    const data = {
      "accountCode": this.tenant,
      "to": collectionData.agentDetails.agentEmail,
      "quoteID": collectionData.quoteID,
      "agentFirstName": collectionData.agentDetails.agentFirstName,
      "agentLastName": collectionData.agentDetails.agentLastName,
      "insuredFirstName": collectionData.contactDetails.first_name,
      "insuredLastName": collectionData.contactDetails.last_name,
      "email": collectionData.contactDetails.email,
      "phone": collectionData.contactDetails.phone_no,
      "preferrredContact": 'email' // this.emailQuoteAgentForm.value.preferredCommunicationType hardcoded info
    }
    console.log("Email agent quote data", data);
    return data;
  }
}
