import { Component, HostListener, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-house-roof-material',
  templateUrl: './house-roof-material.component.html',
  styleUrls: ['./house-roof-material.component.scss']
})
export class HouseRoofMaterialComponent implements OnInit {
  houseRoofMaterialForm: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  isSmallWindow = window.innerWidth <= 768;
  quoteObj: any;
  roofMaterialsMapping: any;
  parentTenant: any;

  constructor(
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) {
    let tenant = this.route.parent.parent.snapshot.params.tenant
    this.parentTenant = tenant.split('-')[0].toLowerCase()
  }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.roofMaterialsMapping = this.quotedataService.getMappingInfoObj().roofMaterialsMapping
    this.initForm()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm() {
    this.houseRoofMaterialForm = this.formBuilder.group({
      roofMaterial: ['Shingles: Asphalt or Composition']
    });
    this.fromAPI()
  }

  fromAPI() {
    if (this.quoteObj.conversation && this.quoteObj.conversation['roof-material']) {
      if (this.quoteObj?.conversation['roof-material']?.roofMaterial) {
        let validRoofTypes = Object.keys(this.roofMaterialsMapping).filter(el => this.roofMaterialsMapping[el]['value'] == this.quoteObj.conversation['roof-material'].roofMaterial)
        if (validRoofTypes.length) {
          this.houseRoofMaterialForm.patchValue(this.quoteObj.conversation['roof-material'])
        }
      }
    }
  }

  submitForm(userSelection) {
    let temp;
    if (this.houseRoofMaterialForm.valid && userSelection == 'next') {
      this.quotedataService.setRoofMaterial(this.houseRoofMaterialForm.value);
      temp = { input: 'Next', pageID: 'roof-material', userInput: this.houseRoofMaterialForm.value }
    } else if (userSelection == 'back') {
      temp = { input: 'Back', pageID: 'roof-material' }
    }
    if (temp) {
      console.log(temp)
      // this.submitFormObj.emit(temp)
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve) {
    if (this.houseRoofMaterialForm.valid) {
      let temp = eve
      temp.userInput = this.houseRoofMaterialForm.value
      temp.currentPageID = 'roof-material'
      this.dataTransferService.sendData(temp)
    }
  }

}
