import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CommonApiService } from "src/app/shared/services/common-api.service";
import { NotifytoastService } from "src/app/shared/services/notificationToast.service";

@Injectable({
    providedIn: 'root'
  })
  export class SendQuoteService {
  
  constructor(
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService
  ) { }

  submitQuote(requestSubmitQuoteData): Observable<any> {
    return this.httpService.post('submitQuote', requestSubmitQuoteData, true, true).pipe(map(resp => {
        if (resp.out === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
            // console.log(JSON.stringify(resp));
        } else {
            // console.log( 'return value Submit Quote' + JSON.stringify(resp.out) );
            return resp.out;
        }
      },(error) => {
          this.notifyToast.error('Please Contact System Administrator.');
      })
    );
  }

  sendAgentEmail(data) {
    return this.httpService.post('emailQuoteToAgent', data, true, true).pipe(map(resp => {
        if (resp.out === undefined) {
          this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          console.log(JSON.stringify(resp));
        } else {
          console.log('email quote' + JSON.stringify(resp.out));
          return resp.out;
        }
      }, (error) => {
        this.notifyToast.error('Please Contact System Administrator.');
      })
    );
  }

  printQuote(data){
    return this.httpService.post('generateAndReturnQuoteDocument', data, true, true).pipe(map(resp => {
      if (resp.out === undefined) {
        this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
        // console.log(JSON.stringify(resp));
      } else {
        // console.log('print quote' + JSON.stringify(resp.out));
        return resp.out;
      }
    }, (error) => {
      this.notifyToast.error('Please Contact System Administrator.');
      })
    );
  }

  base64ToArrayBuffer(base64: string) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  convertPhoneNumber(phoneNum) {
    if (phoneNum != null) {
      return phoneNum.replace(/[-_()' ']/g, '')
    }
    return null;
  }
}