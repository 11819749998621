<form [formGroup]="relationToPropertyForm" *ngIf="quoteObj?.pageID == 'relation-to-property'">
    <div class="row p-t-10 p-b-10">
        <div *ngIf="options.includes('I own it')" class="col-lg-3 p-b-20"
            [ngClass]="{'col-mob-6' : isSmallWindow,'col-lg-6':wide}">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center" (click)="onClick('I own it')">
                    <label for="I own it">

                        <input type="radio" value="I own it" formControlName="relationToProperty" id="I own it"
                            class="radio-col-light-blue" />
                        <img [ngStyle]="styling?.quoterWizard.quoteImager"
                            [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_currently-own.svg'"
                            altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">
                        {{optionDetails["I own it"]}}
                    </p>
                </mat-card>
            </div>
        </div>
        <div *ngIf="options.includes('I\'m buying it')" class="col-lg-3 p-b-20"
            [ngClass]="{'col-mob-6' : isSmallWindow,'col-lg-6':wide}">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center" style="padding: 0px;" (click)="onClick('I\'m buying it')">
                    <label for="I'm buying it">
                        <input type="radio" value="I'm buying it" formControlName="relationToProperty"
                            id="I'm buying it" class="radio-col-light-blue" />
                        <img [ngStyle]="styling?.quoterWizard.quoteImager"
                            [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_buying.svg'"
                            altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">
                        {{optionDetails["I'm buying it"]}}</p>
                </mat-card>
            </div>
        </div>
        <div *ngIf="options.includes('I\'m renting it out')" class="col-lg-3 p-b-20"
            [ngClass]="{'col-mob-6' : isSmallWindow,'col-lg-6':wide}">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center" style="padding: 0px;"
                    (click)="onClick('I\'m renting it out')">
                    <label for="I'm renting it out">
                        <input type="radio" value="I'm renting it out" formControlName="relationToProperty"
                            id="I'm renting it out" class="radio-col-light-blue" />
                        <img [ngStyle]="styling?.quoterWizard.quoteImager"
                            [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_renting_out.svg'"
                            altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">
                        {{optionDetails["I'm renting it out"]}}
                    </p>

                </mat-card>
            </div>
        </div>
        <div *ngIf="options.includes('I\'m a renter')" class="col-lg-3 p-b-20"
            [ngClass]="{'col-mob-6' : isSmallWindow,'col-lg-6':wide}">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center" style="padding: 0px;" (click)="onClick('I\'m a renter')">
                    <label for="I'm a renter">
                        <input type="radio" value="I'm a renter" formControlName="relationToProperty" id="I'm a renter"
                            class="radio-col-light-blue" />
                        <img [ngStyle]="styling?.quoterWizard.quoteImager"
                            [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_renter.svg'"
                            altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">
                        {{optionDetails["I'm a
                        renter"]}}</p>
                </mat-card>
            </div>
        </div>
    </div>
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>
    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6" type="button"
            *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-10 m-b-20" *ngIf="quoteObj?.buttonOptions?.includes('Back')"
            type="button" (click)="submitForm('back')" [ngStyle]="styling?.button?.buttonNegative">{{'BACK' |
            translate}}</button>
        <button class="btn btn-info custom-button-info m-l-10 m-b-20" type="button"
            *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
    </div>
</form>