import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';

@Component({
  selector: 'app-coverage-amount',
  templateUrl: './coverage-amount.component.html',
  styleUrls: ['./coverage-amount.component.scss']
})
export class CoverageAmountComponent implements OnInit {

  styling = this.utilsService.getItemfromSession('themeObject');
  coverageMessage: any;
  pageID: any;
  isSmallWindow = window.innerWidth <= 768;
  quoteObj: any;
  coverageAmount: any;
  next: any;
  url: any;

  constructor(
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.initForm()
    //this.fromAPI()
    console.log(JSON.stringify(this.quoteObj))
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm() {
    this.pageID = this.quoteObj.pageID
    this.coverageAmount = this.quotedataService.getRecoopCoverageValue();
    this.coverageMessage = this.quoteObj.coverageMessage
    if (this.coverageMessage  
      && ((this.coverageMessage.includes('<coverageAmount>')))
      ) {
        console.log("enter if")
        this.coverageMessage = this.coverageMessage.replace('<coverageAmount>', this.coverageAmount)
    }
  }

  navigateToNextPage(userSelection){
    let temp;
    temp = { input: 'Next', pageID: this.pageID}

    if (temp) {
      this.dataTransferService.sendData(temp)
    }
  }

}
