<form [formGroup]="rentalTermForm" *ngIf="quoteObj?.pageID == 'rental-term'">

    <div class="form-group row m-b-20">
        <div class="col-md-4 offset-md-4 justify-content-center">
            <div style="width: 100%;" [ngStyle]="styling?.quoterWizard?.quoteSubField1">
                <ng-select id="rentalTermList" [items]="rentalTermList" appendTo="body" [hideSelected]="true"
                    placeholder="Rental Term" formControlName="rentalTerm" [ngStyle]="styling?.quoterWizard?.quoteSubField1">
                </ng-select>
            </div>
        </div>
    </div>
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>

    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
    <button class="btn btn-info custom-button-info m-r-10 m-b-20"
        *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
        [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    <button class="btn btn-info custom-button-info m-l-10 m-b-20"
        type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
        [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
</div>
</form>