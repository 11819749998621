<form [formGroup]="priorLossForm" *ngIf="quoteObj?.pageID == 'prior-loss'">

    <div class="col-12 m-b-20">
        <div class="col-md-4 offset-md-4 d-flex">
            <div class="text-center" style="width: 100%;">
                <input type="radio" value=false id="notPriorLoss"
                    formControlName="priorLoss" class="radio-col-light-blue" />
                <label [ngStyle]="styling?.fieldValueCheckBox" for="notPriorLoss"
                [ngStyle]="styling?.quoterWizard?.quoteSubTitle">No</label>
            </div>
            <div class="text-center" style="width: 100%;">
                <input type="radio" value=true id="priorLoss" formControlName="priorLoss"
                    class="radio-col-light-blue" />
                <label [ngStyle]="styling?.fieldValueCheckBox" for="priorLoss"
                [ngStyle]="styling?.quoterWizard?.quoteSubTitle">Yes</label>
            </div>
        </div>
    </div>
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>

    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-10 m-b-20"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
        <button class="btn btn-info custom-button-info m-l-10 m-b-20"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
    </div>

    <!-- <div class="row" style="margin: 20px 0; justify-content: center;">
        <button class="btn btn-info custom-button-info col-md-1 user-actions-back" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
        <button class="btn btn-info custom-button-info col-md-1 user-actions-next" type="button" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
    </div> -->
    <!-- <div class="row" style="margin: 20px 0;">
        <div class="col-md-4 offset-md-4">
            <div class="btn-group mr-2">
                <button class="btn btn-info custom-button-info user-actions-back" type="button" (click)="submitForm('back')"
                    [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
            </div>
            <div class="btn-group">
                <button class="btn btn-info custom-button-info user-actions-next" type="button" (click)="submitForm('next')"
                    [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
            </div>
        </div>
    </div> -->
</form>