import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../../shared/services/utils.service';

@Component({
  selector: 'app-insured-details',
  templateUrl: './insured-details.component.html',
  styleUrls: ['./insured-details.component.scss']
})
export class InsuredDetailsComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  contactInfoForm: any;
  isSubmitted = false;
  quoteObj: any;
  next: any;
  pageID: any;
  employeeID: any;
  lastFiveDigitsSSN: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.initForm()
    this.employeeID = this.getFormValue('emp-info', 'employeeID');
    this.lastFiveDigitsSSN = this.getFormValue('pers-info', 'lastFiveDigitsSSN');
  }

  initForm() {
    this.pageID = this.quoteObj.pageID;
    this.contactInfoForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required]
    })
    this.next = this.quoteObj.Next ? this.quoteObj.Next : null;
    this.fromAPI()
  }

  navigateToLink(page) {
    const tenant = this.utilsService.getTenantFromWindow()
    this.router.navigate([`${tenant}/${page}`]);
  }

  fromAPI() {
    if (this.quoteObj.conversation && this.quoteObj?.conversation[this.pageID]) this.contactInfoForm.patchValue(this.quoteObj.conversation[this.pageID])
  }

  getFormValue(formName, value) {
    if (this.quoteObj.conversation[formName]) {
      return this.quoteObj.conversation[formName][value]
    } else {
      return null
    }
  }

  submitForm(userSelection) {
    let temp;
    this.isSubmitted = true;
    if (this.contactInfoForm.valid && userSelection == 'next') {
      temp = {
        input: 'Next',
        pageID: this.pageID,
        userInput: this.contactInfoForm.value
      }
      this.quotedataService.setInsuredName(this.contactInfoForm.value);
    } else if (userSelection == 'back') {
      temp = { input: 'Back', pageID: this.pageID }
    }
    if (temp) {
      this.dataTransferService.sendData(temp)
    }
  }

}