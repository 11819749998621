import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PdfModalComponent } from 'src/app/shared/pdf-modal/pdf-modal.component';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { SendQuoteService } from '../send-quote/send-quote.service';

@Component({
  selector: 'app-csr-send-quote',
  templateUrl: './csr-send-quote.component.html',
  styleUrls: ['./csr-send-quote.component.scss'],
  providers: [CommonApiService]
})
export class CSRSendQuoteComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  tenant: String;
  agentDetails: any;
  displayValue={
    monthlyPremium: 0,
    yearlyPremium: 0
  }
  quoteObj: any;
  isSubmitted: boolean
  sendCSRQuoteForm: any
  quoteID: String;
  agentPhoneNumber: any;
  constructor(
    private formBuilder: FormBuilder,
    public utilsService: UtilsService,
    private notifyToast: NotifytoastService,
    private quotedataService: QuoteDataShare,
    private httpService: CommonApiService,
    private sendQuoteService: SendQuoteService,
    private modalService: BsModalService,
    private dataTransferService: DataTransferService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.quoteID = '';
    this.tenant = this.utilsService.getTenantFromWindow();
    this.quoteObj = this.quotedataService.getConfiguration();
    this.initForm();
    this.setPremium();
  }

  initForm(){
    this.sendCSRQuoteForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      phone_no: ['', [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.maxLength(14) ]],
      email: ['', [Validators.required, ValidationService.emailValidator]],
      agent_number: ['', []]
    });
  }

  setPremium() {
    let monthlyFee = JSON.stringify(this.quotedataService.getEstimatedPremium() / 12)
    this.displayValue = {
      yearlyPremium: this.utilsService.convertNumberToIS(this.quotedataService.getEstimatedPremium()),
      monthlyPremium: this.utilsService.convertNumberToIS(parseFloat(monthlyFee).toFixed(2))
    }
  }

  findAgentUserByID(){
    if(this.sendCSRQuoteForm.value.agent_number){
      let data = {
        agentNumber: this.sendCSRQuoteForm.value.agent_number,
        // agentNumber:'Y0002640',
        accountCode: this.tenant
      };
      this.httpService.post('getAgentUserByID', data, true, true).subscribe(resp => {
        console.log(resp, "show Result");
        this.agentDetails = resp.out;
        let ofcAgentObj = this.agentDetails.agentPhoneNumbers.PhoneNumber.find(res => res.type == 'Office')
        if(ofcAgentObj) this.agentPhoneNumber = ofcAgentObj.hasOwnProperty('type') ? ofcAgentObj.number : null
      }, 
      error => {
        this.agentDetails = null;
        this.notifyToast.error("Cannot find an agent. Please contact System Administrator.");
      })
    } else{
      this.agentDetails = null;
      this.notifyToast.error("Please enter the Agent Number and click Find Agent.");
    };
  }

  sendQuote(){
    this.isSubmitted = true;
    if(this.sendCSRQuoteForm.valid && this.isSubmitted){
      this.emailQuoteAgent();
    }
  }

  emailQuoteAgent() {
    let data = this.submitQuoteObj()
    if(this.quoteID) this.emailQuoteToAgentFn()
    else{
      this.sendQuoteService.submitQuote(data).subscribe((resp) => {
        if (resp === undefined) {
          console.log('No response returned');
        } else {
          this.quoteID = resp.quoteReferenceNumber;
          this.emailQuoteToAgentFn();
        }
      });
    }
      
  }
    
  emailQuoteToAgentFn(){
    let contactDetails = this.sendCSRQuoteForm.value;
    // console.log("submit quote response: " + JSON.stringify(resp));
    this.quotedataService.setQuoteID(this.quoteID);
    let collectiveData = {
      quoteID: this.quoteID, contactDetails, agentDetails: this.agentDetails
    };
    // console.log("quote ID from send quote to an agent: " + quoteID)
    this.sendAgentEmail(collectiveData).subscribe((sendEmailResp) => {
      if (sendEmailResp === undefined) {
        console.log('No response for sending quote by email');
      } else {
        const data = {
          "accountCode": this.tenant,
          "to": contactDetails.email,
          "quoteID": this.quoteID,
          "firstName": contactDetails.first_name,
          "lastName": contactDetails.last_name,
          "phoneNumber": contactDetails.phone_no
        };
        this.sendEmail(data).subscribe((sendEmailResp) => {
          if (sendEmailResp === undefined) {
            console.log('No response for sending quote by email');
          } else {
            this.navigateToLink('quote-agent-submission');
            this.notifyToast.success(sendEmailResp);
            console.log("email quote response: " + JSON.stringify(sendEmailResp));
          }
        });
      }
    });
  }

  sendAgentEmail(collectiveData) {
    let data = this.emailAgentQuoteData(collectiveData);
    return this.httpService.post('emailQuoteToAgent', data, true, true).pipe(
      map((resp) => {
        if (resp.out === undefined) {
          this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          console.log(JSON.stringify(resp));
        } else {
          console.log('email quote' + JSON.stringify(resp.out));
          return resp.out;
        }
      }
        // ,
        // (error) => {
        //   this.notifyToast.error('Please Contact System Administrator.');
        // }
      )
    );
  }

  emailAgentQuoteData(collectionData) {
    const data = {
      "accountCode": this.tenant,
      "to": collectionData.agentDetails.agentEmail,
      "quoteID": collectionData.quoteID,
      "agentFirstName": collectionData.agentDetails.agentFirstName,
      "agentLastName": collectionData.agentDetails.agentLastName,
      "insuredFirstName": collectionData.contactDetails.first_name,
      "insuredLastName": collectionData.contactDetails.last_name,
      "email": collectionData.contactDetails.email,
      "phone": collectionData.contactDetails.phone_no,
      "preferrredContact": 'email' // this.emailQuoteAgentForm.value.preferredCommunicationType hardcoded info
    };
    return data;
  }

  sendEmail(data) {
    return this.httpService.post('emailQuote', data, true, true).pipe(map(resp => {
      if (resp.out === undefined) {
        this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
        console.log(JSON.stringify(resp));
      } else {
        console.log('email quote' + JSON.stringify(resp.out));
        return resp.out;
      }
    }, (error) => {
      this.notifyToast.error('Please Contact System Administrator.');
    })
    );
  }

  navigateToLink(page) {
    this.router.navigate([`${this.tenant}/get-quote/${page}`]);
  }

  printQuoteSelect(){
    this.isSubmitted = true;
    if(this.sendCSRQuoteForm.valid){
      let data = this.submitQuoteObj();
      if(this.quoteID) this.printQuoteFn();
      else{
        this.sendQuoteService.submitQuote(data).subscribe((resp) => {
          if (resp === undefined) {
            console.log('No response returned');
          } else {
            this.quoteID = resp.quoteReferenceNumber;
            this.printQuoteFn();
          }
        });
      }
    }
  }

  printQuoteFn(){
    this.quotedataService.setQuoteID(this.quoteID);
    let data = {
      "accountCode": this.tenant,
      "quoteID": this.quoteID
    };
    this.sendQuoteService.printQuote(data).subscribe(resp => {
      if(resp){
        let contentType = resp.contentType ? resp.contentType : 'application/pdf'
        var file = this.sendQuoteService.base64ToArrayBuffer(resp.contents)
        var fileArrayBuffer = new Blob([file], {type: contentType})
        var fileURL = window.URL.createObjectURL(fileArrayBuffer)
        this.showModal(fileArrayBuffer, fileURL)
      };
    });
  }

  submitQuoteObj(){
    const data = this.quotedataService.getQuoteObjectStructure();
    data.agentID = this.agentDetails.agentID;
    data.firstName =  this.sendCSRQuoteForm.value.first_name;
    data.lastName =  this.sendCSRQuoteForm.value.last_name;
    data.email = this.sendCSRQuoteForm.value.email;
    data.phoneNumber = {
      // number: (this.sendCSRQuoteForm.value.dontContact === '') && (this.sendCSRQuoteForm.value.phone_no != '') ?  this.convertPhoneNumber(this.sendCSRQuoteForm.value.phone_no) : null ,
      number: this.sendQuoteService.convertPhoneNumber(this.sendCSRQuoteForm.value.phone_no),
      type:'Cell',
    }
    console.log(JSON.stringify(data.phoneNumber))
    this.quotedataService.setContactDetails(this.sendCSRQuoteForm.value)
    // add the default value of HomeBuildingType if there is no information collected from UI
    let buildingTypes = this.quotedataService.getBuildingObject().buildingTypes
    if (buildingTypes != null) {
      data.propertyStructureType = data.propertyStructureType === '' ? buildingTypes[0] : data.propertyStructureType;
    }
    return data
  }

  showModal(file, fileURL){
    const modal = this.modalService.show(PdfModalComponent, {backdrop: 'static', class: 'modal-lg'});
    (<PdfModalComponent>modal.content).showConfirmationModal('<b>Quote Details</b>', file, `Close`, 'Download File');
    (<PdfModalComponent>modal.content).onClose.subscribe(resp => {
      if(resp){
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = 'File';
        link.click();
      }
    })
  }

  submitForm(userSelection){
    let temp;
    this.isSubmitted = true;
    if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'csr-contact' }
    }
    if(temp){
      this.dataTransferService.sendData(temp)
    }
  }

}
