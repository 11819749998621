import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-residence-type',
  templateUrl: './residence-type.component.html',
  styleUrls: ['./residence-type.component.scss']
})
export class ResidenceTypeComponent implements OnInit {

  propertyUsageForm: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any;
  pageID: any;
  isSmallWindow = window.innerWidth <= 768;
  next: any;
  helpText: any;
  selectionTwo: any;

  constructor(
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.initForm()
    this.fromAPI()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm() {
    this.pageID = this.quoteObj.pageID
    this.propertyUsageForm = this.formBuilder.group({
      propertyUsage: [this.quoteObj.defaultReply]
    });
    this.helpText = this.quoteObj.helpText;
    this.selectionTwo = "Vacation/Secondary";
    this.next = this.quoteObj.Next ? this.quoteObj.Next : null;
  }

  fromAPI() {
    if (this.quoteObj.conversation && this.quoteObj.conversation[this.pageID]) {
      this.propertyUsageForm.patchValue({
        propertyUsage: this.quoteObj.conversation[this.pageID]
      })
    }
  }


  onClick(eve) {
    this.propertyUsageForm.patchValue({
      propertyUsage: eve
    });
  }

  submitForm(userSelection) {
    let temp;
    if (this.propertyUsageForm.valid && userSelection == 'next') {
      temp = { input: 'Next', pageID: this.pageID, userInput: this.propertyUsageForm.value }
    } else if (userSelection == 'back') {
      temp = { input: 'Back', pageID: this.pageID }
    }
    if (temp) {
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve) {
    if (this.propertyUsageForm.valid) {
      let temp = eve
      temp.userInput = this.propertyUsageForm.value
      temp.currentPageID = this.pageID;
      this.dataTransferService.sendData(temp)
    }
  }

}
