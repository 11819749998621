import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'app-pre-enrollment',
  templateUrl: './pre-enrollment.component.html',
  styleUrls: ['./pre-enrollment.component.scss']
})
export class PreEnrollmentComponent implements OnInit {
  @ViewChild('b_date_input', {static: false}) dateEle: ElementRef
  styling = this.utilsService.getItemfromSession('themeObject');
  preEnrollmentForm: any;
  isSubmitted = false;
  quoteObj: any;
  next: any;
  pageID: any;
  accessUsingSSN: boolean = false;
  accessUsingEmployeeID: boolean = false;
  errorMsgSSN: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute,
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.initForm()
    this.displayErrorMsg()
  }

  initForm() {
    this.pageID = this.quoteObj.pageID;
    this.next = this.quoteObj.Next ? this.quoteObj.Next : null;
    this.accessUsingSSN = this.quoteObj.allowedFields ? this.quoteObj.allowedFields.includes("accessUsingSSN") : false;  
    this.accessUsingEmployeeID = this.quoteObj.allowedFields ? this.quoteObj.allowedFields.includes("accessUsingEmployeeID") : false;
    this.buildForm();
    this.fromAPI()
  }
  //this.quotedataService.getMappingInfoObj().coverageCOrCoverageCtoCoverageA.includes(this.policyType)

  navigateToLink(page) {
    const tenant = this.utilsService.getTenantFromWindow()
    this.router.navigate([`${tenant}/${page}`]);
  }

  buildForm() {
    if (this.accessUsingEmployeeID) {
     this.preEnrollmentForm = this.formBuilder.group({
       employeeID: ['', Validators.required]
     });
    } else if(this.accessUsingSSN){
      this.preEnrollmentForm = this.formBuilder.group({
        lastFiveDigitsSSN: ['', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(5)]],
        dateOfBirth: this.formBuilder.group({
          b_month: [''],
          b_date: ['']
        }, {validators: ValidationService.DaysInMonthCheck}),
      });
    }
  }

  fromAPI() {
    if (this.quoteObj.conversation && this.quoteObj?.conversation[this.pageID]) this.preEnrollmentForm.patchValue(this.quoteObj.conversation[this.pageID])
  }

  submitForm(userSelection) {
    this.isSubmitted = true;
    if (this.preEnrollmentForm.valid && userSelection == 'next') {
      this.preEnrollmentAPI();
    } else if(userSelection == 'back'){
      let temp = {
        input: 'Back',
        pageID: this.pageID
      }
    }
  }

  preEnrollmentAPI() {
    var requestData = {}
    let tenant = this.utilsService.getTenantFromWindow()
    if (this.accessUsingSSN) {
      requestData = {
        "ssnNumber": this.preEnrollmentForm.value.lastFiveDigitsSSN,
        "dateOfBirth" :  this.preEnrollmentForm.get('dateOfBirth.b_month').value + this.preEnrollmentForm.get('dateOfBirth.b_date').value,
        "accountCode": tenant
      }
    }
    if (this.accessUsingEmployeeID) {
      requestData = {
        "employeeId": this.preEnrollmentForm.value.employeeID,
        "accountCode": tenant
      }
    }

    this.httpService
    .post('doPreEnrollmentCheck', requestData, true, true)
    .subscribe(
      (resp) => {
        if (resp.out === undefined) {
          let err = resp.root ? resp.root.Envelope.Body.Fault.faultstring : resp.Error ? resp.Error : 'Please Contact System Administrator.'
          this.notifyToast.error(err);
        }
        else {
          console.log("test", resp)
          let temp = {
            input: 'Next',
            pageID: this.pageID,
            userInput: this.preEnrollmentForm.value
          }
          temp.userInput.errorCode = parseInt(resp.out.errorCode);
          temp.userInput.accountNumber = resp.out.accountNumber;
          this.quotedataService.setAccountNumberPreEnrollment(resp.out.accountNumber);
          this.dataTransferService.sendData(temp)
        }
      },
      (error) => {
        this.notifyToast.error('Please Contact System Administrator.');
      }
    );
  }

  enterValue(eve){
    if(eve.length > 1)
    this.dateEle.nativeElement.focus()
  }

  displayErrorMsg(){
    let tempError = this.preEnrollmentForm.get('lastFiveDigitsSSN')
    if (tempError.errors) {
      if (tempError.errors.required) {
        this.errorMsgSSN = "REQUIRED_FIELD"
      } else if(tempError.errors.pattern){
        this.errorMsgSSN = "LAST_FIVE_DIGITS_PATTERN_ERRMSG"
      } else if(tempError.errors.minlength){
        this.errorMsgSSN = "LAST_FIVE_DIGITS_MINLENGTH_ERRMSG"
      }
    }
  }

}
