<form [formGroup]="numberOfStoriesForm" *ngIf="quoteObj?.pageID == 'number-of-stories'">
    <div class="form-group row p-b-20">
        <div class="w-100 d-flex justify-content-center">
            <div style="width: 180px;">
                <!-- <mat-label class="custom-title-info account-title">Number of Stories * </mat-label> -->
                <ng-select id="stories_7" [items]="stories" appendTo="body" [hideSelected]="true"
                    formControlName="story" placeholder="Number of Stories">
                </ng-select>
                <p class="error-class" *ngIf="!numberOfStoriesForm.get('story').valid && isSubmitted">
                    Number of stories is required</p>
            </div>
        </div>
    </div>
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>
    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
    <button class="btn btn-info custom-button-info m-r-10 m-b-20"
        *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
        [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    <button class="btn btn-info custom-button-info m-l-10 m-b-20"
        type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
        [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
</div>
</form>