import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-purchase-confirmation',
  templateUrl: './purchase-confirmation.component.html',
  styleUrls: ['./purchase-confirmation.component.scss']
})
export class PurchaseConfirmationComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  confirmationTitle: any;
  confirmationContent: any;
  pageID: any;
  isSmallWindow = window.innerWidth <= 768;
  quoteObj: any;
  firstName: any;
  policyNumber: any;
  next: any;
  url: any;
  effectiveDate: any;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService,
    private sanitizer: DomSanitizer,
    private quotedataService: QuoteDataShare
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.initForm()
    //this.fromAPI()
    console.log(JSON.stringify(this.quoteObj))
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm() {
    this.pageID = this.quoteObj.pageID
    this.policyNumber = this.quotedataService.getPolicyNumber()
    this.effectiveDate = this.quotedataService.getEffectiveDate()
    this.firstName = this.quotedataService.getInsuredName()?.firstName
    this.confirmationTitle = this.quoteObj.pageContentPart1
    if (this.confirmationTitle  
      && ((this.confirmationTitle.includes('<FirstName>')
      || this.confirmationTitle.includes('<policyNumber>')
      || this.confirmationTitle.includes('<effectiveDate>')))
      ) {
        console.log("enter if")
        this.confirmationTitle = this.confirmationTitle.replace('<FirstName>', this.firstName)
        this.confirmationTitle = this.confirmationTitle.replace('<policyNumber>', this.policyNumber)
        this.confirmationTitle = this.confirmationTitle.replace('<effectiveDate>', this.effectiveDate)
        console.log("the confirmamationtile after replacement: " + this.confirmationTitle)
    }
    this.confirmationContent = this.quoteObj.pageContentPart2

    this.next = this.quoteObj.Next ? this.quoteObj.Next : null
    this.url = this.quoteObj.navigationURL
  }

  navigateToLink() {
   //window.location.href = this.url;
   window.open(this.url, '_blank')
  }
}
