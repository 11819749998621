
<div class="row">
    <div class="col-md-8 offset-md-2"> 
            <div [innerHTML]="confirmationTitle" style="text-align:justify;"  [ngStyle]="styling?.pageTitle"></div>
        </div>
        <div class="col-md-12 text-center p-20">
            <button class="btn btn-info" (click)="navigateToLink()"
            [ngStyle]="styling?.button?.buttonPositive">{{ this.next || 'NEXT' | translate}}</button>
        </div>
        <div class="col-md-8 offset-md-2">
            <div [innerHTML]="confirmationContent" style="padding-top: 25px; text-align:justify;" [ngStyle]="styling?.card?.content" ></div>
        </div>
</div>


<!-- <app-purchase-confirmation class="ng-star-inserted">
    <div class="row">
        <div class="col-md-8 offset-md-2">
        <div style="text-align: left;font-size: 24px;font-family: proximanova-semibold;color: rgba(0, 0, 0, 0.85);font-weight: unset;">
            <p>Congrats! You’ve filled a major gap left by traditional insurance after a disaster.</p>
            <p>Your policy number is: HO0001333800. </p>
            <p>We've sent you an email with all the details. In the meantime, head over to the Insurity Portal to see your policy documents.</p></div></div>
        <div class="col-md-12 text-center" style="align-content: center;"><button class="btn btn-info" style="background-color: rgb(215, 19, 157); border: 1px solid rgb(215, 19, 157); font-size: 16px; font-family: SourceSansPro-bold; font-weight: unset;">Go to Customer Portal</button></div>
        <div class="col-md-8 offset-md-2">
            <div style="text-align: left; padding-top: 25px;">
                <p>*Coverage begins 14 days after policy effective date.</p>
                <p>To receive your benefit, you must meet three simple claims requirements: You must have an underlying renters or homeowners insurance policy, your home or apartment must be in a state or federally declared disaster area, and you must have sustained damages of $1,000 or more as the result of one of Insurity’s covered perils.</p>
                <p>&copy; 2022 Strategic Product Partners dba Recoop Disaster Insurance</p>
                <p>Descriptions on this page were developed for information and educational purposes only and do not represent a specific contract of insurance. Coverage is subject to the terms and conditions of the actual policy. We encourage you to read the full policy for understanding of coverage and qualifications. Product availability and features vary by state. Premiums are subject to change. All terms and conditions of the insurance policy and attached endorsements apply.</p>
                <p>Insurity is a property and casualty insurance product underwritten by Professional Solutions Insurance Company.</p>
            </div>
        </div>
    </div>
</app-purchase-confirmation> -->