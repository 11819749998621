<div [formGroup]="coverageValues">
    <div class="col-12">
        
        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue">
            <div class="col-mob-6">
                <span style="font-weight: 600; width: 20%; font-size:24px;">Coverages</span>
            </div>
            <div class="col-mob-6">
                <span style="font-weight: 600; width: 20%; font-size:24px;">Limit Amount</span>
            </div>
        </div>
        
        <div class="row custom-title-info account-title coverage-container"   *ngIf="coverageA"  [ngStyle]="styling?.card?.attributeValue">
            <div class="col-mob-6 text-box-heading">
                <span>{{coverageAFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageAValue" type="text"/>  
                </mat-form-field>  
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        *ngIf="coverageB">
            <div class="col-mob-6 text-box-heading">
                <span>{{coverageBFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageBValue" type="text" />
                </mat-form-field>   
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        *ngIf="coverageC">
            <div class="col-mob-6 text-box-heading">
                <span>{{coverageCFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <div *ngIf="curPolicyType === 'HO3'">
                    <ng-select id="coverageCValue_1" [searchable]="false" [items]="this.coverageForms.coverageCValue" 
                            appendTo="body" [hideSelected]="true"
                            placeholder="coverage C Value" formControlName="coverageCValue"
                            class="text-field-mat">
                        </ng-select>
                        <p class="error-class" *ngIf="!coverageValues.get('coverageCValue').valid">coverage C Value is required</p> 
                </div>
                <div *ngIf="curPolicyType !== 'HO3'">
                    <mat-form-field class="text-field-mat">
                        <input matInput formControlName="coverageCValue" type="text"/>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        *ngIf="coverageD">
            <div class="col-mob-6 text-box-heading">
                <span>{{coverageDFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageDValue" type="text"/>
                </mat-form-field>
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        *ngIf="coverageE">
            <div class="col-mob-6 text-box-heading">
                <span>{{coverageEFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <ng-select id="coverageEValue_!" [searchable]="false" [items]="coverageForms.coverageEValue" 
                    appendTo="body" [hideSelected]="true"
                    placeholder="coverage E Value" formControlName="coverageEValue"
                    class="text-field-mat">
                </ng-select>
                <p class="error-class" *ngIf="!coverageValues.get('coverageEValue').valid">
                    coverage E Value is required</p>
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        *ngIf="coverageF">
            <div class="col-mob-6 text-box-heading">
                <span>{{coverageFFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageFValue" type="text"/>
                </mat-form-field>  
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        *ngIf="coverageL">
            <div class="col-mob-6 text-box-heading">
                <span>{{coverageLFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageLValue" type="text"/>
                </mat-form-field>  
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        *ngIf="coverageM">
            <div class="col-mob-6 text-box-heading">
                <span>{{coverageMFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageMValue" type="text"/>
                </mat-form-field>  
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        *ngIf="CoverageDandE">
            <div class="col-mob-6 text-box-heading">
                <span>{{coverageDandEFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageDandE" type="text"/>
                </mat-form-field>  
            </div>
        </div>

        <hr style="height:5px;border-width:0;color:gray;background-color:gray; margin-top: 20px; margin-bottom: 20px;">

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue">
            <div class="col-mob-6">
                <span style="font-weight: 600; width: 20%; font-size:24px;">Deductibles</span>
            </div>
            <div class="col-mob-6">
                <span style="font-weight: 600; width: 20%; font-size:24px;">Deductible Amount</span>
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        *ngIf="aop">
            <div class="col-mob-6 text-box-heading">
                <span>{{aopFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <ng-select id="aopDeductible" [searchable]="false" [items]="coverageForms.aopDeductible" appendTo="body" [hideSelected]="true"
                    placeholder="aop Deductible  Value" formControlName="aopDeductible"
                    class="text-field-mat">
                </ng-select>
                <p class="error-class" *ngIf="!coverageValues.get('aopDeductible').valid">aop Deductible is required</p>  
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        *ngIf="hurricane">
            <div class="col-mob-6 text-box-heading">
                <span>{{hurricaneFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <ng-select id="deductibleApplied_2" [searchable]="false" [items]="coverageForms.deductibleAppliedForHurricaneCoverage" appendTo="body" [hideSelected]="true"
                    placeholder="Hurricane Deductible" formControlName="deductibleAppliedForHurricaneCoverage"
                    class="text-field-mat">
                </ng-select>
                <p class="error-class" *ngIf="!coverageValues.get('deductibleAppliedForHurricaneCoverage').valid">
                Hurricane Deductible is required</p> 
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        *ngIf="waterDamage">
            <div class="col-mob-6 text-box-heading">
                <span>{{waterDamageFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="waterDeductible" type="text" />
                </mat-form-field>  
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        *ngIf="sinkhole">
            <div class="col-mob-6 text-box-heading">
                <span>{{sinkholeFieldName}}</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="sinkholeDeductible" type="text" />
                </mat-form-field>  
            </div>
        </div>

    </div>
</div>

