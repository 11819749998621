import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../shared/services/utils.service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from '../../shared/services/common-api.service';
import { MatStepper } from '@angular/material/stepper';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { Observable, of } from 'rxjs';
import { AnyRecord } from 'dns';
import { flatMap, map } from 'rxjs/operators';
import { ValidationService } from '../../shared/services/validation.service';

@Component({
  selector: 'app-get-a-quote',
  templateUrl: './get-a-quote.component.html',
  styleUrls: ['./get-a-quote.component.scss'],
  providers: [CommonApiService, DatePipe],
})
export class GetAQuoteComponent implements OnInit {
  curPolicyType: any
  agentID: any;
  submitQuoteResponse: any;
  selectedPremium: any;
  rateQuoteResult: any;
  coverageValues = {
    coverageAValue: null,
    coverageBValue: null,
    coverageCValue: null,
    coverageDValue: null,
    coverageEValue: null,
    coverageFValue: null,
    aopDeductible: null,
    deductibleAppliedForHurricaneCoverage: null,
    waterDeductible: null,
  };
  estimatedPremium = {
    silverEstimatedPremium: null,
    goldEstimatedPremium: null,
    platinumEstimatedPremium: null,
  };
  styling = this.utilsService.getItemfromSession('themeObject');
  datePickerSettings = {
    isAnimated: true,
    adaptivePosition: true,
    dateInputFormat: 'DD MMM YYYY',
    showWeekNumbers: false
  };
  quoteForm: FormGroup;
  goldResponse: any;
  emailError = '';
  silverResponse: any;
  platiniumResponse: any;
  propertiesList = [];
  buildingMaterials = [];
  buildingTypes = [];
  stories = [];
  roofShapes = [];
  foundationTypes = ['Concrete Slab', 'Walk-out basement'];
  tenant: any;
  isSubmitted = false;
  today = new Date();
  currentSelectedAddress = {};
  constructor(
    private http: CommonApiService,
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private notifyToast: NotifytoastService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.tenant = this.route.parent.snapshot.params.tenant;
    this.initialForm();
    this.getConstrainedValues();
    this.getAgentInfo();
  }

  getAgentInfo(){
    //1. getPolicyInfoByPolicyNumber? 2. find agents? 3. agent ID
    this.agentID = "951925";
    console.log("agency info" + this.agentID);
  }
  convertGoogleAddresstoStructure(googleAddress) {
    // consult this site for detail  https://stackoverflow.com/questions/34605207/best-way-to-get-address-components-from-google-places-api-response
    for (var i = 0; i < googleAddress.address_components.length; i++) {
      var c = googleAddress.address_components[i];
      console.log('attribute: ', c);
      if (c.types[0] == 'administrative_area_level_1')
        this.currentSelectedAddress['state'] = c;
      else {
        if (c.types[0] == 'locality') this.currentSelectedAddress['city'] = c;
        else this.currentSelectedAddress[c.types[0]] = c;
      }
      console.log('attribute:', this.currentSelectedAddress[c.types[0]]);
    }
    console.log('converted address:', this.currentSelectedAddress);
    this.currentSelectedAddress['formatted_address'] =
      googleAddress.formatted_address;
  }

  addressChange(eve) {
    console.log('Address:', this.quoteForm.get('address').value);
    var insuranceType = this.tenant.toUpperCase()+" "+ this.currentSelectedAddress['state']['long_name']+" "+this.quoteForm.value.policyType;
    this.quoteForm.get('address').updateValueAndValidity();
    var requestRateQuoteData = {
      accountCode: this.tenant,
      phoneNumber: {
        number: null,
        type: null,
      },
      insuranceType: insuranceType,
      policyEffectiveDate: this.quoteForm.value.startDate.toLocaleDateString('en-US'),

      propertyAddress: {
        street:
          this.currentSelectedAddress['street_number']['long_name'] +
          ' ' +
          this.currentSelectedAddress['route']['long_name'],
        city: this.currentSelectedAddress['city']['long_name'],
        stateCode: this.currentSelectedAddress['state']['short_name'],
        zip: this.currentSelectedAddress['postal_code']['long_name'],
      },

      mailingmailingAddress: {
        street: null,
        city: null,
        stateCode: null,
        zip: null,
      },
    };
    console.log("requestRateQuoteData:",requestRateQuoteData)
    this.getPropertyInfo(requestRateQuoteData);
  }

  getAddress(eve) {
    console.log(eve['formatted_address']);
    console.log('address components:', eve);
    this.convertGoogleAddresstoStructure(eve);
    this.quoteForm.patchValue({
      address: eve['formatted_address'],
    });
    this.addressChange(eve);
  }

  initialForm() {
    this.quoteForm = this.formBuilder.group({
      policyType:['',[Validators.required]],
      startDate: [this.today, [Validators.required]],
      address: ['', [Validators.required]],
      property: [null, [Validators.required]],
      year: ['', [Validators.required]],
      buildingMaterial: [null, [Validators.required]],
      squareFeet: [null, [Validators.required]],
      buildingType: [null, [Validators.required]],
      story: [null, [Validators.required]],
      roofShape: [null, [Validators.required]],
      roofYear: [null, [Validators.required]],
      foundationType: [null, [Validators.required]],
      nearFireHydrant: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      ph_number: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),
          Validators.maxLength(14),
        ],
      ],
      countryCode: [
        '1',
        [
          Validators.required,
          Validators.pattern('^[0-9]+$'),
          Validators.maxLength(3),
        ],
      ],
      email: ['', [Validators.required, ValidationService.emailValidator]],
      mailing_street: ['', [Validators.required]],
      mailing_street2: [''],
      mailing_city: ['', [Validators.required]],
      mailing_state: ['', [Validators.required]],
      mailing_zip: ['', [Validators.required]],
      isMailingSameAsProperty: [false],
    });
  }
  mailingChange(eve) {
    if (eve.target.checked) {
      this.quoteForm.patchValue({
        mailing_street:
          this.currentSelectedAddress['street_number']['long_name'] +
          ' ' +
          this.currentSelectedAddress['route']['long_name'],
        mailing_city: this.currentSelectedAddress['city']['long_name'],
        mailing_state: this.currentSelectedAddress['state']['short_name'],
        mailing_zip: this.currentSelectedAddress['postal_code']['long_name'],
      });
    }
  }
  stepperSelected(selectedTab) {}
  resetStepper(stepper: MatStepper) {
    stepper.selectedIndex = 0;
  }
  goForward(stepper: MatStepper) {
    this.isSubmitted = true;
    var insuranceType = this.tenant.toUpperCase()+" "+ this.currentSelectedAddress['state']['long_name']+" "+this.quoteForm.value.policyType;
    const data = {
      //TODO: map fistName and lastName, phone number and type
      //the value of phone type should be one of the list type get from backend
      firstName: "test", 
		  lastName: "test",
      accountCode: this.tenant,
      phoneNumber: {
        number: "4084445212",
        type: "Cell",
      },
      insuranceType: insuranceType,
      policyEffectiveDate: this.quoteForm.value.startDate.toLocaleDateString('en-US'),

      propertyAddress: {
        street:
          this.currentSelectedAddress['street_number']['long_name'] +
          ' ' +
          this.currentSelectedAddress['route']['long_name'],
        city: this.currentSelectedAddress['city']['long_name'],
        stateCode: this.currentSelectedAddress['state']['short_name'],
        zip: this.currentSelectedAddress['postal_code']['long_name'],
      },

      mailingAddress: {
        street: this.quoteForm.value.mailing_street,
        city: this.quoteForm.value.mailing_city,
        stateCode: this.quoteForm.value.mailing_state,
        zip: this.quoteForm.value.mailing_zip,
      },
      propertyUsage: this.quoteForm.value.property,
      propertyYearBuilt: this.quoteForm.value.year,
      propertyConstructionType: this.quoteForm.value.buildingMaterial,
      propertySquareFootage: this.quoteForm.value.squareFeet,
      propertyStructureType: this.quoteForm.value.buildingType,
      propertyNumberOfStories: this.quoteForm.value.story,
      propertyRoofType: this.quoteForm.value.roofShape,
      propertyRoofYearLastRenovated: this.quoteForm.value.roofYear,
      is1000FeetFromFireHydrant: this.quoteForm.value.nearFireHydrant,
      coverageAValue: null,
      coverageBValue: null,
      coverageCValue: null,
      coverageDValue: null,
      coverageEValue: null,
      coverageFValue: null,
      aopDeductible: null,
      deductibleAppliedForHurricaneCoverage: null,
      waterDeductible: null,
      agentID: this.agentID 
    };
    console.log('Data:', data);
    if (stepper.selectedIndex === 0) {
      this.selectedPremium = 'gold';
      this.rateQuote(data).subscribe(
        (resp) => {
          if (resp === undefined) {
            console.log('No response returned');
          } else {
            this.goldResponse = resp;
            console.log('gold response' + JSON.stringify(this.goldResponse));
            let goldPremium = this.goldResponse.rate.estimatedPremium;
            this.applyResponseToCoverageValues(
              this.goldResponse,
              this.coverageValues
            );
            this.applyResponseToCoverageValues(this.goldResponse, data);
            this.estimatedPremium.goldEstimatedPremium = Math.round(
              goldPremium / 12
            );
          
            let valuePlatinum = null;
            let valueSilver = null;

            if (this.curPolicyType === "HO4") {
               valuePlatinum = Number(data.coverageCValue) * Number(1.25);
               valueSilver = Number(data.coverageCValue) * Number(0.9);
            } else {
               valuePlatinum = Number(data.coverageAValue) * Number(1.25);
               valueSilver = Number(data.coverageAValue) * Number(0.9);
            }
            
            if (this.curPolicyType === "HO4") {
              data.coverageCValue = valuePlatinum
            } else {
              data.coverageAValue = valuePlatinum;
            }
            
            this.rateQuote(data).subscribe(
              (respPlatinum) => {
                if (respPlatinum === undefined) {
                  console.log('No response returned');
                } else {
                  console.log(
                    'platinum response' + JSON.stringify(respPlatinum)
                  );
                  this.platiniumResponse = respPlatinum;
                  let platinumPremium = this.platiniumResponse.rate
                    .estimatedPremium;
                  this.estimatedPremium.platinumEstimatedPremium = Math.round(
                    platinumPremium / 12
                  );
                }
              },
              (err) => {
                this.notifyToast.error('Please Contact System Administrator.');
                console.log(err);
              }
            );

            if (this.curPolicyType === "HO4") {
              data.coverageCValue = valueSilver;
            } else {
              data.coverageAValue = valueSilver;
            }

            console.log('data of silver' + JSON.stringify(data));
            this.rateQuote(data).subscribe(
              (respSilver) => {
                if (resp === undefined) {
                  console.log('No response returned');
                } else {
                  console.log('silver response' + JSON.stringify(respSilver));
                  this.silverResponse = respSilver;
                  let silverPremium = this.silverResponse.rate.estimatedPremium;
                  this.estimatedPremium.silverEstimatedPremium = Math.round(
                    silverPremium / 12
                  );
                }
              },
              (err) => {
                this.notifyToast.error('Please Contact System Administrator.');
                console.log(err);
              }
            );
          }
        },
        (err) => {
          this.notifyToast.error('Please Contact System Administrator.');
          console.log(err);
        }
      );
      //Calculate silver and paltinum based on policy form
      // HO3 and HO6 based on coverage A 25% plus for platinum, 10% less for silver
      // HO4 based on coverage C
    }

    if (stepper.selectedIndex === 1) {
      this.applyResponseToCoverageValues(
        this.coverageValues,
        data
      );
      console.log("data to request submit quote" + JSON.stringify(data))
      this.submitQuote(data).subscribe(
        (respSubmitQuote) => {
          if (respSubmitQuote === undefined) {
            console.log('No response returned');
          } else {
            console.log(
              'submit quote response' + JSON.stringify(respSubmitQuote)
            );
            this.submitQuoteResponse = respSubmitQuote;
            console.log("submit quote response" + JSON.stringify(this.submitQuoteResponse));
          }
        },
        (err) => {
          this.notifyToast.error('Please Contact System Administrator.');
          console.log(err);
        }
      );
    }

    stepper.next();
  }
  navigateToLink(page) {
    this.router.navigate([`${this.tenant}/${page}`]);
  }

  getConstrainedValues() {
    const policyForm = 'HO3';
    const state = 'FL';
    this.http
      .get(
        `getConstrainedValues?accountCode=${this.tenant}&policyForm=${policyForm}&state=${state}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error(
              'Please Contact System Administrator.' +
                '\n ' + resp.root.Envelope.Body.Fault.faultstring
            );
          } else {
            this.propertiesList = resp.out.propertyUsage.string;
            this.buildingMaterials = resp.out.constructionType.string;
            this.buildingTypes = resp.out.structureType.string;
            this.stories = resp.out.numberOfStories.string;
            this.roofShapes = resp.out.roofShape.string;
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }
  applyResponseToCoverageValues(response, applyTo) {
    if (response != null) {
      applyTo.coverageAValue = response.coverageAValue;
      applyTo.coverageBValue = response.coverageBValue;
      applyTo.coverageCValue = response.coverageCValue;
      applyTo.coverageDValue = response.coverageDValue;
      applyTo.coverageEValue = response.coverageEValue;
      applyTo.coverageFValue = response.coverageFValue;
      applyTo.aopDeductible = response.aopDeductible;
      applyTo.deductibleAppliedForHurricaneCoverage =
        response.deductibleAppliedForHurricaneCoverage;
      applyTo.waterDeductible = response.waterDeductible;
    }
  }

  rateQuote(requestRateQuoteData): Observable<any> {
    return this.http.post('rateQuote', requestRateQuoteData, true, true).pipe(
      map(
        (resp) => {
          if (resp.out === undefined) {
            console.log(resp)
            this.notifyToast.error(
              'Please Contact System Administrator.' +
                '\n ' + resp.root.Envelope.Body.Fault.faultstring
            );
          } else {
            console.log('return value Rate Quote' + JSON.stringify(resp.out));
            return resp.out;
          }
        }
        // ,
        // (error) => {
        //   this.notifyToast.error('Please Contact System Administrator.');
        // }
      )
    );
  }

  submitQuote(requestSubmitQuoteData): Observable<any> {
    return this.http.post('submitQuote', requestSubmitQuoteData, true, true).pipe(
      map(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error(
              'Please Contact System Administrator.' +
                '\n ' + resp.root.Envelope.Body.Fault.faultstring
            );
          } else {
            console.log('return value Submit Quote' + JSON.stringify(resp.out));
            return resp.out;
          }
        }
        // ,
        // (error) => {
        //   this.notifyToast.error('Please Contact System Administrator.');
        // }
      )
    );
  }

  getPropertyInfo(requestRateQuoteData) {
    this.http
      .post('getPropertyInfo', requestRateQuoteData, true, true)
      .subscribe(
        (resp) => {
          if (resp.out === undefined) {
            console.log("error:",resp)
            this.notifyToast.error(
              'Please Contact System Administrator.' +
                '\n ' 
            );
          } else {
            console.log('return value Rate Quote' + JSON.stringify(resp.out));
            this.quoteForm.patchValue({
              squareFeet: resp.out['propertySquareFootage'],
            });
            this.quoteForm.patchValue({ property: resp.out['propertyUsage'] });
            this.quoteForm.patchValue({ year: resp.out['propertyYearBuilt'] });
            this.quoteForm.patchValue({
              buildingMaterial: resp.out['propertyConstructionType'],
            });
            this.quoteForm.patchValue({
              buildingType: resp.out['propertyStructureType'],
            });
            this.quoteForm.patchValue({
              nearFireHydrant: resp.out['is1000FeetFromFireHydrant'],
            });
            this.quoteForm.patchValue({
              story: resp.out['propertyNumberOfStories'],
            });
            this.quoteForm.patchValue({
              roofYear: resp.out['propertyRoofYearLastRenovated'],
            });
            this.quoteForm.patchValue({
              roofShape: resp.out['propertyRoofType'],
            });

            this.rateQuoteResult = resp.out;
          }
          return this.rateQuoteResult;
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  selectPremium(eve) {
    // this.selectedPremium = "silver";
    if (eve === 1) {
      this.selectedPremium = 'silver';
      this.applyResponseToCoverageValues(
        this.silverResponse,
        this.coverageValues
      );
    } else if (eve === 2) {
      this.selectedPremium = 'gold';
      this.applyResponseToCoverageValues(
        this.goldResponse,
        this.coverageValues
      );
    } else if (eve === 3) {
      this.selectedPremium = 'platinum';
      this.applyResponseToCoverageValues(
        this.platiniumResponse,
        this.coverageValues
      );
    }
  }
  setPolicyType(type){
    this.quoteForm.patchValue({
      policyType: type  
    });
    this.curPolicyType = type;
    console.log(type);

  }
  keyEmail() {
               const temp = this.quoteForm.get('email');
               if (temp.errors) {
                 if (temp.errors.required) {
                   this.emailError = 'Email is required';
                 } else if (temp.errors.invalidEmailAddress) {
                   this.emailError = 'Email is not valid.';
                 }
               }
             }

}
