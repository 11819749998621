import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-number-of-claims',
  templateUrl: './number-of-claims.component.html',
  styleUrls: ['./number-of-claims.component.scss']
})
export class NumberOfClaimsComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  numberOfClaimsForm: any;
  isSubmitted = false;
  quoteObj: any;
  
  isSmallWindow = window.innerWidth <= 768;
  constructor(
    private formBuilder: FormBuilder, 
    private router: Router, 
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.initForm()
  }

    @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

    initForm(){
    this.numberOfClaimsForm = this.formBuilder.group({
      claims: ['', [Validators.required, Validators.min(1)]], 
    })
  }

    submitForm(userSelection){
    let temp;
    this.isSubmitted = true;
    if(this.numberOfClaimsForm.valid && userSelection == 'next'){
      temp = { 
        input: 'Next',
        pageID: 'number-of-claims',
        userInput: this.numberOfClaimsForm.value
      }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'number-of-claims' }
    }
    if(temp){
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve){
    this.isSubmitted = true;
    if (this.numberOfClaimsForm.valid) {
      let temp = eve
      temp.userInput = this.numberOfClaimsForm.value
      temp.currentPageID = 'number-of-claims'
      this.dataTransferService.sendData(temp)
    }
  }

}

