import { Injectable } from "@angular/core";
import { QuoteDataShare } from "src/app/shared/services/quoteDataShare.service";

@Injectable({
    providedIn: 'root'
})

export class DisplayPremiumService {

    constructor(
        private quotedataService: QuoteDataShare
    ) { }

    assignGetConstrainedValues(resp) {
        let coverageItemList = {
            coverageBValue: [],
            coverageCValue: [],
            coverageDValue: [],
            coverageEValue: [],
            coverageEPremisesLiabValue: [],
            coverageFValue: [],
            coverageLValue: [],
            coverageMValue: [],
            coverageDandE: [],
            aopDeductible: [],
            deductibleAppliedForHurricaneCoverage: [],
            waterDeductible: [],
            sinkholeDeductible: []
        }

        let buildingMaterials = resp.out?.constructionType?.string;
        let buildingTypes = resp.out.structureType?.string;
        let stories = resp.out.numberOfStories?.string;
        let roofMaterials = resp.out.roofMaterial?.string;
        let buildingObj = { buildingMaterials, buildingTypes, stories, roofMaterials }
        this.quotedataService.setBuildingObject(buildingObj)
        if (resp.out.coverageBValue != null) {
            coverageItemList.coverageBValue = resp.out.coverageBValue.string;
        }
        if (resp.out.recommendedPercentageOfCoverageCtoCoverageA != null) {
            let tempCoverageCValues = resp.out.recommendedPercentageOfCoverageCtoCoverageA.CoverageCPercentageValue;
            let tempCoverageC = [];
            for (let coverageC of tempCoverageCValues) {
                tempCoverageC.push(coverageC.displayValue);
            }
            coverageItemList.coverageCValue = tempCoverageC;
        }
        if (resp.out.coverageDValue != null) {
            coverageItemList.coverageDValue = resp.out.coverageDValue.string;
        }
        if (resp.out.coverageEValue != null) {
            coverageItemList.coverageEValue = resp.out.coverageEValue.string;
        }
        if (resp.out.coverageEPremisesLiab != null) {
            coverageItemList.coverageEPremisesLiabValue = resp.out.coverageEPremisesLiab.string;
        }
        if (resp.out.coverageFValue != null) {
            coverageItemList.coverageFValue = resp.out.coverageFValue.string;
        }
        if (resp.out.coverageLValue != null) {
            coverageItemList.coverageLValue = resp.out.coverageLValue.string;
        }
        if (resp.out.coverageMValue != null) {
            coverageItemList.coverageMValue = resp.out.coverageMValue.string;
        }
        if (resp.out.aopDeductible != null) {
            coverageItemList.aopDeductible = resp.out.aopDeductible.string;
        }
        if (resp.out.deductibleAppliedForHurricaneCoverage != null) {
            coverageItemList.deductibleAppliedForHurricaneCoverage = resp.out.deductibleAppliedForHurricaneCoverage.string;
        }
        if (resp.out.waterDeductible != null) {
            coverageItemList.waterDeductible = resp.out.waterDeductible.string;
        }
        if (resp.out.CoverageDandE != null) {
            coverageItemList.coverageDandE = resp.out.CoverageDandE.string;
        }
        if (resp.out.sinkholeDeductible != null) {
            coverageItemList.sinkholeDeductible = resp.out.sinkholeDeductible.string;
        }
        // this.quotedataService.setCoverageItemList(coverageItemList)
        return coverageItemList
    }

}