import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';

import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-start-policy-date-form',
  templateUrl: './start-policy-date-form.component.html',
  styleUrls: ['./start-policy-date-form.component.scss']
})
export class StartPolicyDateFormComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  // @Input() dataObj: any;
  // @Output() submitFormObj = new EventEmitter()
  policyDateForm: any;
  isSubmitted = false;
  datePickerSettings = {
    isAnimated: true,
    adaptivePosition: true,
    dateInputFormat: 'MM/DD/YYYY',
    showWeekNumbers: false
  };
  //dateInputFormat: 'DD MMM YYYY',
  minDate= new Date();
  maxDate = new Date();
  
  quoteObj: any;
  isSmallWindow = window.innerWidth <= 768;
  
  constructor(
    private formBuilder : FormBuilder,
    private utilsService : UtilsService,
    private router: Router,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.initForm()
    let effectiveDate = this.quotedataService.getAcntConfig().effectiveDate
    this.maxDate.setDate(this.maxDate.getDate() + effectiveDate)
    this.fromAPI()
  }
 
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }
  
  initForm(){
    this.policyDateForm = this.formBuilder.group({
      startDate: [new Date(), Validators.required],
    })
  }

  navigateToLink(page) {
    const tenant = this.utilsService.getTenantFromWindow()
    this.router.navigate([`${tenant}/${page}`]);
  }

  fromAPI(){
    const startDateVal = this.quoteObj.conversation && this.quoteObj.conversation['policy-start-date']
    if(startDateVal && startDateVal.startDate){
      let date = new Date(startDateVal.startDate)
      this.policyDateForm.patchValue({startDate: date})
    }
  }

  submitForm(userSelection){
    let temp;
    this.isSubmitted = true;
    if(this.policyDateForm.valid && userSelection == 'next'){
        // temp = { input: 'Next', pageID: this.dataObj.pageID, userInput: this.policyDateForm.value }
        temp = { input: 'Next', pageID: 'policy-start-date', userInput: this.policyDateForm.value }
    } else if(userSelection == 'back'){
      // temp = { input: 'Back', pageID: this.dataObj.pageID }
      temp = { input: 'Back', pageID: 'policy-start-date' }
    }
    if(temp){
      console.log(temp)
      // this.submitFormObj.emit(temp)
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve){
    this.isSubmitted = true;
    if (this.policyDateForm.valid) {
      let temp = eve
      temp.userInput = this.policyDateForm.value
      temp.currentPageID = 'policy-start-date'
      this.dataTransferService.sendData(temp)
    }
  }

}
