import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CommonApiService } from "./common-api.service";
import { UtilsService } from "./utils.service";

@Injectable({
    providedIn: 'root'
})

export class AuthQuoteService {
    constructor(
        public router: Router, 
        private utilsService: UtilsService,
        private http: CommonApiService
    ) { }


  
    checkMaintenance(): Observable<string> {
        let pageName: any;
        let consizeData: any;
        let maintenanceSettings = {
          maintenanceControl: false,
          timeSetting: new Date,
        //   reminderStartPriorDT : 1,
        };
        pageName =  this.utilsService.getTenantFromWindow(); 
        return this.http.get(`accountConfiguration?accountCode=${pageName}`, null, true).pipe(map(resp => {
            if(resp){
                let data = { ...resp.quoteMaintenanceMode }
                if (data != null && data.control != null && data.UTCTime != null) {
                    sessionStorage.setItem('maintenanceMode', JSON.stringify(data));
                    sessionStorage.setItem('maintenanceModeTenant', pageName);
                    maintenanceSettings.maintenanceControl = data.control;
                    if (maintenanceSettings.maintenanceControl) {
                        maintenanceSettings.timeSetting = new Date(data.UTCTime); 
                        consizeData = {
                            maintenanceTime: maintenanceSettings.timeSetting,
                            maintenanceControl: maintenanceSettings.maintenanceControl,
                            alertInfo: data.alertInfo,
                            hasMaintenanceInfo: true
                        }
                        return consizeData            
                    } else {
                        consizeData = {
                            maintenanceTime: maintenanceSettings.timeSetting,
                            maintenanceControl: maintenanceSettings.maintenanceControl,
                            hasMaintenanceInfo: true
                        }
                        return consizeData
                    }
                }
            } else{
                consizeData = {
                    hasMaintenanceInfo: false
                }
                return consizeData
            }
        }))
    
    }    
}
