import { PriorInsuranceComponent } from './latest-get-quote-demo/prior-insurance/prior-insurance.component';
import { NumberOfStoriesComponent } from './latest-get-quote-demo/number-of-stories/number-of-stories.component';
import { PriorLossComponent } from './latest-get-quote-demo/prior-loss/prior-loss.component';
import { SeasonalResidenceComponent } from './latest-get-quote-demo/seasonal-residence/seasonal-residence.component';
import { KnockOutComponent } from './latest-get-quote-demo/knock-out/knock-out.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { SiteNotFoundComponent } from '../shared/site-not-found/site-not-found.component';
import { PublicComponent } from './public.component';
import { GetAQuoteComponent } from './get-a-quote/get-a-quote.component';
import { DemoGetAQuoteComponent } from './demo-get-aquote/demo-get-aquote.component';
import { GetQuoteComponent } from './get-quote/get-quote.component';
import { PermissionDeniedComponent } from '../shared/permission-denied/permission-denied.component';
import { FindAgentsInGmapComponent } from './get-quote/find-agents-in-gmap/find-agents-in-gmap.component';
import { MaintenanceViewComponent } from './maintenance-view/maintenance-view.component';
import { AuthGuardMaintenance } from '../shared/services/auth-guard.service';
import { CommonApiService } from '../shared/services/common-api.service';
import { AuthService } from '../shared/services/auth.service';
import { LatestGetQuoteDemoComponent } from './latest-get-quote-demo/latest-get-quote-demo.component';
import { LatestGetQuoteResolver } from './latest-get-quote-demo/latest-get-quote-demo.resolver';
import { WelcomeFormComponent } from './latest-get-quote-demo/welcome-form/welcome-form.component';
import { GoogleAddressFormComponent } from './latest-get-quote-demo/google-address-form/google-address-form.component';
import { StartPolicyDateFormComponent } from './latest-get-quote-demo/start-policy-date-form/start-policy-date-form.component';
import { HouseTypeFormComponent } from './latest-get-quote-demo/house-type-form/house-type-form.component';
import { DatePipe } from '@angular/common';
import { ContactInfoFormComponent } from './latest-get-quote-demo/contact-info-form/contact-info-form.component';
import { IsRentalFormComponent } from './latest-get-quote-demo/is-rental-form/is-rental-form.component';
import { HomeUsageFormComponent } from './latest-get-quote-demo/home-usage-form/home-usage-form.component';
import { HouseRoofTypeComponent } from './latest-get-quote-demo/house-roof-type/house-roof-type.component';
import { RoofBuiltFormComponent } from './latest-get-quote-demo/roof-built-form/roof-built-form.component';
import { HouseBuiltFormComponent } from './latest-get-quote-demo/house-built-form/house-built-form.component';
import { AdditionalHouseDetailsFormComponent } from './latest-get-quote-demo/additional-house-details-form/additional-house-details-form.component';
import { PremiumSelectFormComponent } from './latest-get-quote-demo/premium-select-form/premium-select-form.component';
import { PublicResolver } from './public.resolver';
import { SubmitQuoteFormComponent } from './latest-get-quote-demo/submit-quote-form/submit-quote-form.component';
import { DisplayPremiumComponent } from './latest-get-quote-demo/display-premium/display-premium.component';
import { FindAgentInGmapComponent } from './latest-get-quote-demo/find-agent-in-gmap/find-agent-in-gmap.component';
import { SendQuoteToAgentComponent } from './latest-get-quote-demo/send-quote-to-agent/send-quote-to-agent.component';
import { AgentEmailConfirmationComponent } from './latest-get-quote-demo/agent-email-confirmation/agent-email-confirmation.component';
import { EmailQuoterComponent } from './latest-get-quote-demo/email-quoter/email-quoter.component';
import { EmailQuoterConfirmationComponent } from './latest-get-quote-demo/email-quoter-confirmation/email-quoter-confirmation.component';
import { EditCoveragesComponent } from './latest-get-quote-demo/edit-coverages/edit-coverages.component';
import { GoogleCoOrdinatesResolver } from './latest-get-quote-demo/google-address-form/google-co-ordinates.resolver';
import { HouseRoofMaterialComponent } from './latest-get-quote-demo/house-roof-material/house-roof-material.component';
import { RelationToPropertyComponent } from './latest-get-quote-demo/relation-to-property/relation-to-property.component';
import { OpeningProtectionComponent } from './latest-get-quote-demo/opening-protection/opening-protection.component';
import { CoverageAPageComponent } from './latest-get-quote-demo/coverage-a-page/coverage-a-page.component';
import { CoverageCPageComponent } from './latest-get-quote-demo/coverage-c-page/coverage-c-page.component';
import { PreventBrowserBackGuard } from '../shared/services/prevent-browser-back.service';
import { QuoteSummaryComponent } from './latest-get-quote-demo/quote-summary/quote-summary.component';
import { QuoteSummarySpeComponent } from './latest-get-quote-demo/quote-summary-spe/quote-summary-spe.component';
import { SendQuoteComponent } from './latest-get-quote-demo/send-quote/send-quote.component';
import { NumberOfClaimsComponent } from './latest-get-quote-demo/number-of-claims/number-of-claims.component';
import { LawSuitComponent } from './latest-get-quote-demo/law-suit/law-suit.component';
import { QuoteSubmissionConfirmationComponent } from './latest-get-quote-demo/quote-submission-confirmation/quote-submission-confirmation.component';
import { AuthGuardMaintenanceOnlineQuoter } from '../shared/services/auth-guard-online-quoter.service';
import { YesNoComponent } from './latest-get-quote-demo/yes-no/yes-no.component';
import { RecoopQuotePremiumComponent } from './latest-get-quote-demo/custom-recoop/recoop-quote-premium/recoop-quote-premium.component';
import { MakePaymentComponent } from './latest-get-quote-demo/make-payment/make-payment.component';
import { PurchaseConfirmationComponent } from './latest-get-quote-demo/purchase-confirmation/purchase-confirmation.component';
import { InsuredNameComponent } from './latest-get-quote-demo/insured-name/insured-name.component';
import { RecoopContactInformationComponent } from './latest-get-quote-demo/custom-recoop/reccop-contact-information/recoop-contact-information.component';
import { ExteriorOfHomeComponent } from './latest-get-quote-demo/exterior-of-home/exterior-of-home.component';
import { PreEnrollmentComponent } from './latest-get-quote-demo/pre-enrollment/pre-enrollment.component';
import { PayrollDeductComponent } from './latest-get-quote-demo/payroll-deduct/payroll-deduct.component';
import { CSRSendQuoteComponent } from './latest-get-quote-demo/csr-send-quote/csr-send-quote.component';
import { InsuredDetailsComponent } from './latest-get-quote-demo/custom-recoop/insured-details/insured-details.component';
import { RentalTermComponent } from './latest-get-quote-demo/custom-sfi/rental-term/rental-term.component';
import { ResidenceTypeComponent } from './latest-get-quote-demo/custom-recoop/residence-type/residence-type.component';
import { CoverageAmountComponent } from './latest-get-quote-demo/custom-recoop/coverage-amount/coverage-amount.component';

// const permissionKeys = require("../shared/services/permission-keys.json")
// const pageMapping = require("../shared/services/public-routes.json")

const routes: Routes = [
    // {path: 'maintenance', component: MaintenanceViewComponent},
    { path: 'site-not-found', component: SiteNotFoundComponent },
    {
        path: ':tenant', redirectTo: ':tenant/get-quote', pathMatch: 'full',
        // canActivate: [AuthGuardMaintenance],
        //  canDeactivate: [PreventBrowserBackGuard]
    },
    {
        path: ':tenant',
        component: PublicComponent,
        resolve: { accountConfiguration: PublicResolver },
        // canActivate: [AuthGuardMaintenance],
        // canDeactivate: [PreventBrowserBackGuard],
        children: [
            // { path: 'get-a-quote', component: GetAQuoteComponent, canActivate:[AuthGuardMaintenance]},
            // { path: 'demo-get-a-quote', component: DemoGetAQuoteComponent, canActivate:[AuthGuardMaintenance]},
            // { path: 'get-quote-stable',component:GetQuoteComponent, 
            //     data: {
            //         key: [permissionKeys.GET_A_QUOTE]
            //     }
            // , canActivate:[AuthGuardMaintenance]},
            // {path: 'find-agents', component: FindAgentsInGmapComponent , canActivate:[AuthGuardMaintenance]},
            { path: 'maintenance', component: MaintenanceViewComponent },
            // {path: 'send-quote', component: SendQuoteComponent },
            // {path: 'quote-submission', component: QuoteSubmissionConfirmationComponent},


            // { path: 'get-quote', component: LatestGetQuoteDemoComponent, 
            //     children:[
            //         // { path: 'display-premium', component: DisplayPremiumComponent },
            //         // { path: 'find-agent', component: FindAgentInGmapComponent },
            //         // { path: 'send-quote-agent', component: SendQuoteToAgentComponent },
            //         { path: 'edit-coverages', component: WelcomeFormComponent },

            //     ]
            // },

            // ******* If 2nd Authguard doesn't work please use 1st one *******
            // { path: 'get-quote', component: LatestGetQuoteDemoComponent, resolve: {formDetails: LatestGetQuoteResolver}, canActivate:[AuthGuardMaintenance],
            {
                path: 'get-quote', component: LatestGetQuoteDemoComponent, resolve: { formDetails: LatestGetQuoteResolver }, canActivate: [AuthGuardMaintenanceOnlineQuoter], canActivateChild: [AuthGuardMaintenanceOnlineQuoter],
                children: [
                    { path: 'property-location', component: GoogleAddressFormComponent, resolve: { coOrdinatesObj: GoogleCoOrdinatesResolver } },
                    { path: 'policy-start-date', component: StartPolicyDateFormComponent },
                    { path: 'residence-type', component: ResidenceTypeComponent},
                    { path: 'rental-term', component: RentalTermComponent },
                    { path: 'rental-verify-knock-out', component: KnockOutComponent},
                    { path: 'relation-to-property', component: RelationToPropertyComponent },
                    { path: 'structure-type', component: HouseTypeFormComponent },
                    { path: 'home-usage', component: IsRentalFormComponent },
                    { path: 'house-built', component: HouseBuiltFormComponent },
                    { path: 'roof-shape', component: HouseRoofTypeComponent },
                    { path: 'roof-material', component: HouseRoofMaterialComponent },
                    { path: 'roof-age', component: RoofBuiltFormComponent },
                    { path: 'structure-material', component: AdditionalHouseDetailsFormComponent },
                    { path: 'number-of-stories', component: NumberOfStoriesComponent },
                    { path: 'Coverage-C', component: CoverageCPageComponent },
                    { path: 'Coverage-A', component: CoverageAPageComponent },
                    { path: 'personal-info', component: ContactInfoFormComponent },
                    { path: 'submit-quote', component: SubmitQuoteFormComponent },
                    { path: 'quote-premium', component: DisplayPremiumComponent },
                    { path: 'find-agent', component: FindAgentInGmapComponent },
                    // { path: 'send-quote-agent', component: SendQuoteToAgentComponent },
                    { path: 'email-quote', component: EmailQuoterComponent },
                    { path: 'edit-coverages', component: EditCoveragesComponent },
                    { path: 'number-of-claims', component: NumberOfClaimsComponent },
                    { path: 'prior-lawsuit', component: LawSuitComponent },
                    { path: 'claim-knock-out', component: KnockOutComponent },
                    { path: 'lawsuit-knock-out', component: KnockOutComponent },
                    { path: 'opening-protection', component: OpeningProtectionComponent },
                    { path: 'prior-insurance', component: PriorInsuranceComponent },
                    { path: 'prior-loss', component: PriorLossComponent },
                    { path: 'Seasonal-Residence', component: SeasonalResidenceComponent },
                    { path: 'Mobile-Home-Knock-Out', component: KnockOutComponent },
                    { path: 'square-feet-knock-out', component: KnockOutComponent },
                    { path: 'New-Construction-Knock-Out', component: KnockOutComponent },
                    { path: 'agent-email-confirmation', component: AgentEmailConfirmationComponent },
                    { path: 'email-quote-confirmation', component: EmailQuoterConfirmationComponent },
                    { path: 'quote-summary', component: QuoteSummaryComponent },
                    { path: 'Quote-Summary', component: QuoteSummarySpeComponent },
                    { path: 'Home-Usage-Select', component: HomeUsageFormComponent },
                    { path: 'Welcome-Page', component: WelcomeFormComponent },
                    { path: 'Coverage-Select', component: PremiumSelectFormComponent },
                    { path: 'contact', component: SendQuoteComponent },
                    { path: 'csr-contact', component: CSRSendQuoteComponent },
                    // { path: 'quote-submission', component: QuoteSubmissionConfirmationComponent },
                    { path: 'quote-self-submission', component: QuoteSubmissionConfirmationComponent },
                    { path: 'quote-carrier-submission', component: QuoteSubmissionConfirmationComponent },
                    { path: 'quote-agent-submission', component: QuoteSubmissionConfirmationComponent },
                    { path: 'eligibility-knock-out', component: KnockOutComponent },
                    { path: 'capacity-knock-out', component: KnockOutComponent },
                    { path: 'property-verify-knock-out', component: KnockOutComponent },
                    { path: 'property-cost-knock-out', component: KnockOutComponent },
                    { path: 'address-verify-knock-out', component: KnockOutComponent },
                    { path: 'mobile-home', component: YesNoComponent },
                    { path: 'primary-policy', component: YesNoComponent },
                    { path: 'primary-policy-knock-out', component: KnockOutComponent },
                    { path: 'residence', component: YesNoComponent },
                    { path: 'residence-knock-out', component: KnockOutComponent },
                    { path: 'mobile-home-knock-out', component: KnockOutComponent },
                    { path: 'make-payment', component: MakePaymentComponent, resolve: { coOrdinatesObj: GoogleCoOrdinatesResolver } },
                    { path: 'purchase-confirmation', component: PurchaseConfirmationComponent },
                    { path: 'mobile-home-knock-out', component: KnockOutComponent },
                    { path: 'spe-quote-premium', component: RecoopQuotePremiumComponent },
                    { path: 'insured-name', component: InsuredNameComponent },
                    { path: 'insured-details', component: InsuredDetailsComponent},
                    { path: 'contact-information', component: RecoopContactInformationComponent },
                    { path: 'state-knock-out', component: KnockOutComponent },
                    { path: 'rate-quote-knock-out', component: KnockOutComponent },
                    { path: 'purchase-failed-knock-out', component: KnockOutComponent },
                    { path: 'exterior-of-home', component: ExteriorOfHomeComponent },
                    { path: 'pre-enrollment', component: PreEnrollmentComponent },
                    { path: 'emp-info', component: PreEnrollmentComponent},
                    { path: 'pers-info', component: PreEnrollmentComponent},
                    { path: 'eligibility-name-and-address-knock-out', component: KnockOutComponent },
                    { path: 'employee-status-knock-out', component: KnockOutComponent },
                    { path: 'payroll-deduct', component: PayrollDeductComponent },
                    { path: 'rate-quote-summary-knock-out', component: KnockOutComponent },
                    { path: 'coverage-amount', component: CoverageAmountComponent},
                    
                ]
            }
        ]
    },
    {
        path: ':tenant',
        children: [
            // { path: 'maintenance', component: MaintenanceViewComponent },
            { path: 'under-construction', component: UnderConstructionComponent },
            { path: 'permission-denied', component: PermissionDeniedComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top' })],
    exports: [RouterModule],
    providers: [CommonApiService, AuthService, DatePipe, AuthGuardMaintenance, LatestGetQuoteResolver, PublicResolver, GoogleCoOrdinatesResolver, PreventBrowserBackGuard, AuthGuardMaintenanceOnlineQuoter],
})
export class PublicRoutingModule { }
