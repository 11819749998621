import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PdfModalComponent } from 'src/app/shared/pdf-modal/pdf-modal.component';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { LatestGetQuoteDemoService } from '../latest-get-quote-demo.service';
import { SendQuoteService } from './send-quote.service';

@Component({
  selector: 'app-send-quote',
  templateUrl: './send-quote.component.html',
  styleUrls: ['./send-quote.component.scss'],
  providers: [CommonApiService]
})
export class SendQuoteComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  sendQuoteForm: any;
  tenant: any;
  displayValue: {
    yearlyPremium: any,
    monthlyPremium: any
  };
  // pageObj: {
  //   pageMessage: any,
  //   pageSubTitle: any
  // }
  showImageFlag = false
  // agentDetails: any
  buildingTypes: any
  defaultAgentID: any
  quoteObj: any;
  isSubmitted = false
  quoteID: any; 
  companyDetails: any;
  agentBtn = ''
  constructor(
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quoteDataService: QuoteDataShare,
    private router: Router,
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService,
    private sendQuoteService: SendQuoteService,
    private dataTransferService: DataTransferService,
    private translate: TranslateService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.tenant = this.utilsService.getTenantFromWindow()
    this.quoteObj = this.quoteDataService.getConfiguration();
    this.companyDetails = this.quoteDataService.getAgencyConfig()
    if(this.companyDetails.companyShortName){
      this.translate.get('SEND_TO_AGENT', {agent : this.companyDetails.companyShortName}).subscribe(text =>{
        this.agentBtn = text
      })
    }
    // let monthlyFee: number;
    let monthlyFee = JSON.stringify(this.quoteDataService.getEstimatedPremium()/12)
    this.buildingTypes = this.quoteDataService.getBuildingObject().buildingTypes
    this.displayValue = {
      yearlyPremium: this.utilsService.convertNumberToIS(this.quoteDataService.getEstimatedPremium()),
      monthlyPremium: this.utilsService.convertNumberToIS(parseFloat(monthlyFee).toFixed(2))
    }
    this.defaultAgentID = (this.quoteDataService.getMappingInfoObj().defaultAgentID == undefined || this.quoteDataService.getMappingInfoObj().defaultAgentID  == null )
      ? '3245336' : this.quoteDataService.getMappingInfoObj().defaultAgentID
    // this.pageObj = this.quoteDataService.getPageTitle();
    this.initForm()
  }

  initForm(){
    this.sendQuoteForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      phone_no: ['', [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.maxLength(14) ]],
      email: ['', [Validators.required, ValidationService.emailValidator]],
      dontContact: ['']
    });
    this.fromAPI()
  }
  

  // emailSend(){
  //   if(this.sendQuoteForm.value.dontContact){
  //     this.sendQuoteForm.get('email').clearValidators()
  //     this.sendQuoteForm.get('phone_no').clearValidators()
  //   } else{
  //     this.sendQuoteForm.get('email').setValidators([Validators.required, ValidationService.emailValidator])
  //     this.sendQuoteForm.get('phone_no').setValidators([Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.maxLength(14)])
  //   }
  //   this.sendQuoteForm.get('email').updateValueAndValidity();
  //   this.sendQuoteForm.get('phone_no').updateValueAndValidity();
  // }

  fromAPI(){
    if(this.quoteObj.conversation['contact']) this.sendQuoteForm.patchValue(this.quoteObj.conversation['contact'])
  }
  // sendQuotetoAgent(){
  //   this.isSubmitted = true;
  //   const data = this.quoteDataService.getQuoteObjectStructure();
  //   data.agentID = this.defaultAgentID
  //   data.firstName =  this.sendQuoteForm.value.first_name
  //   data.lastName =  this.sendQuoteForm.value.last_name
  //   // add the default value of HomeBuildingType if there is no information collected from UI
  //   if (this.buildingTypes != null) {
  //     data.propertyStructureType = data.propertyStructureType === '' ? this.buildingTypes[0] : data.propertyStructureType;
  //   }
  //   this.submitQuote(data).subscribe(
  //     (resp) => {
  //     if (resp === undefined) {
  //       console.log('No response returned');
  //     } else {
  //       console.log("submit quote response: " + JSON.stringify(resp))
  //       let quoteID = resp.quoteReferenceNumber;
  //       // this.quoteDataService.setQuoteID(quoteID)
  //       // console.log("quote ID from send quote to an agent: " + quoteID)
  //       this.sendAgentEmail(this.emailAgentQuoteData(quoteID)).subscribe(
  //         (sendEmailResp) => {
  //         if (sendEmailResp === undefined) {
  //           console.log('No response for sending quote by email');
  //         } else {
  //           // this.getCustomerResource();
  //           this.navigateToLink('quote-submission')
  //           this.notifyToast.success(sendEmailResp);
  //           console.log("email quote response: " + JSON.stringify(sendEmailResp))
  //         }
  //       });
  //     }
  //   });
  // }

  sendQuoteTo(sendQuote){
    this.isSubmitted = true
    if(this.sendQuoteForm.valid){
      let data = this.submitQuoteObj()
        this.sendQuoteService.submitQuote(data).subscribe((resp) => {
          if (resp === undefined) {
            console.log('No response returned');
          } else {
            console.log("submit quote response: " + JSON.stringify(resp))
            this.quoteID = resp.quoteReferenceNumber;
            this.quoteDataService.setQuoteID(this.quoteID)
            if(sendQuote == 'email') this.emailQuoteToSelf(true)
            else if(sendQuote == 'printQuote'){
              let data = {
                  "accountCode": this.tenant,
                  "quoteID": this.quoteID
                }
              this.sendQuoteService.printQuote(data).subscribe(resp => {
                if(resp){
                  // let contentType = resp.contentType ? resp.contentType : 'application/pdf'
                  // var file = new Blob([this.sendQuoteService.base64ToArrayBuffer(resp.contents)], {type: contentType})
                  // var fileURL = window.URL.createObjectURL(file)
                  // console.log(file, fileURL)
                  // window.open(fileURL, '_blank')
                  let contentType = resp.contentType ? resp.contentType : 'application/pdf'
                  var file = this.sendQuoteService.base64ToArrayBuffer(resp.contents)
                  // console.log(file)
                  var fileArrayBuffer = new Blob([file], {type: contentType})
                  var fileURL = window.URL.createObjectURL(fileArrayBuffer)
                  this.showModal(fileArrayBuffer, fileURL)
                }
              })
            } else if(sendQuote == 'agent') {
              this.emailQuoteToSelf();
              let emailQuoteToAgentObj = this.emailAgentQuoteData()
              this.sendQuoteService.sendAgentEmail(emailQuoteToAgentObj).subscribe(
                (sendEmailResp) => {

                if (sendEmailResp === undefined || (sendEmailResp?.returnCode && sendEmailResp?.returnCode != 0)) {
                  const message = "There was an issue submitting your quote. Please try again or contact your system administrator.";
                  this.notifyToast.error(message);
                } else {
                  // this.getCustomerResource();
                  this.navigateToLink('quote-carrier-submission')
                  // this.notifyToast.success(sendEmailResp);
                  console.log("email quote response: " + JSON.stringify(sendEmailResp))
                }
              });
          } else if (sendQuote == 'purchase') {
            const temp = {
              input: 'purchase',
              pageID: 'contact',
              userInput: this.sendQuoteForm.value
            }
            this.dataTransferService.sendData(temp);
          }
        }
      });
    }
  }

  showModal(file, fileURL){
    const modal = this.modalService.show(PdfModalComponent, {backdrop: 'static', class: 'modal-lg'});
    (<PdfModalComponent>modal.content).showConfirmationModal('<b>Quote Details</b>', file, `Close`, 'Download File');
    (<PdfModalComponent>modal.content).onClose.subscribe(resp => {
      if(resp){
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = 'File';
        link.click();
      }
    })
  }

  submitQuoteObj(){
    const data = this.quoteDataService.getQuoteObjectStructure();
    data.agentID = this.defaultAgentID
    data.firstName =  this.sendQuoteForm.value.first_name
    data.lastName =  this.sendQuoteForm.value.last_name
    data.email = this.sendQuoteForm.value.dontContact ? null : this.sendQuoteForm.value.email
    data.phoneNumber = {
      number: (this.sendQuoteForm.value.dontContact === '') && (this.sendQuoteForm.value.phone_no != '') ?  this.convertPhoneNumber(this.sendQuoteForm.value.phone_no) : null ,
      type:'Cell',
    }
    console.log(JSON.stringify(data.phoneNumber))
    this.quoteDataService.setContactDetails(this.sendQuoteForm.value)
    // add the default value of HomeBuildingType if there is no information collected from UI
    if (this.buildingTypes != null) {
      data.propertyStructureType = data.propertyStructureType === '' ? this.buildingTypes[0] : data.propertyStructureType;
    }

    return data
  }

  convertPhoneNumber(phoneNum) {
    if (phoneNum != null) {
      return phoneNum.replace(/[-_()' ']/g, '')
    }
    return null;
  }

  getFormValue(formName, value){
    if(this.quoteObj.conversation[formName]){
      return this.quoteObj.conversation[formName][value]
    } else{
      return null
    }
  }

  emailQuoteToSelf(isSelf?) {
    let data = this.emailQuoteData()
    this.sendEmail(data).subscribe(
      (sendEmailResp) => {
    if (sendEmailResp === undefined || (sendEmailResp?.returnCode && sendEmailResp?.returnCode != 0)) {
      const message = "There was an issue submitting your quote. Please try again or contact your system administrator.";
      this.notifyToast.error(message);
    } else if(isSelf) {
      // this.getCustomerResource();
      this.navigateToLink('quote-self-submission')
      console.log("email quote response: " + JSON.stringify(sendEmailResp))
    }
  });
  }

  sendEmail(data) {
    return this.httpService.post('emailQuote', data, true, true).pipe(map(resp => {
        if (resp.out === undefined) {
          this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          console.log(JSON.stringify(resp));
        } else {
          console.log('email quote' + JSON.stringify(resp.out));
          return resp.out;
        }
      }, (error) => {
        this.notifyToast.error('Please Contact System Administrator.');
      })
    );
  }

  emailQuoteData() {
    const data = {
        "accountCode": this.tenant,
        "to": this.sendQuoteForm.value.email,
        "quoteID": this.quoteID,
        "firstName": this.sendQuoteForm.value.first_name,
        "lastName": this.sendQuoteForm.value.last_name,
        "phoneNumber": this.sendQuoteForm.value.phone_no,
		    "packageName": this.quoteDataService?.getMappingInfoObj()?.sendDocumentsMapping?.sendToSelf
     }
    return data;
  }

  emailAgentQuoteData() {
    const data = {
      "accountCode": this.tenant,
      "to": this.companyDetails.agency_email,
      "quoteID": this.quoteID,
      "insuredFirstName": this.sendQuoteForm.value.first_name,
      "insuredLastName": this.sendQuoteForm.value.last_name,
      "preferrredContact": "email",
      "email": this.sendQuoteForm.value.email,
      "packageName": this.quoteDataService?.getMappingInfoObj()?.sendDocumentsMapping?.sendToAgent
    }
    return data;
  }

  navigateToLink(page) {
    this.router.navigate([`${this.tenant}/get-quote/${page}`]);
  }

  submitForm(userSelection){
    let temp;
    this.isSubmitted = true;
    if(this.sendQuoteForm.valid && userSelection == 'findAgent'){
      this.quoteDataService.setContactDetails(this.sendQuoteForm.value)
      this.setPhoneNumberForFindAgent();
      temp = { 
        input: 'findAgent',
        pageID: 'contact',
        userInput: this.sendQuoteForm.value
      }
    } else if (this.sendQuoteForm.valid && userSelection == 'purchase') {
      this.quoteDataService.setContactDetails(this.sendQuoteForm.value);
      this.quoteDataService.setEmail(this.sendQuoteForm.value.email);
      this.quoteDataService.setPhoneNumber(this.convertPhoneNumber(this.sendQuoteForm.value.phone_no))
      this.sendQuoteTo("purchase");
    }
    else if (userSelection == 'back') {
      temp = { input: 'Back', pageID: 'contact' }
    }
    if(temp){
      this.dataTransferService.sendData(temp)
    }
      }
    setPhoneNumberForFindAgent() {
      const data = this.quoteDataService.getQuoteObjectStructure();
      data.phoneNumber = {
        number: (this.sendQuoteForm.value.dontContact === '') && (this.sendQuoteForm.value.phone_no != '') ?  this.convertPhoneNumber(this.sendQuoteForm.value.phone_no) : null ,
        type:'Cell',
      }
      this.quoteDataService.setContactDetails(this.sendQuoteForm.value)
    }

}
