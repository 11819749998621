import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-opening-protection',
  templateUrl: './opening-protection.component.html',
  styleUrls: ['./opening-protection.component.scss']
})
export class OpeningProtectionComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any
  openingProtectionForm: any;
  
  tenant: any;
  isSmallWindow = window.innerWidth <= 768;
  openingProtectionMessages: any

  constructor(
    private formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) {
    this.tenant = this.route.parent.parent.snapshot.params.tenant
   }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.openingProtectionMessages = this.quotedataService.getAcntConfig()["content_management"]["quote"]["opening_protection"]
    this.openingProtectionForm = this.formBuilder.group({
      openingProtection: ['None']
    });
    this.fromAPI()
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  onClick(eve) {
    this.openingProtectionForm.patchValue({
      openingProtection : eve
    });
  }

  fromAPI(){
    if(this.quoteObj.conversation && this.quoteObj.conversation['opening-protection'] && this.quoteObj.conversation['opening-protection']["openingProtection"]) {
      this.openingProtectionForm.patchValue(this.quoteObj.conversation['opening-protection'])}
  }

  submitForm(userSelection){
    let temp;
    if(this.openingProtectionForm.valid && userSelection == 'next'){
        temp = { input: 'Next', pageID: 'opening-protection', userInput: this.openingProtectionForm.value }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'opening-protection' }
    }
    if(temp){
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve){
    if (this.openingProtectionForm.valid) {
      let temp = eve
      temp.userInput = this.openingProtectionForm.value
      temp.currentPageID = 'opening-protection'
      this.dataTransferService.sendData(temp)
    }
  }

}

