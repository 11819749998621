import { Component, OnInit, ɵConsole } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { formatCurrency } from '@angular/common';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-edit-coverages',
  templateUrl: './edit-coverages.component.html',
  styleUrls: ['./edit-coverages.component.scss'],
  providers: [CommonApiService]
})
export class EditCoveragesComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  coverageValues: any;
  editCoveragesObj: any;
  coverageItemList: any;
  selectedAgentDetails: any;

  curPolicyType: any;
  curCoverageNames: any;
  curDeductibleNames: any;
  tenant: any;

  // policyTypeSelections: any;
  //policyTypeArray: any;
  quoteObj: any;
  currentSelectedAddress: any;
  policyType: any;
  
  loading = false;
  progressNumber = 0
  interval: any;

  coverageA= false;
  coverageB= false;
  coverageC= false;
  coverageD= false;
  coverageE= false;
  CoverageDandE=false;
  coverageF= false;
  coverageL= false;
  coverageM= false;
  hurricane= false;
  aop=false;
  sinkhole=false;
  waterDamage=false;

  coverageAFieldName: any;
  coverageBFieldName: any;
  coverageCFieldName: any;
  coverageDFieldName: any;
  coverageEFieldName: any;
  coverageFFieldName: any;
  coverageDandEFieldName: any;
  coverageLFieldName: any;
  coverageMFieldName: any;
  hurricaneFieldName: any;
  aopFieldName: any;
  sinkholeFieldName: any;
  waterDamageFieldName: any;
  defaultAgentID:any;
  insuranceTypeValue: any;

  constructor(
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private notifyToast: NotifytoastService,
    private httpService: CommonApiService,
    private router: Router,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.initForm()
    this.tenant = this.utilsService.getTenantFromWindow()
    this.editCoveragesObj = this.quotedataService.getCoverageObj()
    this.coverageItemList = this.quotedataService.getCoverageItemList() //coverageForms
    this.curPolicyType = this.quotedataService.getPolicyObjData() == null ? "HO3" : this.quotedataService.getPolicyObjData().policyType
    this.curCoverageNames = this.quotedataService.getCoverageNameObj().curCoverageNames
    this.curDeductibleNames = this.quotedataService.getCoverageNameObj().curDeductibleNames
    //this.policyTypeSelections = this.quotedataService.getPolicyObjData() == null ? "own_house_primary": this.quotedataService.getPolicyObjData().policyTypeSelections
    //this.policyTypeArray = this.quotedataService.getPolicyObjData().policyTypeArray
    this.quoteObj = this.quotedataService.getConfiguration()
    this.currentSelectedAddress = this.quotedataService.getAddressObj().currentSelectedAddress
    this.policyType = this.quotedataService.getPolicyObjData()  == null ? "HO3" : this.quotedataService.getPolicyObjData().policyType
    this.insuranceTypeValue = (this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote == undefined || this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote == null) ?  this.policyType: this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote[this.policyType]
    this.setCoverageValue()
    this.setCoverageBasedOnPolicyType();
    this.selectedAgentDetails = this.quotedataService.getEmailQuote()
    
    this.defaultAgentID = (this.quotedataService.getMappingInfoObj().defaultAgentID == undefined || this.quotedataService.getMappingInfoObj().defaultAgentID  == null )? '3245336' : this.quotedataService.getMappingInfoObj().defaultAgentID  
    console.log('default agent ID: ' + this.quotedataService.getMappingInfoObj().defaultAgentID);
  }

  initForm(){
    this.coverageValues = this.formBuilder.group({
      coverageAValue: [''],
      coverageBValue: [''],
      coverageCValue: [''],
      coverageDValue: [''],
      coverageEValue: [''],
      coverageFValue: [''],
      aopDeductible: [''],
      deductibleAppliedForHurricaneCoverage: [''],
      waterDeductible: ['Limited'],
      coverageLValue: [''],
      coverageMValue: [''],
      CoverageDandE: [''],
      sinkholeDeductible: [''],
      coverageDandE: ['']
    })
  }

  setCoverageValue(){
    this.coverageValues.patchValue(this.editCoveragesObj)
  }

  hasCuCoverage(name) {
    return this.curCoverageNames.has(name);
  }

  setCoverageBasedOnPolicyType(){ 
    if (this.curCoverageNames.has("coverageA")) {
      this.coverageAFieldName = this.curCoverageNames.get("coverageA");
      if (this.coverageValues.value.coverageAValue !== undefined && this.coverageValues.value.coverageAValue !== null){
        this.coverageA = true;
      }
    }

    if (this.curCoverageNames.has("coverageB")) {
      this.coverageBFieldName = this.curCoverageNames.get("coverageB");
      if (this.coverageValues.value.coverageBValue !== undefined && this.coverageValues.value.coverageBValue !== null){
        this.coverageB = true;
      }
    }

    if (this.curCoverageNames.has("coverageC")) {
      this.coverageCFieldName = this.curCoverageNames.get("coverageC");
      if (this.coverageValues.value.coverageCValue !== undefined && this.coverageValues.value.coverageCValue !== null){
        this.coverageC = true;
      }
    }

    if (this.curCoverageNames.has("coverageD")) {
      this.coverageDFieldName = this.curCoverageNames.get("coverageD");
      if (this.coverageValues.value.coverageDValue !== undefined && this.coverageValues.value.coverageDValue !== null){
        this.coverageD = true;
      }
    }

    if (this.curCoverageNames.has("coverageE")) {
      this.coverageEFieldName = this.curCoverageNames.get("coverageE");
      if (this.coverageValues.value.coverageEValue !== undefined && this.coverageValues.value.coverageEValue !== null){
        this.coverageE = true;
      }
    }

    if (this.curCoverageNames.has("coverageF")) {
      this.coverageFFieldName = this.curCoverageNames.get("coverageF");
      if (this.coverageValues.value.coverageFValue !== undefined && this.coverageValues.value.coverageFValue !== null){
        this.coverageF = true;
      }
    }

    if (this.curCoverageNames.has("CoverageDandE")) {
      this.coverageDandEFieldName = this.curCoverageNames.get("CoverageDandE");
      if (this.coverageValues.value.coverageDandEValue !== undefined && this.coverageValues.value.coverageDandEValue !== null){
        this.CoverageDandE = true;
      }
    }

    if (this.curCoverageNames.has("coverageL")) {
      this.coverageLFieldName = this.curCoverageNames.get("coverageL");
      if (this.coverageValues.value.coverageLValue !== undefined && this.coverageValues.value.coverageLValue !== null){
        this.coverageL = true;
      }
    }

    if (this.curCoverageNames.has("coverageM")) {
      this.coverageMFieldName = this.curCoverageNames.get("coverageM");
      if (this.coverageValues.value.coverageMValue !== undefined && this.coverageValues.value.coverageMValue !== null){
        this.coverageM = true;
      }
    }

    if (this.curDeductibleNames.has("hurricane")) {
      this.hurricaneFieldName = this.curDeductibleNames.get("hurricane");
      if (this.coverageValues.value.deductibleAppliedForHurricaneCoverage !== undefined && this.coverageValues.value.deductibleAppliedForHurricaneCoverage !== null){
        this.hurricane = true;
      }
    }

    if (this.curDeductibleNames.has("aop")) {
      this.aopFieldName = this.curDeductibleNames.get("aop");
      if (this.coverageValues.value.aopDeductible !== undefined && this.coverageValues.value.aopDeductible !== null){
        this.aop = true;
      }
    }

    if (this.curDeductibleNames.has("sinkhole")) {
      this.sinkholeFieldName = this.curDeductibleNames.get("sinkhole");
      if (this.coverageValues.value.sinkholeDeductible !== undefined && this.coverageValues.value.sinkholeDeductible !== null){
        this.aop = true;
      }
    }

    if (this.curDeductibleNames.has("waterDamage")) {
      this.waterDamageFieldName = this.curDeductibleNames.get("waterDamage");
      if (this.coverageValues.value.waterDeductible !== undefined && this.coverageValues.value.waterDeductible !== null){
        this.waterDamage = true;
      }
    }
  }



  navigateToLink(page) {
    this.router.navigate([`${this.tenant}/get-quote/${page}`]);
  }

  reCalculatePremium() {
    this.loading = true;
    this.fakeLoader()
    this.submitRateQuote(this.rateAQuoteObjectStructure()).subscribe(
      (resp) => {
      if (resp === undefined) {
        this.loading = false
        console.log('No response returned');
      } else {
        this.quotedataService.setQuoteID(resp.quoteReferenceNumber)
        console.log("quote ID from edit coverage: " + resp.quoteReferenceNumber)
        let estimatedPremium = resp.quoteInfo.rate.estimatedPremium;
        console.log("estimated premium from edit coverage: " + estimatedPremium)
        this.quotedataService.setEstimatedPremium(estimatedPremium);
        this.applyResponseToCoverageValues(resp);
        this.navigateToLink('display-premium')
        this.loading = false;
      }
    });
  }

  submitRateQuote(data): Observable<any> {
    return this.httpService.post('submitQuote', data, false, true).pipe(
      map((resp) => {
        if (resp.out === undefined) {
            this.notifyToast.error(
              'Please Contact System Administrator.' +
                '\n' +
                resp.root.Envelope.Body.Fault.faultstring
            );
        } else {
          console.log('return value Rate Quote' + JSON.stringify(resp.out));
          this.loading = false;
          return resp.out;
        }
      })
    );
  }

  applyResponseToCoverageValues(response) {
    if (response != null) {
      const coverageFormObj = {
        coverageAValue : formatCurrency(response.coverageAValue, "en_US", "$", "USD"),
        coverageBValue : formatCurrency(response.coverageBValue, "en_US", "$", "USD"),
        coverageCValue : this.policyType === 'HO3' ? response.recommendedPercentageOfCoverageCtoCoverageA : formatCurrency(response.coverageCValue, "en_US", "$", "USD"),
        coverageDValue : formatCurrency(response.coverageDValue, "en_US", "$", "USD"),
        coverageEValue: response.coverageEValue,
        coverageFValue: response.coverageFValue,
        aopDeductible: response.aopDeductible,
        deductibleAppliedForHurricaneCoverage : response.deductibleAppliedForHurricaneCoverage,
        waterDeductible: response.waterDeductible,
        coverageLValue: response.coverageLValue,
        coverageMValue: response.coverageMValue,
        CoverageDandE: response.CoverageDandE,
        sinkholeDeductible: response.sinkholeDeductible,
      }
      this.quotedataService.setCoverageObj(coverageFormObj)
    }
  }

  rateAQuoteObjectStructure() {
    let policyForm = ""; 
    // if (this.policyTypeArray !== null && this.policyTypeArray !== undefined && this.policyTypeArray.length > 1) {
    //   for (let item of this.policyTypeArray) {
    //     policyForm = policyForm + item + " "
    //   }
    // } else {
      policyForm = this.policyType;
    // }
    const data = {
      // firstName: this.getFormValue('Personal-Info' , 'firstName'),
      // lastName: this.getFormValue('Personal-Info' , 'lastName'),
      accountCode: this.tenant,
      phoneNumber: {
        number: '4084445212',
        type: 'Cell',
      },
      // insuranceType: "SFI Florida DF3 CO",
      insuranceType: this.insuranceTypeValue,
        // this.quotedataService.getInsuranceTypePrefix() +
        // ' ' +
        // this.currentSelectedAddress['state']['long_name'] +
        // ' ' +
        // policyForm,
      policyEffectiveDate: new Date(this.getFormValue('policy-start-date' , 'startDate')).toLocaleDateString('en-US'),
      propertyAddress: this.getPropAddress(),
      mailingAddress: this.getPropAddress(),
    //   propertyUsage: (this.policyTypeSelections === 'rent_condo' || this.policyTypeSelections === 'rent_house') ? null : this.getFormValue('Home-Usage-Select' , 'usageResidence'),
    //   propertyYearBuilt: (this.policyTypeSelections === 'rent_condo' )? null : this.getFormValue('House-Built' , 'yearBuilt'),
    //   propertySquareFootage: (this.policyTypeSelections === 'rent_condo' )?null : this.getFormValue('House-Built' , 'squareFeet'),
    //   propertyNumberOfStories: (this.policyTypeSelections === 'rent_condo' )? null : this.getFormValue('House-Built' , 'story'),
    //   propertyConstructionType: (this.policyTypeSelections === 'rent_condo' )? null : this.getFormValue('House-Details' , 'buildingMaterial'),
    //   propertyStructureType: (this.policyTypeSelections === 'rent_condo' )?null : this.getFormValue('House-Details' , 'buildingType'),
    //   is1000FeetFromFireHydrant: (this.policyTypeSelections === 'rent_condo' )? null : this.getFormValue('House-Details' , 'nearFireHydrant'),
    //   propertyRoofType: (this.policyTypeSelections === 'rent_condo' )? null : this.getFormValue('House-Roof-Select' , 'roofType'),
    //   propertyRoofYearLastRenovated: (this.policyTypeSelections === 'rent_condo' )? null: this.getFormValue('Roof-Built' , 'roofYear'),
    //   propertyRoofMaterial: (this.policyTypeSelections === 'rent_condo' )? null : this.getFormValue('Roof-Built' , 'roofMaterial'),
    //   coverageAValue: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues.value.coverageAValue,
    //   coverageBValue: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues.value.coverageBValue,
    //   coverageCValue: (this.policyTypeSelections === 'rent_condo' || this.policyTypeSelections === 'own_house_primary')? null : this.coverageValues.value.coverageCValue,
    //   RecommendedPercentageOfCoverageCtoCoverageA :(this.policyTypeSelections !== 'own_house_primary')? null : this.coverageValues.value.coverageCValue,
    //   coverageDValue: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues.value.coverageDValue,
    //   coverageEValue: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues.value.coverageEValue,
    //   coverageFValue: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues.value.coverageFValue,
    //   aopDeductible: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues.value.aopDeductible,
    //   deductibleAppliedForHurricaneCoverage: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues.value.deductibleAppliedForHurricaneCoverage,
    //   waterDeductible: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues.value.waterDeductible,
    //   agentID: (this.selectedAgentDetails == undefined || this.selectedAgentDetails == null )? this.defaultAgentID  : this.selectedAgentDetails.agentID, 
    
     };
     // hard code the the insurance type with SFI when the tenant is upc-quote
    if (this.tenant === 'upc-quote' || this.tenant === 'acme') {
      data.insuranceType =
        'SFI' +
        ' ' +
        this.currentSelectedAddress['state']['long_name'] +
        ' ' +
        policyForm;
    }
    console.log(' request quote data: ' + JSON.stringify(data));
    this.quotedataService.setQuoteObjectStructure(data)
    return data;
  }

  getFormValue(formName, value){
    if(this.quoteObj.conversation[formName]){
      return this.quoteObj.conversation[formName][value]
    } else{
      return null
    }
  }

  getPropAddress() {
    const tempData = {
      street:
        (this.currentSelectedAddress['street_number'] &&
          this.currentSelectedAddress['street_number']['long_name']) +
        ' ' +
        (this.currentSelectedAddress['route'] && this.currentSelectedAddress['route']['long_name']) + 
        ' ' +
        this.getFormValue('property-address' , 'unitNumber'),
      city: this.currentSelectedAddress['city']['long_name'],
      stateCode: this.currentSelectedAddress['state']['short_name'],
      zip:
        this.currentSelectedAddress['postal_code'] &&
        this.currentSelectedAddress['postal_code']['long_name'],
    };
    console.log('current selected address', this.currentSelectedAddress);
    return tempData;
  }

  fakeLoader(){
    this.progressNumber = 0
    this.startTimer()
  }

  startTimer(){
    this.interval = window.setInterval(()=> {
      if(this.progressNumber<98) this.progressNumber++
      else if(!this.loading) this.stopTimer(100)
      else this.stopTimer(100)
    }, 200)
  }
  
  stopTimer(time){
    window.clearInterval(this.interval)
    window.clearTimeout(this.interval)
  }

}
