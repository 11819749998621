import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';


@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss']
})
export class YesNoComponent implements OnInit {


  yesNoForm: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any;
  pageID: any;
  isSmallWindow = window.innerWidth <= 768;
  next: any;
  helpText: any;

  constructor(
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.initForm()
    this.fromAPI()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm() {
    this.pageID = this.quoteObj.pageID
    this.yesNoForm = this.formBuilder.group({
      yesNo: [this.quoteObj.defaultReply]
    });
    this.helpText = this.quoteObj.helpText;
    this.next = this.quoteObj.Next ? this.quoteObj.Next : null;
  }

  fromAPI() {
    if (this.quoteObj.conversation && this.quoteObj.conversation[this.pageID]) {
      const fieldName = (this.pageID.includes('-')) ? this.pageID.replace('-', '_') : this.pageID;
      this.yesNoForm.patchValue({
        yesNo: this.quoteObj.conversation[this.pageID][fieldName]
      })
    }
  }


  onClick(eve) {
    this.yesNoForm.patchValue({
      yesNo: eve
    });
  }

  submitForm(userSelection) {
    let temp;
    if (this.yesNoForm.valid && userSelection == 'next') {
      const pageId = (this.pageID.includes('-')) ? this.pageID.replace('-', '_') : this.pageID;
      let userInput = {};
      userInput[pageId] = this.yesNoForm.value.yesNo;
      temp = { input: 'Next', pageID: this.pageID, userInput: userInput }
    } else if (userSelection == 'back') {
      temp = { input: 'Back', pageID: this.pageID }
    }
    if (temp) {
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve) {
    if (this.yesNoForm.valid) {
      let temp = eve
      temp.userInput = this.yesNoForm.value
      temp.currentPageID = this.pageID;
      this.dataTransferService.sendData(temp)
    }
  }

}
