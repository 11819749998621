import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';
import * as _ from "lodash"

@Component({
  selector: 'app-prior-insurance',
  templateUrl: './prior-insurance.component.html',
  styleUrls: ['./prior-insurance.component.scss']
})
export class PriorInsuranceComponent implements OnInit {
  priorInsuranceForm: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any;
  page: any;
  isSmallWindow = window.innerWidth <= 768;
  county: any;
  roofMaterial: any;
  coverageObj: any;
  policyTypeList: any;
  policyType: any;
  tenant = '';
  temp: any;

  constructor(
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute,
    private httpService: CommonApiService,
    private router: Router,
    private notifyToast: NotifytoastService,
    private datePipe: DatePipe
  ) {
    this.page = this.route.snapshot.routeConfig.path
  }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.county = this.quotedataService.getAddressObj().county;
    this.coverageObj = this.quotedataService.getCoverageObj();
    this.convertCounty();
    this.convertRoofMaterial();
    this.initForm()
    this.fromAPI()
    this.tenant = this.utilsService.getTenantFromWindow()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm() {
    this.priorInsuranceForm = this.formBuilder.group({
      priorInsurance: ['false']
    });
  }

  convertRoofMaterial() {
    this.roofMaterial = this.quotedataService.getMappingInfoObj().roofMaterialConvertValueForProductMapping[this.getFormValue('roof-material', 'roofMaterial')]
    //console.log(this.roofMaterial)
  }
  fromAPI() {
    if (this.quoteObj.conversation && this.quoteObj.conversation['prior-insurance'])
      this.priorInsuranceForm.patchValue(this.quoteObj.conversation['prior-insurance'])
  }


  onClick(eve) {
    this.priorInsuranceForm.patchValue({
      priorInsurance: eve
    });
  }

  getProductMapping() {
    const requestData = {
      accountCode: this.tenant,
      relationToProperty: this.getFormValue('relation-to-property', 'relationToProperty'),
      homeStructureType: this.getFormValue('structure-type', 'checkHouse'),
      occupancyType: this.getFormValue('relation-to-property', 'relationToProperty') === 'I\'m a renter' ? "Renter" : this.getFormValue('home-usage', 'checkRental'),

      county: this.county,
      newConstruction: this.getFormValue('property-location', 'newConstruction') ? "Yes" : "No",
      priorInsurance: (this.priorInsuranceForm.value.priorInsurance == "true") ? "Yes" : "No",

      coverageALimit: this.coverageObj.coverageAValue === "" ? null : this.coverageObj.coverageAValue,
      coverageCLimit: this.coverageObj.coverageCValue === "" ? null : this.coverageObj.coverageCValue,
      openingProtection: this.getFormValue('opening-protection', 'openingProtection'),
      priorLossCount: this.getFormValue('prior-loss', 'priorLoss') === false ? null : this.getFormValue('number-of-claims', 'claims'),
      roofMaterial: this.roofMaterial,
      roofAge: this.getFormValue('roof-age', 'roofYear') === null ? null : (new Date()).getFullYear() - Number(this.getFormValue('roof-age', 'roofYear')),
      squareFootage: this.getFormValue('house-built', 'squareFeet') === null ? null : Number(this.getFormValue('house-built', 'squareFeet')),
      exteriorInsuredByAssociation: this.getFormValue('exterior-of-home', 'exteriorOfHome') === null ? null : this.getFormValue('exterior-of-home', 'exteriorOfHome'),

      partiallyRented: JSON.parse(this.getFormValue('Seasonal-Residence', 'seasonalRent')) ? "Yes" : "No",

      rentalTerm: this.getFormValue('rental-term', 'rentalTerm')
      //distanceToCoast: distanceToCoast,
    };

    // hack until API issues have been fixed
    if (requestData.occupancyType === "Renter") {
      requestData.roofMaterial = null;
      requestData.squareFootage = null;
    }

    if (requestData.homeStructureType === "Condo" || requestData.homeStructureType === "Apartment") {
      requestData.roofMaterial = null;
      requestData.roofAge = null;
    }

    // this.quotedataService.setProductMapping(requestData);
    this.httpService
      .post('getProductMapping', requestData, true, true)
      .subscribe(
        (resp) => {
          if (_.isEmpty(resp) || resp.out === undefined) {
            this.temp.userInput.eligibility = "false";
            if (this.temp) this.dataTransferService.sendData(this.temp);
          }
          else {
            this.temp.userInput.eligibility = "true";
            this.policyTypeList = resp.out.Product;
            let quoteStartDate = new Date(this.quotedataService.getConfiguration().conversation['policy-start-date']['startDate']);
            const getCapacityStatusInput = {
              accountCode: this.tenant,
              eligibleProducts: this.policyTypeList,
              zipCode: this.quotedataService.getPropertyAddress().zipCode,
              censusBlockGroup: this.quotedataService.getPropertyAddress().cbg,
              effectiveDate: this.datePipe.transform(
                quoteStartDate,
                'MM/dd/yyyy'
              ),
            };
            this.httpService
              .post('getCapacityStatus', getCapacityStatusInput, true, true)
              .subscribe(
                (capacity) => {
                  if (capacity.out === undefined) {
                    this.temp.userInput.capacity = "false";
                    if (this.temp) this.dataTransferService.sendData(this.temp);
                  } else {
                    let capacityStatus = null;
                    let capacityList = {}
                    for (let i = 0; i < capacity.out.CapacityStatus.length; i++) {
                      capacityList[capacity.out.CapacityStatus[i].homeFormType] = capacity.out.CapacityStatus[i].capacityStatus;
                    }
                    let i = 0;
                    for (i = 0; i < this.policyTypeList.length; i++) {
                      if (capacityList[this.policyTypeList[i]] != "Closed") {
                        this.policyType = this.policyTypeList[i].replace('-', ' ');
                        capacityStatus = capacityList[this.policyTypeList[i]];
                        const globalPolicyObj = {
                          policyType: this.policyType,
                          capacityStatus: capacityStatus,
                        };
                        this.quotedataService.setPolicyObjData(globalPolicyObj);
                        this.temp.userInput.capacity = "true";
                        this.temp.userInput.policyType = this.policyType;
                        if (this.temp) this.dataTransferService.sendData(this.temp);
                        break;
                      }
                    }
                    if (i == this.policyTypeList.length) {
                      this.temp.userInput.capacity = "false";
                      if (this.temp) this.dataTransferService.sendData(this.temp);
                    }
                  }
                },
                (error) => {
                  this.notifyToast.error(
                    'Please Contact System Administrator.'
                  );
                }
              );
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  getFormValue(formName, value) {
    if (this.quoteObj.conversation[formName]) {
      return this.quoteObj.conversation[formName][value]
    } else {
      return null
    }
  }

  convertCounty() {
    if (this.county != null && this.county != undefined) {
      this.county = this.county.replace(' County', '')
    }
  }

  submitForm(userSelection) {
    if (this.priorInsuranceForm.valid && userSelection == 'next') {
      this.temp = {
        input: 'Next', pageID: 'prior-insurance',
        userInput:
          this.priorInsuranceForm.value
      }
      this.getProductMapping();
    } else if (userSelection == 'back') {
      this.temp = { input: 'Back', pageID: 'prior-insurance' }
      this.dataTransferService.sendData(this.temp)
    }
  }

  submitBackToSummary(eve) {
    if (this.priorInsuranceForm.valid) {
      let temp = eve
      temp.userInput = this.priorInsuranceForm.value
      temp.currentPageID = 'prior-insurance'
      this.dataTransferService.sendData(temp)
    }
  }

}
