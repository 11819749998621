import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators'
import { CommonApiService } from '../shared/services/common-api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ThemeChangerService } from '../shared/services/themeChanger.service';
import { UtilsService } from '../shared/services/utils.service';
import { environment } from '../../environments/environment';
import { QuoteDataShare } from '../shared/services/quoteDataShare.service'
import { NotifytoastService } from '../shared/services/notificationToast.service';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
  providers: [CommonApiService]
})
export class PublicComponent implements OnInit {
  styling: any;
  pageName: any;
  bgImg = '';
  headerImg = '';
  pageDetailsObj: any;
  isSfi = true;
  bannerObj: any;
  date = environment.buildDate
  version = environment.buildVersion
  header: any;
  agentLoginName: any;
  payrollDeduct: any;
  recoopCoverageValue: any;
  footer_content = {
    footer_left_message: '',
    footer_center_message: '',
    footer_right_message: ''
  }
  clickableLinks = {
    iconHeader: '',
    contactUs: ''
  }
  constructor(
    private http: CommonApiService,
    private route: ActivatedRoute,
    private themeService: ThemeChangerService,
    private router: Router,
    public utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private notifyToast: NotifytoastService
  ) { }

  ngOnInit(): void {
    this.scrollForChildPages()
    this.pageName = this.route.snapshot.params.tenant
    if (this.pageName.indexOf('sfi') > -1) {
      this.isSfi = true
      this.bgImg = `url('/assets/multiTenantImages/png/SFILoginBackground.png')`
    } else {
      this.isSfi = false
    }
    // this.mobileAppBanner()
    this.agentLoginName = this.route.snapshot.queryParamMap.get('agentName');
    this.quotedataService.setAgentLoginName(this.agentLoginName);
    this.payrollDeduct = this.route.snapshot.queryParamMap.get('payrollDeduct');
    if(this.payrollDeduct == 'false' || this.payrollDeduct == null){
      this.quotedataService.setPayrollDeduct(false);
    } else {
      this.quotedataService.setPayrollDeduct(true);
    }
    this.recoopCoverageValue = this.route.snapshot.queryParamMap.get('recoopCoverageValue');
    this.quotedataService.setRecoopCoverageValue(this.recoopCoverageValue);
    this.route.data.subscribe(resp => {
      this.pageValid(resp.accountConfiguration)
    }, error => {
      this.router.navigate(['/site-not-found'])
    })
  }

  scrollForChildPages() {
    this.router.events.pipe(filter(eve => eve instanceof NavigationEnd)).subscribe(res => {
      document.getElementById('main-quoter-wrapper').scrollTop = 0
    })
  }

  mobileAppBanner() {
    this.http.get(`mobileAppInstallation?accountCode=${this.pageName}`, null, true).subscribe(resp => {
      if (resp && resp.MobileAppInstallation) this.bannerObj = resp.MobileAppInstallation
    })
  }

  // pageValid(){
  //   this.http.get(`accountConfiguration?accountCode=${this.pageName}`, null, true).subscribe(resp => {
  //     if(resp){
  //       let data = { ...resp.page_configuration }
  //       delete data.cardStyling
  //       this.utilsService.setTabTitleAndIcon(data)
  //       sessionStorage.setItem('companybackground', JSON.stringify(data))
  //       let permissionKey = resp['AccessControl'] ? resp['AccessControl']: []
  //       sessionStorage.setItem('permissionKeys', JSON.stringify(permissionKey))
  //       if(resp.page_configuration.styling){
  //         sessionStorage.setItem('themeObject', JSON.stringify(resp.page_configuration.styling))
  //       }
  //       if(resp.content_management){
  //         this.footer_content ={
  //           footer_left_message: resp.content_management.footer_left_message,
  //           footer_center_message: resp.content_management.footer_center_message,
  //           footer_right_message: resp.content_management.footer_right_message
  //         }
  //       }
  //       this.themeService.setDefaultTheme()
  //       if(this.pageName.indexOf('sfi') == -1)this.bgImg = `url(${resp.page_configuration.company_background})`
  //       this.pageDetailsObj = resp
  //     } else{
  //       this.router.navigate(['/site-not-found'])
  //     }
  //   }, error => {
  //     this.router.navigate(['/site-not-found'])
  //   }
  //   )
  // }

  pageValid(accountConfigObj) {
    if (accountConfigObj) {
      let browserData = {
        tabIcon: accountConfigObj.page_configuration.browser.browserTabIcon,
        tabTitle: accountConfigObj.quote.browser_title ? accountConfigObj.quote.browser_title : accountConfigObj.page_configuration.browser.browserTabTitle
      }
      this.utilsService.setTabTitleAndIcon(browserData)
      this.accountConfigSetter(accountConfigObj)
      // let permissionKey = accountConfigObj['AccessControl'] ? accountConfigObj['AccessControl']: []
      // sessionStorage.setItem('permissionKeys', JSON.stringify(permissionKey))
      if (accountConfigObj.page_configuration.styling) {
        this.styling = accountConfigObj.page_configuration.styling
        sessionStorage.setItem('themeObject', JSON.stringify(accountConfigObj.page_configuration.styling))
      }
      if (accountConfigObj.content_management) {
        this.footer_content = {
          footer_left_message: accountConfigObj.content_management.footer.left_message,
          footer_center_message: accountConfigObj.content_management.footer.center_message,
          footer_right_message: accountConfigObj.content_management.footer.right_message
        }
      }
      let navUrl = accountConfigObj.page_configuration.navigateUrl
      if(navUrl){
        this.clickableLinks = {
          contactUs: navUrl.contactUs,
          iconHeader: navUrl.headericon
        }
      }
      this.themeService.setDefaultTheme()
      if (this.pageName.indexOf('sfi') == -1 && accountConfigObj.page_configuration.company_background) this.bgImg = `url(${accountConfigObj.page_configuration.company_background})`
      this.headerImg = accountConfigObj.page_configuration.company_logo
      this.pageDetailsObj = accountConfigObj
    } else {
      this.router.navigate(['/site-not-found'])
    }
  }

  accountConfigSetter(acntConfigObj) {
    acntConfigObj.effectiveDate = acntConfigObj.quote.Effective_Day_Range ? acntConfigObj.quote.Effective_Day_Range : 31
    acntConfigObj.image = acntConfigObj.page_configuration.virtual_quoter_icon ? acntConfigObj.page_configuration.virtual_quoter_icon : ''
    const acntConfigSettings = acntConfigObj;

    this.quotedataService.setAcntConfig(acntConfigSettings)
    this.header = acntConfigSettings.page_configuration.header;
    let data = {
      agency_email: acntConfigObj.quote.agency_email,
      quotePhone: acntConfigObj.quote.quotePhone,
      companyShortName: acntConfigObj.quote.companyShortName,
      company_Name: acntConfigObj.quote.company_Name,
      quoteForm: acntConfigObj.quote.quoteForm,
      partner: acntConfigObj.quote.partner ? acntConfigObj.quote.partner.isPartner : false
    }
    this.quotedataService.setAgencyConfig(data)
  }

  navTo(isLanding) {
    if (isLanding) {
      window.open(this.header.image_click_link, '_blank');
      // window.open("https://www.securityfirstflorida.com/", '_blank')
    } else {
      window.open(this.header.contact_us_link, '_blank');
      // window.open("https://www.securityfirstflorida.com/contact-us?_ga=2.244235973.1512143232.1604434318-1069710922.1600780991", '_blank')
    }
  }

  navToPage(page) {
    if (page == 'privacy') {
      window.open("https://www.securityfirstflorida.com/privacy-policy", '_blank')
    } else if (page == 'terms') {
      window.open("https://www.securityfirstflorida.com/terms-use", '_blank')
    }
  }

  navigationPageForDefault(page){
    if(page == 'contact-us' && this.clickableLinks.contactUs){
      window.open(this.clickableLinks.contactUs, '_blank')
    } else if(page == 'header-icon' && this.clickableLinks.iconHeader){
      window.open(this.clickableLinks.iconHeader, '_blank')
    } else{
      this.notifyToast.error("Please Contact System Administrator")
    }
  }
}
