import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';
import * as _ from "lodash"
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { formatCurrency } from '@angular/common';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';

@Component({
  selector: 'app-submit-quote-form',
  templateUrl: './submit-quote-form.component.html',
  styleUrls: ['./submit-quote-form.component.scss'],
  providers: [CommonApiService]
})
export class SubmitQuoteFormComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  pagesListNavigated = [];
  @Input() dataObj: any;
  @Output() submitFormObj = new EventEmitter()
  loading: any;
  quoteObj: any;
  page: any;
  tenant: any;
  quoteID: any;
  isSubmitted = false;


  estimatedPremium: any;
  estimatedPremiumTax: any;
  basicResponse: any;
  upgradedResponse: any;
  highEndResponse: any;
  policyType: any;
  // policyTypeArray: any;
  // policyTypeSelections: any;
  currentSelectedAddress: any;
  selectedAgentDetails:any;
  defaultAgentID:any;

  progressNumber = 0
  interval: any;
  insuranceTypeValue: any;

  botMessage = [
    'Are you sure you want to submit this form?',
    'Please give me a moment while I find the best policy coverages and a price for you'
  ]
  
  constructor(
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService
    ) {
      this.page = this.route.snapshot.routeConfig.path
    }

  ngOnInit(): void {
    this.tenant = this.utilsService.getTenantFromWindow()
    this.quoteObj = this.quotedataService.getConfiguration()
    this.policyType = 'HO3' 
    // this.quotedataService.getPolicyObjData().policyType
    // this.policyTypeArray = this.quotedataService.getPolicyObjData().policyTypeArray
    // this.policyTypeSelections = this.quotedataService.getPolicyObjData().policyTypeSelections
    // this.state = this.quotedataService.getAddressObj().stateCode
    this.pagesListNavigated = this.quotedataService.getVisitedPages()
    this.currentSelectedAddress = this.quotedataService.getAddressObj().currentSelectedAddress
    this.insuranceTypeValue = (this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote == undefined || this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote == null) ?  this.policyType: this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote[this.policyType]
    this.selectedAgentDetails = this.quotedataService.getEmailQuote()
    this.defaultAgentID = (this.quotedataService.getMappingInfoObj().defaultAgentID == undefined || this.quotedataService.getMappingInfoObj().defaultAgentID  == null )? '3245336' : this.quotedataService.getMappingInfoObj().defaultAgentID  
    console.log('default agent ID: ' + this.quotedataService.getMappingInfoObj().defaultAgentID);
  }

  submitForm(userSelection){
    let temp;
    if(userSelection == 'submit') this.calculatePremium()
    else if(userSelection == 'back') {
      // temp = { input: 'Back', pageID: this.dataObj.pageID, userInput: {} }
      // this.submitFormObj.emit(temp)
      temp = { input: 'Back', pageID: this.quoteObj.pageID, userInput: {} }
      this.dataTransferService.sendData(temp)
    }
  }

  // submitQuote(){
  //   let submitFormObj = {};
  //   let conversationObject = this.dataObj.conversation
  //   this.pagesListNavigated.forEach(pageName => { //get all 
  //     let temp = _.get(conversationObject, pageName)
  //     if(submitFormObj) Object.assign(submitFormObj, temp)
  //   });
  //   // this.httpService.
    
  // }

  navigateToLink(page) {
    this.router.navigate([`${this.tenant}/${page}`]);
  }

  calculatePremium() {
    // stepper.next();
    this.loading = true;
    this.fakeLoader()
    this.submitRateQuote(this.rateAQuoteObjectStructure()).subscribe(
      (resp) => {
      if (resp === undefined) {
        console.log('No response returned');
      } else {
        this.isSubmitted = true;
        this.quoteID = resp.quoteReferenceNumber;
        this.quotedataService.setQuoteID(this.quoteID)
        console.log("quote ID from submit quote form: " + this.quoteID)

        this.basicResponse = resp.quoteInfo;
        this.applyResponseToCoverageValues(this.basicResponse);
        this.estimatedPremium = this.basicResponse.rate.estimatedPremium
        this.quotedataService.setEstimatedPremium(this.estimatedPremium);
        this.estimatedPremiumTax = this.basicResponse.rate.taxes;
        this.quotedataService.setPremiumTax(this.estimatedPremiumTax);  
        console.log("premium from submit quote form: " + this.estimatedPremium)

        let temp = { input: 'Next', pageID: this.quoteObj.pageID, userInput: {} }
        this.dataTransferService.sendData(temp)
        // this.navigateToLink('get-quote/display-premium')
        // this.loading = false; 
      }
    });
  }

  submitRateQuote(data): Observable<any> {
    return this.httpService.post('submitQuote', data, false, true).pipe(
      map((resp) => {
        if (resp.out === undefined) {
          // if(this.stepper.selectedIndex === 10){
          if(this.page == 'quote-summary'){
            this.loading = false
            this.utilsService.confirmModalPopup(`${resp.root.Envelope.Body.Fault.faultstring}`)
            // this.stepper.previous()
          } else{
            this.notifyToast.error(
              'Please Contact System Administrator.' +
                '\n' +
                resp.root.Envelope.Body.Fault.faultstring
            );
          }
        } else {
          console.log('return value Rate Quote' + JSON.stringify(resp.out));
          return resp.out;
        }
      })
    );
  }

  applyResponseToCoverageValues(response) {
    if (response != null) {
      const coverageFormObj = {
        coverageAValue : formatCurrency(response.coverageAValue, "en_US", "$", "USD"),
        coverageBValue : formatCurrency(response.coverageBValue, "en_US", "$", "USD"),
        coverageCValue : this.policyType === 'HO3' ? response.recommendedPercentageOfCoverageCtoCoverageA : formatCurrency(response.coverageCValue, "en_US", "$", "USD"),
        coverageDValue : formatCurrency(response.coverageDValue, "en_US", "$", "USD"),
        coverageEValue: response.coverageEValue,
        coverageFValue: response.coverageFValue,
        aopDeductible: response.aopDeductible,
        deductibleAppliedForHurricaneCoverage : response.deductibleAppliedForHurricaneCoverage,
        waterDeductible: response.waterDeductible,
        coverageLValue: response.coverageLValue,
        coverageMValue: response.coverageMValue,
        CoverageDandE: response.CoverageDandE,
        sinkholeDeductible: response.sinkholeDeductible,
      }
      this.quotedataService.setCoverageObj(coverageFormObj)
    }
  }

  rateAQuoteObjectStructure() {
    let policyForm = this.policyType;
    let coverageObj = this.quotedataService.getCoverageObj()
    console.log(coverageObj, 'coverageObj')
    const data = {
      // firstName: this.getFormValue('personal-info' , 'firstName'),
      // lastName: this.getFormValue('personal-info' , 'lastName'),
      accountCode: this.tenant,
      phoneNumber: {
        number: '4084445212',
        type: 'Cell',
      },
      // insuranceType: "SFI Florida DF3 CO",

      insuranceType: this.insuranceTypeValue,
        // this.quotedataService.getInsuranceTypePrefix() +
        // ' ' +
        // this.currentSelectedAddress['state']['long_name'] +
        // ' ' +
        // policyForm,
      policyEffectiveDate: new Date(this.getFormValue('policy-start-date' , 'startDate')).toLocaleDateString('en-US'),
      propertyAddress: this.getPropAddress(),
      mailingAddress: this.getPropAddress(),
      propertyUsage: this.pagesListNavigated.indexOf('home-usage') > -1 ? this.getFormValue('home-usage' , 'checkRental') : null,
      propertyYearBuilt: this.getFormValue('house-built' , 'yearBuilt'),
      propertySquareFootage: this.getFormValue('house-built' , 'squareFeet'),
      propertyNumberOfStories: this.getFormValue('structure-material' , 'story'),
      propertyConstructionType: this.getFormValue('structure-material' , 'constructionType'),
      propertyStructureType: this.getFormValue('structure-material' , 'buildingType'),
      is1000FeetFromFireHydrant: this.getFormValue('structure-material' , 'nearFireHydrant'),
      propertyRoofType: this.getFormValue('roof-shape' , 'roofType'),
      propertyRoofYearLastRenovated: this.getFormValue('roof-age' , 'roofYear'),
      propertyRoofMaterial: this.getFormValue('roof-material' , 'roofMaterial'),
      openingProtectionLevel: this.pagesListNavigated.indexOf('home-usage') > -1 ? this.getFormValue('opening-protection' , 'openingProtection') : null,
      hasPriorInsurance: this.getFormValue('prior-insurance' , 'priorInsurance'),
      coverageAValue: coverageObj.coverageAValue,
      coverageBValue: coverageObj.coverageBValue,
      coverageCValue: coverageObj.coverageCValue,
      RecommendedPercentageOfCoverageCtoCoverageA :coverageObj.coverageCValue,
      coverageDValue: coverageObj.coverageDValue,
      coverageEValue: coverageObj.coverageEValue,
      coverageFValue: coverageObj.coverageFValue,
      aopDeductible: coverageObj.aopDeductible,
      deductibleAppliedForHurricaneCoverage: coverageObj.deductibleAppliedForHurricaneCoverage,
      waterDeductible: coverageObj.waterDeductible,
      agentID: (this.selectedAgentDetails == undefined || this.selectedAgentDetails == null )? this.defaultAgentID : this.selectedAgentDetails.agentID, 
      // email: this.emailQuoteForm_14.value.email, //pending
      // includeFloodPremium: false,
    };
    console.log(' request quote data: ' + JSON.stringify(data));
    this.quotedataService.setQuoteObjectStructure(data)
    return data;
  }

  getPropAddress() {
    const tempData = {
      street:
        (this.currentSelectedAddress['street_number'] &&
          this.currentSelectedAddress['street_number']['long_name']) +
        ' ' +
        (this.currentSelectedAddress['route'] && this.currentSelectedAddress['route']['long_name']) + 
        ' ' +
        this.getFormValue('property-address' , 'unitNumber'),
      city: this.currentSelectedAddress['city']['long_name'],
      stateCode: this.currentSelectedAddress['state']['short_name'],
      zip:
        this.currentSelectedAddress['postal_code'] &&
        this.currentSelectedAddress['postal_code']['long_name'],
    };
    return tempData;
  }

  getFormValue(formName, value){
    if(this.quoteObj.conversation[formName]){
      return this.quoteObj.conversation[formName][value]
    } else{
      return null
    }
  }


  fakeLoader(){
    this.progressNumber = 0
    this.startTimer()
  }

  startTimer(){
    this.interval = window.setInterval(()=> {
      if(this.progressNumber<98) this.progressNumber++
      else if(!this.loading) this.stopTimer(100)
      else this.stopTimer(100)
    }, 200)
  }
  
  stopTimer(time){
    window.clearInterval(this.interval)
    window.clearTimeout(this.interval)
  }


}
