import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from "@agm/core";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotifytoastService } from './shared/services/notificationToast.service';
import { ThemeChangerService } from './shared/services/themeChanger.service';
import { SharedModule } from './shared/shared.module';
import { PublicModule } from './public/public.module';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { PaginationService } from './shared/services/pagination.service';
import { PdfModalComponent } from './shared/pdf-modal/pdf-modal.component';

// export function getDatepickerConfig(): BsDatepickerConfig {
//   return Object.assign(new BsDatepickerConfig(), {
//     containerClass: 'theme-blue',
//     dateInputFormat: 'DD/MM/YYYY'
//   });
// }

@NgModule({
  declarations: [
    AppComponent,
    PdfModalComponent
  ],
  imports: [
    BrowserModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBXaK96lS5Q6vs4IQPx4fZgpLLmfK_F44k'
    }),
    PublicModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PdfJsViewerModule
  ],
  providers: [
    NotifytoastService,
    ThemeChangerService,
    PaginationService,
    // { provide: BsDatepickerConfig, useFactory: getDatepickerConfig }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
