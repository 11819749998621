import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-contact-info-form',
  templateUrl: './contact-info-form.component.html',
  styleUrls: ['./contact-info-form.component.scss']
})
export class ContactInfoFormComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  contactInfoForm: any;
  // @Input() dataObj: any;
  // @Output() submitFormObj = new EventEmitter()
  isSubmitted = false;
  emailError = '';
  quoteObj: any;
  

  constructor(
    private formBuilder: FormBuilder, 
    private router: Router, 
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.initForm()
  }

  initForm(){
    this.contactInfoForm = this.formBuilder.group({
      firstName: ['', Validators.required], 
      lastName: ['', Validators.required], 
      email: ['', Validators.required]
    })
    this.fromAPI()
  }

  navigateToLink(page) {
    const tenant = this.utilsService.getTenantFromWindow()
    this.router.navigate([`${tenant}/${page}`]);
  }

  fromAPI(){
    if(this.quoteObj.conversation['personal-info']) this.contactInfoForm.patchValue(this.quoteObj.conversation['personal-info'])
  }

  keyEmail(){
    const temp = this.contactInfoForm.get('email')
    if(temp.errors){
      if (temp.errors.invalidEmailAddress && !temp.errors.required) {
        this.emailError =  'Email is not valid.'
      } else{
        this.emailError = ''
      }
    }
  }

  submitForm(userSelection){
    let temp;
    this.isSubmitted = true;
    if(this.contactInfoForm.valid && userSelection == 'next'){
      temp = { 
        // input: `${this.contactInfoForm.value.firstName} ${this.contactInfoForm.value.lastName} ${this.contactInfoForm.value.email}`,
        // ...this.quoteObj
        input: 'Next',
        pageID: 'personal-info',
        userInput: this.contactInfoForm.value
      }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'personal-info' }
    }
    if(temp){
      // console.log(temp)
      // this.submitFormObj.emit(temp)
      this.dataTransferService.sendData(temp)
    }
  }

}
