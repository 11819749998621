<form [formGroup]="homeUsageForm" *ngIf="quoteObj?.pageID == 'Home-Usage-Select'">
    <div class="row">
        <div class="p-b-20" [ngClass]="(quoteCustomizedFeature && showOptionAtStepSix) ? 'col-lg-3': 'col-lg-4'">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center radio-selector-wrapper">
                    <label class="p-2" for="primary">
                        <p class="radio-selector-title">Primary Residence</p>
        
                        <input type="radio" value="primary" formControlName="usageResidence" id="primary" class="radio-col-light-blue" />
                        <img class="radio-image-selector p-2" [src]="'assets/multiTenantImages/tenants' + parentTenant + '/quote_property_usage_primary.svg'" altImgTenants>
                    </label>
                </mat-card>
            </div>
        </div>
        <div class="p-b-20" [ngClass]="(quoteCustomizedFeature && showOptionAtStepSix) ? 'col-lg-3': 'col-lg-4'">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center radio-selector-wrapper">
                    <label class="p-2" for="secondary">
                        <p class="radio-selector-title">Secondary / Seasonal</p>
        
                        <input type="radio" value="secondary" formControlName="usageResidence" id="secondary" class="radio-col-light-blue" />
                        <img class="radio-image-selector p-2" [src]="'assets/multiTenantImages/tenants' + parentTenant + '/quote_property_usage_vacation.svg'" altImgTenants>
                    </label>
                </mat-card>
            </div>
        </div>
        <div class="p-b-20" [ngClass]="(quoteCustomizedFeature && showOptionAtStepSix) ? 'col-lg-3': 'col-lg-4'">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center radio-selector-wrapper">
                    <label class="p-2" for="landlord">
                        <p class="radio-selector-title">Landlord  / Rental Property</p>
        
                        <input type="radio" value="landlord" formControlName="usageResidence" id="landlord" class="radio-col-light-blue" />
                        <img class="radio-image-selector p-2" [src]="'assets/multiTenantImages/tenants' + parentTenant + '/quote_property_usage_rental.svg'" altImgTenants>
                    </label>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-3" *ngIf="(quoteCustomizedFeature && showOptionAtStepSix)">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center radio-selector-wrapper">
                    <label class="p-2" for="vacant">
                        <p class="radio-selector-title">Vacant / Unoccupied</p>
        
                        <input type="radio" value="vacant" formControlName="usageResidence" id="vacant" class="radio-col-light-blue" />
                        <img class="radio-image-selector p-2" [src]="'assets/multiTenantImages/tenants' + parentTenant + '/quote_property_usage_vacant.svg'" altImgTenants>
                    </label>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="col-12 form-group">
        <div  class="text-center p-b-40">
            <button class="btn btn-info custom-button-info m-r-20" *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button"
                (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
            <button class="btn btn-info custom-button-info" type="button"
                *ngIf="quoteObj?.buttonOptions?.includes('Next')"
                (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        </div>
    </div>
</form>