<div class="col-md-6 offset-md-3" *ngIf="quoteObj?.pageID == 'csr-contact'">
    <div class="row">
        <div class="col-md-12 text-center p-b-10">
            <h4 class="estimation-text">
                {{'ESTIMATED_MONTHLY_PREMIUM' | translate}}
            </h4>
            <h2 class="premium-monthly-amt">
                ${{displayValue?.monthlyPremium}} plus Fees
            </h2>
            <h4 class="premium-yearly-amt">
                ${{displayValue?.yearlyPremium}}/yr
            </h4>
            <div class="p-b-20">
                <button class="btn btn-info custom-button-info" type="button" (click)="submitForm('back')"
                    [ngStyle]="styling?.button?.buttonNegative">{{'BACKTOEDITCOVERAGE' | translate}}</button>
            </div>
            <!-- <h4 class="estimation-text">
                {{'LETS_GET_IN_TOUCH' | translate}}
            </h4>
            <span [ngStyle]="styling?.quoterWizard?.quoteSubTitle">
                {{'PROFESSIONAL_SEEK_HELP' | translate}}
            </span> -->
            <h4 class="card-title">

                <h2 [ngStyle]="styling?.quoterWizard?.pageTitle">{{quoteObj?.pageMessage}}</h2>
                <h4 [ngStyle]="styling?.quoterWizard?.quoteSubTitle" style="padding-top: 10px;" [innerHTML]="quoteObj?.pageSubTitle"></h4>
            

                <!-- <app-bot-message [message]="quoteObj?.pageMessage" [subTitle] = "quoteObj?.pageSubTitle" [showImageFlag]="showImageFlag"></app-bot-message> -->
            </h4>
        </div>
    </div>
    <form [formGroup]="sendCSRQuoteForm">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field style="width: 100%">
                    <input matInput [ngStyle]="styling?.quoterWizard?.quoteSubField1" [ngStyle]="styling?.quoterWizard?.quoteSubField1" placeholder="First Name *" formControlName="first_name" maxlength="50" />
                </mat-form-field>
                <p class="error-class mat-angular-error-text" *ngIf="!sendCSRQuoteForm.get('first_name').valid && isSubmitted">First Name is required</p>
            </div>
            <div class="col-md-6">
                <mat-form-field style="width: 100%">
                    <input matInput [ngStyle]="styling?.quoterWizard?.quoteSubField1" [ngStyle]="styling?.quoterWizard?.quoteSubField1" placeholder="Last Name *" formControlName="last_name" maxlength="50" />
                </mat-form-field>
                <p class="error-class mat-angular-error-text" *ngIf="!sendCSRQuoteForm.get('last_name').valid && isSubmitted">Last Name is required</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field style="width: 100%">
                    <input matInput phoneDisplay [preValue]="sendCSRQuoteForm.value.phone_no" [ngStyle]="styling?.fieldValueInput" [ngStyle]="styling?.quoterWizard?.quoteSubField1" placeholder="Phone Number" formControlName="phone_no" maxlength="14" />
                </mat-form-field>
                <!-- <p class="error-class mat-angular-error-text" *ngIf="sendCSRQuoteForm.get('phone_no').errors?.required && isSubmitted">Phone Number is required</p> -->
                <p class="error-class mat-angular-error-text" *ngIf="sendCSRQuoteForm.get('phone_no').errors?.pattern && isSubmitted">Invalid Phone Number</p>
            </div>
            <div class="col-md-6">
                <mat-form-field style="width: 100%">
                    <input matInput [ngStyle]="styling?.quoterWizard?.quoteSubField1" [ngStyle]="styling?.quoterWizard?.quoteSubField1" placeholder="Email Address *" formControlName="email" maxlength="50" />
                    <!-- <span matSuffix>
                        <a class="fas fa-question-circle" popoverTitle="Info" [popover]="'EMAIL_NOTIFY_USER' | translate" container="body" triggers="mouseenter:mouseleave"></a>
                    </span> -->
                </mat-form-field>
                <p class="error-class mat-angular-error-text" *ngIf="!sendCSRQuoteForm.get('email').valid && isSubmitted">Email Address is required</p>
                <p [ngStyle]="styling?.quoterWizard?.quoteSubField1" [innerHTML]="quoteObj?.emailMessage"></p>
            </div>
        </div>
    

        <div class="card m-t-20">
            <div class="card-body text-center">
                <h2 [ngStyle]="styling?.quoterWizard?.pageTitle">
                    Find Agent Details
                </h2>
                <div class="row p-t-30">
                    <div class="col-md-6">
                        <mat-form-field style="width: 100%">
                            <input matInput [ngStyle]="styling?.quoterWizard?.quoteSubField1" [ngStyle]="styling?.quoterWizard?.quoteSubField1" placeholder="Agent Number *" formControlName="agent_number" maxlength="50" />
                        </mat-form-field>
                        <p class="error-class mat-angular-error-text" *ngIf="!sendCSRQuoteForm.get('agent_number').valid && isSubmitted">Email Address is required</p>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-info custom-button-info col-lg-6" type="button" (click)="findAgentUserByID()" [ngStyle]="styling?.button?.buttonPositive">Find Agent</button>
                    </div>
                </div>
                <div class="card m-t-20" *ngIf="agentDetails">
                    <div class="card-body text-left">
                        <div class="row">
                            <div class="offset-1 col-11">
                                <span class="font-weight-bold">{{ agentDetails.agentFirstName }} {{ agentDetails.agentLastName }}</span>
                                <div class="address-font-card">{{ agentDetails.agencyName }}</div>
                                <div class="address-font-card">{{ agentDetails.agencyLocationAddress.street }}, {{ agentDetails.agencyLocationAddress.city }}, {{ agentDetails.agencyLocationAddress.zip }} <i class="fas fa-globe-americas"></i></div>
                                <div class="address-font-card">{{ utilsService.formatNumber(agentPhoneNumber) }}</div>
                            </div>
                        </div>
                        <div class="row" style="margin: 20px 0; justify-content: center;">
                            <button class="btn btn-info custom-button-info col-lg-6" type="button" (click)="sendQuote()"
                                [ngStyle]="styling?.button?.buttonPositive">Email {{ agentDetails.agentFirstName }} the Quote</button>
                            <span class="m-r-20 m-b-20"></span>
                            <button class="btn btn-info custom-button-info col-lg-5" type="button" (click)="printQuoteSelect()"
                                [ngStyle]="styling?.button?.buttonPositive">{{'PRINT_QUOTE' | translate}}</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="card m-t-20">
                    <div class="card-body text-left">
                        <div class="row">
                            <div class="offset-1 col-11">
                                <h3 [ngStyle]="styling?.quoterWizard?.pageTitle">RYAN TOOMBS</h3>
                                <h4 [ngStyle]="styling?.quoterWizard?.quoteSubTitle">TOOMBS INSURANCE GROUP, LLC</h4>
                                <h4 [ngStyle]="styling?.quoterWizard?.quoteSubTitle">16590 N Dale Mabry Hwy, Tampa, 33618 <i class="fas fa-globe-americas"></i></h4>
                                <h4 [ngStyle]="styling?.quoterWizard?.quoteSubTitle">(111) 111-1111</h4>
                            </div>
                        </div>
                        <div class="row" style="margin: 20px 0; justify-content: center;">
                            <button class="btn btn-info custom-button-info col-lg-6" type="button" (click)="sendQuoteTo('email')"
                                [ngStyle]="styling?.button?.buttonPositive">Email RYAN the Quote</button>
                            <span class="m-r-20 m-b-20"></span>
                            <button class="btn btn-info custom-button-info col-lg-5" type="button" (click)="sendQuoteTo('printQuote')"
                                [ngStyle]="styling?.button?.buttonPositive">{{'PRINT_QUOTE' | translate}}</button>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="row m-t-20">
                    <div class="col-md-12 text-center">
                        <button class="btn btn-info custom-button-info m-r-20" type="button"
                        [ngStyle]="styling?.button?.buttonPositive" (click)="emailQuote()">{{'EMAIL_MYSELF' | translate}}</button>
                        <button class="btn btn-info custom-button-info" type="button"
                        [ngStyle]="styling?.button?.buttonPositive">{{'PRINT_QUOTE' | translate}}</button>
                    </div>
                </div> -->
            </div>
        </div>
    </form>

</div>