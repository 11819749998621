<form [formGroup]="isRentalForm" *ngIf="quoteObj?.pageID == 'home-usage'">
    <div class="row p-20">
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center"
                (click)="onClick('Primary Residence')">
                    <label for="Primary Residence">
                        <input type="radio" value="Primary Residence" formControlName="checkRental" id="Primary Residence" class="radio-col-light-blue" />
                        <img [ngStyle]="styling?.quoterWizard.quoteImage" [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_own_house.svg'" altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">Primary Residence</p>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center"
                (click)="onClick('Seasonal Residence')">
                    <label for="seasonal">
                        <input type="radio" value="Seasonal Residence" formControlName="checkRental" id="Seasonal Residence" class="radio-col-light-blue" />
                    <img [ngStyle]="styling?.quoterWizard.quoteImage" [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_seasonal.svg'" altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption">Seasonal Residence</p>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center"
                (click)="onClick('Vacant/Unoccupied')">
                    <label for="Vacant/Unoccupied">
                        <input type="radio" value="Vacant/Unoccupied" formControlName="checkRental" id="Vacant/Unoccupied" class="radio-col-light-blue" />
                    <img [ngStyle]="styling?.quoterWizard.quoteImage" [ngStyle]="styling?.quoterWizard.quoteImage" [src]="'assets/multiTenantImages/tenants/' + parentTenant + '/quote_vacant.svg'" altImgTenants>
                    </label>
                    <p class="radio-selector-title" [ngStyle]="styling?.quoterWizard.quoteImageCaption"> {{ 'VACANT' | translate }}</p>
                </mat-card>
            </div>
        </div>
    </div>
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>
    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
    <button class="btn btn-info custom-button-info m-r-10 m-b-20"
        *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
        [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    <button class="btn btn-info custom-button-info m-l-10 m-b-20"
        type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
        [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
</div>
</form>