<div class="col-12 form-group" *ngIf="quoteObj?.pageID == 'Welcome-Page'">
    <div class="row">
        <div class="col-md-4 offset-md-4">
        </div>
    </div>
    <div class="col-12 form-group">
        <div  class="text-center p-b-40">
            <!-- <button class="btn btn-info custom-button-info m-r-20" *ngIf="quoteObj?.buttons?.indexOf('Back') > -1" type="button"
                (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button> -->
            <button class="btn btn-info custom-button-info" type="button"
                *ngIf="quoteObj?.buttons?.indexOf('Next') > -1"
                (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        </div>
    </div>
</div>