import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-house-roof-type',
  templateUrl: './house-roof-type.component.html',
  styleUrls: ['./house-roof-type.component.scss']
})
export class HouseRoofTypeComponent implements OnInit {
  houseRoofTypeForm: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any;
  
  roofMaterialsMapping: any;
  isSmallWindow = window.innerWidth <= 768;
  parentTenant: any;

  constructor(
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) {
    let tenant = this.route.parent.parent.snapshot.params.tenant
    this.parentTenant = tenant.split('-')[0].toLowerCase()
   }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.initForm()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }
  initForm(){
    this.houseRoofTypeForm = this.formBuilder.group({
      roofType: ['Gable']
    });
    this.fromAPI()
  }

  fromAPI(){
    if(this.quoteObj.conversation && this.quoteObj.conversation['roof-shape']) {
      let formKeys = ["Gable","Hip","Flat","Other"]
      let matchedKeys = formKeys.filter(res => res.toLowerCase().match(this.quoteObj.conversation['roof-shape'].roofType.toLowerCase()) )
      let roofType = matchedKeys.length ? matchedKeys[0] : formKeys[0] 
      this.houseRoofTypeForm.patchValue({roofType})
      // this.houseRoofTypeForm.patchValue(this.quoteObj.conversation['roof-shape'])
    }
  }

  submitForm(userSelection){
    let temp;
    if(this.houseRoofTypeForm.valid && userSelection == 'next'){
      temp = { input: 'Next', pageID: 'roof-shape', userInput: this.houseRoofTypeForm.value }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'roof-shape' }
    }
    if(temp){
     this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve){
    if (this.houseRoofTypeForm.valid) {
      let temp = eve
      temp.userInput = this.houseRoofTypeForm.value
      temp.currentPageID = 'roof-shape'
      this.dataTransferService.sendData(temp)
    }
  }

}
