import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
@Component({
  selector: 'app-contact-information',
  templateUrl: './recoop-contact-information.component.html',
  styleUrls: ['./recoop-contact-information.component.scss']
})
export class RecoopContactInformationComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  contactInfoForm: any;
  isSubmitted = false;
  emailError = '';
  quoteObj: any;
  next: any;
  pageID: any;
  helpText: any;
  rateQuoteData: any;
  tenant: any;
  isSmallWindow = window.innerWidth <= 768;
  coverageValueParameter: boolean = false;
  paymentOption: any;
  documentPreference: any;
  loading = false;
  progressNumber = 0;
  interval: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.documentPreference = this.quoteObj.documentPreference;
    this.initForm();
    this.keyEmail();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }


  initForm() {
    this.pageID = this.quoteObj.pageID;
    this.helpText = this.quoteObj.helpText;
    this.tenant = this.utilsService.getTenantFromWindow();
    this.contactInfoForm = this.formBuilder.group({
      phone_no: ['', [Validators.required, Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.maxLength(14)]],
      email: [null, [Validators.required, ValidationService.emailValidator]]
    })
    this.fromAPI();
    if(this.quotedataService.getRecoopCoverageValue()!=null){
      this.coverageValueParameter = true;
    }
  }

  navigateToLink(page) {
    this.router.navigate([`${this.tenant}/${page}`]);
  }

  fromAPI() {
    if (this.quoteObj.conversation[this.pageID]) this.contactInfoForm.patchValue(this.quoteObj.conversation[this.pageID])
  }

  keyEmail() {
    const temp = this.contactInfoForm.get('email')
    if (temp.errors) {
      if (temp.errors.required) {
        this.emailError = 'Email is required.'
      } else if (temp.errors.invalidEmailAddress) {
        this.emailError = 'Email is not valid.'
      }
    }
  }

  convertPhoneNumber(phoneNum) {
    if (phoneNum != null) {
      return phoneNum.replace(/[-_()' ']/g, '')
    }
    return null;
  }

  submitForm(userSelection) {
    if (userSelection == "next") {
      this.isSubmitted = true;
      if (this.contactInfoForm.valid) {
        this.submitQuote();
      }
    }
    else if (userSelection == 'back') {
      const temp = { input: 'Back', pageID: this.quoteObj.pageID };
      this.dataTransferService.sendData(temp);
    }

  }


  getEffectiveDate() {
    let result = new Date();
    result.setDate(result.getDate() + 1);
    return result.toLocaleDateString('en-US');
  }
  getFormValue(formName, value) {
    if (this.quoteObj.conversation[formName]) {
      return this.quoteObj.conversation[formName][value]
    } else {
      return null
    }
  }
  getPolicyForm() {
    return this.quotedataService.getAcntConfig()["quote"]["productDetails"]["policyForm"];
  }
  getRateQuoteData() {
    const currentSelectedAddress = this.quotedataService.getAddressObj().currentSelectedAddress
    const propertyAddress = {
      street:
        (currentSelectedAddress['street_number'] &&
          currentSelectedAddress['street_number']['long_name']) +
        ' ' +
        (currentSelectedAddress['route'] && currentSelectedAddress['route']['long_name']) +
        ' ' +
        this.getFormValue("property-location", "unitNumber"),
      city: currentSelectedAddress['city']['long_name'],
      state: currentSelectedAddress['state']['short_name'],
      zip:
        currentSelectedAddress['postal_code'] &&
        currentSelectedAddress['postal_code']['long_name'],
      latitude: this.quotedataService.getRiskAddressObj()["lat"],
      longitude: this.quotedataService.getRiskAddressObj()["lng"]
    }
    const insuranceType = this.quotedataService.getAcntConfig()["quote"]["productDetails"]["insuranceType"];
    const occupancyStatus = this.quotedataService.getAcntConfig()["quote"]["relation_to_property_options"][this.getFormValue('relation-to-property', 'relationToProperty')];
    const propertyUsage= this.getFormValue('residence-type', 'propertyUsage');

    const data = {
      agentID: (this.quotedataService.getAgentID() != null) ? this.quotedataService.getAgentID() : this.quotedataService.getMappingInfoObj().defaultAgentID,
      firstName: this.quotedataService.getInsuredName().firstName,
      lastName: this.quotedataService.getInsuredName().lastName,
      accountCode: this.tenant,
      insuranceType: insuranceType,
      policyEffectiveDate: this.getEffectiveDate(),
      propertyAddress: propertyAddress,
      mailingAddress: propertyAddress,
      propertyOccupancyStatus: occupancyStatus,
      coverageDisasterValue: this.getFormValue('spe-quote-premium', 'coverageValue'),
      email: this.contactInfoForm.value.email,
      eeId: this.quotedataService.getAccountNumberPreEnrollment(),
      phoneNumber: {
        number: this.convertPhoneNumber(this.contactInfoForm.value.phone_no)
      },
      propertyUsage: propertyUsage
    }
    this.rateQuoteData = data;
  }

  submitRateQuote(data): Observable<any> {
    return this.httpService.post('submitQuote', data, true, true).pipe(
      map((resp) => {
        if (resp.out === undefined) {
          this.utilsService.confirmModalPopup(`${resp.root.Envelope.Body.Fault.faultstring}`)
        } else {
          return resp.out;
        }
      })
    );
  }

  getPaymentOptions() {
    const data = {
      accountCode: this.tenant,
      billType: "MortgageBill",
      feeAmount: this.quotedataService.getPolicyFees(),
      feeType: "policyFee",
      policyNum: this.quotedataService.getQuoteID(),
      policyType: "NewBusiness",
      productIdentifier: this.quotedataService.getAcntConfig()["quote"]["productDetails"]["productIdentifier"],
      state: this.quotedataService.getAddressObj().stateCode,
      termStartDate: this.getEffectiveDate(),
      termPeriod: "12",
      termUnit: "Months",
      totalPremium: this.quotedataService.getEstimatedPremium()
    }
    this.httpService.post('getPaymentOptions', data, true, true)
      .subscribe(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp?.root?.Envelope?.Body?.Fault?.faultstring);
          } else {
            // Per requirement, payment plan will be monthly pay and payroll deduct
            for (let i = 0; i < resp.out.billPlans.BillPlan.length; i++) {
              if (resp.out.billPlans.BillPlan[i]["numberOfInstallments"] == 1) {
                this.paymentOption = resp.out.billPlans.BillPlan[i];
                console.log(this.paymentOption)
                break;
              }
            }
            this.bindPolicy();
            this.loading = true;
            this.fakeLoader();
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  bindPolicy() {
    let input = {};
    input = {
      "accountCode": this.tenant,
      "creditCard": false,
      "achAccount": false,
      "quoteNumber": this.quotedataService.getQuoteID(),
      "documentPreference": this.documentPreference,
      "paymentOption": {
        "paymentMethod": this.paymentOption.paymentMethod,
        "billType": this.paymentOption.billType,
        "billPlanIdentifier": this.paymentOption.billPlanIdentifier
      },
      "email": this.quotedataService.getEmail(),
      "phoneNumber": this.quotedataService.getPhoneNumber(),
      "fullName":this.quotedataService.getInsuredName().firstName + " " + this.quotedataService.getInsuredName().lastName
    }
    this.httpService.post('bindPolicy', input, false, true)
      .subscribe((resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp?.root?.Envelope?.Body?.Fault?.faultstring);
          } else {
            console.log("result from bind policy: " + JSON.stringify(resp))
            const temp = {
              input: 'Next',
              pageID: this.quoteObj.pageID,
                userInput: this.contactInfoForm.value
            };
            temp.userInput.payrollDeduct = this.quotedataService.getPayrollDeduct();
            temp.userInput.bindPolicy = this.coverageValueParameter;
            this.quotedataService.setPolicyNumber(resp.out.policyNumber);
            if (temp) {
              this.dataTransferService.sendData(temp);
            } 
          }
        },
          (error) => {
            this.notifyToast.error('Please Contact System Administrator.');
          }
        );
  }

  fakeLoader() {
    this.progressNumber = 0
    this.startTimer()
  }

  startTimer() {
    this.interval = window.setInterval(() => {
      if (this.progressNumber < 98) this.progressNumber++
      else if (!this.loading) this.stopTimer(100)
      else this.stopTimer(100)
    }, 200)
  }

  stopTimer(time) {
    window.clearInterval(this.interval)
    window.clearTimeout(this.interval)
  }

  submitQuote() {
    this.getRateQuoteData();
    this.submitRateQuote(this.rateQuoteData).subscribe(
      (resp) => {
        if (resp === undefined) {
          console.log('No response returned');
        } else {
          const quoteReferenceNumber = resp.quoteReferenceNumber;
          this.quotedataService.setQuoteID(quoteReferenceNumber);
          this.quotedataService.setEmail(this.contactInfoForm.value.email);
          this.quotedataService.setPhoneNumber(this.convertPhoneNumber(this.contactInfoForm.value.phone_no));
          this.quotedataService.setEffectiveDate(this.getEffectiveDate);
          if(!this.coverageValueParameter){
            const temp = {
              input: 'Next',
              pageID: this.quoteObj.pageID,
              userInput: this.contactInfoForm.value
            }
            temp.userInput.payrollDeduct = this.quotedataService.getPayrollDeduct();
            temp.userInput.bindPolicy = this.coverageValueParameter;
            this.dataTransferService.sendData(temp);
        }
        else{
          this.getPaymentOptions();
          this.loading = true;
          this.fakeLoader();
        }
        }
      });
  }

}
