import { formatCurrency } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-coverage-c-page',
  templateUrl: './coverage-c-page.component.html',
  styleUrls: ['./coverage-c-page.component.scss']
})
export class CoverageCPageComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  coverageCPageForm: any;
  isSubmitted = false;
  quoteObj: any;

  coverageCValues: Array<{ labelDisplay: any, valueDisplay: any }> = []
  coverageCList: any;
  coverageCValue: any;
  policyType: any;
  defaultValue: any;
  isSmallWindow = window.innerWidth <= 768;

  valueBelongsToCurList = false;
  defaultCoverageCValue: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.initForm()
    this.fromAPI()
    this.getReplacementCost()
    this.getCoverageCValues()

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  getCoverageCValues() {
    this.coverageCList = this.quotedataService.getMappingInfoObj().coverageCPageCalculation
    console.log(this.coverageCList)
    let valueA = Number(this.coverageCValue);
    for (let item of this.coverageCList) {
      let stringValue;
      let totalAmt;
      if (item != null && item.calculationValue != null) {
        totalAmt = valueA * Number(item.calculationValue)
        stringValue = formatCurrency(totalAmt, "en_US", "$", "USD", '1.0-0')
      }
      //console.log("calculation value: " +stringValue)
      this.coverageCValues.push({ labelDisplay: stringValue + item.displayValue, valueDisplay: totalAmt })
      if (item.displayValue == "") {
        this.defaultCoverageCValue = totalAmt
      }
      if (this.coverageCPageForm.value.coverageCField === totalAmt) {
        this.valueBelongsToCurList = true;
      }
    }
    if (!this.valueBelongsToCurList) {
      this.coverageCPageForm.patchValue({
        coverageCField: this.defaultCoverageCValue
      });
    }
  }
  getReplacementCost() {
    // this.coverageCValue = 40000;
    this.policyType = this.quotedataService.getPolicyObjData() == null ? 'HO4' : this.quotedataService.getPolicyObjData().policyType
    if (this.policyType != null || this.policyType != undefined) {
      this.defaultValue = this.quotedataService.getMappingInfoObj().coverageDefaultValue
      this.coverageCValue = this.defaultValue[this.policyType]
    } else {
      console.log("Error: policy type is null.")
    }
  }

  initForm() {
    this.coverageCPageForm = this.formBuilder.group({
      coverageCField: [''],
    })
  }

  fromAPI() {
    if (this.quoteObj.conversation && this.quoteObj.conversation['Coverage-C'])
      this.coverageCPageForm.patchValue(this.quoteObj.conversation['Coverage-C'])
  }

  submitForm(userSelection) {
    let temp;
    this.isSubmitted = true;
    if (this.coverageCPageForm.valid && userSelection == 'next') {
      const coverageFormObj = {
        coverageCValue: this.coverageCPageForm.value["coverageCField"]
      }
      this.quotedataService.setCoverageObj(coverageFormObj)

      temp = {
        input: 'Next',
        pageID: 'Coverage-C',
        userInput: this.coverageCPageForm.value
      }
    } else if (userSelection == 'back') {
      const coverageFormObj = {
        coverageCValue: ''
      }
      this.quotedataService.setCoverageObj(coverageFormObj)

      temp = { input: 'Back', pageID: 'Coverage-C' }
    }
    if (temp) {
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve) {
    this.isSubmitted = true;
    if (this.coverageCPageForm.valid) {
      let temp = eve
      temp.userInput = this.coverageCPageForm.value
      temp.currentPageID = 'Coverage-C'
      this.dataTransferService.sendData(temp)
    }
  }

}
