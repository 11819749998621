import { formatCurrency } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { CommonApiService } from '../../../shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';

@Component({
  selector: 'app-coverage-a-page',
  templateUrl: './coverage-a-page.component.html',
  styleUrls: ['./coverage-a-page.component.scss']
})
export class CoverageAPageComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  coverageAPageForm: any;
  constructionTypeMapping: any;
  isSubmitted = false;
  temp: any;
  valuesAvailable = false;
  quoteObj: any;
  
  coverageAValues: Array<{labelDisplay: any, valueDisplay: any}> = []
  coverageAList: any;
  coverageAValue: any;
  isSmallWindow = window.innerWidth <= 768;

  valueBelongsToCurList = false;
  defaultCoverageAValue: any;

  constructor(
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService,
    private formBuilder: FormBuilder, 
    private router: Router, 
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.getReplacementCost()
    this.initForm()
    this.fromAPI()
    this.constructionTypeMapping = this.quotedataService.getMappingInfoObj().constructionTypeMapping

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }
  
  getCoverageAValues() {
    this.coverageAList = this.quotedataService.getMappingInfoObj().coverageAPageCalculation
    let valueA = Number(this.coverageAValue);
    let i = 0;
    for(i=0; i< this.coverageAList.length;i++){
      let item = this.coverageAList[i]
      let stringValue;
      let totalAmt;
      if (item != null && item.calculationValue != null) {
        const amountWithoutRounding = valueA * Number(item.calculationValue)
        totalAmt = Math.round(amountWithoutRounding/1000)*1000
        stringValue = formatCurrency(totalAmt, "en_US", "$", "USD", '1.0-0')
      }
      this.coverageAValues.push({labelDisplay: stringValue, valueDisplay: totalAmt})
      if (item.displayValue == "") {
        this.defaultCoverageAValue = totalAmt
      }
      if (this.coverageAPageForm.value.coverageAField === totalAmt) {
        this.valueBelongsToCurList = true;
      }
    }
    if (!this.valueBelongsToCurList) {
      this.coverageAPageForm.patchValue({
        coverageAField: this.defaultCoverageAValue
      });
    }
    if(i == this.coverageAList.length){
      this.valuesAvailable = true
    }
  }
  getFormValue(formName, value){
    if(this.quoteObj.conversation[formName]){
      return this.quoteObj.conversation[formName][value]
    } else{
      return null
    }
  }
  getReplacementCost() {
   //TODO: get and set the coverage A value from API when the API is ready from PNC end
    const inputToReplacementCost = {
      accountCode: this.utilsService.getTenantFromWindow(),
      propertyAddress: this.quotedataService.getPropertyAddress(),
      homeStructureType: this.quoteObj.conversation["structure-type"]["checkHouse"],
      numberOfStories: (this.getFormValue("number-of-stories","story"))?this.getFormValue("number-of-stories","story"):'1',
      squareFootage: this.quoteObj.conversation["house-built"]["squareFeet"],
      yearBuilt: this.quoteObj.conversation["house-built"]["yearBuilt"]
    }
    this.httpService
      .post('getHomeReplacementCost', inputToReplacementCost, true, true)
      .subscribe(
        (resp) => {
          if (resp.out === undefined) {
            this.temp = { 
              input: 'Next',
              pageID: 'Coverage-A',
              userInput: {replacementCost:"false"}
            };
            if(this.temp){
              this.dataTransferService.sendData(this.temp)
            }
          } else {
            const replacementCost = Number(resp.out.replacementCost)
            this.coverageAValue = Math.round(replacementCost/1000)*1000;
            console.log("DEBUG replacement cost:", replacementCost);
            this.getCoverageAValues();
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  initForm(){
    this.coverageAPageForm = this.formBuilder.group({
      coverageAField: ['',],
    })
  }

  fromAPI(){
    if(this.quoteObj.conversation && this.quoteObj.conversation['Coverage-A']) 
      this.coverageAPageForm.patchValue(this.quoteObj.conversation['Coverage-A'])
  }

  submitForm(userSelection){
    this.isSubmitted = true;
    if(this.coverageAPageForm.valid && userSelection == 'next'){
      const coverageFormObj = {
        coverageAValue : this.coverageAPageForm.value["coverageAField"]
      }
      this.quotedataService.setCoverageObj(coverageFormObj)
      this.temp = { 
        input: 'Next',
        pageID: 'Coverage-A',
        userInput: this.coverageAPageForm.value
      }
      this.temp.userInput.replacementCost = "true";
    } else if(userSelection == 'back'){
      const coverageFormObj = {
        coverageAValue : ''
      }
      this.quotedataService.setCoverageObj(coverageFormObj)
      this.temp = { input: 'Back', pageID: 'Coverage-A' }
    }
    if(this.temp){
      this.dataTransferService.sendData(this.temp)
    }
  }

  submitBackToSummary(eve){
    this.isSubmitted = true;
    if (this.coverageAPageForm.valid) {
      let temp = eve
      temp.userInput = this.coverageAPageForm.value
      temp.currentPageID = 'Coverage-A'
      this.dataTransferService.sendData(temp)
    }
  }

}
