import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-law-suit',
  templateUrl: './law-suit.component.html',
  styleUrls: ['./law-suit.component.scss']
})
export class LawSuitComponent implements OnInit {
  lawSuitForm: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any;
  
  isSmallWindow = window.innerWidth <= 768;

  constructor(
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.initForm()
  }

    @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm(){
    this.lawSuitForm = this.formBuilder.group({
      lawSuit: ['no']
    });
  }

    submitForm(userSelection){
    let temp;
    if(this.lawSuitForm.valid && userSelection == 'next'){
        temp = { input: 'Next', pageID: 'prior-lawsuit', userInput: this.lawSuitForm.value }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'prior-lawsuit' }
    }
    if(temp){
      this.dataTransferService.sendData(temp)
    }
  }
}
