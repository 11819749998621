<div class="col-12">
    <div class="p-b-20">
        <div class="text-center">
            <ng-container *ngIf="showImageFlag">
                <!-- <img src="assets/multiTenantImages/png/insurbot_Primary.png" alt="bot_picture" class="bot-image"> -->
                <img [src]="showImage" alt="bot_picture" class="bot-image" [ngStyle]="styling?.quoterWizard?.quoteTopIcon">
            </ng-container>
            <ng-container *ngIf="!showImageFlag">
                <div style="padding-top: 20px;"></div>
            </ng-container>
            <h2 [ngStyle]="styling?.quoterWizard?.quoteTitle">{{displayMessage}}</h2>
            <h4 *ngIf="subTitle" [ngStyle]="styling?.quoterWizard?.quoteSubTitle" style="padding-top: 10px;" [innerHTML]="displaySubTitle">
            </h4>
        </div>
    </div>
</div>