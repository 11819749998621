<!-- <div class="col-md-6 offset-md-3">
    <div class="row">
        <div class="col-md-12 text-center">
            <h4 class="card-title">
                <app-bot-message [message]="'TITLE_QUOTE_SUBMISSION_CONFIRMATION' | translate"
                 [subTitle] = "'SUBTITLE_QUOTE_SUBMISSION_CONFIRMATION' | translate" [showImageFlag]="true"></app-bot-message>
            </h4>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 text-center p-b-30">
            <button class="btn btn-info custom-button-info" type="button"
                (click)="printQuote()" [ngStyle]="styling?.button?.buttonPositive">{{'PRINT_QUOTE' | translate}}</button>
        </div>
    </div>

    <div class="row p-b-20 p-t-20">
        <div class="col-md-12">
            <span [ngStyle]="styling?.quoterWizard?.quoteSubTitle">
                {{quoteSubmissionConfirmationPage.contentSummary}}
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="testimonial">
                        <div class="testimonial-content">
                            <i class="fas fa-quote-right quote-img"></i>
                            <div>
                                {{quoteSubmissionConfirmationPage.contentPersonOne}}
                            </div>
                        </div>
                        <div class="testimonial-author">
                            <div class="d-flex">
                                <div class="customer-pic">
                                    <img src="assets/multiTenantImages/png/gina.jpg" class="rounded-circle" />
                                </div>
                                <div class="customer">
                                    <h3 class="customer-name">{{quoteSubmissionConfirmationPage.personNameOne}}</h3>
                                    <div class="customer-occupation">{{quoteSubmissionConfirmationPage.personOneSubtitle}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="testimonial">
                        <div class="testimonial-content">
                            <i class="fas fa-quote-right quote-img"></i>
                            <div>
                                {{quoteSubmissionConfirmationPage.contentPersonTwo}}
                            </div>
                        </div>
                        <div class="testimonial-author">
                            <div class="d-flex">
                                <div class="customer-pic">
                                    <img src="assets/multiTenantImages/png/jaqueline.jpg" class="rounded-circle"/>
                                </div>
                                <div class="customer">
                                    <h3 class="customer-name">{{quoteSubmissionConfirmationPage.personNameTwo}}</h3>
                                    <div class="customer-occupation">{{quoteSubmissionConfirmationPage.personTwoSubtitle}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div> -->

<div class="col-md-6 offset-md-3">
    <div class="row">
        <div class="col-md-12 text-center">
            <h4 class="card-title">
                <app-bot-message [message]="quoteSubmissionConfirmationPage.title | translate"
                 [subTitle] = "quoteSubmissionConfirmationPage.subtitle | translate" [showImageFlag]="true"></app-bot-message>
            </h4>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 text-center p-b-30">
            <button class="btn btn-info custom-button-info" type="button"
                (click)="printQuote()" [ngStyle]="styling?.button?.buttonPositive">{{'PRINT_QUOTE' | translate}}</button>
        </div>
    </div>

    <div class="row p-b-20 p-t-20">
        <div class="col-md-12">
            <span [ngStyle]="styling?.quoterWizard?.quoteSubTitle">
                {{quoteSubmissionConfirmationPage.contentSummary}}
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card" *ngFor="let personObj of quoteSubmissionConfirmationPage?.person">
                <div class="card-body">
                    <div class="testimonial">
                        <div class="testimonial-content">
                            <i class="fas fa-quote-right quote-img"></i>
                            <div>
                                {{personObj.content}}
                            </div>
                        </div>
                        <div class="testimonial-author">
                            <div class="d-flex">
                                <div class="customer-pic" *ngIf="personObj.personImg">
                                    <img [src]="personObj.personImg" class="rounded-circle" setDefaultCustPic/>
                                    <!-- <img src="assets/multiTenantImages/tenants/acme/sam.svg" class="rounded-circle" setDefaultCustPic/> -->
                                </div>
                                <div class="customer">
                                    <h3 class="customer-name">{{personObj.personName}}</h3>
                                    <div class="customer-occupation">{{personObj.subtitle}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="card">
                <div class="card-body">
                    <div class="testimonial">
                        <div class="testimonial-content">
                            <i class="fas fa-quote-right quote-img"></i>
                            <div>
                                {{quoteSubmissionConfirmationPage.contentPersonOne}}
                            </div>
                        </div>
                        <div class="testimonial-author">
                            <div class="d-flex">
                                <div class="customer-pic">
                                    <img src="assets/multiTenantImages/png/gina.jpg" class="rounded-circle" />
                                </div>
                                <div class="customer">
                                    <h3 class="customer-name">{{quoteSubmissionConfirmationPage.personNameOne}}</h3>
                                    <div class="customer-occupation">{{quoteSubmissionConfirmationPage.personOneSubtitle}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="testimonial">
                        <div class="testimonial-content">
                            <i class="fas fa-quote-right quote-img"></i>
                            <div>
                                {{quoteSubmissionConfirmationPage.contentPersonTwo}}
                            </div>
                        </div>
                        <div class="testimonial-author">
                            <div class="d-flex">
                                <div class="customer-pic">
                                    <img src="assets/multiTenantImages/png/jaqueline.jpg" class="rounded-circle"/>
                                </div>
                                <div class="customer">
                                    <h3 class="customer-name">{{quoteSubmissionConfirmationPage.personNameTwo}}</h3>
                                    <div class="customer-occupation">{{quoteSubmissionConfirmationPage.personTwoSubtitle}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    
</div>