<div *ngIf="!loading">
    <div class="col-md-8 offset-md-2">
        <div class="row">
            <div class="col-md-12 text-center p-b-10">
                <h4 [ngStyle]="styling?.quoterWizard?.quotePremiumTitle">
                    {{'ESTIMATED_MONTHLY_PREMIUM' | translate}}
                </h4>
                <h2 [ngStyle]="styling?.quoterWizard?.quoteMonthlyPremium" (ngModel)="displayValue.monthlyPremium">
                    ${{displayValue?.monthlyPremium}} plus fees
                </h2>
                <h4 class=" p-b-30" [ngStyle]="styling?.quoterWizard?.quoteAnnualPremium"
                    (ngModel)="displayValue.yearlyPremium">
                    ${{displayValue?.yearlyPremium}}/yr
                </h4>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-12 text-center p-b-30" [ngStyle]="styling?.quoterWizard?.quoteTitle">
                Estimated Annual Premium
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center p-b-30" [ngStyle]="styling?.quoterWizard?.quoteTitle"  [ngStyle]="styling?.hyperlink" 
            (ngModel)="displayValue">
                $ {{displayValue}}
            </div>
        </div> -->
        <div class="row">
            <div class="col-md-12 text-center p-b-10">
                <h4 class="col-md-12 text-center p-b-10" [ngStyle]="styling?.quoterWizard?.quoteTitle">
                    Edit Your Coverages
                </h4>
                <h2 class="col-md-12 text-center p-b-30" [ngStyle]="styling?.quoterWizard?.quoteSubTitle">
                    Adjust your coverages to meet your needs and select Update Premium to see the estimated amount
                    change.
                </h2>
            </div>
        </div>
        <!-- <div class="row">
            <h4 class="col-md-12 text-center p-b-10" [ngStyle]="styling?.quoterWizard?.quoteTitle">
                Edit Your Coverages
            </h4>
        </div>
        <div class="row">
            <h2 class="col-md-12 text-center p-b-30" [ngStyle]="styling?.quoterWizard?.quoteSubTitle">
                Adjust your coverages to meet your needs and select Update Premium to see the estimated amount change.
            </h2>
        </div> -->
    </div>

    <div class="col-12 p-b-40" style="text-align: center;">
        <button class="btn btn-info custom-button-info m-r-20" type="button" (click)="reCalculatePremium()"
            [ngStyle]="styling?.button?.buttonNegative">{{'UPDATE_PREMIUM' | translate}}</button>

        <button class="btn btn-info " class="btn btn-info custom-button-info m-r-20"
            *ngIf="quoteObj?.buttonOptions?.includes('Next')" type="button" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'CONTINUE' | translate}}</button>
    </div>


    <form [formGroup]="coverageValues">
        <div class="col-md-8 offset-md-2">
            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="insuranceScore">
                <div class="col-md-6">
                    <span style="font-weight: 600; width: 20%; font-size:24px;"
                        [ngStyle]="styling?.quoterWizard?.quoteCoverageTitleStyle">{{insuranceScoreTitle}}</span>
                </div>
                <div class="col-md-6 text-md-right hidden-sm-down">
                    <span style="font-weight: 600; width: 20%; font-size:24px;"
                        [ngStyle]="styling?.quoterWizard?.quoteCoverageTitleStyle">{{insuranceScoreTitleValue}}</span>
                </div>
            </div>
            <div class="row  p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="insuranceScore">
                <div class="col-md-6 text-box-heading m-b-10">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{insuranceScoreDes}}</span>
                    <a [ngStyle]="styling?.quoterWizard?.toolTip" placement="auto" containerClass="custom-tooltip"
                        [adaptivePosition]="true" triggers="mouseenter:mouseleave" [popover]="insuranceScoreDecTooltip"
                        container="body">{{insuranceScoreDesSecond}}</a>

                </div>
                <div class="col-md-6 text-md-right">
                    <ng-select id="insuranceScoreList" [searchable]="false" [clearable]="false"
                        [items]="insuranceScoreList" appendTo="body" [hideSelected]="true"
                        placeholder="Estimated Credit Score" formControlName="estimatedCreditScore"
                        class="text-field-mat" style="display: inline-block;">
                    </ng-select>
                </div>
            </div>


            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue">
                <div class="col-md-6">
                    <span style="font-weight: 600; width: 20%; font-size:24px;"
                        [ngStyle]="styling?.quoterWizard?.quoteCoverageTitleStyle">Coverages</span>
                </div>
                <div class="col-md-6 text-md-right hidden-sm-down">
                    <span style="font-weight: 600; width: 20%; font-size:24px;"
                        [ngStyle]="styling?.quoterWizard?.quoteCoverageTitleStyle">Amount</span>
                </div>
            </div>

            <div class="row p-b-20 custom-title-info account-title coverage-container" *ngIf="coverageA"
                [ngStyle]="styling?.card?.attributeValue">
                <div class="col-md-6 text-box-heading">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{coverageAFieldName}}</span>
                    <p [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{coverageADescription}}</p>
                </div>
                <div class="col-md-6 text-md-right">
                    <mat-form-field class="text-field-mat">
                        <input matInput currencyOnly formControlName="coverageAValue" type="text" />
                    </mat-form-field>
                </div>
            </div>


            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="coverageB">
                <div class="col-md-6 text-box-heading">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{coverageBFieldName}}</span>
                    <p [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{coverageBDescription}}</p>
                </div>
                <!-- <div class="col-md-6 text-md-right">
                    <mat-form-field class="text-field-mat">
                        <input matInput currencyOnly formControlName="coverageBValue" type="text" />
                    </mat-form-field>
                </div> -->
                <div class="col-md-6 text-md-right">
                    <ng-select id="coverageBValue_!" [searchable]="false" [clearable]="false"
                        [items]="coverageItemList?.coverageBValue" appendTo="body" [hideSelected]="true"
                        placeholder="coverage B Value" formControlName="coverageBValue" class="text-field-mat"
                        style="display: inline-block;">
                    </ng-select>
                    <p class="error-class" *ngIf="!coverageValues.get('coverageBValue').valid">
                        coverage B Value is required</p>
                </div>
            </div>

            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="coverageC">
                <div class="col-md-6 text-box-heading m-b-10">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{coverageCFieldName}}</span>
                    <p [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{coverageCDescription}}</p>
                </div>
                <div class="col-md-6 text-md-right">
                    <div *ngIf="this.iscoverageCValue">
                        <ng-select id="coverageCValue_1" [searchable]="false" [clearable]="false"
                            [items]="coverageItemList?.coverageCValue" appendTo="body" [hideSelected]="true"
                            placeholder="coverage C Value" formControlName="coverageCValue" class="text-field-mat"
                            style="display: inline-block;">
                        </ng-select>
                        <p class="error-class" *ngIf="!coverageValues.get('coverageCValue').valid">coverage C Value is
                            required</p>
                    </div>
                    <div *ngIf="!this.iscoverageCValue">
                        <mat-form-field class="text-field-mat">
                            <input matInput currencyOnly formControlName="coverageCValue" type="text" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="coverageD">
                <div class="col-md-6 text-box-heading">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{coverageDFieldName}}</span>
                    <p [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{coverageDDescription}}</p>
                </div>
                <!-- <div class="col-md-6 text-md-right">
                    <mat-form-field class="text-field-mat">
                        <input matInput currencyOnly formControlName="coverageDValue" type="text" />
                    </mat-form-field>
                </div> -->
                <div class="col-md-6 text-md-right">
                    <ng-select id="coverageDValue_!" [searchable]="false" [clearable]="false"
                        [items]="coverageItemList?.coverageDValue" appendTo="body" [hideSelected]="true"
                        placeholder="coverage D Value" formControlName="coverageDValue" class="text-field-mat"
                        style="display: inline-block;">
                    </ng-select>
                    <p class="error-class" *ngIf="!coverageValues.get('coverageDValue').valid">
                        coverage D Value is required</p>
                </div>
            </div>

            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="coverageE">
                <div class="col-md-6 text-box-heading m-b-10">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{coverageEFieldName}}</span>
                    <p [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{coverageEDescription}}</p>
                </div>
                <div class="col-md-6 text-md-right">
                    <ng-select id="coverageEValue_!" [searchable]="false" [clearable]="false"
                        [items]="coverageItemList?.coverageEValue" appendTo="body" [hideSelected]="true"
                        placeholder="coverage E Value" formControlName="coverageEValue" class="text-field-mat"
                        style="display: inline-block;">
                    </ng-select>
                    <p class="error-class" *ngIf="!coverageValues.get('coverageEValue').valid">
                        coverage E Value is required</p>
                </div>
            </div>

            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="coverageEPremisesLiab">
                <div class="col-md-6 text-box-heading m-b-10">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{coverageEPremisesLiabFieldName}}</span>
                    <p [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{coverageEPremisesLiabDescription}}</p>
                </div>
                <div class="col-md-6 text-md-right">
                    <ng-select id="coverageEPremisesLiabValue_!" [searchable]="false" [clearable]="false"
                        [items]="coverageItemList?.coverageEPremisesLiabValue" appendTo="body" [hideSelected]="true"
                        placeholder="coverage E Premises Liability Value" formControlName="coverageEPremisesLiabValue" class="text-field-mat"
                        style="display: inline-block;">
                    </ng-select>
                    <p class="error-class" *ngIf="!coverageValues.get('coverageEPremisesLiabValue').valid">
                        coverage E Premises Liability Value is required</p>
                </div>
            </div>

            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="coverageF">
                <div class="col-md-6 text-box-heading">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{coverageFFieldName}}</span>
                    <p [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{coverageFDescription}}</p>
                </div>
                <div class="col-md-6 text-md-right">
                    <ng-select id="coverageFValue_!" [searchable]="false" [clearable]="false"
                        [items]="coverageItemList?.coverageFValue" appendTo="body" [hideSelected]="true"
                        placeholder="coverage F Value" formControlName="coverageFValue" class="text-field-mat"
                        style="display: inline-block;">
                    </ng-select>
                    <p class="error-class" *ngIf="!coverageValues.get('coverageFValue').valid">
                        coverage F Value is required</p>
                </div>
                <!-- <div class="col-md-6 text-md-right">
                    <mat-form-field class="text-field-mat">
                        <input matInput currencyOnly formControlName="coverageFValue" type="text"/>
                    </mat-form-field>  
                </div> -->
            </div>

            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="coverageL">
                <div class="col-md-6 text-box-heading">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{coverageLFieldName}}</span>
                </div>
                <div class="col-md-6 text-md-right">
                    <mat-form-field class="text-field-mat">
                        <input matInput currencyOnly formControlName="coverageLValue" type="text" />
                    </mat-form-field>
                </div>
            </div>

            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="coverageM">
                <div class="col-md-6 text-box-heading">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{coverageMFieldName}}</span>
                </div>
                <div class="col-md-6 text-md-right">
                    <mat-form-field class="text-field-mat">
                        <input matInput currencyOnly formControlName="coverageMValue" type="text" />
                    </mat-form-field>
                </div>
            </div>

            <!-- <hr style="height:5px;border-width:0;color:gray;background-color:gray; margin-top: 20px; margin-bottom: 20px;"> -->

            <div class="row  p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="curDeductibleNames">
                <div class="col-md-6 text-box-heading">
                    <span [ngStyle]="styling?.quoterWizard?.quoteCoverageTitleStyle">Deductibles</span>
                </div>
                <div class="col-md-6 text-md-right">
                    <span></span>
                </div>
            </div>

            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="aop">
                <div class="col-md-6 text-box-heading m-b-10">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{aopFieldName}}</span>
                    <p [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{aopDescription}}</p>
                </div>
                <div class="col-md-6 text-md-right">
                    <ng-select id="aopDeductible" [searchable]="false" [clearable]="false"
                        [items]="coverageItemList?.aopDeductible" appendTo="body" [hideSelected]="true"
                        placeholder="aop Deductible  Value" formControlName="aopDeductible" class="text-field-mat"
                        style="display: inline-block;">
                    </ng-select>
                    <p class="error-class" *ngIf="!coverageValues.get('aopDeductible').valid">aop Deductible is required
                    </p>
                </div>
            </div>

            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="hurricane">
                <div class="col-md-6 text-box-heading m-b-10">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{hurricaneFieldName}}</span>
                    <p [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{hurricaneDescription}}</p>
                </div>
                <div class="col-md-6 text-md-right">
                    <ng-select id="deductibleApplied_2" [searchable]="false" [clearable]="false"
                        [items]="coverageItemList?.deductibleAppliedForHurricaneCoverage" appendTo="body"
                        [hideSelected]="true" placeholder="Hurricane Deductible"
                        formControlName="deductibleAppliedForHurricaneCoverage" class="text-field-mat"
                        style="display: inline-block;">
                    </ng-select>
                    <p class="error-class" *ngIf="!coverageValues.get('deductibleAppliedForHurricaneCoverage').valid">
                        Hurricane Deductible is required</p>
                </div>
            </div>

            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="waterDamage">
                <div class="col-md-6 text-box-heading">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{waterDamageFieldName}}</span>
                    <p [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{waterDamageDescription}}</p>
                </div>
                <div class="col-md-6 text-md-right">
                    <mat-form-field class="text-field-mat">
                        <input matInput currencyOnly formControlName="waterDeductible" type="text" />
                    </mat-form-field>
                </div>
            </div>

            <div class="row  p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="floodCoverage">
                <div class="col-md-6 text-box-heading">
                    <span [ngStyle]="styling?.quoterWizard?.quoteCoverageTitleStyle">{{optionalCoverageTitle}}</span>
                </div>
                <div class="col-md-6 text-md-right">
                    <span></span>
                </div>
            </div>
            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="floodCoverage">
                <div class="col-md-6 text-box-heading m-b-20">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">{{floodCoverageFieldName}}</span>
                    <p [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{floodCoverageDescription}}</p>
                </div>
                <div class="col-md-6 text-md-right">
                    <div style="width: 100%;">
                        <input type="checkbox" value="includeFloodPremium" id="includeFloodPremium"
                            formControlName="includeFloodPremium" class="radio-col-light-blue" />
                        <label [ngStyle]="styling?.fieldValueCheckBox" for="includeFloodPremium"
                            [ngStyle]="styling?.quoterWizard?.quoteSubField5">{{floodCoverageFiledValue}}</label>
                    </div>
                </div>
            </div>

            <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue" *ngIf="discountTitle != null">
                <div class="col-md-6">
                    <span style="font-weight: 600; width: 20%; font-size:24px;"
                        [ngStyle]="styling?.quoterWizard?.quoteCoverageTitleStyle">{{discountTitle}}</span>
                </div>
            </div>
            <!-- <div class="row p-b-20 custom-title-info account-title coverage-container"
                [ngStyle]="styling?.card?.attributeValue">
                <div class="col-md-6 text-box-heading">
                    <span [ngStyle]="styling?.quoterWizard?.quoteSubField4">Age of Policyholder</span>
                    <p [ngStyle]="styling?.quoterWizard?.quoteSubField5">Are there any individuals, 55 or older, who
                        will be listed on the policy?</p>
                </div>
                <div class="col-md-3 offset-md-3 text-md-left d-flex">
                   <div class="col-md-6" style="width: 100%; padding: 0px;">
                        <input type="radio" [value]="true" id="true" formControlName="seniorDiscount"
                            class="radio-col-light-blue" />
                        <label [ngStyle]="styling?.fieldValueCheckBox" [for]="true">Yes</label>
                    </div>
                    <div class="col-md-6" style="width: 100%; padding: 0px;">
                        <input type="radio" [value]="false" id="false" formControlName="seniorDiscount"
                            class="radio-col-light-blue" />
                        <label [ngStyle]="styling?.fieldValueCheckBox" [for]="false">No</label>
                    </div>
                </div>
                </div> -->


            <div class="row" *ngIf="discountDescription != null">
                <h2 class="col-md-12 text-center p-b-30" [ngStyle]="styling?.quoterWizard?.quoteCoverageBottomLine">
                    {{discountDescription}}
                </h2>
            </div>

            <div class="row">
                <div class="col-md-12 text-center p-b-30">
                    <button class="btn btn-info custom-button-info premium-btn" type="button"
                        (click)="reCalculatePremium()" [ngStyle]="styling?.button?.buttonNegative">{{'UPDATE_PREMIUM' |
                        translate}}</button>
                </div>
            </div>


            <div class="text-center p-b-40" *ngIf="isSmallWindow">
                <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6" type="button"
                    *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
                    [ngStyle]="styling?.button?.buttonPositive">Finalize Quote</button>
                <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
                    *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
                    [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
            </div>
            <div class="text-center p-b-40" *ngIf="!isSmallWindow">
                <button class="btn btn-info custom-button-info m-r-20 m-b-20"
                    *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
                    [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
                <button class="btn btn-info custom-button-info m-r-20 m-b-20" type="button"
                    *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
                    [ngStyle]="styling?.button?.buttonPositive">Finalize Quote</button>
            </div>
        </div>
    </form>
</div>

<div class="col-12" *ngIf="loading">
    <div class="p-b-20">
        <!-- <div class="text-center">
            <div class="message-bot-display" [ngStyle]="styling?.card?.attributeValue">
                Please give me a moment while I find the best policy coverages and a price for you
            </div>
        </div> -->
        <div class="row" style="justify-content: center;">
            <h4 class="card-title">
                <app-bot-message [message]="botMessage[1]" [showImageFlag]="true"></app-bot-message>
            </h4>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <div class="col-sm-8" style="margin-top: 100px;margin-bottom: 100px;">
            <div class="pipe-container">
                <div class="pipe-loader" [style.width.%]="progressNumber">
                    {{progressNumber}}%
                </div>
            </div>
        </div>
    </div>
</div>