<div>
    <h4 class="text-center p-b-40 p-t-20">
        <span>Your quote has been sent to {{email}}.</span>
        <span>We look forward to hearing from you! </span>
        <span>Please feel free to visit {{this.tenant | uppercase}} Insurance and look through our customer resources.</span>
    </h4>
    <div class="col-md-6 offset-md-3 text-center" style="padding-top: 30px;">
        <div [innerHTML]="customerResource" style="padding-top: 15px; text-align: center;" ></div>
        <button class="btn btn-info" (click)="navigateToLink('get-quote')"
        [ngStyle]="styling?.button?.buttonPositive">Get Another Quote</button>
    </div>
    <!-- <div class="col-12">
        
    </div>
    <div class="col-md-4 offset-md-4 row p-20 text-center d-flex justify-content-center" style="align-content: center;">
        <button class="btn btn-info" (click)="navigateToLink('get-quote')"
        [ngStyle]="styling?.button?.buttonMajorAction">Get Another Quote</button>
    </div> -->
</div>