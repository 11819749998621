import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-exterior-of-home',
  templateUrl: './exterior-of-home.component.html',
  styleUrls: ['./exterior-of-home.component.scss']
})
export class ExteriorOfHomeComponent implements OnInit {
  exteriorOfHomeForm: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any;
  isSmallWindow = window.innerWidth <= 768;
  
  constructor(
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.initForm()
    this.fromAPI()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm(){
    this.exteriorOfHomeForm = this.formBuilder.group({
      exteriorOfHome: ['No']
    });
  }

  fromAPI(){
    if(this.quoteObj.conversation && this.quoteObj.conversation['exterior-of-home']) 
      this.exteriorOfHomeForm.patchValue(this.quoteObj.conversation['exterior-of-home'])
  }


  onClick(eve) {
    this.exteriorOfHomeForm.patchValue({
      exteriorOfHome : eve
    });
  }

  submitForm(userSelection){
    let temp;
    if(this.exteriorOfHomeForm.valid && userSelection == 'next'){
        temp = { input: 'Next', pageID: 'exterior-of-home', userInput: this.exteriorOfHomeForm.value }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'exterior-of-home' }
    }
    if(temp){
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve){
    if (this.exteriorOfHomeForm.valid) {
      let temp = eve
      temp.userInput = this.exteriorOfHomeForm.value
      temp.currentPageID = 'exterior-of-home'
      this.dataTransferService.sendData(temp)
    }
  }

}


