<div class="col-12">
    <div class="card-header blue-background" style=" border: 1px solid black;">You're all set!</div>
    <div class="col-md-6 offset-md-3" style="padding-top: 30px;">
        Your quote # {{quoteID}} has been sent and {{agentFirstName}} {{agentLastName}} will be in touch with you
        shortly! <br>
        <div style="padding-top: 15px;">In the meantime please feel free to look through our customer resources.</div>
        <div [innerHTML]="customerResource" style="padding-top: 15px; text-align: center;" ></div>
        
        <div class="col-md-4 offset-md-4 row p-20 text-center d-flex justify-content-center" style="align-content: center;">
            <button class="btn btn-info" (click)="navigateToLink('get-quote')"
            [ngStyle]="styling?.button?.buttonPositive">Get Another Quote</button>
        </div>
    
    </div>
</div>