import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-agent-email-confirmation',
  templateUrl: './agent-email-confirmation.component.html',
  styleUrls: ['./agent-email-confirmation.component.scss'],
  providers: [CommonApiService]
})
export class AgentEmailConfirmationComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  tenant: any;
  customerResource: any;
  agentDetails: any;
  agentFirstName: any;
  agentLastName: any;
  quoteID: any;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService,
    private sanitizer: DomSanitizer,
    private quotedataService: QuoteDataShare
  ) { }

  ngOnInit(): void {
    this.tenant = this.utilsService.getTenantFromWindow()
    this.agentDetails = this.quotedataService.getEmailQuote()
    this.quoteID = this.quotedataService.getQuoteID()
    this.getCustomerResource()
    this.setAgentDetails()
  }

  navigateToLink(page) {
    // this.router.navigate([`${this.tenant}/get-quote/${page}`]);
    window.location.reload();
  }

  setAgentDetails() {
    this.agentFirstName = this.agentDetails.agentFirstName;
    this.agentLastName = this.agentDetails.agentLastName;
  }

  getCustomerResource() {
    var infoType = 'agent_email_confirmation_resource';
    this.httpService
      .get(
        `getCardInfo?accountCode=${this.tenant}&infoType=${infoType}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp === undefined) {
            this.notifyToast.error(
              'Please Contact System Administrator.' +
                '\nError: ' +
                resp.root.Envelope.Body.Fault.faultstring
            );
          } else {
            this.customerResource = resp.out;
            this.customerResource = this.sanitizer.bypassSecurityTrustHtml(this.customerResource);
            console.log('Customer resources after sending email:', this.customerResource);
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

}
