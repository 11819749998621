<form [formGroup]="googleAddressForm" *ngIf="quoteObj?.pageID == 'property-location'">
    <div class="row p-20">
        <div class="col-md-5 offset-md-3">
            <mat-form-field style="width: 100%" [ngStyle]="styling?.quoterWizard?.quoteSubField1">
                <input [ngStyle]="styling?.quoterWizard?.quoteSubField1" matInput ngx-google-places-autocomplete
                    [autofocus]="true" type="text" formControlName="address" [options]="options" #placesRef="ngx-places"
                    (onAddressChange)="handleAddressChange($event)" (input)="addressTyping()"
                    placeholder="Property Address" #googleAddressInput />
            </mat-form-field>
            <p class="error-class mat-angular-error-text"
                *ngIf="googleAddressForm.get('address').errors?.required && isSubmitted"
                [ngStyle]="styling?.quoterWizard?.quoteSubField1">
                Address is required</p>
            <p class="error-class mat-angular-error-text"
                *ngIf="googleAddressForm.get('address').errors?.addressDropdown && isSubmitted"
                [ngStyle]="styling?.quoterWizard?.quoteSubField1">

                Select address from drop down</p>
        </div>
        <div class="col-md-4">
            <mat-form-field style="width: 50%" [ngStyle]="styling?.quoterWizard?.quoteSubField1">
                <input [ngStyle]="styling?.quoterWizard?.quoteSubField1" matInput type="text"
                    formControlName="unitNumber" placeholder="Apt/Unit #" />
            </mat-form-field>
            <p class="error-class mat-angular-error-text"
                *ngIf="!googleAddressForm.get('unitNumber').valid && isSubmitted"
                [ngStyle]="styling?.quoterWizard?.quoteSubField1">
                {{'INVALID_ADDRESS' | translate}}
            </p>
        </div>
    </div>
    <div class="row p-20" *ngIf="hasNewConstructionFeature">
        <div class="col-md-5 offset-md-3" style="padding: 15px;" [ngStyle]="styling?.quoterWizard?.quoteSubTitle">
            Is this property a new construction or currently being built?
        </div>
        <div class="col-md-4 d-flex" style="align-items: center;">
            <div class="col-md-2" style="width: 100%; padding: 0px;">
                <input type="radio" [value]="true" id="true" formControlName="newConstruction"
                    class="radio-col-light-blue" (change)="constructionChange($event)" />
                <label [ngStyle]="styling?.fieldValueCheckBox" [for]="true">Yes</label>
            </div>
            <div class="col-md-2" style="width: 100%; padding: 0px;">
                <input type="radio" [value]="false" id="false" formControlName="newConstruction"
                    class="radio-col-light-blue" (change)="constructionChange($event)" />
                <label [ngStyle]="styling?.fieldValueCheckBox" [for]="false">No</label>
            </div>
        </div>
    </div>

    <div class="col-12 form-group">
        <div class="col-12 form-group">
            <div class="text-center p-b-40">
                <button class="btn btn-info custom-button-info m-r-20" *ngIf="quoteObj?.buttonOptions?.includes('Back')"
                    type="button" (click)="submitForm('back')" [ngStyle]="styling?.button?.buttonNegative">{{'BACK' |
                    translate}}</button>
                <button class="btn btn-info custom-button-info" type="button"
                    *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
                    [ngStyle]="styling?.button?.buttonPositive">{{this.next || 'Let\'s Get Started' |
                    translate}}</button>
            </div>
        </div>
    </div>
</form>