<form [formGroup]="contactInfoForm">
    <div class="col-12 form-group">
        <div class="row">
            <div class="col-md-3 offset-md-3">
                <mat-form-field style="width: 100%">
                    <input matInput [autofocus]="true" formControlName="firstName" type="text"
                        placeholder="{{'FIRST_NAME' | translate}} *" />
                </mat-form-field>
                <p class="error-class mat-angular-error-text"
                    *ngIf="!contactInfoForm.get('firstName').valid && isSubmitted">
                    {{'REQUIRED_FIELD' | translate: {value:'First Name'} }}</p>
            </div>
            <div class="col-md-3">
                <mat-form-field style="width: 100%">
                    <input matInput formControlName="lastName" type="text"
                        placeholder="{{'LAST_NAME' | translate}} *" />
                </mat-form-field>
                <p class="error-class mat-angular-error-text"
                    *ngIf="!contactInfoForm.get('lastName').valid && isSubmitted">
                    {{'REQUIRED_FIELD' | translate: {value:'Last Name'} }}</p>
            </div>
        </div>
    </div>

    <div class="col-12 form-group">
        <div class="text-center p-b-40">
            <button class="btn btn-info custom-button-info m-r-20" *ngIf="quoteObj?.buttonOptions?.includes('Back')"
                type="button" (click)="submitForm('back')" [ngStyle]="styling?.button?.buttonNegative">{{'BACK' |
                translate}}</button>
            <button class="btn btn-info custom-button-info" type="button"
                *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{this.next||'GET-QUOTE' | translate}}</button>
        </div>
    </div>
</form>