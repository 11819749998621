import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit, OnChanges {
  windowWidth:any;
  @Input() customTableActions: any
  @Input() tableDataObj: any
  @Input() tableName: any
  @Input() noDataAvailableTxt: any
  @Output() actionEventTable = new EventEmitter()
  styling =this.utilsService.getItemfromSession('themeObject')
  @Input() openAccordion: any;
  accordionObj = []
  availableHeaders = []
  tableHeaders = []
  accordionHeaders = []
  private subject: Subject<string> = new Subject();
  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.checkCondition();
    });
  }

  ngOnChanges(changes: any){
    if (changes.hasOwnProperty('customTableActions')) {
      if (changes.customTableActions.currentValue) {
        console.log('has appeared :1')
        if(this.customTableActions.actions && this.availableHeaders.indexOf('ACTIONS') == -1) this.availableHeaders.push('ACTIONS')
        else this.availableHeaders = this.availableHeaders.filter(resp => resp != 'ACTIONS')
        this.checkCondition()
      }
    } 
    if(changes.hasOwnProperty('tableDataObj')) {
      if (changes.tableDataObj.currentValue) {
        console.log('has appeared :2')
        // console.log(this.tableDataObj)
        if(this.tableDataObj.length){
          this.availableHeaders = Object.keys(this.tableDataObj[0])
          if(this.customTableActions.actions) this.availableHeaders.push('ACTIONS')
          
          this.checkCondition()
          if(this.openAccordion != undefined){
            setTimeout(() => {
              if(document.getElementById(`${'#NumberIndex' + this.tableName + this.openAccordion}`)) document.getElementById(`${'#NumberIndex' + this.tableName + this.openAccordion}`).click()
            }, 500);
          }
        }
      }
    }
  }

  callbackFn(actionType, rowData, file?){
    // console.log('bow', actionType, rowData)
    let data: any;
    if(file) data = {actionType, rowData, file}
    else data = {actionType, rowData}
    this.actionEventTable.emit(data)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth
    this.subject.next()
  }

  checkCondition(){
    let items: number
    if(window.innerWidth < 561) items = 2
    else if(window.innerWidth > 560 && window.innerWidth < 801) items = 3
    else if(window.innerWidth > 800 && window.innerWidth < 1148) items = 4
    else items = 5
    if(this.tableDataObj && this.tableDataObj.length) this.convertAccordionTable(items)
  }

  convertAccordionTable(items: number){
    this.tableHeaders = this.availableHeaders.slice(0, items)
    this.accordionHeaders = this.availableHeaders.slice(items)
    // console.log(this.tableHeaders, this.accordionHeaders,items)

    const accordionObj=this.tableDataObj.map(resp => { 
      let temp = {}
      this.accordionHeaders.forEach(ele => {
        temp[ele] = resp[ele]
      });
      return temp
    })
    // console.log(accordionObj) 
  }

  fileChange(event, actionType, rowData) {
    const file = event.target.files;
    if (file.length) {
      const setFiles = [...file]
      this.callbackFn(actionType, rowData, setFiles)
    }
    // if (!file.length) {
    //   this.notificationToast.error('Please upload a valid file')
    // }
  }

}
