import { Injectable } from '@angular/core';

Injectable()
export class PaginationService {
    constructor(){}

    getPageDetails(allItems: number, currentPage: number = 1, perPageList: number = 10){
        let allPages = Math.ceil(allItems / perPageList);

        if(currentPage < 1){
            currentPage = 1;
        } else if(currentPage > allPages){
            currentPage = allPages
        }

        let startPage: number, endPage: number;
        if(allPages <= 5){
            startPage = 1;
            endPage = allPages;
        } else{
            if(currentPage <= 3){
                startPage = 1;
                endPage = 5;
            } else if(currentPage + 2 <= allPages){
                startPage = currentPage - 2
                endPage = currentPage + 2
            } else if(currentPage + 1 == allPages){
                startPage = currentPage - 3
                endPage = allPages
            } else{
                startPage = currentPage - 4
                endPage = allPages
            }
        }
        let pages = []
        for(let i = startPage; i <= endPage; i++){
            pages.push(i)
        }

        return{
            allItems,
            currentPage,
            perPageList,
            allPages,
            startPage,
            endPage,
            pages
        }

    }
}