<form [formGroup]="houseTypeForm" *ngIf="quoteObj?.pageID == 'structure-type'">

    <div class="form-group row m-b-20">
        <div class="col-md-4 offset-md-4 justify-content-center">
            <div style="width: 100%;" [ngStyle]="styling?.quoterWizard?.quoteSubField1">
                <ng-select id="StructureTypelist" [items]="StructureTypelist" appendTo="body" [hideSelected]="true"
                    placeholder="Home Structure Type" formControlName="checkHouse" [ngStyle]="styling?.quoterWizard?.quoteSubField1">
                </ng-select>
            </div>
        </div>
    </div>
    
    <!-- <div class="row  p-20">
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center radio-selector-wrapper"
                (click)="onClick('house')">
                    <label class="p-2" for="house">
                        <p class="radio-selector-title">Single Family Home</p>
                        <input type="radio" value="house" formControlName="checkHouse" id="house" class="radio-col-light-blue" />
                        <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_hometype_house.svg">
                    </label>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center radio-selector-wrapper"
                (click)="onClick('condo')">
                    <label class="p-2" for="condo">
                        <p class="radio-selector-title">Condo</p>
        
                        <input type="radio" value="condo" formControlName="checkHouse" id="condo" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_hometype_apartment.svg">
                    </label>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center radio-selector-wrapper"
                (click)="onClick('townhouse')">
                    <label class="p-2" for="townhouse">
                        <p class="radio-selector-title">Townhouse</p>
        
                        <input type="radio" value="townhouse" formControlName="checkHouse" id="townhouse" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_hometype_townhouse.svg">
                    </label>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center radio-selector-wrapper"
                (click)="onClick('duplex')">
                    <label class="p-2" for="duplex">
                        <p class="radio-selector-title">Duplex</p>
        
                        <input type="radio" value="duplex" formControlName="checkHouse" id="duplex" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_hometype_duplex.svg">
                    </label>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center radio-selector-wrapper"
                (click)="onClick('apartment')">
                    <label class="p-2" for="apartment">
                        <p class="radio-selector-title">Apartment</p>
        
                        <input type="radio" value="apartment" formControlName="checkHouse" id="apartment" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_hometype_apartment.svg">
                    </label>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center radio-selector-wrapper"
                (click)="onClick('mobile')">
                    <label class="p-2" for="mobile">
                        <p class="radio-selector-title">Mobile</p>
                        <input type="radio" value="mobile" formControlName="checkHouse" id="mobile" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_hometype_mobile.svg">
                    </label>
                </mat-card>
            </div>
        </div>
    </div> -->


    <!-- <div class="col-12 form-group">
        <div  class="text-center p-b-40">
            <button class="btn btn-info custom-button-info m-r-20" *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button"
                (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
            <button class="btn btn-info custom-button-info" type="button"
                *ngIf="quoteObj?.buttonOptions?.includes('Next')"
                (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        </div>
    </div> -->
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>

    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
    <button class="btn btn-info custom-button-info m-r-10 m-b-20"
        *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
        [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    <button class="btn btn-info custom-button-info m-l-10 m-b-20"
        type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
        [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
</div>
</form>