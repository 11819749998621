import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-email-quoter-confirmation',
  templateUrl: './email-quoter-confirmation.component.html',
  styleUrls: ['./email-quoter-confirmation.component.scss'],
  providers: [CommonApiService]
})
export class EmailQuoterConfirmationComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  customerResource: any;
  tenant: any;
  email: any;

  constructor(
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService,
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer,
    private quotedataService: QuoteDataShare
  ) { }

  ngOnInit(): void {
    this.tenant = this.utilsService.getTenantFromWindow()
    this.email = this.quotedataService.getEmailQuote()
    this.getCustomerResource()
  }

  navigateToLink(page) {
    // this.router.navigate([`${this.tenant}/get-quote/${page}`]);
    window.location.reload()
  }

  getCustomerResource() {
    var infoType = 'customer_resources';
    this.httpService
      .get(`getCardInfo?accountCode=${this.tenant}&infoType=${infoType}`, null, true)
      .subscribe(
        (resp) => {
          if (resp === undefined) {
          this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          } else {
            this.customerResource = resp.out;
            this.customerResource = this.sanitizer.bypassSecurityTrustHtml(this.customerResource);
            console.log('Customer resources after sending email:', this.customerResource);
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

}
