import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-google-address-form',
  templateUrl: './google-address-form.component.html',
  styleUrls: ['./google-address-form.component.scss'],
  providers: [CommonApiService]
})
export class GoogleAddressFormComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  @ViewChild('googleAddressInput') googleAddressInput: ElementRef
  quoteObj: any;
  newConstructionFeature: any;
  googleAddressForm: any;
  currentSelectedAddress = {};
  isSubmitted = false;
  options: any;
  tenant: any;
  next: any;

  @ViewChild("placesRef") placesRef: any;
  dropdownSelected = false;
  hasNewConstructionFeature: any;

  constructor(
    private httpService: CommonApiService,
    private formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute,
    private http: CommonApiService,
    private notifyToast: NotifytoastService
  ) {
    this.tenant = this.utilsService.getTenantFromWindow()
  }
  ngOnInit(): void {
    this.getQuoteAdditionalFeatures();
    this.route.data.subscribe(resp => {
      this.setBounds(resp.coOrdinatesObj);

    }, error => {
      this.notifyToast.error('Unable to find state')
    })
    this.quoteObj = this.quotedataService.getConfiguration()
    this.initForm()
    this.fromAPI()
  }

  getQuoteAdditionalFeatures() {
    this.httpService
      .get(`getQuoteAdditionalFeatures?accountCode=${this.tenant}`, null, true)
      .subscribe(
        (resp) => {
          if (resp) {
            this.quotedataService.setAdditionalFeatures(resp);
            this.hasNewConstructionFeature = resp.includes('new-construction');
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }



  setBounds(resp) {
    // let regionBounds = {
    //   north: 31.018292,
    //   south: 24.349674,
    //   east: -79.441021,
    //   west: -87.668408
    // }
    this.options = {
      bounds: (Object.keys(resp).length === 0) ? null : resp,
      types: [],
      componentRestrictions: { country: 'US' },
      strictBounds: true
    }
    // if(resp) this.options.bounds = resp

  }

  initForm() {
    this.googleAddressForm = this.formBuilder.group({
      newConstruction: [false],
      address: ['', [Validators.required]],
      unitNumber: ['', [
        Validators.pattern(/(^([aA][pP][tT]|[uU][nN][iI][tT]) \w+$)|(^(?!([aA][pP][tT]))(?!([uU][nN][iI][tT]))\w*$)/)
      ]]
    });
    this.next = this.quoteObj.Next ? this.quoteObj.Next : null;
  }

  fromAPI() {
    if (this.quoteObj.conversation && this.quoteObj.conversation['property-location']) {
      this.dropdownSelected = !this.quoteObj.conversation['property-location']["unKnownAddress"]
      //this.dropdownSelected = false; //currentSelected Address checking is done before submitting SPE-172447, when component is reinitialized currentSelectedAddress is undefined
      if (this.dropdownSelected) {
        this.currentSelectedAddress = this.quotedataService.getAddressObj().currentSelectedAddress
      }
      this.googleAddressForm.patchValue(this.quoteObj.conversation['property-location']);
    }
  }

  addressTyping() {
    this.dropdownSelected = false
    if(Object.keys(this.currentSelectedAddress).length) {
      this.currentSelectedAddress = {}
      let addressObj : {
        stateCode: any,
        county: any,
        currentSelectedAddress: any
      }
      this.quotedataService.setAddressObj(addressObj)
    }
    console.log(this.currentSelectedAddress, "reached here")
  }

  handleAddressChange(googleAddress) {
    //console.log("DEBUG ADDRESS:", googleAddress.address_components)
    this.dropdownSelected = true
    if (googleAddress.address_components) {
      for (var i = 0; i < googleAddress.address_components.length; i++) {
        var c = googleAddress.address_components[i];
        if (c.types[0] == 'administrative_area_level_1')
          this.currentSelectedAddress['state'] = c;
        else {
          if (c.types[0].includes('locality') && (!this.currentSelectedAddress['city'])) this.currentSelectedAddress['city'] = c;
          else this.currentSelectedAddress[c.types[0]] = c;
        }
        if (c.types[0] == 'administrative_area_level_2') {
          this.currentSelectedAddress['county'] = c;
        }
      }
      this.currentSelectedAddress['formatted_address'] =
        googleAddress.formatted_address;
      this.googleAddressForm.patchValue({
        address: this.googleAddressInput.nativeElement.value
      });
      let addressDetails = {
        "city": this.currentSelectedAddress['city'] && this.currentSelectedAddress['city']['short_name'],
        "street": (this.currentSelectedAddress['street_number'] && this.currentSelectedAddress['street_number']['short_name']) + " " + (this.currentSelectedAddress['route'] && this.currentSelectedAddress['route']['long_name']),
        "zipCode": (this.currentSelectedAddress['postal_code'] && this.currentSelectedAddress['postal_code']['long_name']),
        "stateCode": (this.currentSelectedAddress['state'] && this.currentSelectedAddress['state']['short_name']),
        "lat": googleAddress.geometry.location.lat(),
        "lng": googleAddress.geometry.location.lng()
      }
      this.quotedataService.setRiskAddressObj(addressDetails)
    } else {
      this.googleAddressForm.patchValue({ address: '' });
    }

  }

  constructionChange(eve) {
    this.googleAddressForm.get('address').setErrors(null);
  }

  submitForm(userSelection) {
    let temp;

    if (this.googleAddressForm.valid && userSelection == 'next') {
      this.isSubmitted = true;
      temp = {
        input: 'Next',
        pageID: 'property-location',
        userInput: this.googleAddressForm.value,
      }
      if (!this.dropdownSelected) {
        if (this.googleAddressForm.value.newConstruction == true) {
          temp.userInput.unKnownAddress = true;
          this.dataTransferService.sendData(temp)
        } else {
          this.googleAddressForm.get('address').setErrors({ addressDropdown: true });
        }
      }
      else if (this.dropdownSelected) {
        temp.userInput.unKnownAddress = false;
        if (Object.keys(this.currentSelectedAddress).length) {
          let addressObj;
		      console.log("current address:", this.currentSelectedAddress);
		      var countyName = this.currentSelectedAddress['county'] != undefined ? this.currentSelectedAddress['county']['long_name'] : null;
          addressObj = {
            stateCode: this.currentSelectedAddress['state']['short_name'],
            county: countyName,
            currentSelectedAddress: this.currentSelectedAddress
          }
          this.quotedataService.setAddressObj(addressObj)
          temp.userInput.excludedStates = this.quotedataService.getAcntConfig()["quote"]["excludedStates"];
          temp.userInput.state = this.currentSelectedAddress['state']['long_name'];
          this.dataTransferService.sendData(temp)
        }
      }
      // excludedStates
    } else if (userSelection == 'back') {
      temp = { input: 'Back', pageID: 'property-location' }
      this.dataTransferService.sendData(temp)
    }

    this.quotedataService.setUnitNumber(this.googleAddressForm.value.unitNumber);
  }

}
