// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isDebugMode: false, // ******* IMP -> FOR (QA and PROD) EBS deploymentchange this flag to false and then run build command  *********
  sfiUrl: window.location.origin + '/sfi/',
  upcUrl: window.location.origin + '/upc/',
  acmeUrl: window.location.origin + '/acme/',
  custPortalUrl: 'https://insuredportal-uat-mw.hostedinsurance.com/default/v3/',
  // custPortalUrl: 'http://localhost:8080/default/v3/',
  // custPortalUrl: 'https://insuredportal-uat-mw.hostedinsurance.com/default/',
  //custPortalUrl: 'https://pz109w6m83.execute-api.us-east-1.amazonaws.com/test/default/',
  newApiUrl: 'http://localhost:8080/default/',
  //gmapsID: 'AIzaSyBXaK96lS5Q6vs4IQPx4fZgpLLmfK_F44k',   // test GMAP ID
  gmapsID: 'AIzaSyD8-rw2oRMpigSnMMRsLYzbBIKFD6FX-EM',
  agentFindUrl: 'https://gzyd0zy8mk.execute-api.us-east-1.amazonaws.com/test/default/',
  demoPayment: "demo=y",
  //agentFindUrl_prod: 'https://a1nflgwlhb.execute-api.us-east-1.amazonaws.com/prod/',  


  buildVersion: 'v CO-2023-02.02',
  buildDate: '11/22/2023',
  gtmID: {
    'sfi': 'GTM-PS78J9C',
    'sfi-qa': 'GTM-PS78J9C',
    'sfi-partner-allstate-test': 'GTM-PS78J9C',
    'sfi-partner-advanceage-test': 'GTM-PS78J9C',
    'sfi-partner-geico-test': 'GTM-PS78J9C',
    'sfi-partner-schackow-test': 'GTM-PS78J9C',
    'sfi-partner-jeanarthur-test': 'GTM-PS78J9C',
    'sfi-partner-pontell-test': 'GTM-PS78J9C',
    'sfi-partner-rilesallen-test': 'GTM-PS78J9C'
    // 'sfi-prod': 'GTM-PDZ733Q',
    // 'sfi': 'GTM-NHCDGZ2'
  },
  idleTime: 30 * 60 // enter value in seconds
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
