<form [formGroup]="contactInfoForm">

    <div class="col-12 form-group">
        <div class="row">
            <div class="col-md-3 offset-md-3">
                <mat-form-field style="width: 100%">
                    <input matInput phoneDisplay [preValue]="contactInfoForm.value.phone_no"
                        [ngStyle]="styling?.fieldValueInput" [ngStyle]="styling?.quoterWizard?.quoteSubField1"
                        placeholder="phone number*" formControlName="phone_no" maxlength="14" />
                </mat-form-field>
                <p class="error-class mat-angular-error-text"
                    *ngIf="contactInfoForm.get('phone_no').errors?.pattern && isSubmitted">Invalid Phone Number</p>
            </div>
            <div class="col-md-3">
                <mat-form-field style="width: 100%">
                    <input matInput formControlName="email" style="padding-right: 0px;" (input)="keyEmail()" type="text"
                        placeholder="email address*" />
                </mat-form-field>
                <p class="error-class mat-angular-error-text"
                    *ngIf="(!contactInfoForm.get('email').valid) && isSubmitted ">
                    {{emailError}}
                </p>
            </div>
        </div>
    </div>

    <div class="col-12" [ngStyle]="styling?.quoterWizard?.helpText" style="text-align: center;"> {{this.helpText}}
    </div>

    <div class="col-12 form-group">
        <div class="text-center p-b-40" *ngIf="!isSmallWindow">
            <button class="btn btn-info custom-button-info m-r-10 m-b-20"
                *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' |
                translate}}</button>
            <button class="btn btn-info custom-button-info m-l-10 m-b-20" type="button"
                *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{ 'NEXT' | translate}}</button>
        </div>
        <div class="text-center p-b-40" *ngIf="isSmallWindow">
            <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6" type="button"
                *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
            <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
                *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
        </div>
    </div>
</form>