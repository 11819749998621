import { Component, OnInit } from '@angular/core';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { formatCurrency, DatePipe } from '@angular/common';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from "lodash";
import { LatestGetQuoteDemoService } from '../latest-get-quote-demo.service';

@Component({
  selector: 'app-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.scss']
})
export class QuoteSummaryComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  loading: any;
  quoteObj: any;
  page: any;
  tenant: any;
  quoteID: any;
  isSubmitted = false;
  pagesListNavigated = [];

  estimatedPremium: any;
  estimatedPremiumTax: any;
  basicResponse: any;
  upgradedResponse: any;
  highEndResponse: any;
  currentSelectedAddress: any;
  selectedAgentDetails: any;
  defaultAgentID: any;

  progressNumber = 0
  interval: any;
  summaryData: any;

  county: any;
  policyTypeList: any;
  temp: any;
  policyType: any;
  capacityParameters = {};
  coverageObj: any;
  mapForCoverageNames = new Map();
  state: any;

  structureType: any;
  insuranceTypeValue: any;

  iscoverageCValue: false;

  botMessage = [
    'Ready to Quote',
    'Please give me a moment while I find the best policy coverages and a price for you'
  ]

  constructor(
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: CommonApiService,
    private notifyToast: NotifytoastService,
    private datePipe: DatePipe,
    private getQuoteService: LatestGetQuoteDemoService,
    private quoteDataShare: QuoteDataShare
  ) {
    this.page = this.route.snapshot.routeConfig.path
  }

  ngOnInit(): void {
    this.policyType = this.quotedataService.getPolicyObjData().policyType;
    this.tenant = this.utilsService.getTenantFromWindow()
    this.quoteObj = this.quotedataService.getConfiguration()
    this.pagesListNavigated = this.quotedataService.getVisitedPages()
    this.currentSelectedAddress = this.quotedataService.getAddressObj().currentSelectedAddress
    this.selectedAgentDetails = this.quotedataService.getEmailQuote()
    this.defaultAgentID = (this.quotedataService.getMappingInfoObj().defaultAgentID == undefined || this.quotedataService.getMappingInfoObj().defaultAgentID == null) ? '3245336' : this.quotedataService.getMappingInfoObj().defaultAgentID
    this.insuranceTypeValue = (this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote == undefined || this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote == null) ?  this.policyType: this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote[this.policyType]
    this.iscoverageCValue = (this.quotedataService.getMappingInfoObj().coverageCOrCoverageCtoCoverageA == undefined || this.quotedataService.getMappingInfoObj().coverageCOrCoverageCtoCoverageA == null) ?  false : this.quotedataService.getMappingInfoObj().coverageCOrCoverageCtoCoverageA.includes(this.policyType)
    this.state = this.quotedataService.getAddressObj().stateCode;
    this.convertStructureType();
    const address = this.quotedataService.getRiskAddressObj();
    const unitNumber = this.getFormValue('property-location', 'unitNumber')
    const cleanseAddress = address.street + (unitNumber ? " " : "") + unitNumber + ", " + address.city + ", " + address.stateCode + ", " + address.zipCode;
    this.county = this.quotedataService.getAddressObj().county;
    this.convertCounty();
    this.summaryData = {
      relationToProperty: this.getFormValue('relation-to-property', 'relationToProperty'),
      structureType: this.getFormValue('structure-type', 'checkHouse'),
      propertyUsage: this.pagesListNavigated.indexOf('home-usage') > -1 ? this.getFormValue('home-usage', 'checkRental') : null,
      propertyYearBuilt: this.getFormValue('house-built', 'yearBuilt'),
      propertySquareFootage: this.getFormValue('house-built', 'squareFeet'),
      propertyRoofType: this.getFormValue('roof-shape', 'roofType'),
      propertyRoofMaterial: this.getFormValue('roof-material', 'roofMaterial'),
      propertyConstructionType: this.getFormValue('structure-material', 'constructionType'),
      noOfStories: this.getFormValue('number-of-stories', 'story'),
      openingProtectionLevel: this.pagesListNavigated.indexOf('home-usage') > -1 ? this.getFormValue('opening-protection', 'openingProtection') : null,
      address: cleanseAddress
    }
    this.coverageObj = this.quotedataService.getCoverageObj()
    console.log(this.coverageObj, 'coverageObj')

    this.getProductMapping();
  }

  convertStructureType() {
    this.structureType = this.quotedataService.getMappingInfoObj().structureTypeConvertValueForRateQuote[this.getFormValue('structure-type', 'checkHouse')]
    // console.log(this.structureType)
  }

  getListOfCoverageNamesMapping() {
    this.httpService
      .get(`getListOfCoverageNamesMapping?accountCode=${this.tenant}`, null, true)
      .subscribe(
        (resp) => {
          if (resp === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          } else {
            this.mapForCoverageNames = this.getQuoteService.objectToMap(resp);
            this.setCoverageNames()
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  setCoverageNames() {
    let listOfCoveragesUnderState;
    // this.policyType = this.quotedataService.getPolicyObjData().policyType;
    if (this.mapForCoverageNames.has(this.state)) {
      listOfCoveragesUnderState = this.mapForCoverageNames.get(this.state);
      if (listOfCoveragesUnderState.has(this.policyType)) {
        let curCoverageNames = listOfCoveragesUnderState.get(this.policyType).get("Coverages");
        let curDeductibleNames = listOfCoveragesUnderState.get(this.policyType).get("Deductibles");
        let curInsuranceScore = listOfCoveragesUnderState.get(this.policyType).get("InsuranceScore");
        let curOptionalCoverages = listOfCoveragesUnderState.get(this.policyType).get("OptionalCoverages");
        let curDiscount = listOfCoveragesUnderState.get(this.policyType).get("Discount");
        let setCoverageNameObj = { curCoverageNames, curDeductibleNames, curInsuranceScore, curOptionalCoverages, curDiscount }
        this.quotedataService.setCoverageNameObj(setCoverageNameObj)
        let temp = {
          input: 'Next', pageID: this.quoteObj.pageID, userInput: {
            "capacity": this.capacityParameters["capacity"],
            "eligibility": this.capacityParameters["eligibility"]
          }
        }
        this.dataTransferService.sendData(temp)
        // console.log("list of deductible names based on the policy type: " + curDeductibleNames);
        // console.log("list of coverage names based on the policy type: " + curCoverageNames);
      } else {
        console.log("No coverage names based on the current policy type.")
        this.notifyToast.error('Please Contact System Administrator.');
      }
    } else {
      console.log("No coverage names in this state: " + this.state);
      this.notifyToast.error('Please Contact System Administrator.');
    }
  }

  getProductMapping() {
    const requestData = {
      accountCode: this.tenant,
      relationToProperty: this.getFormValue('relation-to-property', 'relationToProperty'),
      homeStructureType: this.getFormValue('structure-type', 'checkHouse'),
      occupancyType: this.getFormValue('relation-to-property', 'relationToProperty') === 'I\'m a renter' ? "Renter" : this.getFormValue('home-usage', 'checkRental'),
      county: this.county,
      newConstruction: this.getFormValue('property-location', 'newConstruction') ? "Yes" : "No",
      priorInsurance: (this.getFormValue('prior-insurance', 'priorInsurance') == "true") ? "Yes" : "No",
      coverageALimit: this.coverageObj.coverageAValue === "" ? null : this.coverageObj.coverageAValue,
      coverageCLimit: this.coverageObj.coverageCValue === "" ? null : this.coverageObj.coverageCValue,
      openingProtection: this.getFormValue('opening-protection', 'openingProtection'),
      priorLossCount: this.getFormValue('prior-loss', 'priorLoss') === false ? null : this.getFormValue('number-of-claims', 'claims'),
      roofMaterial: this.quotedataService.getMappingInfoObj().roofMaterialConvertValueForProductMapping[this.getFormValue('roof-material', 'roofMaterial')],
      roofAge: this.getFormValue('roof-age', 'roofYear') === null ? null : (new Date()).getFullYear() - Number(this.getFormValue('roof-age', 'roofYear')),
      squareFootage: this.getFormValue('house-built', 'squareFeet') === null ? null : Number(this.getFormValue('house-built', 'squareFeet')),

      partiallyRented: JSON.parse(this.getFormValue('Seasonal-Residence', 'seasonalRent')) ? "Yes" : "No",
      exteriorInsuredByAssociation: this.getFormValue('exterior-of-home', 'exteriorOfHome') === null ? null : this.getFormValue('exterior-of-home', 'exteriorOfHome'),

      rentalTerm: this.getFormValue('rental-term', 'rentalTerm')
      //distanceToCoast: distanceToCoast,
    };

    // hack until API issues have been fixed
    if (requestData.occupancyType === "Renter") {
      requestData.roofMaterial = null;
      requestData.squareFootage = null;
    }

    if (requestData.homeStructureType === "Condo" || requestData.homeStructureType === "Apartment") {
      requestData.roofMaterial = null;
      requestData.roofAge = null;
    }
    // let requestData = this.quotedataService.getProductMapping();
    requestData["coverageALimit"] = this.coverageObj.coverageAValue === "" ? null : this.coverageObj.coverageAValue;
    requestData["coverageCLimit"] = this.coverageObj.coverageCValue === "" ? null : this.coverageObj.coverageCValue;
    if (requestData.relationToProperty === "Renter") {
      requestData.roofMaterial = null;
      requestData.squareFootage = null;
    }

    //const requestData = {
    // accountCode: this.tenant,
    // relationToProperty: this.getFormValue('relation-to-property' , 'relationToProperty'),
    // homeStructureType: this.getFormValue('structure-type' , 'checkHouse'),
    // occupancyType: this.getFormValue('relation-to-property' , 'relationToProperty') === 'I\'m a renter' ? "Renter" : this.getFormValue('home-usage' , 'checkRental'),

    // county: this.county,
    // newConstruction:  this.getFormValue('property-location', 'newConstruction') === true ? "Yes" : "No",
    // priorInsurance: this.getFormValue('prior-insurance' , 'priorInsurance') === true ? "Yes" : "No",

    // coverageALimit: this.coverageObj.coverageAValue === "" ? null : this.coverageObj.coverageAValue,
    // coverageCLimit: this.coverageObj.coverageCValue === "" ? null : this.coverageObj.coverageCValue ,
    // openingProtection: this.getFormValue('opening-protection' , 'openingProtection'),
    // priorLossCount: this.getFormValue('prior-loss', 'priorLoss') === false ? null : this.getFormValue('number-of-claims', 'claims'),
    // roofMaterial: this.getFormValue('prior-insurance', 'roofMaterialForProduct'),
    // roofAge: this.getFormValue('roof-age' , 'roofYear') === null ? null : (new Date()).getFullYear() - Number(this.getFormValue('roof-age' , 'roofYear')),
    // squareFootage:  this.getFormValue('house-built' , 'squareFeet') === null ? null : Number(this.getFormValue('house-built' , 'squareFeet')),

    // // partiallyRented: partiallyRented,
    // //distanceToCoast: distanceToCoast,
    // };



    console.log("getProductMapping API request: " + JSON.stringify(requestData))
    this.httpService
      .post('getProductMapping', requestData, true, true)
      .subscribe(
        (resp) => {
          if (_.isEmpty(resp) || resp.out === undefined || resp.out.Product === undefined) {
            this.capacityParameters["eligibility"] = 'false';
            this.temp = {
              input: 'Next',
              pageID: this.quoteObj.pageID,
              userInput: {
                capacity: 'false',
                eligibility: this.capacityParameters['eligibility'],
              },
            };
            if (this.temp) this.dataTransferService.sendData(this.temp);
          }
          else {
            this.capacityParameters["eligibility"] = 'true';
            this.policyTypeList = resp.out.Product;
            let quoteStartDate = new Date(this.quotedataService.getConfiguration().conversation['policy-start-date']['startDate']);
            const getCapacityStatusInput = {
              accountCode: this.tenant,
              eligibleProducts: this.policyTypeList,
              zipCode: this.quotedataService.getPropertyAddress().zipCode,
              censusBlockGroup: this.quotedataService.getPropertyAddress().cbg,
              effectiveDate: this.datePipe.transform(
                quoteStartDate,
                'MM/dd/yyyy'
              ),
            };
            this.httpService
              .post('getCapacityStatus', getCapacityStatusInput, true, true)
              .subscribe(
                (capacity) => {
                  if (capacity.out === undefined) {
                    this.temp = {
                      input: 'Next',
                      pageID: this.quoteObj.pageID,
                      userInput: {
                        capacity: 'false',
                        eligibility: this.capacityParameters['eligibility'],
                      },
                    };
                    if (this.temp) this.dataTransferService.sendData(this.temp);
                  } else {
                    let capacityStatus = null;
                    let capacityList = {}
                    for (let i = 0; i < capacity.out.CapacityStatus.length; i++) {
                      capacityList[capacity.out.CapacityStatus[i].homeFormType] = capacity.out.CapacityStatus[i].capacityStatus;
                    }
                    let i = 0;
                    for (i = 0; i < this.policyTypeList.length; i++) {
                      if (capacityList[this.policyTypeList[i]] != "Closed") {
                        this.policyType = this.policyTypeList[i].replace('-', ' ');
                        capacityStatus = capacityList[this.policyTypeList[i]];
                        const globalPolicyObj = {
                          policyType: this.policyType,
                          capacityStatus: capacityStatus,
                        };
                        this.quotedataService.setPolicyObjData(globalPolicyObj);
                        this.capacityParameters["capacity"] = 'true';
                        this.capacityParameters["policyType"] = this.policyType;
                        break;
                      }
                    }
                    if (i == this.policyTypeList.length) {
                      this.temp = {
                        input: 'Next',
                        pageID: this.quoteObj.pageID,
                        userInput: {
                          capacity: "false",
                          eligibility: this.capacityParameters["eligibility"]
                        }
                      };
                      if (this.temp) this.dataTransferService.sendData(this.temp);
                    };
                    this.insuranceTypeValue = (this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote == undefined || this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote == null) ?  this.policyType: this.quotedataService.getMappingInfoObj().insuranceTypeForRateAndSubmitQuote[this.policyType]
                  }
                },
                (error) => {
                  this.notifyToast.error(
                    'Please Contact System Administrator.'
                  );
                }
              );
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  convertCounty() {
    if (this.county != null && this.county != undefined) {
      this.county = this.county.replace(' County', '')
    }
  }
  submitForm(userSelection) {
    let temp;
    if (userSelection == 'submit') this.calculatePremium()
    else if (userSelection == 'back') {
      temp = { input: 'Back', pageID: this.quoteObj.pageID, userInput: {} }
      this.dataTransferService.sendData(temp)
    }
  }

  navigateToLink(page) {
    this.router.navigate([`${this.tenant}/${page}`]);
  }

  calculatePremium() {
    this.loading = true;
    this.fakeLoader()
    this.submitRateQuote(this.rateAQuoteObjectStructure()).subscribe(
      (resp) => {
        if (resp === undefined) {
          console.log('No response returned');
          this.notifyToast.error('Please Contact System Administrator.');
        } else {
          this.isSubmitted = true;
          this.basicResponse = resp;
          this.applyResponseToCoverageValues(this.basicResponse);
          this.estimatedPremium = this.basicResponse.rate.estimatedPremium
          this.quotedataService.setEstimatedPremium(this.estimatedPremium);
          this.estimatedPremiumTax = this.basicResponse.rate.taxes;
          this.quotedataService.setPremiumTax(this.estimatedPremiumTax);
          this.getListOfCoverageNamesMapping()
          console.log("rate quote response: " + JSON.stringify(resp))
          // this.navigateToLink('get-quote/display-premium')
          // this.loading = false; 
        }
      });
  }

  submitRateQuote(data): Observable<any> {
    return this.httpService.post('rateQuote', data, false, true).pipe(
      map((resp) => {
        if (resp.out === undefined) {
          // if(this.stepper.selectedIndex === 10){
          if (this.page == 'quote-summary') {
            this.loading = false
            this.utilsService.confirmModalPopup(`${resp.root.Envelope.Body.Fault.faultstring}`)
            // this.stepper.previous()
          } else {
            this.notifyToast.error(
              'Please Contact System Administrator.' +
              '\n' +
              resp.root.Envelope.Body.Fault.faultstring
            );
          }
        } else {
          return resp.out;
        }
      })
    );
  }
  getOccupancyStatus() {
    return (this.getFormValue('home-usage', 'checkRental') && (this.quotedataService.getMappingInfoObj().propertyOccupancyStatusMapping[this.getFormValue('home-usage', 'checkRental')])) ? this.quotedataService.getMappingInfoObj().propertyOccupancyStatusMapping[this.getFormValue('home-usage', 'checkRental')] : this.quotedataService.getMappingInfoObj().propertyOccupancyStatusMapping[this.getFormValue('relation-to-property', 'relationToProperty')]
  }
  getPropertyUsage() {
    const propertyUsageMapping = this.quotedataService.getMappingInfoObj().propertyUsageMapping;
    const relationToProperty = this.getFormValue('relation-to-property', 'relationToProperty');
    const homeUsage = this.getFormValue('home-usage', 'checkRental');
    return ((propertyUsageMapping[relationToProperty]) || (propertyUsageMapping[homeUsage]))
  }
  applyResponseToCoverageValues(response) {
    if (response != null) {
      const coverageFormObj = {
        coverageAValue: response.coverageAValue !== null ? formatCurrency(response.coverageAValue, "en_US", "$", "USD") : null,
        coverageBValue: response.coverageBValue,
        coverageCValue: this.iscoverageCValue ? response.recommendedPercentageOfCoverageCtoCoverageA : formatCurrency(response.coverageCValue, "en_US", "$", "USD"),
        coverageDValue: response.coverageDValue,
        coverageEValue: response.coverageEValue,
        coverageEPremisesLiabValue: response.coverageEPremisesLiab,
        coverageFValue: response.coverageFValue,
        aopDeductible: response.aopDeductible,
        deductibleAppliedForHurricaneCoverage: response.deductibleAppliedForHurricaneCoverage,
        waterDeductible: response.waterDeductible,
        coverageLValue: response.coverageLValue,
        coverageMValue: response.coverageMValue,
      }
      this.quotedataService.setCoverageObj(coverageFormObj)
    }
  }
  determineRoofMaterial(form) {
    const roofMaterialwithoutConsideringForm = this.getFormValue('roof-material', 'roofMaterial');
    const roofMaterialsMapping = this.quotedataService.getMappingInfoObj().roofMaterialsMapping;
    const selectedRoofMaterial_key = Object.keys(roofMaterialsMapping).filter(el => roofMaterialsMapping[el]['value'] == roofMaterialwithoutConsideringForm);
    const roofMaterial = roofMaterialsMapping[selectedRoofMaterial_key[0]][form] || roofMaterialsMapping[selectedRoofMaterial_key[0]]["value"];
    this.quoteDataShare.setRoofMaterial(roofMaterial)
    return roofMaterial;
  }

  rateAQuoteObjectStructure() {
    let policyForm = this.quotedataService.getPolicyObjData().policyType;
    console.log("policy type: " + policyForm)
    const data = {
      accountCode: this.tenant,
      phoneNumber: {
        number: null,
        type: null,
      },
      // insuranceType: "SFI Florida DF3 CO", 
      insuranceType: this.insuranceTypeValue,
        // this.quotedataService.getInsuranceTypePrefix() +
        // ' ' +
        // this.currentSelectedAddress['state']['long_name'] +
        // ' ' +
        // policyForm,
      policyEffectiveDate: new Date(this.getFormValue('policy-start-date', 'startDate')).toLocaleDateString('en-US'),
      propertyAddress: this.getPropAddress(),
      mailingAddress: this.getPropAddress(),
      propertyUsage: this.getPropertyUsage(),
      propertyOccupancyStatus: this.getOccupancyStatus(),
      propertyYearBuilt: this.getFormValue('house-built', 'yearBuilt'),
      propertySquareFootage: this.getFormValue('house-built', 'squareFeet'),
      propertyNumberOfStories: (this.getFormValue('number-of-stories', 'story')) ? this.getFormValue('number-of-stories', 'story') : '1',
      propertyConstructionType: this.getFormValue('structure-material', 'constructionType'),
      propertyStructureType: this.structureType,
      propertyRoofType: this.getFormValue('roof-shape', 'roofType'),
      propertyRoofYearLastRenovated: this.getFormValue('roof-age', 'roofYear'),
      propertyRoofMaterial: this.determineRoofMaterial(policyForm),
      openingProtectionLevel: this.getFormValue('opening-protection', 'openingProtection') ? this.getFormValue('opening-protection', 'openingProtection') : "None",
      hasPriorInsurance: this.getFormValue('prior-insurance', 'priorInsurance'),
      coverageAValue: this.coverageObj.coverageAValue,
      coverageBValue: this.coverageObj.coverageBValue,
      coverageCValue: this.coverageObj.coverageCValue,
      RecommendedPercentageOfCoverageCtoCoverageA: this.coverageObj.coverageCValue,
      coverageDValue: this.coverageObj.coverageDValue,
      coverageEValue: this.coverageObj.coverageEValue,
      coverageEPremisesLiabValue: this.coverageObj.coverageEPremisesLiabValue,
      coverageFValue: this.coverageObj.coverageFValue,
      aopDeductible: this.coverageObj.aopDeductible,
      deductibleAppliedForHurricaneCoverage: this.coverageObj.deductibleAppliedForHurricaneCoverage,
      waterDeductible: this.coverageObj.waterDeductible,
      agentID: (this.selectedAgentDetails == undefined || this.selectedAgentDetails == null) ? this.defaultAgentID : this.selectedAgentDetails.agentID,
      capacityStatus: this.quotedataService.getPolicyObjData()["capacityStatus"],
    };
    this.quotedataService.setQuoteObjectStructure(data)
    console.log("rate quote request: " + JSON.stringify(data))
    return data;
  }

  getPropAddress() {
    const tempData = {
      street:
        (this.currentSelectedAddress['street_number'] &&
          this.currentSelectedAddress['street_number']['long_name']) +
        ' ' +
        (this.currentSelectedAddress['route'] && this.currentSelectedAddress['route']['long_name']) +
        ' ' +
        this.getFormValue('property-location', 'unitNumber'),
      city: this.currentSelectedAddress['city']['long_name'],
      stateCode: this.currentSelectedAddress['state']['short_name'],
      zip:
        this.currentSelectedAddress['postal_code'] &&
        this.currentSelectedAddress['postal_code']['long_name'],
      latitude: this.quotedataService.getRiskAddressObj()["lat"],
      longitude: this.quotedataService.getRiskAddressObj()["lng"]
    };
    return tempData;
  }

  getFormValue(formName, value) {
    if (this.quoteObj.conversation[formName]) {
      return this.quoteObj.conversation[formName][value]
    } else {
      return null
    }
  }

  redirectPageOnClick(navigateTo) {
    let temp = {
      "input": "navigateToPage",
      "pageID": navigateTo,
    }
    this.quoteDataShare.setSummaryPageBtnDisplay(true)
    this.dataTransferService.sendData(temp)
  }


  fakeLoader() {
    this.progressNumber = 0
    this.startTimer()
  }

  startTimer() {
    this.interval = window.setInterval(() => {
      if (this.progressNumber < 98) this.progressNumber++
      else if (!this.loading) this.stopTimer(100)
      else this.stopTimer(100)
    }, 300)
  }

  stopTimer(time) {
    window.clearInterval(this.interval)
    window.clearTimeout(this.interval)
  }


}
