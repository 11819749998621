<form [formGroup]="additionalHouseDetailsForm" *ngIf="quoteObj?.pageID == 'structure-material'">
    <div class="form-group row">
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center">
                    <label for="constructionTypeMapping.masonry.value">
                        <input type="radio" value="{{constructionTypeMapping.masonry.value}}"
                            formControlName="constructionType" id="constructionTypeMapping.masonry.value"
                            class="radio-col-light-blue" />
                            <div [innerHTML]="constructionTypeMessages.masonry | safeHtml"></div>
                        </label>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center">
                    <label for="constructionTypeMapping.frame.value">
                        <input type="radio" value="{{constructionTypeMapping.frame.value}}"
                            formControlName="constructionType" id="constructionTypeMapping.frame.value"
                            class="radio-col-light-blue" />
                            <div [innerHTML]="constructionTypeMessages.frame | safeHtml"></div>
                        </label>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-4 p-b-20">
            <div class="w-100 d-flex justify-content-center">
                <mat-card class="card-selection text-center">
                    <label for="constructionTypeMapping.mixed.value">
                        <input type="radio" value="{{constructionTypeMapping.mixed.value}}"
                            formControlName="constructionType" id="constructionTypeMapping.mixed.value"
                            class="radio-col-light-blue" />
                            <div [innerHTML]="constructionTypeMessages.mixed | safeHtml"></div>
                        </label>
                </mat-card>
            </div>
        </div>
    </div>
    <app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>
    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
    <button class="btn btn-info custom-button-info m-r-10 m-b-20"
        *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
        [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    <button class="btn btn-info custom-button-info m-l-10 m-b-20"
        type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
        [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
</div>

</form>