import { Inject, Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private modalService: BsModalService,
    private titleService: Title,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) { }

  getPolicyNumber(){
    if(sessionStorage.getItem('policyDetails') ){
      let selectedIndex = this.selectedPolicy()
      let policySummaryArray = JSON.parse(sessionStorage.getItem('policyDetails')).PolicySummary
      return policySummaryArray[selectedIndex].policyDetails.policyNumber
    } else{
      return null
    }
  }

  getPolicyStatus(){
    if(sessionStorage.getItem('policyDetails') ){
      let selectedIndex = this.selectedPolicy()
      let policySummaryArray = JSON.parse(sessionStorage.getItem('policyDetails')).PolicySummary
      return policySummaryArray[selectedIndex].policyDetails.status
    } else{
      return null
    }
  }

  getItemfromSession(key, isLocal?){
    let storage = isLocal ? localStorage : sessionStorage
    if(storage.getItem(key) && storage.getItem(key) != 'undefined'){
      return JSON.parse(storage.getItem(key))
    }else{
      return null
    }
  }

  getTenantFromWindow(){
    if(!window.location.hash.includes('?')){
      return window.location.hash.split('/')[1]
    }else{
       return window.location.hash.split('/')[1].split('?')[0]
    }
    
  }

  confirmModalPopup(errorText){
    const modal = this.modalService.show(ErrorModalComponent, {backdrop: 'static'});
    (<ErrorModalComponent>modal.content).showConfirmationModal('Error', errorText);
  }

  confirmModalPopupWithConfig(title,errorText,buttonContent){
    const modal = this.modalService.show(ErrorModalComponent, {backdrop: 'static'});
    (<ErrorModalComponent>modal.content).showConfirmationModalWithConfigOfButton(title, errorText, buttonContent);
  }

  convertNumberToIS(number){
    if(number){
      // if(number.length == 10) return number.toString().replace(/\B(?=(?=\d*\.)(\d{3})+(?!\d))/g, ",")
      // else return number
      return number.toString().replace(/\B(?=(?=\d)(\d{3})+(?!\d))/g, ",")
    }
    else {
      return null
    }
  }

  formatNumber(number){
    let temp = ('' + number).replace(/\D/g, '')
    let match = temp.match(/^(\d{3})(\d{3})(\d{4})/)
    if(match) return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    return null
  }

  selLangFile(tenant, prevLang){
    let lang = ''
    if(tenant.indexOf('sfi') > -1) lang = prevLang+'-sfi'
    else lang = prevLang
    return lang
  }

  selectedPolicy(){
    let currentPolicy = sessionStorage.getItem('CurPolicySelection')
    let policyDetails = sessionStorage.getItem('policyDetails') ? JSON.parse(sessionStorage.getItem('policyDetails')) : []
    if(policyDetails.PolicySummary && policyDetails.PolicySummary.length > currentPolicy){
      return currentPolicy
    } else{
      return 0
    }
  }

  getTagId(){
    let tenant = this.getTenantFromWindow()
    if(tenant.indexOf('sfi') > -1){
      return 'GTM-W44WH26'
    } else{
      return 'UA-185070269-2'
    }
  }

  setTabTitleAndIcon(browserSettings) {
    if(browserSettings.tabIcon) this._document.getElementById('appFavicon').setAttribute('href', browserSettings.tabIcon);
    let title = browserSettings.tabTitle ? browserSettings.tabTitle : 'Instant Quote';
    this.titleService.setTitle(title);  
  }

  convertPhoneNumber(phoneNum) {
    if (phoneNum != null) {
      return phoneNum.replace(/[-_()' ']/g, '')
    }
    return null;
  }

  getStringValueFromXML(xmlDoc, key: string){
    let domParser = new DOMParser()
    let domDoc = domParser.parseFromString(xmlDoc, 'text/xml')
    if ( domDoc.getElementsByTagName(key).length !=0 ) {
      return domDoc.getElementsByTagName(key)[0].textContent
    } else {
      return null
    }
  }

}
