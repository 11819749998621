<div class="col-md-6 offset-md-3" *ngIf="quoteObj?.pageID == 'contact'">
    <div class="row">
        <div class="col-md-12 text-center p-b-10">
            <h4 class="estimation-text">
                {{'ESTIMATED_MONTHLY_PREMIUM' | translate}}
            </h4>
            <h2 class="premium-monthly-amt" [ngStyle]="styling?.quoterWizard?.quoteMonthlyPremium">
                ${{displayValue?.monthlyPremium}} plus Fees
            </h2>
            <h4 class="premium-yearly-amt" [ngStyle]="styling?.quoterWizard?.quoteAnnualPremium">
                ${{displayValue?.yearlyPremium}}/yr
            </h4>
            <div class="p-b-20">
                <button class="btn btn-info custom-button-info" type="button" (click)="submitForm('back')"
                    [ngStyle]="styling?.button?.buttonNegative">{{'BACKTOEDITCOVERAGE' | translate}}</button>
            </div>
            <!-- <h4 class="estimation-text">
                {{'LETS_GET_IN_TOUCH' | translate}}
            </h4>
            <span [ngStyle]="styling?.quoterWizard?.quoteSubTitle">
                {{'PROFESSIONAL_SEEK_HELP' | translate}}
            </span> -->
            <h4 class="card-title">

                <h2 [ngStyle]="styling?.quoterWizard?.pageTitle">{{quoteObj?.pageMessage}}</h2>
                <h4 [ngStyle]="styling?.quoterWizard?.quoteSubTitle" style="padding-top: 10px;" [innerHTML]="quoteObj?.pageSubTitle"></h4>
            

                <!-- <app-bot-message [message]="quoteObj?.pageMessage" [subTitle] = "quoteObj?.pageSubTitle" [showImageFlag]="showImageFlag"></app-bot-message> -->
            </h4>
        </div>
    </div>
    <form [formGroup]="sendQuoteForm">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field style="width: 100%">
                    <input matInput [ngStyle]="styling?.quoterWizard?.quoteSubField1" [ngStyle]="styling?.quoterWizard?.quoteSubField1" placeholder="First Name *" formControlName="first_name" maxlength="50" />
                </mat-form-field>
                <p class="error-class mat-angular-error-text" *ngIf="!sendQuoteForm.get('first_name').valid && isSubmitted">First Name is required</p>
            </div>
            <div class="col-md-6">
                <mat-form-field style="width: 100%">
                    <input matInput [ngStyle]="styling?.quoterWizard?.quoteSubField1" [ngStyle]="styling?.quoterWizard?.quoteSubField1" placeholder="Last Name *" formControlName="last_name" maxlength="50" />
                </mat-form-field>
                <p class="error-class mat-angular-error-text" *ngIf="!sendQuoteForm.get('last_name').valid && isSubmitted">Last Name is required</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field style="width: 100%">
                    <input matInput phoneDisplay [preValue]="sendQuoteForm.value.phone_no" [ngStyle]="styling?.fieldValueInput" [ngStyle]="styling?.quoterWizard?.quoteSubField1" placeholder="Phone Number" formControlName="phone_no" maxlength="14" />
                </mat-form-field>
                <!-- <p class="error-class mat-angular-error-text" *ngIf="sendQuoteForm.get('phone_no').errors?.required && isSubmitted">Phone Number is required</p> -->
                <p class="error-class mat-angular-error-text" *ngIf="sendQuoteForm.get('phone_no').errors?.pattern && isSubmitted">Invalid Phone Number</p>
            </div>
            <div class="col-md-6">
                <mat-form-field style="width: 100%">
                    <input matInput [ngStyle]="styling?.quoterWizard?.quoteSubField1" [ngStyle]="styling?.quoterWizard?.quoteSubField1" placeholder="Email Address *" formControlName="email" maxlength="50" />
                    <!-- <span matSuffix>
                        <a class="fas fa-question-circle" popoverTitle="Info" [popover]="'EMAIL_NOTIFY_USER' | translate" container="body" triggers="mouseenter:mouseleave"></a>
                    </span> -->
                </mat-form-field>
                <p class="error-class mat-angular-error-text" *ngIf="!sendQuoteForm.get('email').valid && isSubmitted">Email Address is required</p>
                <p [ngStyle]="styling?.quoterWizard?.quoteSubField1" [innerHTML]="quoteObj?.emailMessage"></p>
            </div>
        </div>
        <!-- <div class="row m-t-20">
            <div class="col-md-12 text-center">
                <button class="btn btn-info custom-button-info m-b-20" type="button"
                [ngStyle]="styling?.button?.buttonPositive" (click)="navigateToLink('find-agent')">{{'FIND_AN_AGENT' | translate}}</button>
                <span class="m-r-20 hidden-xs-down"></span>
                <button class="btn btn-info custom-button-info m-b-20" type="button"
                [ngStyle]="styling?.button?.buttonPositive" (click)="sendQuotetoAgent()">{{'SEND_TO_SECURITY_FIRST' | translate}}</button>
            </div>
        </div> -->
        <!-- findAgent -->
        <div class="row" style="margin: 20px 0; justify-content: center;">
            <button class="btn btn-info custom-button-info col-lg-3" type="button" *ngIf="quoteObj?.buttonOptions?.includes('findAgent')" (click)="submitForm('findAgent')"
                [ngStyle]="styling?.button?.buttonPositive">{{'FIND_AN_AGENT' | translate}}</button>
            <button class="btn btn-info custom-button-info col-lg-3" type="button"
                *ngIf="quoteObj?.buttonOptions?.includes('Purchase')" (click)="submitForm('purchase')"
                [ngStyle]="styling?.button?.buttonPositive">Purchase</button>
            <span class="m-r-20 m-b-20"></span>
            <button class="btn btn-info custom-button-info col-lg-4" type="button" (click)="sendQuoteTo('agent')"
                [ngStyle]="styling?.quoterWizard?.button?.buttonCompany">{{agentBtn}}</button>
        </div>
    

        <div class="card m-t-20">
            <div class="card-body text-center">
                <h2 [ngStyle]="styling?.quoterWizard?.pageTitle">
                    {{'STILL_SHOPPING' | translate}}
                </h2>
                <span [ngStyle]="styling?.quoterWizard?.quoteSubTitle">{{'SEND_EMAIL_YOURSELF_TEXT' | translate}}</span>
                <div class="row m-t-10">
                    <div class="col-lg-12">
                        <p class="checkbox checkbox-primary p-t-10">
                            <input id="checkbox-signup" type="checkbox" formControlName="dontContact">
                            <label for="checkbox-signup" [ngStyle]="styling?.fieldValueCheckBox"> {{'DONT_CONTACT_TEXT' | translate}} </label>
                        </p>
                    </div>
                </div>

                <div class="row" style="margin: 20px 0; justify-content: center;">
                    <button class="btn btn-info custom-button-info col-lg-3" type="button" (click)="sendQuoteTo('email')"
                        [ngStyle]="styling?.button?.buttonPositive">{{'EMAIL_MYSELF' | translate}}</button>
                    <span class="m-r-20 m-b-20"></span>
                    <button class="btn btn-info custom-button-info col-lg-4" type="button" (click)="sendQuoteTo('printQuote')"
                        [ngStyle]="styling?.button?.buttonPositive">{{'PRINT_QUOTE' | translate}}</button>
                </div>
                <!-- <div class="row m-t-20">
                    <div class="col-md-12 text-center">
                        <button class="btn btn-info custom-button-info m-r-20" type="button"
                        [ngStyle]="styling?.button?.buttonPositive" (click)="emailQuote()">{{'EMAIL_MYSELF' | translate}}</button>
                        <button class="btn btn-info custom-button-info" type="button"
                        [ngStyle]="styling?.button?.buttonPositive">{{'PRINT_QUOTE' | translate}}</button>
                    </div>
                </div> -->
            </div>
        </div>
    </form>

</div>