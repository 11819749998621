import { Output } from '@angular/core';
// import { animate, query, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import * as _ from "lodash"
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { HttpService } from '../../shared/services/base.service';
import { CommonApiService } from '../../shared/services/common-api.service';
import { UtilsService } from '../../shared/services/utils.service';
import { LatestGetQuoteDemoService } from './latest-get-quote-demo.service';
import { PlatformLocation } from '@angular/common'

@Component({
  selector: 'app-latest-get-quote-demo',
  templateUrl: './latest-get-quote-demo.component.html',
  styleUrls: ['./latest-get-quote-demo.component.scss'],
  providers: [CommonApiService, LatestGetQuoteDemoService],
})
export class LatestGetQuoteDemoComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  responseFromApi: any;
  botMessage = '';
  subTitle: any;
  guID = '';
  tenant = '';
  pageID: String;
  quoteUserDetails = {
    quoteUsername: 'Buddy',
    quoteEmail: ''
  }
  showMessageContainer = true;
  showImageFlag = true;
  address: any;
  pagesListNavigated = []
  state = ''
  currentSelectedAddress: any;
  unitNumber: any;
  houseDetailsMap = {
    checkRental: '',
    checkHouse: '',
    usageResidence: ''
  };
  map = new Map();
  mapForCoverageNames = new Map();
  mapInfo: any;
  policyTypeSelections: String;
  policyType = 'HO3';
  policyTypeArray: any;
  msgHouseBuiltForm: any;
  prefillPropertyInfo: any;
  subscription: Subscription;
  addressCleansed: any;
  @HostListener('window: popstate', ['$event'])
  onPopState(event) {
    window.location.reload()
  }

  constructor(
    private httpService: CommonApiService,
    private router: Router,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private notifyToast: NotifytoastService,
    private getQuoteService: LatestGetQuoteDemoService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private location: PlatformLocation
  ) {
    this.router.events.subscribe(event => {
      let pagesList = ['quote-submission', 'quote-self-submission', 'quote-carrier-submission', 'quote-agent-submission', 'contact', 'csr-contact',
        'quote-premium', 'find-agent', 'send-quote-agent', 'agent-email-confirmation', 'email-quote', 'email-quote-confirmation', 'spe-quote-premium',
        'edit-coverages', 'quote-summary', 'Quote-Summary'];
      let ignoreImgPage = ['roof-age'];
      if (event instanceof NavigationEnd) {
        let url = event.url.substring(event.url.lastIndexOf('/') + 1);
        this.showMessageContainer = pagesList.indexOf(url) > -1 ? false : true;
        this.showImageFlag = ignoreImgPage.indexOf(url) > -1 ? false : true;
      }
    })
  }

  ngOnInit(): void {
    this.tenant = this.utilsService.getTenantFromWindow()
    this.getListOfCoverageNamesMapping();
    this.getQuoteMappingInfo();
    this.route.data.subscribe(data => {
      let temp = data.formDetails
      this.botMessage = temp.pageMessage
      this.subTitle = temp.pageSubTitle
      this.guID = temp.guID
      this.pageID = temp.pageID
      this.responseFromApi = temp
      this.quotedataService.setConfiguration(temp)
      this.navigateToLink(`get-quote/${temp.pageID}`)
    })

    this.subscription = this.dataTransferService.recievedObj.subscribe(resp => {
      this.submitForm(resp)
    })

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  navigateToLink(page) {
    this.router.navigate([`${this.tenant}/${page}`]);
  }

  visitedPages(pageObj) {
    if (pageObj.input == "Next") {
      if (this.pagesListNavigated.indexOf(pageObj.pageID) < 0) this.pagesListNavigated.push(pageObj.pageID)
    } else if (pageObj.input == "Back") {
      this.pagesListNavigated = this.pagesListNavigated.filter(ele => ele != pageObj.pageID)
    }
  }

  submitForm(eve) {
    this.visitedPages(eve)

    if (eve.userInput && eve.userInput.firstName) {
      this.quoteUserDetails = {
        quoteUsername: eve.userInput.firstName,
        quoteEmail: ''
      }
    }
    let checkAddress;
    if(this.quotedataService.getAddressObj()){
      checkAddress = Object.keys(this.quotedataService.getAddressObj().currentSelectedAddress).length
    }

    if (eve.pageID == 'property-location' && checkAddress && eve.input == 'Next') {

      this.state = this.quotedataService.getAddressObj().stateCode;
      this.currentSelectedAddress = this.quotedataService.getAddressObj().currentSelectedAddress;
      this.setCoverageNames();
      this.getInsuranceTypePrefix();
      this.getPropertyInfo(eve);
    }
    else this.getQuoteInfo(eve)
  }

  getQuoteInfo(eve) {
    eve.guID = this.guID
    eve.accountCode = this.tenant
    this.httpService.post('getQuoteInfo', eve, true, true).subscribe(resp => {
      if (resp.pageID == 'submit-quote' || resp.pageID == 'quote-summary' || resp.pageID == 'Quote-Summary') this.quotedataService.setVisitedPages(this.pagesListNavigated);
      // if(resp.pageID == 'contact'){
      //   this.quotedataService.setPageTitle({pageMessage: resp.pageMessage, pageSubTitle: resp.pageSubTitle})
      // }

      if (resp.pageID) this.getHouseBuiltDetails(resp, resp.pageID)
      // this.navigateToLink(`get-quote/${resp.pageID}`)
    })
  }

  getListOfCoverageNamesMapping() {
    this.httpService
      .get(`getListOfCoverageNamesMapping?accountCode=${this.tenant}`, null, true)
      .subscribe(
        (resp) => {
          if (resp === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          } else {
            this.mapForCoverageNames = this.getQuoteService.objectToMap(resp);
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  getQuoteMappingInfo() {
    this.httpService
      .get(`getQuoteMappingInfo?accountCode=${this.tenant}`, null, true)
      .subscribe(
        (resp) => {
          if (resp === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          } else {
            this.mapInfo = resp;
            this.setMappingInfo();
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  getInsuranceTypePrefix() {
    this.quotedataService.setInsuranceTypePrefix(this.tenant.split('-')[0].toUpperCase());
  }

  setMappingInfo() {
    if (this.mapInfo != null) {
      this.quotedataService.setMappingInfoObj(this.mapInfo)
    }
  }

  setCoverageNames() {
    let listOfCoveragesUnderState;
    if (this.mapForCoverageNames.has(this.state)) {
      listOfCoveragesUnderState = this.mapForCoverageNames.get(this.state);
      if (listOfCoveragesUnderState.has(this.policyType)) {
        let curCoverageNames = listOfCoveragesUnderState.get(this.policyType).get("Coverages");
        let curDeductibleNames = listOfCoveragesUnderState.get(this.policyType).get("Deductibles");
        let curInsuranceScore = listOfCoveragesUnderState.get(this.policyType).get("InsuranceScore");
        let curOptionalCoverages = listOfCoveragesUnderState.get(this.policyType).get("OptionalCoverages");
        let setCoverageNameObj = { curCoverageNames, curDeductibleNames, curInsuranceScore, curOptionalCoverages }
        this.quotedataService.setCoverageNameObj(setCoverageNameObj)
        // console.log("list of deductible names based on the policy type: " + curDeductibleNames);
        // console.log("list of coverage names based on the policy type: " + curCoverageNames);
      } else {
        // console.log("No coverage names based on the current policy type.")
      }
    } else {
      // console.log("No coverage names in this state: " + this.state);
    }
  }

  // getConstrainedValues() {
  //   const policyType = this.policyType.replace(" ", "-")
  //   this.httpService
  //     .get(`getConstrainedValues?accountCode=${this.tenant}&policyForm=${policyType}&state=${this.state}`, null, true)
  //     .subscribe(
  //       (resp) => {
  //         if (resp.out === undefined) {
  //           this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp?.root?.Envelope?.Body?.Fault?.faultstring);
  //         } else {
  //           this.getQuoteService.assignGetConstrainedValues(resp)
  //         }
  //       },
  //       (error) => {
  //         this.notifyToast.error('Please Contact System Administrator.');
  //       }
  //     );
  // }

  getPropertyInfo(eve) {
    if (eve.userInput.unKnownAddress == 'true') this.getQuoteInfo(eve)
    else {
      const address = this.quotedataService.getRiskAddressObj();
      this.address = address.street + ((eve.userInput.unitNumber) ? " " : "") + eve.userInput.unitNumber + ", " + address.city + ", " + address.stateCode + ", " + address.zipCode;
      const requestRateQuoteData = {
        accountCode: this.tenant,
        propertyAddress: {
          street:
            (this.currentSelectedAddress['street_number'] &&
              this.currentSelectedAddress['street_number']['long_name']) +
            ' ' +
            (this.currentSelectedAddress['route'] && this.currentSelectedAddress['route']['long_name']) +
            ' ' +
            ((eve.userInput.unitNumber) ? eve.userInput.unitNumber : ''),
          city: this.currentSelectedAddress['city'] && this.currentSelectedAddress['city']['long_name'],
          state: this.currentSelectedAddress['state']['short_name'],
          zip:
            this.currentSelectedAddress['postal_code'] &&
            this.currentSelectedAddress['postal_code']['long_name'],
          latitude: this.quotedataService.getRiskAddressObj()["lat"],
          longitude: this.quotedataService.getRiskAddressObj()["lng"]
        }
      };
      this.httpService.post('getPropertyInfo', requestRateQuoteData, true, true).subscribe((resp) => {
        if (resp.out === undefined) {
          this.addressCleansed = "no";
          eve.userInput.addressCleansed = this.addressCleansed;
          eve.userInput.hasResultCode = "no";
          this.getQuoteInfo(eve);
        } else {
          this.addressCleansed = "yes";
          eve.userInput.hasResultCode = (resp.out.geoCode?.resultCode) ? "yes" : "no";
          eve.userInput.addressCleansed = this.addressCleansed;
          this.getQuoteInfo(eve);
          this.msgHouseBuiltForm = true
          this.prefillPropertyInfo = {
            'house-built': {
              squareFeet: this.checkNumber(resp.out['propertySquareFootage']),
              yearBuilt: this.checkNumber(resp.out['propertyYearBuilt']),
            },
            'structure-material': {
              constructionType: resp.out['propertyConstructionType']
            },
            'number-of-stories': {
              story: resp.out['propertyNumberOfStoriesInUnit']
            },
            'opening-protection': {
              openingProtection: resp.out['openingProtectionLevel']
            },
            'roof-material': {
              roofMaterial: resp.out['propertyRoofMaterial']
            },
            'roof-age': {
              roofYear: this.checkNumber(resp.out['propertyRoofYearLastRenovated'])
            },
            'roof-shape': {
              roofType: resp.out['propertyRoofType']
            }
          };
          this.quotedataService.setPropertyAddress(resp.out.geoCode);
        }
      },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
    }
  }

  checkNumber(num) {
    let val = num
    if (num) val = num.split(",").join("")
    let resp = isNaN(val) ? null : Number(val)
    return resp
  }

  getHouseBuiltDetails(responseObj, pageID) {
    let responseFromApi = responseObj;
    this.botMessage = (responseObj.pageMessage?.includes('<FirstName>')) ? responseObj.pageMessage.replace('<FirstName>', this.quotedataService.getInsuredName().firstName) : responseObj.pageMessage;
    this.subTitle = (responseObj?.pageSubTitle?.includes('<propertyaddress>')) ? responseObj.pageSubTitle.replace('<propertyaddress>', this.address) : responseObj.pageSubTitle
    if (this.msgHouseBuiltForm) {
      let temp = Object.keys(this.prefillPropertyInfo)
      if (temp.indexOf(pageID) > -1 && !responseObj.conversation[pageID]) responseFromApi.conversation[pageID] = this.prefillPropertyInfo[pageID]
      // if(!responseObj.conversation[pageID]) responseFromApi = _.merge(responseObj, {conversation: this.prefillPropertyInfo})
    }
    this.quotedataService.setConfiguration(responseFromApi)
    this.navigateToLink(`get-quote/${pageID}`)
  }

}
