import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-relation-to-property',
  templateUrl: './relation-to-property.component.html',
  styleUrls: ['./relation-to-property.component.scss']
})
export class RelationToPropertyComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any
  relationToPropertyForm: any;
  isSmallWindow = window.innerWidth <= 768;
  parentTenant: any;
  optionDetails: any;
  options: any;
  wide: any;

  constructor(
    private formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
  ) {
    let tenant = this.route.parent.parent.snapshot.params.tenant
    this.parentTenant = tenant.split('-')[0].toLowerCase()
  }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration();
    this.optionDetails = this.quotedataService.getAcntConfig()["quote"]["relation_to_property_options"];
    this.options = Object.keys(this.optionDetails);
    this.wide = (this.options.length < 3);
    this.relationToPropertyForm = this.formBuilder.group({
      relationToProperty: [this.quoteObj.defaultReply]
    });
    this.fromAPI()
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  onClick(eve) {
    this.relationToPropertyForm.patchValue({
      relationToProperty: eve
    });
  }

  fromAPI() {
    if (this.quoteObj.conversation && this.quoteObj.conversation['relation-to-property'])
      this.relationToPropertyForm.patchValue(this.quoteObj.conversation['relation-to-property'])
  }

  submitForm(userSelection) {
    let temp;
    if (this.relationToPropertyForm.valid && userSelection == 'next') {
      temp = { input: 'Next', pageID: 'relation-to-property', userInput: this.relationToPropertyForm.value }
    } else if (userSelection == 'back') {
      temp = { input: 'Back', pageID: 'relation-to-property' }
    }
    if (temp) {
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve) {
    if (this.relationToPropertyForm.valid) {
      let temp = eve
      temp.userInput = this.relationToPropertyForm.value
      temp.currentPageID = 'relation-to-property'
      this.dataTransferService.sendData(temp)
    }
  }

}