import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import * as moment from 'moment';
import { debounceTime, map } from 'rxjs/operators';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { NotifytoastService } from 'src/app/shared/services/notificationToast.service';
// import { Request} from "node_modules/@types/request"
import { Observable, of } from 'rxjs';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit {
  // @ViewChild('googleAddressInput') googleAddressInput: ElementRef
  paymentForm: FormGroup;
  paymentFormBankAccount: FormGroup;
  paymentFormCreditCard: FormGroup;
  styling = this.utilsService.getItemfromSession('themeObject');
  quoteObj: any;
  pageID: any;
  isSmallWindow = window.innerWidth <= 768;
  next: any;
  responseStatus: any;
  paymentAmountMonthly: any;
  paymentAmountAnnual: any;
  currentSelectedAddress = {};
  options: any;

  loading = false;
  progressNumber = 0
  interval: any;

  isSubmitted = false;
  cardType = '';
  allowedCardTypes: any;
  private subject: Subject<string> = new Subject();
  propertyAddress: any;
  paymentGatewayToken: any;
  paymentGatewayURL: any;
  paymentGatewayAction: any;
  paymentGatewayReturnurl: any;
  requestURL: any;

  policyNumber: any;
  tenant: any;
  policyNumberAfterPay: any;
  annualPaymentData: any;
  monthlyPaymentData: any;
  paymentOption: any;
  documentPreference: any;
  accountType: any;
  billingId: any;
  helperText: any;
  termsAndConditionURL: any;
  creditCardText: any;

  constructor(
    private http: CommonApiService,
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute,
    private notifyToast: NotifytoastService,
    private loader: SpinnerVisibilityService,
    private httpTrustCommerce: HttpClient
  ) { }

  ngOnInit(): void {
    this.tenant = this.utilsService.getTenantFromWindow();
    this.route.data.subscribe(resp => {
      // this.setBounds(resp.coOrdinatesObj);
      this.setBounds()

    }, error => {
      this.notifyToast.error('Unable to find state')
    })
    this.quoteObj = this.quotedataService.getConfiguration();
    console.log("configuration: " + JSON.stringify(this.quoteObj))
    this.subject.pipe(debounceTime(800)).subscribe((resp) => {
      this.validateCard(resp);
    });
    this.creditCardText = this.quoteObj.creditCardText?this.quoteObj.creditCardText:"Credit Card";
    this.initForm()
    this.getFromSharedData()
    this.getSupportedPaymentDetails()
    this.helperText = this.quoteObj.helperText;
    this.termsAndConditionURL = this.quoteObj.termsAndConditionURL;
    console.log(JSON.stringify(this.quoteObj))
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }

  initForm() {
    this.pageID = this.quoteObj.pageID
    this.paymentForm = this.formBuilder.group({
      paymentPlan: [this.quoteObj.defaultSelection],
      paymentMethod: [this.quoteObj.defaultPaymentMethod],
      paymentAmount: [],
      amount: [null,],
      name: [null, [Validators.required]],
      agreementCheck: [false, [Validators.requiredTrue]],
      addressCheck: [false],
      cardNumber: [
        ''
      ],
      expirationMonth: [
        ''
      ],
      expirationYear: [
        ''
      ],
      cvv: [''],
      accountType: [null],
      routingNumber: [null],
      accountNumber: [null],
      billingName: [null],
      billingAddress: [null, [Validators.required]],
      city: [null, [Validators.required]],
      zip: [null, [Validators.required]],
      billingAddress2: [null],
      state: [null, [Validators.required, Validators.pattern('^[a-zA-Z]{2}$')]],
    });
    this.paymentForm.value.expiration =
      this.paymentForm.value.expiration_month +
      this.paymentForm.value.expiration_year;
    this.next = this.quoteObj.Next ? this.quoteObj.Next : 'NEXT';
    this.determinePaymentMethod('bankAccount');
  }

  setBounds(resp?) {
    // let regionBounds = {
    //   north: 31.018292,
    //   south: 24.349674,
    //   east: -79.441021,
    //   west: -87.668408
    // }
    this.options = {
      // bounds: (Object.keys(resp).length === 0) ? null : resp,
      types: [],
      componentRestrictions: { country: 'US' },
      strictBounds: true
    }
    // if(resp) this.options.bounds = resp

  }

  determinePaymentPlan(selectedPlan) {
    if (selectedPlan == 'monthlyInstallments') {
      this.paymentOption = this.monthlyPaymentData;
    } else {
      this.paymentOption = this.annualPaymentData;
    }
  }

  determinePaymentMethod(selectedMethod) {
    if (selectedMethod == 'bankAccount') {
      // this.paymentForm.get('cardNumber').clearValidators();
      // this.paymentForm.get('cardNumber').updateValueAndValidity();

      // this.paymentForm.get('expirationMonth').clearValidators();
      // this.paymentForm.get('expirationMonth').updateValueAndValidity();

      // this.paymentForm.get('expirationYear').clearValidators();
      // this.paymentForm.get('expirationYear').updateValueAndValidity();

      // this.paymentForm.get('cvv').clearValidators();
      // this.paymentForm.get('cvv').updateValueAndValidity();
      let clearValidArr = ['cardNumber', 'expirationMonth', 'expirationYear', 'cvv']
      this.clearValidationFn(clearValidArr)

      this.paymentForm
        .get('routingNumber')
        .setValidators([Validators.required, Validators.pattern('^[0-9]{9}$')]); //, Validators.minLength(9), Validators.maxLength(9)]);
      this.paymentForm.get('routingNumber').updateValueAndValidity();

      this.paymentForm
        .get('accountNumber')
        .setValidators([Validators.required]);
      this.paymentForm.get('accountNumber').updateValueAndValidity();
    } else if (selectedMethod == 'creditCard') {
      // this.paymentForm.get('routingNumber').clearValidators();
      // this.paymentForm.get('routingNumber').updateValueAndValidity();

      // this.paymentForm.get('accountNumber').clearValidators();
      // this.paymentForm.get('accountNumber').updateValueAndValidity();

      let clearValidArr = ['routingNumber', 'accountNumber']
      this.clearValidationFn(clearValidArr)

      this.paymentForm
        .get('cardNumber')
        .setValidators([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(23),
        ]);
      this.paymentForm.get('cardNumber').updateValueAndValidity();

      this.paymentForm
        .get('expirationMonth')
        .setValidators([
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(2),
          Validators.maxLength(2),
        ]);
      this.paymentForm.get('expirationMonth').updateValueAndValidity();

      this.paymentForm
        .get('expirationYear')
        .setValidators([
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(2),
          Validators.maxLength(2),
        ]);
      this.paymentForm.get('expirationYear').updateValueAndValidity();
    }
  }

  clearValidationFn(formCtrlNames: Array<any>){
    formCtrlNames.forEach(formCtrlName => {
      this.paymentForm.get(`${formCtrlName}`).clearValidators();
      this.paymentForm.get(`${formCtrlName}`).updateValueAndValidity();
    })
  }

  getEffectiveDate() {
    let result = new Date();
    result.setDate(result.getDate() + 1);
    return result.toLocaleDateString('en-US');
  }

  getFromSharedData() {
    const data = {
      accountCode: this.tenant,
      billType: "PolicyBill",
      feeAmount: this.quotedataService.getPolicyFees(),
      feeType: "policyFee",
      policyNum: this.quotedataService.getQuoteID(),
      policyType: "NewBusiness",
      productIdentifier: this.quotedataService.getAcntConfig()["quote"]["productDetails"]["productIdentifier"],
      state: this.quotedataService.getAddressObj().stateCode,
      termStartDate: this.getEffectiveDate(),
      termPeriod: "12",
      termUnit: "Months",
      totalPremium: this.quotedataService.getEstimatedPremium(),
      premiumTax: this.quotedataService.getPremiumTax()
    }
    //this.quotedataService.getAcntConfig()["quote"]["productDetails"]["productIdentifier"] //insuranceType //policyForm
    this.http.post('getPaymentOptions', data, true, true)
      .subscribe(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp?.root?.Envelope?.Body?.Fault?.faultstring);
          } else {
            //determining the monthly and Annual installment amount 
            for (let i = 0; i < resp.out.billPlans.BillPlan.length; i++) {
              if (resp.out.billPlans.BillPlan[i]["numberOfInstallments"] == 1) {          
                this.paymentAmountAnnual = resp.out.billPlans.BillPlan[i].installments.Installment[0].dueAmount;
                this.annualPaymentData = resp.out.billPlans.BillPlan[i];
              } else if (resp.out.billPlans.BillPlan[i]["numberOfInstallments"] == 12) {
                this.paymentAmountMonthly = resp.out.billPlans.BillPlan[i].installments.Installment[0].dueAmount;
                this.monthlyPaymentData = resp.out.billPlans.BillPlan[i];
              }
              this.determinePaymentPlan(this.quoteObj.defaultSelection);
              if (this.paymentAmountAnnual != undefined && this.paymentAmountMonthly != undefined) break;
            }
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );

    this.policyNumber = this.quotedataService.getQuoteID();
    this.allowedCardTypes = this.quoteObj.allowedCardTypes;
    this.documentPreference = this.quoteObj.documentPreference;
    this.accountType = this.quoteObj.accountType;
    //this.paymentForm.patchValue({
    //  billingAddressAPT: this.quotedataService.getUnitNumber()
    //});
    //   if (this.quoteObj.conversation && this.quoteObj.conversation[this.pageID])
    //     this.yesNoForm.patchValue(this.quoteObj.conversation[this.pageID])
  }
  onClick(eve) {
    this.paymentForm.patchValue({
      paymentPlan: eve
    });
  }

  updateCheckBox2(eve) {
    if (!this.paymentForm.value.addressCheck) {
      this.paymentForm.patchValue({
        addressCheck: true,
      });
      const propertyAddress = this.quotedataService.getRiskAddressObj();
      const unitNumber = this.quotedataService.getUnitNumber();
      this.paymentForm.patchValue({
        billingAddress: propertyAddress["street"] + ((unitNumber) ? (" " + unitNumber) : ""),
        city: propertyAddress["city"],
        zip: propertyAddress["zipCode"],
        state: propertyAddress["stateCode"]
      })
      console.log("debug default billing address:", this.paymentForm.value);
      //console.log("billing true " + this.paymentForm.value.addressCheck)
    } else {
      this.paymentForm.patchValue({
        addressCheck: false,
      });
      //console.log("billing false " + this.paymentForm.value.addressCheck)
      this.paymentForm
        .get('billingAddress')
        .setValidators([Validators.required]);
    }
  }

  updateCheckBox1(eve) {
    if (!this.paymentForm.value.agreementCheck) {
      this.paymentForm.patchValue({
        agreementCheck: true,
      });
      this.paymentForm.get('agreementCheck').clearValidators();
      this.paymentForm.get('agreementCheck').updateValueAndValidity();
      console.log("agreement true" + this.paymentForm.value.agreementCheck)
    } else {
      this.paymentForm.patchValue({
        agreementCheck: false,
      });
      //Validators.required,
      this.paymentForm
        .get('agreementCheck')
        .setValidators([Validators.requiredTrue]);
      this.paymentForm.get('agreementCheck').updateValueAndValidity();
      console.log("agreement false" + this.paymentForm.value.agreementCheck)
    }
  }

  autoTab(eve) {
    if (eve.length > 1) {
      if (eve > 12) {
        this.paymentForm.patchValue({
          expirationMonth: `0${eve % 10}`,
        });
      }
      if (this.paymentForm.value.expirationMonth == 0) {
        this.paymentForm.patchValue({ expirationMonth: '01' });
      }
      document.getElementById('yearInput').focus();
      this.checkPastYear();
    }
  }

  checkPastYear() {
    var ex_year = this.paymentForm.value.expirationYear;
    var ex_month = this.paymentForm.value.expirationMonth;
    if (ex_month.length == 2 && ex_year.length == 2) {
      var curYear = (new Date().getFullYear() / 100).toFixed();
      var expiredDate = moment(`${curYear}${ex_year}-${ex_month}-01 00:00:00`)
        .endOf('month')
        .toDate();
      console.log(expiredDate);
      if (new Date() < expiredDate) {
        this.paymentForm.get('expirationYear').setErrors(null);
        // this.paymentForm.get('expiration_year').updateValueAndValidity()
      } else {
        this.paymentForm.get('expirationYear').setErrors({ pastDate: true });
      }
    }
  }

  validateCard(resp) {
    this.paymentForm.patchValue({ cvv: '' });
    let tempValue = resp.split(' ').join('');
    if (tempValue.match(/^4[0-9]{12}(?:[0-9]{6})?/)) this.cardType = 'Visa';
    else if (tempValue.match(/^6(?:011|5[0-9]{2})[0-9]{12}/))
      this.cardType = 'Discover';
    else if (
      tempValue.match(
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))/
      )
    )
      this.cardType = 'MasterCard';
    else if (tempValue.match(/^3[47][0-9]{13}$/))
      this.cardType = 'American Express';
    else if (tempValue.match(/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/))
      this.cardType = 'DinersCC';
    else this.cardType = '';
    this.checkErrors(tempValue);
    this.setCVV();
  }

  setCVV() {
    if (this.cardType == 'American Express') {
      this.paymentForm
        .get('cvv')
        .setValidators([Validators.required, Validators.pattern(/^[0-9]{4}$/)]);
      this.paymentForm.get('cvv').updateValueAndValidity();
    } else if (this.cardType) {
      this.paymentForm
        .get('cvv')
        .setValidators([Validators.required, Validators.pattern(/^[0-9]{3}$/)]);
      this.paymentForm.get('cvv').updateValueAndValidity();
    } else {
      this.paymentForm
        .get('cvv')
        .setValidators([Validators.required, Validators.pattern(/^[0-9]{3}$/)]);
      this.paymentForm.get('cvv').updateValueAndValidity();
    }
  }

  checkErrors(number) {
    console.log("card number pass to " + number)
    console.log("allowed card types: " + this.allowedCardTypes)
    if (number) {
      if (!this.luhnValidator(number)) {
        this.paymentForm.get('cardNumber').setErrors(null);
        this.paymentForm.get('cardNumber').setErrors({ luhnError: true });
      } else if (this.cardType == '') {
        this.paymentForm.get('cardNumber').setErrors(null);
        this.paymentForm.get('cardNumber').setErrors({ cardTypeNull: true });
      } else if (!this.allowedCardTypes.includes(this.cardType)) {
        this.paymentForm.get('cardNumber').setErrors(null);
        this.paymentForm.get('cardNumber').setErrors({ cardType: true });
      } else {
        this.paymentForm.get('cardNumber').setErrors(null);
      }
      this.paymentForm.get('cardNumber').updateValueAndValidity();
    }
  }

  getSupportedPaymentDetails() {
    const currentSelectedAddress = this.quotedataService.getAddressObj() == null ? null : this.quotedataService.getAddressObj().currentSelectedAddress
    if (currentSelectedAddress != null) {
      this.propertyAddress = {
        street:
          (currentSelectedAddress['street_number'] &&
            currentSelectedAddress['street_number']['long_name']) +
          ' ' +
          (currentSelectedAddress['route'] && currentSelectedAddress['route']['long_name']) +
          ' ' +
          this.getFormValue("property-location", "unitNumber"),
        city: currentSelectedAddress['city']['long_name'],
        state: currentSelectedAddress['state']['short_name'],
        zip:
          currentSelectedAddress['postal_code'] &&
          currentSelectedAddress['postal_code']['long_name'],
        latitude: this.quotedataService.getRiskAddressObj()["lat"],
        longitude: this.quotedataService.getRiskAddressObj()["lng"]
      }
    }

  }

  getFormValue(formName, value) {
    if (this.quoteObj.conversation[formName]) {
      return this.quoteObj.conversation[formName][value]
    } else {
      return null
    }
  }

  luhnValidator(cardNo) {
    let cardNumber = cardNo.split(' ').join('');
    return (
      /^\d+$/.test(cardNumber) &&
      cardNumber
        .split('')
        .reverse()
        .reduce((sum, d, n) => {
          return +sum + (n % 2 ? [0, 2, 4, 6, 8, 1, 3, 5, 7, 9][+d] : +d);
        }, 0) %
      10 ==
      0
    );
  }

  cardNumberInput(value) {
    this.subject.next(value);
  }

  submitForm(userSelection) {
    // this.loading = true;
    // this.fakeLoader();
    // this.isSubmitted = true;
    // let responseStatus = {};
    // responseStatus["status"] = ""
    // Comment out the API calls related to trust commerce until the account is created from the user end
    // this.getPaymentGatewayToken().subscribe(
    //   (respToken) => {
    //     if (respToken === undefined) {
    //       // TODO: navigate to KO page
    //       console.log('No response returned from payment gateway token');
    //       this.navigateToNextPage(userSelection, responseStatus);
    //     } else {
    //       console.log('get payment gateway token response' + JSON.stringify(respToken));
    //       this.paymentGatewayToken = respToken;

    //       this.getPaymentData().subscribe(
    //         (resp) => {
    //           if (resp === undefined) {
    //             //TO: navigate to KO page
    //             console.log('No payment data from DB');
    //             this.navigateToNextPage(userSelection, responseStatus);
    //           } else {
    //             console.log("get payment data from DB: " + resp)
    //             //https://vault.trustcommerce.com/trusteeapi/payment.php?token=ZTExYTE3OWZiNjdlM2Q3Mjg5M2Y1ZWM1MTcxZWQ5ZmY=&action=store&returnurl=xml&demo=y&cc=4111111111111111&exp=0423&cvv=123"
    //             if (this.paymentForm.valid) {
    //               this.requestURL = this.paymentGatewayURL + '?' + 'token=' + this.paymentGatewayToken + '&action=' + this.paymentGatewayAction + '&returnurl=' + this.paymentGatewayReturnurl
    //               console.log("first part of request URL: " + this.requestURL)
    //               if (this.paymentForm.value.paymentMethod === 'bankAccount') {
    //                 this.requestURL = this.requestURL + this.bankAccountPaymentData();
    //                 console.log("second part of request URL: " + this.requestURL)
    //               } else {
    //                 this.requestURL = this.requestURL + this.creditCardPaymentData();
    //                 console.log("second part of request URL: " + this.requestURL)
    //               }
    //               this.makePaymentGateWay().then(
    //                 (response) => {
    //                   if (response === undefined) {
    //                     //TO: navigate to KO page
    //                     console.log('No response returned');
    //                     this.navigateToNextPage(userSelection, responseStatus);
    //                   } else {
    //                     this.completePaymentGateway().subscribe(
    //                       (resp) => {
    //                         if (resp === undefined) {
    //                           //TO: navigate to KO page
    //                           console.log('No payment data from DB');
    //                           this.navigateToNextPage(userSelection, responseStatus);
    //                         } else {
    //                           console.log("complete payment gateway: " + JSON.stringify(resp))
    //                           if (resp.statusCode === 200) {
    //                             let parameters = resp.body.trim().split('\n');
    //                             let parmsInJson = {};
    //                             for (let i = 0; i < parameters.length; i++) {
    //                               let keyValue = parameters[i].split('=');
    //                               parmsInJson[keyValue[0]] = keyValue[1];
    //                             }
    //                             console.log("result from completePaymentGateway: " + JSON.stringify(parmsInJson))
    //                             let accepted_billing_codes = [];
    //                             accepted_billing_codes = this.quotedataService.getAcntConfig()["quote"]["accepted_billing_codes"]
    //                             const additional_features = this.quotedataService.getAdditionalFeatures();
    //                             //additional_features
    //                             responseStatus = (!(additional_features.includes("Billing_Address_Validation")) || ((additional_features.includes("Billing_Address_Validation") && ("avs" in parmsInJson) && (accepted_billing_codes.includes(parmsInJson["avs"])))) || !("avs" in parmsInJson)) ? { status: parmsInJson["status"] } : { status: "billing_validation_error" };
    //                             this.billingId = parmsInJson["billingid"];
    //                             if (responseStatus["status"] == "accepted" || responseStatus["status"] == "approved") {
    //                               this.bindPolicy().subscribe(
    //                                 (resp) => {
    //                                   if (resp != undefined && resp.policyNumber != undefined) {
    //                                     this.policyNumberAfterPay = resp.policyNumber;
    //                                     this.quotedataService.setPolicyNumber(resp.policyNumber);
    //                                     this.navigateToNextPage(userSelection, responseStatus);
    //                                   } else {
    //                                     // TODO: navigate to KO page
    //                                     this.navigateToNextPage(userSelection, { "status": "bind_failed" });
    //                                   }

    //                                 });
    //                             } else {
    //                               //TO: navigate to KO page
    //                               console.log('Billing address validation failed.');
    //                               this.navigateToNextPage(userSelection, responseStatus);
    //                             }
    //                             // if (parmsInJson["status"] == "accepted")
    //                           } else {
    //                             //TO: navigate to KO page
    //                             console.log('Payment gateway failed.');
    //                             this.navigateToNextPage(userSelection, responseStatus);
    //                           }
    //                         }
    //                       });

    //                     console.log('response from UI make payment' + JSON.stringify(response));
    //                   }
    //                 },
    //                 (err) => {
    //                   this.loading = false;
    //                   this.notifyToast.error('Please Contact System Administrator.');
    //                   console.log(err);
    //                 }
    //               );
    //             } else {
    //               this.loading = false;
    //               this.notifyToast.error('Please enter the following information.');
    //             }
    //           }

    //         });

    //     }
    //   },
    //   (err) => {
    //     this.notifyToast.error('Please Contact System Administrator.');
    //     console.log(err);
    //   }
    // );

    // different approach - code cleaning Pending ********
    this.isSubmitted = true;
    if (this.paymentForm.valid) {
      this.loading = true;
      this.fakeLoader();
      this.getPaymentTokenAndURL()
    }
  }

  getPaymentTokenAndURL() {
    this.loader.show();
    let data = {
      policyNumber: this.policyNumber.replace(/\d/g, "X"),
      accountCode: this.tenant
    }
    forkJoin([
      this.http.post(`getPaymentGatewayToken`, data, false, true),
      this.http.post(`paymentURL`, data, false, true)
    ]).subscribe(resp => {
      let token, paymentObj;
      if (resp[0] && resp[0].statusCode == 200) {
        token = resp[0].body
      }
      if (resp[1]) {
        paymentObj = resp[1]
      }
      if (token && paymentObj) this.callPaymentGateway(token, paymentObj)
      else {
        this.notifyToast.error("Please Contact System Administrator")
        this.loader.hide() // loader is written seperately to avoid loader(after getting response) lag between API calls
      }
    }, error => {
      this.loader.hide()
      this.notifyToast.error("Unable to retrieve data. Please contact System Administrator.")
    })
  }

  callPaymentGateway(token, paymentObj: { url: string, action: string, returnurl: string }) {
    // let requestURL = paymentObj.url + '?' + 'token=' + token + '&action=' + paymentObj.action + '&returnurl=' + paymentObj.returnurl
    let requestURL = paymentObj.url
    let payloadData;
    if (this.paymentForm.value.paymentMethod == 'bankAccount') {
      // requestURL = requestURL + this.bankAccountPaymentData();
      payloadData = this.formDataBankAcnt({token, action: paymentObj.action, returnurl: paymentObj.returnurl})
      console.log("second part of request URL: " + requestURL)
    } else {
      // requestURL = requestURL + this.creditCardPaymentData();
      payloadData = this.formDataCreditCard({token, action: paymentObj.action, returnurl: paymentObj.returnurl})
      console.log("second part of request URL: " + requestURL)
    }
    this.http.transactionPost(requestURL, payloadData).subscribe(resp => {
      if (resp != undefined) {
        // let newToken = resp.token ? resp.token : token
        let isActualTrustCommerce = requestURL.indexOf("https://vault.trustcommerce.com/") > -1
        let tokenFromXML = this.utilsService.getStringValueFromXML(resp, 'token')
        // let newToken = isActualTrustCommerce ? token : tokenFromXML ? tokenFromXML: resp.token //use only when resp is returning in JSON
        let newToken = isActualTrustCommerce ? token : tokenFromXML
        this.callCompletePaymentGateway(newToken)
      } else {
        this.loader.hide()
        this.notifyToast.error("Payment Gateway Unsuccessful")
      }
    }, error => {
      console.log("Handle CORS temp fix")
      this.callCompletePaymentGateway(token)
    })
  }

  callCompletePaymentGateway(token){
    this.completePaymentGateway(token).subscribe(resp => {
      if (resp && resp.statusCode == 200 && resp.body) {
        this.afterPaymentStatus(resp)
      } else {
        let respStatus = {status: ''}
        this.navigateToNextPage("next", respStatus);
        this.loader.hide()
        this.notifyToast.error("Payment Unsuccessful")
      }
    }, error => {
      this.loader.hide()
      this.notifyToast.error("Payment Unsuccessful")
    })
  }

  completePaymentGateway(token) {
    const data = {
      accountCode: this.tenant,
      policyNumber: this.policyNumber,
      token
    }
    return this.http.post('completePaymentGateway', data, false, true).pipe(
      map((resp) => {
        if (resp === undefined || resp === null || resp.statusCode != 200) {
          let respStatus = {status: ''}
          this.navigateToNextPage("next", respStatus);
          this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
        } else {
          console.log('return value from getpaymentgatewaytoken' + JSON.stringify(resp));
          return resp;
        }
      })
    );
  }

  afterPaymentStatus(resp){
    console.log("complete payment gateway: " + JSON.stringify(resp))
    let responseStatus = {};
    responseStatus["status"] = ""
    let parameters = resp.body.trim().split('\n');
    let parmsInJson = {};
    for (let i = 0; i < parameters.length; i++) {
      let keyValue = parameters[i].split('=');
      parmsInJson[keyValue[0]] = keyValue[1];
    }
    if (resp.statusCode === 200 && !(parmsInJson['status'] == 'baddata' || parmsInJson['error']) ) {
      console.log("result from completePaymentGateway: " + JSON.stringify(parmsInJson))
      let accepted_billing_codes = [];
      accepted_billing_codes = this.quotedataService.getAcntConfig()["quote"]["accepted_billing_codes"]
      const additional_features = this.quotedataService.getAdditionalFeatures();
      //additional_features
      // responseStatus = (!(additional_features.includes("Billing_Address_Validation")) || ((additional_features.includes("Billing_Address_Validation") && ("avs" in parmsInJson) && (accepted_billing_codes.includes(parmsInJson["avs"])))) || !("avs" in parmsInJson)) ? { status: parmsInJson["status"] } : { status: "billing_validation_error" };


      // let checkBillingAddValdn = additional_features.includes("Billing_Address_Validation")
      // let checkAVS = (parmsInJson["avs"]) && accepted_billing_codes.some(e => e.toLowerCase() == parmsInJson["avs"].toLowerCase())
      // responseStatus = (
      //   !(checkBillingAddValdn) || 
      //   (
      //     checkBillingAddValdn && 
      //     ("avs" in parmsInJson) && 
      //     checkAVS
      //   ) ||  
      //   !("avs" in parmsInJson)
      // ) ? { status: parmsInJson["status"] } : { status: "billing_validation_error" };
      let checkBillingAddValdn = additional_features.includes("Billing_Address_Validation")
      let checkAVS = !(parmsInJson["avs"]) || parmsInJson["avs"] == "null" || (accepted_billing_codes.some(e => e.toLowerCase() == parmsInJson["avs"].toLowerCase()))
      responseStatus = !(checkBillingAddValdn) || checkAVS  ? { status: parmsInJson["status"] } : { status: "billing_validation_error" };

      this.billingId = parmsInJson["billingid"];
      console.log(responseStatus, additional_features,parmsInJson, "print here",
      (checkBillingAddValdn) ,
    ("avs" in parmsInJson),
    (accepted_billing_codes.includes(parmsInJson["avs"])),
    accepted_billing_codes,
    (parmsInJson["avs"])
      )
      if (responseStatus["status"] == "accepted" || responseStatus["status"] == "approved") {
        this.bindPolicy().subscribe(
          (resp) => {
            if (resp != undefined && resp.policyNumber != undefined) {
              this.policyNumberAfterPay = resp.policyNumber;
              this.quotedataService.setPolicyNumber(resp.policyNumber);
              this.navigateToNextPage('next', responseStatus);
            } else {
              // TODO: navigate to KO page
              this.navigateToNextPage('next', { "status": "bind_failed" });
            }

          });
      } else {
        //TO: navigate to KO page
        console.log('Billing address validation failed.');
        this.navigateToNextPage('next', responseStatus);
      }
      // if (parmsInJson["status"] == "accepted")
    } else {
      //TO: navigate to KO page
      console.log('Payment gateway failed.');
      this.navigateToNextPage('next', responseStatus);
    }
  }

  navigateToNextPage(userSelection, userInput) {
    this.loading = true;
    let temp;
    if (this.paymentForm.valid && userSelection == 'next') {
      if (this.paymentForm.value.addressCheck && this.getFormValue("property-location", "address")) {
        this.paymentForm.patchValue({
          billingAddress: this.getFormValue("property-location", "address"),
          billingAddressAPT: this.getFormValue("property-location", "unitNumber")
        })
      }
      temp = { input: 'Next', pageID: this.pageID, userInput: userInput }
      //TO: set policy number after payment
      //temp.userInput.responseStatus =  'success';
      console.log("submit" + JSON.stringify(temp))
    } else if (userSelection == 'back') {
      temp = { input: 'Back', pageID: this.pageID }
    }
    if (temp) {
      this.dataTransferService.sendData(temp)
    }
  }

  //not using handleAddressChange as we aren't using google address input
  handleAddressChange(googleAddress) {
    //console.log(googleAddress)
    if (googleAddress.address_components) {
      for (var i = 0; i < googleAddress.address_components.length; i++) {
        var c = googleAddress.address_components[i];
        if (c.types[0] == 'administrative_area_level_1')
          this.currentSelectedAddress['state'] = c;
        else {
          if (c.types[0].includes('locality') && (!this.currentSelectedAddress['city'])) this.currentSelectedAddress['city'] = c;
          else this.currentSelectedAddress[c.types[0]] = c;
        }
        if (c.types[0] == 'administrative_area_level_2') {
          this.currentSelectedAddress['county'] = c;
        }
      }
      this.currentSelectedAddress['formatted_address'] =
        googleAddress.formatted_address;
      this.paymentForm.patchValue({
        billingAddress: this.currentSelectedAddress["formatted_address"],
        city: this.currentSelectedAddress['city'] && this.currentSelectedAddress['city']['short_name'],
        zip: this.currentSelectedAddress['postal_code']['long_name'],
        state: this.currentSelectedAddress['state']['short_name']
      })
      console.log("DEBUG:", this.paymentForm.value)
      // let addressDetails = {
      //   "city": this.currentSelectedAddress['city']['short_name'],
      //   "street": (this.currentSelectedAddress['street_number'] && this.currentSelectedAddress['street_number']['short_name']) + " " + this.currentSelectedAddress['route']['long_name'],
      //   "zipCode": this.currentSelectedAddress['postal_code']['long_name'],
      //   "stateCode": this.currentSelectedAddress['state']['short_name'],
      //   "lat": googleAddress.geometry.location.lat(),
      //   "lng": googleAddress.geometry.location.lng()
      // }
      // this.quotedataService.setRiskAddressObj(addressDetails)
    } else {
      this.paymentForm.patchValue({ billingAddress: '' });
    }
  }

  // addressTyping() {
  //   this.dropdownSelected = false
  // }

  getPaymentGatewayToken() {
    // this.quotedataService.setEstimatedPremium(estimatedPremium);
    let data = {
      accountCode: this.tenant,
      policyNumber: this.policyNumber
    }
    return this.http.post('getPaymentGatewayToken', data, false, true).pipe(
      map((resp) => {
        if (resp === undefined || resp === null || resp.statusCode != 200) {
          // TODO: navigate to KO page
          let responseStatus = {};
          responseStatus["status"] = ""
          this.navigateToNextPage("next", responseStatus);
          this.notifyToast.error(
            'Please Contact System Administrator.' +
            '\n' +
            resp.root.Envelope.Body.Fault.faultstring
          );

        } else {
          console.log('return value from getpaymentgatewaytoken' + JSON.stringify(resp));
          return resp.body;
        }
      })
    );
  }

  completePaymentGateway1() {
    const data = {
      accountCode: this.tenant,
      policyNumber: this.policyNumber,
      token: this.paymentGatewayToken
    }
    return this.http.post('completePaymentGateway', data, false, true).pipe(
      map((resp) => {
        if (resp === undefined || resp === null || resp.statusCode != 200) {
          // TODO: navigate to KO page
          let responseStatus = {};
          responseStatus["status"] = ""
          this.navigateToNextPage("next", responseStatus);
          this.notifyToast.error(
            'Please Contact System Administrator.' +
            '\n' +
            resp.root.Envelope.Body.Fault.faultstring
          );

        } else {
          console.log('return value from getpaymentgatewaytoken' + JSON.stringify(resp));
          return resp;
        }
      })
    );
  }

  getPaymentData() {
    const data = {
      accountCode: this.tenant,
      referenceId: this.policyNumber
    }
    return this.http.post('paymentURL', data, false, true).pipe(
      map((resp) => {
        if (resp === undefined || resp === null) {
          // TODO: navigate to KO page
          let responseStatus = {};
          responseStatus["status"] = ""
          this.navigateToNextPage("next", responseStatus);
          this.notifyToast.error(
            'Please Contact System Administrator.'
          );

        } else {
          console.log('return value from get payment data' + JSON.stringify(resp));
          this.paymentGatewayURL = resp.url;
          this.paymentGatewayAction = resp.action;
          this.paymentGatewayReturnurl = resp.returnurl;
          console.log(this.paymentGatewayAction + " " + this.paymentGatewayReturnurl + " " + this.paymentGatewayURL)
          return true;
        }
      })
    );
  }
  // bankAccountPaymentData() {
  //   let address: any;
  //   address = "&verify=y" +
  //     "&address1=" + this.paymentForm.value.billingAddress +
  //     "&city=" + this.paymentForm.value.city +
  //     "&state=" + this.paymentForm.value.state +
  //     "&zip=" + this.paymentForm.value.zip;
  //   address = address.replaceAll(" ", "%20");
  //   let requestedURLSecond = '&routing=' + this.paymentForm.value.routingNumber +
  //     '&account=' + this.paymentForm.value.accountNumber +
  //     "&media=ach" + address + '&name=' + this.paymentForm.value.name;

  //   return requestedURLSecond;
  // }

  // creditCardPaymentData() {
  //   let card = this.paymentForm.value.cardNumber.trim().replaceAll(" ", "");
  //   let address: any;
  //   address = "&verify=y" +
  //     "&address1=" + this.paymentForm.value.billingAddress +
  //     "&city=" + this.paymentForm.value.city +
  //     "&state=" + this.paymentForm.value.state +
  //     "&zip=" + this.paymentForm.value.zip;
  //   address = address.replaceAll(" ", "%20");
  //   let requestedURLSecond = '&cc=' + card +
  //     '&exp=' + this.paymentForm.value.expirationMonth + this.paymentForm.value.expirationYear +
  //     '&cvv=' + this.paymentForm.value.cvv + address + '&name=' + this.paymentForm.value.name;
  //   return requestedURLSecond;
  // }

  formDataCreditCard(paymentObj: { token: string, action: string, returnurl: string}){
    const formData = new FormData()
    let shortExpYear = parseInt(this.paymentForm.value.expirationYear) % 100
    let card = this.paymentForm.value.cardNumber.trim().replaceAll(" ", "");
    formData.append('address1', `${this.paymentForm.value.billingAddress}, ${this.paymentForm.value.city}, ${this.paymentForm.value.state}`)
    formData.append('verify', 'y')
    formData.append('demo', environment.demoPayment.substr(environment.demoPayment.length - 1))
    formData.append('name', this.paymentForm.value.name)
    formData.append('zip', this.paymentForm.value.zip)
    formData.append('cc', card)
    formData.append('exp', this.paymentForm.value.expirationMonth + shortExpYear)
    formData.append('cvv', this.paymentForm.value.cvv)
    formData.append('token', paymentObj.token)
    formData.append('action', paymentObj.action)
    formData.append('returnurl', paymentObj.returnurl)
    return formData
  }

  formDataBankAcnt(paymentObj: { token: string, action: string, returnurl: string}){
    const formData = new FormData()
    formData.append('address1', `${this.paymentForm.value.billingAddress}, ${this.paymentForm.value.city}, ${this.paymentForm.value.state}`)
    formData.append('verify', 'y')
    formData.append('demo', environment.demoPayment.substr(environment.demoPayment.length - 1))
    formData.append('name', this.paymentForm.value.name)
    formData.append('zip', this.paymentForm.value.zip)
    formData.append('media', "ach")
    formData.append('routing', this.paymentForm.value.routingNumber)
    formData.append('account', this.paymentForm.value.accountNumber)
    formData.append('token', paymentObj.token)
    formData.append('action', paymentObj.action)
    formData.append('returnurl', paymentObj.returnurl)
    return formData
  }

  makePaymentGateWay() {
    return fetch(this.requestURL, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      //body: JSON.stringify({token: "", b: 2}), 
      //credentials: 'include', 
      headers: {
        'Content-Type': 'application/json',
        //'Accept': 'application/json', 
        //'Access-Control-Request-Method': 'POST', 
        'Access-Control-Allow-Origin': '*',
        //'Origin': 'https://watson-web-client.s3.amazonaws.com', 
        //'Access-Control-Request-Headers': 'content-type,Access-Control-Allow-Origin,Access-Control-Request-Method,Origin' 
      },
    }).then(response => {
      console.log("response:", response);
      return response.status === 200;
    });

    // let parameters = {
    //   url: "https://vault.trustcommerce.com/trusteeapi/payment.php",
    //   action: 'Preauth',
    //   cc: "378282246310005",
    //   avs: 'y',
    //   zip: "95682",
    //   address1: "3372 Covello Cir, Cameron Park, CA 95682",
    //   exp: "02/25",
    //   cvv: "123",
    //   amount: "28.00",

    // };

    // const headers = {
    //   'C ontent-Type': 'text/xml',
    //   token: "OGFkODBkMjYwODQwYmU0MzJiN2Q2MjM4OTE5ODVkNzc=",
    //   custid: "",
    //   password: "",
    //   action: 'store',
    //   cc: "378282246310005",
    //   exp: "0225",
    //   amount: "2800",
    // };

  }

  bindPolicy() {
    let input = {};
    if (this.paymentForm.value.paymentMethod === 'bankAccount') {
      input = {
        "accountCode": this.tenant,
        "creditCard": false,
        "achAccount": true,
        "quoteNumber": this.policyNumber,
        "documentPreference": this.documentPreference,
        "paymentOption": {
          "paymentMethod": this.paymentOption.paymentMethod,
          "billType": this.paymentOption.billType,
          "billPlanIdentifier": this.paymentOption.billPlanIdentifier
        },
        "accountType": this.accountType,
        "last4DigitBankAccount": this.paymentForm.value.accountNumber.substring(this.paymentForm.value.accountNumber.length - 4, this.paymentForm.value.accountNumber),
        "routingNumber": this.paymentForm.value.routingNumber,
        "achAccountAddress": {
          "street": this.paymentForm.value.billingAddress,
          "addressLine2": this.paymentForm.value.billingAddress2,
          "city": this.paymentForm.value.city,
          "stateCode": this.paymentForm.value.state,
          "zip": this.paymentForm.value.zip
        },
        "email": this.quotedataService.getEmail(),
        "phoneNumber": this.quotedataService.getPhoneNumber(),
        "fullName": this.paymentForm.value.name,
        "token": this.billingId
      }
    } else {
      input = {
        "accountCode": this.tenant,
        "creditCard": true,
        "achAccount": false,
        "quoteNumber": this.policyNumber,
        "documentPreference": this.documentPreference,
        "paymentOption": {
          "paymentMethod": this.paymentOption.paymentMethod,
          "billType": this.paymentOption.billType,
          "billPlanIdentifier": this.paymentOption.billPlanIdentifier
        },
        "cardType": this.formattedCardType(),
        "expirationMonth": this.paymentForm.value.expirationMonth,
        "expirationYear": this.paymentForm.value.expirationYear,
        "cvvCode": this.paymentForm.value.cvv,
        "last4DigitCreditCard": this.paymentForm.value.cardNumber.substring(this.paymentForm.value.cardNumber.length - 4, this.paymentForm.value.cardNumber.length),
        "cardHolderAddress": {
          "street": this.paymentForm.value.billingAddress,
          "addressLine2": this.paymentForm.value.billingAddress2,
          "city": this.paymentForm.value.city,
          "stateCode": this.paymentForm.value.state,
          "zip": this.paymentForm.value.zip
        },
        "email": this.quotedataService.getEmail(),
        "phoneNumber": this.quotedataService.getPhoneNumber(),
        "fullName": this.paymentForm.value.name,
        "token": this.billingId
      }
    }
    console.log("input of the request bindPolicy: " + JSON.stringify(input))
    return this.http.post('bindPolicy', input, false, true)
      .pipe(
        map((resp) => {
          if (resp.out === undefined) {
            //TODO: navigate to KO page
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp?.root?.Envelope?.Body?.Fault?.faultstring);
          } else {
            console.log("result from bind policy: " + JSON.stringify(resp))
            return resp.out;
          }
        },
          (error) => {
            //TODO: navigate to KO page
            this.notifyToast.error('Please Contact System Administrator.');
          }
        ));
  }


  formattedCardType(){
    const cardsList = [
      { name: 'Visa', ccType: 'Visa' },
      { name: 'Discover', ccType: 'Discover' },
      { name: 'MasterCard', ccType: 'Mastercard' },
      { name: 'American Express', ccType: 'Amex' },
      { name: 'DinersCC', ccType: 'DinersCC' }
    ]
    let cardObj = cardsList.find(card => card.name.toLowerCase() == this.cardType.toLowerCase());
    return cardObj.ccType;
  }

  fakeLoader() {
    this.progressNumber = 0
    this.startTimer()
  }

  startTimer() {
    this.interval = window.setInterval(() => {
      if (this.progressNumber < 98) this.progressNumber++
      else if (!this.loading) this.stopTimer(100)
      else this.stopTimer(100)
    }, 200)
  }

  stopTimer(time) {
    window.clearInterval(this.interval)
    window.clearTimeout(this.interval)
  }

}
