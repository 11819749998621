import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DataTransferService } from '../services/dataTransfer.service';
import { QuoteDataShare } from '../services/quoteDataShare.service';

export class SummaryData {
  input: string;
  pageID: string;
}

@Component({
  selector: 'app-summary-back-btn',
  templateUrl: './summary-back-btn.component.html',
  styleUrls: ['./summary-back-btn.component.scss']
})

export class SummaryBackBtnComponent implements OnInit {
  @Input() styling: any
  @Output() summaryClickedData: EventEmitter<SummaryData> = new EventEmitter<SummaryData>()
  // @Output() summaryClickedData: EventEmitter<any> = new EventEmitter<any>()
  showSummaryBtn: boolean = false;
  constructor(private dataTransferService: DataTransferService, private quoteDataShare: QuoteDataShare){}

  ngOnInit(){
    this.showSummaryBtn = this.quoteDataShare.getSummaryPageBtnDisplay()
  }

  backToSummaryPage(){
    let navigateTo =
      this.quoteDataShare.getMappingInfoObj()?.quoteSummaryMapping?.pageName;
    let temp = {
      "input": "navigateToPage",
      "pageID": (navigateTo)? navigateTo : 'quote-summary',
    }
    this.summaryClickedData.emit(temp)
    // this.dataTransferService.sendData(temp)
    this.quoteDataShare.setSummaryPageBtnDisplay(false)
  }
}
