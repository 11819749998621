import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: '[autofocus]'
})

export class AutoFocusDirective{
    private focus;
    constructor(private el: ElementRef){}

    ngOnInit(){
        setTimeout(() => {
            if(this.focus || typeof this.focus == 'undefined') this.el.nativeElement.focus()
        }, 500);
    }

    @Input() set autofocus(condition: boolean){
        this.focus = condition != false 
    }
}