<!-- <app-installation-banner [bannerObj]="bannerObj"></app-installation-banner>
<section id="wrapper" class="mod-login-register" [ngStyle]="{'background-image': bgImg}"  *ngIf="isSfi; else otherContainer">
    <div class="header-wrapper">
        <div class="col-12">
            <div class="row">
                <div class="col-2 hidden-xs-down"></div>
                <div class="col-8 text-center">
                    <img (click)="navTo(true)" src="assets/multiTenantImages/png/SFI-Long-Logo.png" class="text-center" height="50px" alt="SFI Logo" style="cursor: pointer;"/>
                </div>
                <div class="col-2 hidden-sm-up"></div>
                <div class="col-2" style="margin: auto;">
                    <a class="float-right contact-us hidden-xs-down" (click)="navTo(false)">Contact Us</a>
                    <div class="btn-group float-right hidden-sm-up" placement="bottom left" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-info dropdown-toggle" aria-controls="dropdown-basic">
                            <i class="fas fa-bars"></i><span class="caret"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="margin-left: -110px;">
                            <li role="menuitem" (click)="navTo(false)"><i class="icon-fas fas fa-phone"></i>Contact us</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="routing-container">
        <router-outlet></router-outlet>
    </div>
    <div class="footer-wrapper">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4 text-center" style="margin: auto;">
                    <div [innerHTML]="footer_content.footer_left_message | safeHtml"></div>
                </div>
                <div class="col-md-4 customer-care-block">
                    <div [innerHTML]="footer_content.footer_center_message | safeHtml"></div>
                </div>
                <div class="col-md-4 privacy-policy-block">
                    <div [innerHTML]="footer_content.footer_right_message | safeHtml"></div>
                    <h6><span class="version-text" style="font-family:Calibri">{{'FOOTER_VERSION' | translate: {date: date, version:version} }}</span></h6> 
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #otherContainer>
    <section id="wrapper" class="mod-login-register" [ngStyle]="{'background-image': bgImg}">
        <div class="login-page-layout hidden-md-down">
            <p class="page-main-title">
               {{pageDetailsObj?.page_configuration?.welcome_message}}
            </p>
        </div>
        <router-outlet></router-outlet>
    </section>
</ng-template> -->

<!-- <app-installation-banner [bannerObj]="bannerObj"></app-installation-banner> -->
<section id="main-quoter-wrapper" class="mod-login-register" [ngStyle]="{'background-image': bgImg}">
    <div class="header-wrapper" [ngStyle]="styling?.headerBackground">
        <div class="col-12">
            <div class="row">
                <div class="col-2 hidden-xs-down"></div>
                <div class="col-8 text-center" style="margin-top: 5px;">
                    <img *ngIf="headerImg" (click)="navigationPageForDefault('header-icon')" [src]="headerImg" class="text-center" height="50px" [alt]="pageName+' Logo'" style="cursor: pointer;"/>
                </div>
                <div class="col-2 hidden-sm-up"></div>
                <div class="col-2" style="margin: auto;">
                    <a class="float-right contact-us hidden-xs-down" (click)="navigationPageForDefault('contact-us')">Contact Us</a>
                    <div class="btn-group float-right hidden-sm-up" placement="bottom left" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-info dropdown-toggle" aria-controls="dropdown-basic">
                            <i class="fas fa-bars"></i><span class="caret"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="margin-left: -110px;">
                            <li role="menuitem" (click)="navigationPageForDefault('contact-us')"><i class="icon-fas fas fa-phone"></i>Contact us</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="routing-container">
        <router-outlet></router-outlet>
    </div>
    <div class="footer-wrapper" [ngStyle]="styling?.footerBackground">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4 text-center quote-footer-icon" style="margin: auto;">
                    <div [innerHTML]="footer_content.footer_left_message | safeHtml"></div>
                </div>
                <div class="col-md-4 customer-care-block quote-footer-contact-services">
                    <div [innerHTML]="footer_content.footer_center_message | safeHtml"></div>
                </div>
                <div class="col-md-4 privacy-policy-block quote-footer-policy-terms">
                    <div [innerHTML]="footer_content.footer_right_message | safeHtml"></div>
                    <h6><span class="version-text">{{'FOOTER_VERSION' | translate: {date: date, version:version} }}</span></h6> 
                </div>
            </div>
        </div>
    </div>
</section>