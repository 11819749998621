import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, CanActivateChild, Router,  } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { DatePipe } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { AuthQuoteService } from './auth-quote.service';

@Injectable()
export class AuthGuardMaintenanceOnlineQuoter implements CanActivate, CanActivateChild {
  maintenanceDetails: any;
  occurenceModal = 0;
  constructor(
    private authService: AuthQuoteService, private router: Router,private utilsService: UtilsService, private datepipe: DatePipe, private modalService: BsModalService, private route: ActivatedRoute,
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const pageName =  this.utilsService.getTenantFromWindow(); 
     if (pageName != undefined) {
      return this.authService.checkMaintenance().pipe(map((resp: any) => {
        this.maintenanceDetails = resp
        return true
      }))
    } else {
      this.maintenanceDetails = {
        hasMaintenanceInfo: false
      };
      return true
    }

  }

  canActivateChild(){
    if(!this.maintenanceDetails && this.maintenanceDetails.hasMaintenanceInfo) {
      this.router.navigate(['/site-not-found'])
      return false
    } else{
        if(this.maintenanceDetails && this.maintenanceDetails.maintenanceControl) {
          this.checkPage()
          // return true
        }
        else{
          // console.log('non-maintenance pages')
          return true
        }
      return true
    }
  }

  checkPage(){
    let tenant =  this.utilsService.getTenantFromWindow(); 
    let currentDate = new Date();
    let timeDifference = Math.abs(this.maintenanceDetails.maintenanceTime.getTime() - currentDate.getTime());
    let timeDifferenceAsMinutes = timeDifference/1000/60;
    if (this.maintenanceDetails.maintenanceTime <= currentDate) {
        // console.log("maintenance")
        this.router.navigate([`${tenant}/maintenance`]).then(() => {
          return false;
        });
    } else if (timeDifferenceAsMinutes <= 60 && this.occurenceModal < 1){
        this.occurenceModal++
        // console.log("reminder")
        if (this.maintenanceDetails.alertInfo != null 
          && this.maintenanceDetails.alertInfo.alertMessage != null
          && this.maintenanceDetails.alertInfo.title != null
          && this.maintenanceDetails.alertInfo.buttonContent != null) {
            let alertMessage = this.maintenanceDetails.alertInfo.alertMessage;
            if (this.maintenanceDetails.alertInfo.insertMaintenanceTime 
              && this.maintenanceDetails.alertInfo.alertMessageInsertMaintenanceTime != null
              && this.maintenanceDetails.alertInfo.alertMessageInsertMaintenanceTime.insertAfter != null
              && this.maintenanceDetails.alertInfo.alertMessageInsertMaintenanceTime.insertBefore != null) {
                let time = this.maintenanceDetails.maintenanceTime;
                let date = this.datepipe.transform(time, 'MM/dd/yyyy');
                let hourAndMinute = this.datepipe.transform(time, 'shortTime');
                alertMessage = this.maintenanceDetails.alertInfo.alertMessageInsertMaintenanceTime.insertBefore 
                + ' at ' + hourAndMinute + ' on ' + date + this.maintenanceDetails.alertInfo.alertMessageInsertMaintenanceTime.insertAfter;
              }
            this.utilsService.confirmModalPopupWithConfig(this.maintenanceDetails.alertInfo.title,alertMessage,this.maintenanceDetails.alertInfo.buttonContent);
            return true
          } else {
            this.utilsService.confirmModalPopupWithConfig("Maintenance Reminder","This site will be under maintenance","OK");
          }
          return true;
    } else {
      // console.log('non-maintenance pages')
      return true
    } 
  }
  
}