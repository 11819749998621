<div class="card get-quote-container zero-margin">
    <div class="card-body" [ngStyle]="styling?.pageFormBackground">
        <h4 class="card-title" *ngIf="showMessageContainer">
            <app-bot-message [message]="botMessage" [subTitle] = "subTitle" [showImageFlag]="showImageFlag"></app-bot-message>
        </h4>

        <!-- <div [@myAnimation]="o.isActivated ? o.activatedRoute : ''">
            <router-outlet #o="outlet"></router-outlet>
        </div> -->
        <router-outlet></router-outlet>
        <!-- <app-quote-submission-confirmation></app-quote-submission-confirmation> -->
    </div>
</div>