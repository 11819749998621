import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { UtilsService } from '../../shared/services/utils.service';
import { CommonApiService } from '../../shared/services/common-api.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { map, filter, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { formatCurrency } from '@angular/common';
import { ValidationService } from '../../shared/services/validation.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-get-quote',
  templateUrl: './get-quote.component.html',
  styleUrls: ['./get-quote.component.scss'],
  providers: [CommonApiService],
})
export class GetQuoteComponent implements OnInit {
  isEnglish = true;
  @ViewChild('stepper') stepper: MatStepper
  policyType = 'HO3';
  messageBot= null;
  selectedAgentDetails: any;
  showMessage = true;
  riskAddress: any;
  loading = false;
  currentSelectedAddress = {};
  buildingMaterials = [];
  buildingTypes = [];
  stories = [];
  roofMaterials = [];
  foundationTypes = ['Concrete Slab', 'Walk-out basement'];
  styling = this.utilsService.getItemfromSession('themeObject');
  tenant: any;
  findAgent = false;
  message = [];
  personalInfoForm_0: any;
  addressForm_1: any;
  startDateForm_2: any;
  isRentForm_3: any;
  isHouseForm_4: any;
  frequencyUsage_5: any;
  roofForm_6: any;
  additionalRoofInfoForm_7: any;
  homeAgeAndSizeForm: any;
  emailQuoteForm_14: any;
  additionalHomeDetailsForm: any;
  minDate= new Date();
  maxDate = new Date();
  datePickerSettings = {
    isAnimated: true,
    adaptivePosition: true,
    dateInputFormat: 'DD MMM YYYY',
    showWeekNumbers: false
  };
  selectedPremium_10 = 'basic';
  estimatedPremium: any;
  coverageValues_13: any;
  emailQuoteAgentForm: any;
  basicResponse: any;
  upgradedResponse: any;
  highEndResponse: any;
  updatedReponse: any;

  map = new Map();
  mapForCoverageNames = new Map();
  policyTypeSelections: any;
  state: any;
  curCoverageNames: any;
  curDeductibleNames: any;
  coverageForms = {
    coverageBValue: [],
    coverageCValue: [],
    coverageDValue: [],
    coverageEValue: [],
    coverageEPremisesLiabValue: [],
    coverageFValue: [],
    coverageLValue: [],
    coverageMValue: [],
    coverageDandE: [],
    aopDeductible: [],
    deductibleAppliedForHurricaneCoverage: [],
    waterDeductible: [],
    sinkholeDeductible: []
  }
  previousCountForRent = 0;
  previousCountForOwnRent = 0;
  isSubmitted = false;
  customerResource: any;
  quoteID: any;
  quoteCustomizedFeature: boolean;
  showOptionAtStepSix: boolean;
  policyTypeArray: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private http: CommonApiService,
    private utilsService: UtilsService,
    private notifyToast: NotifytoastService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    this.checkPermission().subscribe(resp => {
      let permission = this.utilsService.getItemfromSession('permissionKeys')
      if (permission) {
        let routerPermissionData = resp.key
        let keyAvailable = routerPermissionData.every(resp=> permission.includes(resp))
        if (keyAvailable) {
            return true
        } else {
            const tenant = this.utilsService.getTenantFromWindow()
            this.router.navigate([`${tenant}/permission-denied`])
            return false
        }
      }
    })
  }

  ngOnInit(): void {
    this.tenant = this.route.parent.snapshot.params.tenant;
    console.log("tenant " + this.tenant)
    this.initForm();
    this.accessMessage();
    this.getPolicyTypeMapping();
    this.getListOfCoverageNamesMapping();
    this.getQuoteMessage()
  }

  getQuoteMessage(){
    let lang = this.isEnglish ? 'en':'es'
    let selectedLang = this.utilsService.selLangFile(this.tenant, lang)
    this.translate.use(selectedLang)
    this.http.get(`accountConfiguration?accountCode=${this.tenant}&langCode=${lang}`, null, true).subscribe(resp => {
      if(resp){
        let effectiveDate = resp.quote.Effective_Day_Range ? resp.quote.Effective_Day_Range : 31
        this.maxDate.setDate(this.maxDate.getDate() + effectiveDate)
        let data: any;
        if(resp.content_management && resp.content_management.quote_flow_messages) data = { ...resp.content_management.quote_flow_messages }
        else data = null
        this.messageBot = data
        console.log(this.messageBot)
        // get the variable to see if the portal customized feature is enabled or disabled
        this.quoteCustomizedFeature = resp.quote.Quote_Customized_Feature ? resp.quote.Quote_Customized_Feature : false;
      }
    })
  }
  

  getCustomerResource() {
    console.log("get customer resource tenant " + this.tenant)
    var infoType = 'customer_resources';
    this.http
      .get(`getCardInfo?accountCode=${this.tenant}&infoType=${infoType}`, null, true)
      .subscribe(
        (resp) => {
          if (resp === undefined) {
          this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          } else {
            this.customerResource = resp.out;
            this.customerResource = this.sanitizer.bypassSecurityTrustHtml(this.customerResource);
            console.log('Customer resources after sending email:', this.customerResource);
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  checkPermission() {
    return this.router.events
        .pipe(
         filter(event => event instanceof NavigationEnd),
      map(() => this.route),
         map(route => {
        if (route.firstChild) {
            return route.firstChild
        } else {
            return route
        }
      }),
         switchMap(route => route.data),
         map(data => data))
  }

  private objectToMap(obj: Object) {
    let m = new Map()
    for (let k of Object.keys(obj)) {
      if (obj[k] instanceof Object) {
            m.set(k, this.objectToMap(obj[k]))   
      }
        else {
            m.set(k, obj[k])
      }
    }
    return m
  }

  getPolicyTypeMapping() {
    console.log("policy type mapping tenant " + this.tenant)
    this.http
      .get(`getPolicyTypeMapping?accountCode=${this.tenant}`, null, true)
      .subscribe(
        (resp) => {
          if (resp === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          } else {
            console.log(JSON.stringify(resp));
            this.map = this.objectToMap(resp);
            console.log('Got policy type mapping:', this.map.values());
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  getListOfCoverageNamesMapping() {
    this.http
      .get(`getListOfCoverageNamesMapping?accountCode=${this.tenant}`, null, true)
      .subscribe(
        (resp) => {
          if (resp === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          } else {
            this.mapForCoverageNames = this.objectToMap(resp);
            console.log('Got map for coverage names mapping:', this.mapForCoverageNames.values());
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }
  initForm() {
	  
	if (0)
	{
      this.personalInfoForm_0 = this.formBuilder.group({
        firstName: ['Vishnu', [Validators.required]],
        lastName: ['VN', [Validators.required]],
      });
      this.addressForm_1 = this.formBuilder.group({
      address: ['', [Validators.required]],
      unitNumber: ['', [Validators.pattern(/(^([aA][pP][tT]|[uU][nN][iI][tT]) \w+$)|(^(?!([aA][pP][tT]))(?!([uU][nN][iI][tT]))\w*$)/)]]
      });
      this.startDateForm_2 = this.formBuilder.group({
        startDate: [new Date(), Validators.required],
      });
      this.isRentForm_3 = this.formBuilder.group({
        checkRental: ['own'],
      });
      this.isHouseForm_4 = this.formBuilder.group({
        checkHouse: ['house'],
      });
      this.frequencyUsage_5 = this.formBuilder.group({
        usageResidence: ['primary'],
      });
      this.roofForm_6 = this.formBuilder.group({
        roofType: ['gable'],
      });
      this.additionalRoofInfoForm_7 = this.formBuilder.group({
        roofYear: ['2014', [Validators.required]],
		  roofMaterial: ['Shingles: Asphalt or Composition', [Validators.required]],
      });
      this.homeAgeAndSizeForm = this.formBuilder.group({
        yearBuilt: ['2000', [Validators.required]],
        squareFeet: ['1500', [Validators.required]],
        story: ['3', [Validators.required]],
      });
      this.additionalHomeDetailsForm = this.formBuilder.group({
        buildingMaterial: ['Masonry 100%', [Validators.required]],
        foundationType: ['Single Family House', [Validators.required]],
        buildingType: ['Concrete Slab', [Validators.required]],
        nearFireHydrant: [true, [Validators.required]],
      });
      this.emailQuoteForm_14 = this.formBuilder.group({
        email: ['', [Validators.required, ValidationService.emailValidator]],
    })
    this.emailQuoteAgentForm = this.formBuilder.group({
      ph_number: [
        '',
        [
          Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),
          Validators.maxLength(14),
        ],
      ],
      countryCode: [
        '1',
        [
          Validators.pattern('^[0-9]+$'),
          Validators.maxLength(3),
        ],
      ],
      message: [null],
      // agentFirstName: [null],
      // agentId:[null],
      // agentLastName:[null],
      // agentEmail:[null],
      email: [null, [Validators.required,ValidationService.emailValidator]],
      preferredCommunicationType:['email',[Validators.required]]
    });
	}
	else
	{
      this.personalInfoForm_0 = this.formBuilder.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email:[null, [Validators.required ,ValidationService.emailValidator]],
      });
      this.addressForm_1 = this.formBuilder.group({
      address: ['', [Validators.required]],
      unitNumber: ['', [
        Validators.pattern(/(^([aA][pP][tT]|[uU][nN][iI][tT]) \w+$)|(^(?!([aA][pP][tT]))(?!([uU][nN][iI][tT]))\w*$)/)
      ]]
      });
      this.startDateForm_2 = this.formBuilder.group({
        startDate: [new Date(), Validators.required],
      });
      this.isRentForm_3 = this.formBuilder.group({
      checkRental: ['own']
      });
      this.isHouseForm_4 = this.formBuilder.group({
        checkHouse: ['house'],
      });
      this.frequencyUsage_5 = this.formBuilder.group({
        usageResidence: ['primary'],
      });
      this.roofForm_6 = this.formBuilder.group({
        roofType: ['gable'],
      });
      this.additionalRoofInfoForm_7 = this.formBuilder.group({
		  roofYear: ['', [Validators.required, Validators.min(1970), Validators.max((new Date()).getFullYear())]],
      roofMaterial: ['', [Validators.required]]
      });
      this.homeAgeAndSizeForm = this.formBuilder.group({
		  yearBuilt: ['', [Validators.required,  Validators.min(1650), Validators.max((new Date()).getFullYear())]],
        squareFeet: ['', [Validators.required, Validators.max(100000), Validators.min(1)]],
        story: ['', [Validators.required]],
      });
      this.additionalHomeDetailsForm = this.formBuilder.group({
        buildingMaterial: ['', [Validators.required]],
        // foundationType: ['', [Validators.required]],
        buildingType: ['', [Validators.required]],
        nearFireHydrant: [null, [Validators.required]],
      });
    }
    this.coverageValues_13 = this.formBuilder.group({
      coverageAValue: [''],
      coverageBValue: [''],
      coverageCValue: [''],
      coverageDValue: [''],
      coverageEValue: [''],
      coverageEPremisesLiabValue: [''],
      coverageFValue: [''],
      aopDeductible: [''],
      deductibleAppliedForHurricaneCoverage: [''],
      waterDeductible: ['Limited'],

      coverageLValue: [''],
      coverageMValue: [''],
      CoverageDandE: [''],
      sinkholeDeductible: [''],
      coverageDandE: ['']
    });
    this.emailQuoteForm_14 = this.formBuilder.group({
      email: ['', [Validators.required, ValidationService.emailValidator]],
    })

    this.emailQuoteAgentForm = this.formBuilder.group({
        ph_number: [
          '',
          [ 
            Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),
            Validators.maxLength(14),
          ],
        ],
        countryCode: [
          '1',
          [
            Validators.pattern('^[0-9]+$'),
            Validators.maxLength(3),
          ],
        ],
        message: [null],
        email: [null, [Validators.required,ValidationService.emailValidator]],
        preferredCommunicationType:['email',[Validators.required]]
    
      });
  }

  accessMessage() {
	  
    this.message = [
      "Hi there! I can get you a quote with a few simple questions! Let's start with the basics.",
      `Thanks ${this.personalInfoForm_0.value.firstName}! Can you give me your home address?`,
      'When were you thinking of starting a policy?',
      'Fantastic! Do you own or rent the property?',
      'Is this a house or a condo/townhouse?',
      'Thank you! Can you tell me how you use your home?',
      `${this.personalInfoForm_0.value.firstName}, take a look at the roof pictures below and pick yours.`,
      "Fantastic, I'll also need to know when your roof was built or replaced and your roofing material.",
      `Thanks ${this.personalInfoForm_0.value.firstName}. Can you tell me about the age and size of your home?`,
      "We're really close to getting you a quote. I just need a few more details on your home.",
      'Last, but not least. How would you describe the finishes in your house? This will help me determine the proper amount of coverage for you.',
      `${this.personalInfoForm_0.value.firstName}, I was able to find the best policy and price based on your home.`,
      'Here are the details of your quote. Feel free to tweak the numbers and I can get you an updated quote.',
      'Sure thing! Please confirm the email address you would like us to send your quote to:',
      `Your quote has been sent to ${this.emailQuoteForm_14.value.email}. \nWe look forward to hearing from you! \nPlease feel free to visit ${this.tenant.toUpperCase()} Insurance and look through our customer resources.`,
      'Please select an agent.'
    ];
  }

  getAddress(googleAddress) {
    if (googleAddress.address_components) {
      for (var i = 0; i < googleAddress.address_components.length; i++) {
        var c = googleAddress.address_components[i];
        if (c.types[0] == 'administrative_area_level_1')
          this.currentSelectedAddress['state'] = c;
        else {
          if (c.types[0] == 'locality') this.currentSelectedAddress['city'] = c;
          else this.currentSelectedAddress[c.types[0]] = c;
        }
      }
      console.log('converted address:', this.currentSelectedAddress);
      this.currentSelectedAddress['formatted_address'] =
        googleAddress.formatted_address;
      this.addressForm_1.patchValue({
        address: googleAddress.formatted_address
      });
      this.riskAddress = {
      "city": this.currentSelectedAddress['city']['short_name'],
      "street":this.currentSelectedAddress['street_number']['short_name'] +" "+ this.currentSelectedAddress['route']['long_name'],
      "zipCode": this.currentSelectedAddress['postal_code']['long_name'],
      "stateCode":this.currentSelectedAddress['state']['short_name'],
      "lat":googleAddress.geometry.location.lat(),
      "lng":googleAddress.geometry.location.lng()
      }
    } else {
      this.addressForm_1.patchValue({ address: '' });
    }
  }

  updateValidator(){
    this.homeAgeAndSizeForm.get('yearBuilt').setValidators([Validators.required, Validators.max(this.additionalRoofInfoForm_7.value.roofYear), Validators.min(1650)]);
    this.homeAgeAndSizeForm.get('yearBuilt').updateValueAndValidity()
  }

  goBack(stepper: MatStepper) {
    if (stepper.selectedIndex === 6 
      && this.previousCountForRent === 6) {
        // when the previous selection is rent-house, skip back to step 4
        stepper.selectedIndex = 5;
    } else if (stepper.selectedIndex === 10 
      && this.previousCountForRent === 10) {
        // when the previous selection is rent-condo, skip back to step 4
        stepper.selectedIndex = 5;
    } else if (stepper.selectedIndex === 8
      && this.previousCountForOwnRent === 8) {
      // when the previous selection is own-condo, skip back to step 5
        stepper.selectedIndex = 6;
    }
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    this.isSubmitted = true
    if (stepper.selectedIndex === 0 && this.personalInfoForm_0.valid) {
      this.emailQuoteAgentForm.patchValue({
        email : this.personalInfoForm_0.value.email
    });
      this.emailQuoteForm_14.patchValue({
        email : this.personalInfoForm_0.value.email
      });
      this.isSubmitted = false
      this.accessMessage();
      stepper.next();
    } else if (stepper.selectedIndex === 1) {
      if (this.addressForm_1.valid) {
        this.isSubmitted = false
        this.message[1] = `Thanks ${this.personalInfoForm_0.value.firstName}! Can you give me your home address?`
        console.log("unit number: " + this.addressForm_1.value.unitNumber)
        stepper.next();
      } else this.message[1] = `Hmm, I wasn't able to find that address. Can you double-check your entry and try again?`
    } else if (stepper.selectedIndex === 2 && this.startDateForm_2.valid) {
      this.isSubmitted = false
      stepper.next();
    } else if (stepper.selectedIndex === 3) {
      this.previousCountForRent = 0;
      stepper.next();
    } else if (stepper.selectedIndex === 4) {
      if (this.isRentForm_3.value.checkRental === 'rent') {
        this.policyTypeSelections = this.isRentForm_3.value.checkRental + '_' + this.isHouseForm_4.value.checkHouse;
        this.setPolicyType();
        if (this.isHouseForm_4.value.checkHouse === 'house') {
        // If the selection of step 3 and 4 is rent-house, then skip frequencyUsage question
          this.previousCountForRent = 6;
          this.stepper.selectedIndex = 5;
        } else if (this.isHouseForm_4.value.checkHouse === 'condo') {
        // If the selection of step 3 and 4 is rent-condo, then skip to premium calculation question
          this.previousCountForRent = 10;
          this.stepper.selectedIndex = 9;
        }
      } 
      if (this.isRentForm_3.value.checkRental === 'own' && this.isHouseForm_4.value.checkHouse === 'house') {
        this.showOptionAtStepSix = true;
      } else {
        this.showOptionAtStepSix = false;
      }
      this.previousCountForOwnRent = 0;
      stepper.next();
    } else if (stepper.selectedIndex === 5) {
      // get the policy type
      this.policyTypeSelections = this.isRentForm_3.value.checkRental
      + '_' + this.isHouseForm_4.value.checkHouse
      + '_' + this.frequencyUsage_5.value.usageResidence;

      this.setPolicyType();
      // this.setCoverageNames();
      // this.getConstrainedValues();
      // this.getPropertyInfo();
      // If the selection of step 3 and 4 is own-condo, then skip roof questions
      if (this.isRentForm_3.value.checkRental === 'own' 
          && this.isHouseForm_4.value.checkHouse === 'condo') {
            this.previousCountForOwnRent = 8;
            this.stepper.selectedIndex = 7;
      }
      stepper.next();
    } else if (stepper.selectedIndex === 6) {
      this.isSubmitted = false;
      stepper.next();
    } else if (stepper.selectedIndex === 7 && this.additionalRoofInfoForm_7.valid) {
      this.updateValidator()
      this.isSubmitted = false;
      stepper.next();
    } else if (stepper.selectedIndex === 8 && this.homeAgeAndSizeForm.valid) {
      this.isSubmitted = false;
      stepper.next();
    } else if (stepper.selectedIndex === 9 && this.additionalHomeDetailsForm.valid) {
      this.isSubmitted = false;
      stepper.next();
    } else if (stepper.selectedIndex === 10) {
      this.calculatePremium(stepper);
      
    } else if (stepper.selectedIndex === 11) {
      stepper.next();
    } else if (stepper.selectedIndex === 12) {
      stepper.next();
    } else if (stepper.selectedIndex === 13 && this.emailQuoteForm_14.valid) {
      this.accessMessage();
      console.log("email address: " + this.emailQuoteForm_14.value.email);
      this.emailQuote(stepper);
    } else if (stepper.selectedIndex === 16 && this.emailQuoteAgentForm.valid) {
     this.emailQuoteAgent(stepper);
    }
  }

  emailQuoteAgent(stepper: MatStepper) {
    const data = this.rateAQuoteObjectStructure();
    // add the default value of HomeBuildingType if there is no information collected from UI
    if (this.buildingTypes != null) {
      data.propertyStructureType = data.propertyStructureType === '' ? this.buildingTypes[0] : data.propertyStructureType;
    }
    this.submitQuote(data).subscribe(
      (resp) => {
      if (resp === undefined) {
        console.log('No response returned');
      } else {
        console.log("agent details in submit quote:", this.selectedAgentDetails)
        console.log("submit quote response: " + JSON.stringify(resp))
        this.isSubmitted = true;
        this.quoteID = resp.quoteReferenceNumber;
          this.sendAgentEmail(this.emailAgentQuoteData()).subscribe(
            (sendEmailResp) => {
          if (sendEmailResp === undefined) {
            console.log('No response for sending quote by email');
          } else {
            // this.getCustomerResource();
            // stepper.next();
            this.notifyToast.success(sendEmailResp);
                console.log("email quote response: " + JSON.stringify(sendEmailResp))
                
                stepper.next();
              }
        });
      }
    });
  }


  emailQuote(stepper: MatStepper) {
    const data = this.rateAQuoteObjectStructure();
    // add the default value of HomeBuildingType if there is no information collected from UI
    if (this.buildingTypes != null) {
      data.propertyStructureType = data.propertyStructureType === '' ? this.buildingTypes[0] : data.propertyStructureType;
    }
    this.submitQuote(data).subscribe(
      (resp) => {
      if (resp === undefined) {
        console.log('No response returned');
      } else {
          console.log("submit quote response: " + JSON.stringify(resp))
        this.isSubmitted = true;
        this.quoteID = resp.quoteReferenceNumber;
          this.sendEmail(this.emailQuoteData()).subscribe(
            (sendEmailResp) => {
          if (sendEmailResp === undefined) {
            console.log('No response for sending quote by email');
          } else {
            this.getCustomerResource();
            stepper.next();
                console.log("email quote response: " + JSON.stringify(sendEmailResp))
          }
        });
      }
    });
  }

  emailAgentQuoteData() {
    let message = (this.emailQuoteAgentForm.value.message == null)?" " : ". Message from "+ this.personalInfoForm_0.value.firstName + " "+ this.personalInfoForm_0.value.lastName +": "+this.emailQuoteAgentForm.value.message
    const data = {
      "accountCode": this.tenant,
      "to": this.selectedAgentDetails.agencyEmail,
      "quoteID": this.quoteID,
      "agentFirstName": this.selectedAgentDetails.agentFirstName,
      "agentLastName": this.selectedAgentDetails.agentLastName,
      "insuredFirstName": this.personalInfoForm_0.value.firstName,
      "insuredLastName":this.personalInfoForm_0.value.lastName,
      "preferrredContact":this.emailQuoteAgentForm.value.preferredCommunicationType,
      "email": this.emailQuoteAgentForm.value.email,
      "phone": this.emailQuoteAgentForm.value.ph_number
    }
     console.log("Email agent quote data", data);
    return data;
  }

  emailQuoteData() {
    const data = {
        "accountCode": this.tenant,
        "to": this.emailQuoteForm_14.value.email,
        "quoteID": this.quoteID,
        "firstName": this.personalInfoForm_0.value.firstName,
        "lastName": this.personalInfoForm_0.value.lastName,
        "phoneNumber": "(111)111-1111",
     }
    return data;
  }
  setPolicyType() {
    this.state = this.currentSelectedAddress['state']['short_name'];
    console.log(this.state)

    let mapUnderState;
    if (this.map.get(this.state)) {
      mapUnderState = this.map.get(this.state);
      if (mapUnderState.has(this.policyTypeSelections)) {
        this.policyType = mapUnderState.get(this.policyTypeSelections);
        this.policyTypeArray = this.policyType.split("-");
        console.log("policy type array: " + this.policyTypeArray)
        this.setCoverageNames();
        this.getConstrainedValues();
        this.getPropertyInfo(); 
      } else {
        console.log("No policy form based on the current combination: " + this.policyTypeSelections)
      }
    } else {
      console.log("No policy form in this state: " + this.state);
    }
    console.log("policy type based on the selections: " + this.policyType)
  }

  setCoverageNames() {
    let listOfCoveragesUnderState;
    if (this.mapForCoverageNames.has(this.state)) {
      listOfCoveragesUnderState = this.mapForCoverageNames.get(this.state);
      if (listOfCoveragesUnderState.has(this.policyType)) {
        this.curCoverageNames = listOfCoveragesUnderState.get(this.policyType).get("Coverages");
        this.curDeductibleNames = listOfCoveragesUnderState.get(this.policyType).get("Deductibles");
      } else {
        console.log("No coverage names based on the current policy type.")
      }
    } else {
      console.log("No coverage names in this state: " + this.state);
    }
    console.log("list of coverage names based on the policy type: " + this.curCoverageNames);
    console.log("list of deductible names based on the policy type: " + this.curDeductibleNames);
  }

  calculatePremium(stepper: MatStepper) {
    // stepper.next();
    this.loading = true;
    this.submitRateQuote(this.rateAQuoteObjectStructure()).subscribe(
      (resp) => {
      if (resp === undefined) {
        console.log('No response returned');
      } else {
        this.basicResponse = resp;
        this.applyResponseToCoverageValues(this.basicResponse);
        if (this.selectedPremium_10 === 'basic') {
          console.log("selected premium: " + this.selectedPremium_10);
          this.estimatedPremium = this.basicResponse.rate.estimatedPremium;
          stepper.next();
          this.loading = false; 
        } else if (this.selectedPremium_10 === 'upgraded') {
          console.log("selected premium: " + this.selectedPremium_10);
          let data = this.calculateRequestDataBasedOnPolicyType('upgraded');
          // data.coverageAValue = Number(this.basicResponse.coverageAValue) * Number(1.1);
            console.log("coverage a after calculating" + JSON.stringify(data))
            this.submitRateQuote(data).subscribe(
              (respUpgraded) => {
            if (respUpgraded === undefined) {
              console.log('No response returned');
            } else {
              this.upgradedResponse = respUpgraded;
                  console.log('upgraded response' + JSON.stringify(this.upgradedResponse));
              this.estimatedPremium = this.upgradedResponse.rate.estimatedPremium;
              this.applyResponseToCoverageValues(this.upgradedResponse);
              stepper.next();
              this.loading = false;
            }
          });
              
        } else if (this.selectedPremium_10 === 'highEnd') {
          console.log("selected premium: " + this.selectedPremium_10);
          let data = this.calculateRequestDataBasedOnPolicyType('highEnd');
          // data.coverageAValue = Number(this.basicResponse.coverageAValue) * Number(1.2);
            console.log("coverage a after calculating" + JSON.stringify(data))
            this.submitRateQuote(data).subscribe(
              (respHighEnd) => {
            if (respHighEnd === undefined) {
              console.log('No response returned');
            } else {
              this.highEndResponse = respHighEnd;
                  console.log('high end response' + JSON.stringify(this.highEndResponse));
              this.estimatedPremium = this.highEndResponse.rate.estimatedPremium;
              this.applyResponseToCoverageValues(this.highEndResponse);
              stepper.next();
              this.loading = false;
            }
          });
              
        }
        console.log('basic response' + JSON.stringify(this.basicResponse));
      }
    });
  }

  reCalculatePremium(stepper: MatStepper) {
    this.loading = true;
    this.submitRateQuote(this.rateAQuoteObjectStructure()).subscribe(
      (resp) => {
      if (resp === undefined) {
        console.log('No response returned');
      } else {
        this.updatedReponse = resp;
        console.log('updated response' + JSON.stringify(this.updatedReponse));
        this.estimatedPremium = this.updatedReponse.rate.estimatedPremium;
        this.applyResponseToCoverageValues(this.updatedReponse);
        stepper.previous();
        this.loading = false; 
      }
    });
  }

  applyResponseToCoverageValues(response) {
    if (response != null) {
      this.coverageValues_13.patchValue({
        coverageAValue : formatCurrency(response.coverageAValue, "en_US", "$", "USD"),
        coverageBValue : formatCurrency(response.coverageBValue, "en_US", "$", "USD"),
        coverageCValue : this.policyType === 'HO3' ? response.recommendedPercentageOfCoverageCtoCoverageA : formatCurrency(response.coverageCValue, "en_US", "$", "USD"),
        coverageDValue : formatCurrency(response.coverageDValue, "en_US", "$", "USD"),
        coverageEValue: response.coverageEValue,
        coverageEPremisesLiabValue: response.coverageEPremisesLiab,
        coverageFValue: response.coverageFValue,
        aopDeductible: response.aopDeductible,
        deductibleAppliedForHurricaneCoverage : response.deductibleAppliedForHurricaneCoverage,
        waterDeductible: response.waterDeductible,

        coverageLValue: response.coverageLValue,
        coverageMValue: response.coverageMValue,
        CoverageDandE: response.CoverageDandE,
        sinkholeDeductible: response.sinkholeDeductible,
      });
    }
  }

  calculateRequestDataBasedOnPolicyType(premiumPackage) {
    let data = this.rateAQuoteObjectStructure();
    if (this.policyType === 'HO3') {
      if (premiumPackage === 'upgraded') {
        data.coverageAValue = Number(this.basicResponse.coverageAValue) * Number(1.1);
        data.RecommendedPercentageOfCoverageCtoCoverageA = "50% of Coverage A";
      } else if (premiumPackage === 'highEnd') {
        data.coverageAValue = Number(this.basicResponse.coverageAValue) * Number(1.25);
        data.RecommendedPercentageOfCoverageCtoCoverageA = "75% of Coverage A";;
      }
    } else if (this.policyType === 'HO4') {
      if (premiumPackage === 'upgraded') {
        data.coverageCValue = Number(this.basicResponse.coverageCValue) * Number(1.1);
      } else if (premiumPackage === 'highEnd') {
        data.coverageCValue = Number(this.basicResponse.coverageCValue) * Number(1.25);
      }
    } else if (this.policyType === 'HO6') {
      if (premiumPackage === 'upgraded') {
        data.coverageAValue = Number(this.basicResponse.coverageAValue) * Number(1.1);
        data.coverageCValue = Number(this.basicResponse.coverageCValue) * Number(1.1);
      } else if (premiumPackage === 'highEnd') {
        data.coverageAValue = Number(this.basicResponse.coverageAValue) * Number(1.25);
        data.coverageCValue = Number(this.basicResponse.coverageCValue) * Number(1.25);
      }
    } else if ( this.policyType === 'DF3-DO' || this.policyType === 'DF3-DL' || this.policyType === 'DF1') {
      if (premiumPackage === 'upgraded') {
        data.coverageAValue = Number(this.basicResponse.coverageAValue) * Number(1.1);
        data.coverageCValue = Number(this.basicResponse.coverageAValue) * Number(0.25);
      } else if (premiumPackage === 'highEnd') {
        data.coverageAValue = Number(this.basicResponse.coverageAValue) * Number(1.25);
        data.coverageCValue = Number(this.basicResponse.coverageAValue) * Number(0.5);
      }
    } else if (this.policyType === 'DF3-CO' || this.policyType === 'DF3-CL') {
      if (premiumPackage === 'upgraded') {
        data.coverageAValue = Number(this.basicResponse.coverageAValue) * Number(1.1);
        data.coverageCValue = Number(this.basicResponse.coverageCValue) * Number(1.1);
      } else if (premiumPackage === 'highEnd') {
        data.coverageAValue = Number(this.basicResponse.coverageAValue) * Number(1.25);
        data.coverageCValue = Number(this.basicResponse.coverageCValue) * Number(1.25);
      }
    }
    return data;
  }

  navigateToLink(page) {
    if (page === "get-quote-stable") {
      window.location.reload();
    }
    this.router.navigate([`${this.tenant}/${page}`]);
    console.log(this.tenant)
    console.log(page);
  }

  getConstrainedValues() {
    const policyForm = this.policyType;
    const state = this.currentSelectedAddress['state']['short_name'];;
    this.http
      .get(`getConstrainedValues?accountCode=${this.tenant}&policyForm=${policyForm}&state=${state}`, null, true)
      .subscribe(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
          } else {
              // this.propertiesList = resp.out.propertyUsage.string;
              this.buildingMaterials = resp.out.constructionType.string;
              this.buildingTypes = resp.out.structureType.string;
              this.stories = resp.out.numberOfStories.string;
              // this.roofShapes = resp.out.roofShape.string;
            this.roofMaterials = resp.out.roofMaterial.string;
            if (resp.out.coverageBValue != null) {
              this.coverageForms.coverageBValue = resp.out.coverageBValue.string;
            }
            if (resp.out.recommendedPercentageOfCoverageCtoCoverageA != null) {
              let tempCoverageCValues = resp.out.recommendedPercentageOfCoverageCtoCoverageA.CoverageCPercentageValue;
              let tempCoverageC = [];
              for (let coverageC of tempCoverageCValues) {
                tempCoverageC.push(coverageC.displayValue);
              }
              this.coverageForms.coverageCValue = tempCoverageC;
              console.log(this.coverageForms.coverageCValue)
            }
            if (resp.out.coverageDValue != null) {
              this.coverageForms.coverageDValue = resp.out.coverageDValue.string;
            }
            if (resp.out.coverageEValue != null) {
              this.coverageForms.coverageEValue = resp.out.coverageEValue.string;
              console.log(this.coverageForms.coverageEValue)
            }
            if (resp.out.coverageEPremisesLiab != null){
              this.coverageForms.coverageEPremisesLiabValue = resp.out.coverageEPremisesLiab.string;
            }
            if (resp.out.coverageFValue != null) {
              this.coverageForms.coverageFValue = resp.out.coverageFValue.string;
            }
            if (resp.out.coverageLValue != null) {
              this.coverageForms.coverageLValue = resp.out.coverageLValue.string;
            }
            if (resp.out.coverageMValue != null) {
              this.coverageForms.coverageMValue =
                resp.out.coverageMValue.string;
            }
            if (resp.out.aopDeductible != null) {
              this.coverageForms.aopDeductible = resp.out.aopDeductible.string;
            }
            if (resp.out.deductibleAppliedForHurricaneCoverage != null) {
              this.coverageForms.deductibleAppliedForHurricaneCoverage =
                resp.out.deductibleAppliedForHurricaneCoverage.string;
            }
            if (resp.out.waterDeductible != null) {
              this.coverageForms.waterDeductible = resp.out.waterDeductible.string;
            }
            if (resp.out.CoverageDandE != null) {
              this.coverageForms.coverageDandE = resp.out.CoverageDandE.string;
            }
            if (resp.out.sinkholeDeductible != null) {
              this.coverageForms.sinkholeDeductible = resp.out.sinkholeDeductible.string;
            }
            console.log('Got Constrained Values:', resp.out);
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  getPropertyInfo() {
    let policyForm = ""; 
    //console.log("the length of policy type array: " + this.policyTypeArray.length)
    if (this.policyTypeArray !== null && this.policyTypeArray !== undefined && this.policyTypeArray.length > 1) {
      for (let item of this.policyTypeArray) {
        policyForm = policyForm + item + " "
      }
    } else {
      policyForm = this.policyType;
    }
    var insuranceType =
      this.tenant.toUpperCase() +
      ' ' +
      this.currentSelectedAddress['state']['long_name'] +
      ' ' +
      policyForm;
      // hard code the insurance type with SFI when the tenant is upc-quote
    if (this.tenant === 'upc-quote' || this.tenant === 'acme') {
        insuranceType =
          'SFI' +
          ' ' +
          this.currentSelectedAddress['state']['long_name'] +
          ' ' +
          policyForm;
      }
    const requestRateQuoteData = {
	  // hard coded to SFI as SFI is the only env that could support quote	
      accountCode: this.tenant,
      phoneNumber: {
        number: null,
        type: null,
      },
      insuranceType: insuranceType,
      policyEffectiveDate: this.startDateForm_2.value.startDate.toLocaleDateString('en-US'),

      propertyAddress: {
        street:
          (this.currentSelectedAddress['street_number'] &&
            this.currentSelectedAddress['street_number']['long_name']) +
          ' ' +
          (this.currentSelectedAddress['route'] && this.currentSelectedAddress['route']['long_name']) +
          ' ' +
          this.addressForm_1.value.unitNumber,
        city: this.currentSelectedAddress['city']['long_name'],
        stateCode: this.currentSelectedAddress['state']['short_name'],
        zip:
          this.currentSelectedAddress['postal_code'] &&
          this.currentSelectedAddress['postal_code']['long_name'],
      },

      mailingAddress: {
        street: null,
        city: null,
        stateCode: null,
        zip: this.currentSelectedAddress['postal_code'] && this.currentSelectedAddress['postal_code']['long_name'],
      },
    };
    console.log(
      ' get property data requestRateQuoteData:',
      requestRateQuoteData
    );
    this.http
      .post('getPropertyInfo', requestRateQuoteData, true, true)
      .subscribe(
        (resp) => {
          if (resp.out === undefined) {
            console.log('error property Info', resp);
            this.notifyToast.error(
              'Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring
            );
          } else {
            console.log('return value Rate Quote' + JSON.stringify(resp.out));
            this.message[8] = `Thanks ${this.personalInfoForm_0.value.firstName}.
             I was able to find some information about your home.
             Can you look it over and change anything that looks off?` 
            this.homeAgeAndSizeForm.patchValue({
              squareFeet: resp.out['propertySquareFootage'],
              yearBuilt: resp.out['propertyYearBuilt'],
              story: resp.out['propertyNumberOfStories'],
              // property: resp.out['propertyUsage'],
              // buildingMaterial: resp.out['propertyConstructionType'],
              // buildingType: resp.out['propertyStructureType'],
              // nearFireHydrant: resp.out['is1000FeetFromFireHydrant'],
              // roofYear: resp.out['propertyRoofYearLastRenovated'],
              // roofShape: resp.out['propertyRoofType']
            });
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  clickedPremium(eve) {
    //Over Here We can determine which type of package the user has selected Gold/Platinum/Silver But Please write after the API is called...
    this.selectedPremium_10 = eve;
  }

  agentDetails(eve, stepper: MatStepper) {
    this.selectedAgentDetails = eve;

    stepper.next();

  }

  navigateToCoverageDetails(stepper) {
    console.log('stepper parent' + stepper);
    stepper.next();
  }

  navigateToEmailQuote(stepper) {
    stepper.next();
    stepper.next();
  }

  navigateToAStep(stepper) {
    this.showMessage = false;
    this.stepper.selectedIndex = 15;
    this.findAgent = true;
    this.isSubmitted = false;
    }

  langSwitcher(){
    this.isEnglish = !this.isEnglish
    this.getQuoteMessage()
  }

  navigateToShowPremium(stepper) {
    stepper.previous();
    stepper.previous();
  }

  navigateToPremium(){
    this.stepper.selectedIndex = 11
  }

  rateAQuoteObjectStructure() {
    let policyForm = ""; 
    if (this.policyTypeArray !== null && this.policyTypeArray !== undefined && this.policyTypeArray.length > 1) {
      for (let item of this.policyTypeArray) {
        policyForm = policyForm + item + " "
      }
    } else {
      policyForm = this.policyType;
    }
    const data = {
      firstName: this.personalInfoForm_0.value.firstName,
      lastName: this.personalInfoForm_0.value.lastName,
      accountCode: this.tenant,
      phoneNumber: {
        number: '4084445212',
        type: 'Cell',
      },
      // insuranceType: "SFI Florida HO3",
      insuranceType:
        this.tenant.toUpperCase() +
        ' ' +
        this.currentSelectedAddress['state']['long_name'] +
        ' ' +
        policyForm,
      policyEffectiveDate: this.startDateForm_2.value.startDate.toLocaleDateString('en-US'),
      propertyAddress: this.getPropAddress(),
      mailingAddress: this.getPropAddress(),
      propertyUsage: (this.policyTypeSelections === 'rent_condo' || this.policyTypeSelections === 'rent_house')? null : this.frequencyUsage_5.value.usageResidence,
      propertyYearBuilt: (this.policyTypeSelections === 'rent_condo' )? null : this.homeAgeAndSizeForm.value.yearBuilt,
      propertyConstructionType: (this.policyTypeSelections === 'rent_condo' )? null : this.additionalHomeDetailsForm.value
        .buildingMaterial,
      propertySquareFootage: (this.policyTypeSelections === 'rent_condo' )?null : this.homeAgeAndSizeForm.value.squareFeet,
      propertyStructureType: (this.policyTypeSelections === 'rent_condo' )?null : this.additionalHomeDetailsForm.value.buildingType,
      propertyNumberOfStories: (this.policyTypeSelections === 'rent_condo' )? null : this.homeAgeAndSizeForm.value.story,
      propertyRoofType: (this.policyTypeSelections === 'rent_condo' )? null : this.roofForm_6.value.roofType,
      propertyRoofYearLastRenovated: (this.policyTypeSelections === 'rent_condo' )? null: this.additionalRoofInfoForm_7.value.roofYear,
      is1000FeetFromFireHydrant: (this.policyTypeSelections === 'rent_condo' )? null : this.additionalHomeDetailsForm.value.nearFireHydrant,
      coverageAValue: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues_13.value.coverageAValue,
      coverageBValue: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues_13.value.coverageBValue,
      coverageCValue: (this.policyTypeSelections === 'rent_condo' || this.policyTypeSelections === 'own_house_primary')? null : this.coverageValues_13.value.coverageCValue,
      RecommendedPercentageOfCoverageCtoCoverageA :(this.policyTypeSelections !== 'own_house_primary')? null : this.coverageValues_13.value.coverageCValue,
      coverageDValue: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues_13.value.coverageDValue,
      coverageEValue: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues_13.value.coverageEValue,
      coverageEPremisesLiabValue: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues_13.value.coverageEPremisesLiabValue,
      coverageFValue: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues_13.value.coverageFValue,
      aopDeductible: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues_13.value.aopDeductible,
      deductibleAppliedForHurricaneCoverage: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues_13.value
        .deductibleAppliedForHurricaneCoverage,
      waterDeductible: (this.policyTypeSelections === 'rent_condo' )? null : this.coverageValues_13.value.waterDeductible,
      agentID: (this.selectedAgentDetails == undefined || this.selectedAgentDetails == null )? '951925' : this.selectedAgentDetails.agentID,
      email: this.emailQuoteForm_14.value.email,
      propertyRoofMaterial: (this.policyTypeSelections === 'rent_condo' )? null : this.additionalRoofInfoForm_7.value.roofMaterial,
      // includeFloodPremium: false,
    };
     // hard code the the insurance type with SFI when the tenant is upc-quote
    if (this.tenant === 'upc-quote' || this.tenant === 'acme') {
      data.insuranceType =
        'SFI' +
        ' ' +
        this.currentSelectedAddress['state']['long_name'] +
        ' ' +
        policyForm;
    }
    console.log(' request quote data: ' + JSON.stringify(data));
    return data;
  }

  submitRateQuote(data): Observable<any> {
    return this.http.post('rateQuote', data, false, true).pipe(
      map((resp) => {
        if (resp.out === undefined) {
          if(this.stepper.selectedIndex === 10){
            this.loading = false
            this.utilsService.confirmModalPopup(`${resp.root.Envelope.Body.Fault.faultstring}`)
            // this.stepper.previous()
          } else{
            this.notifyToast.error(
              'Please Contact System Administrator.' +
                '\n' +
                resp.root.Envelope.Body.Fault.faultstring
            );
          }
        } else {
          console.log('return value Rate Quote' + JSON.stringify(resp.out));
          return resp.out;
        }
      })
    );
  }

  sendEmail(data) {
    return this.http.post('emailQuote', data, true, true).pipe(
      map(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error(
              'Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring
            );
            console.log(JSON.stringify(resp));
          } else {
            console.log('email quote' + JSON.stringify(resp.out));
            return resp.out;
          }
        }
        // ,
        // (error) => {
        //   this.notifyToast.error('Please Contact System Administrator.');
        // }
      )
    );
  }


  sendAgentEmail(data) {
    return this.http.post('emailQuoteToAgent', data, true, true).pipe(
      map(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error(
              'Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring
            );
            console.log(JSON.stringify(resp));
          } else {
            console.log('email quote' + JSON.stringify(resp.out));
            return resp.out;
          }
        }
        // ,
        // (error) => {
        //   this.notifyToast.error('Please Contact System Administrator.');
        // }
      )
    );
  }


  submitQuote(requestSubmitQuoteData): Observable<any> {
    return this.http
      .post('submitQuote', requestSubmitQuoteData, true, true)
      .pipe(
        map(
          (resp) => {
            if (resp.out === undefined) {
              this.notifyToast.error(
                'Please Contact System Administrator.' +
                  '\n' +
                  resp.root.Envelope.Body.Fault.faultstring
              );
              console.log(JSON.stringify(resp));
            } else {
              console.log(
                'return value Submit Quote' + JSON.stringify(resp.out)
              );
              return resp.out;
            }
          }
          // ,
          // (error) => {
          //   this.notifyToast.error('Please Contact System Administrator.');
          // }
        )
      );
  }

  getPropAddress() {
    const tempData = {
      street:
        (this.currentSelectedAddress['street_number'] &&
          this.currentSelectedAddress['street_number']['long_name']) +
        ' ' +
        (this.currentSelectedAddress['route'] && this.currentSelectedAddress['route']['long_name']) + 
        ' ' +
        this.addressForm_1.value.unitNumber,
      city: this.currentSelectedAddress['city']['long_name'],
      stateCode: this.currentSelectedAddress['state']['short_name'],
      zip:
        this.currentSelectedAddress['postal_code'] &&
        this.currentSelectedAddress['postal_code']['long_name'],
    };
    console.log('current selected address', this.currentSelectedAddress);
    return tempData;
  }
}
