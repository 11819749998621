import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from 'rxjs/operators'
import { CommonApiService } from "../shared/services/common-api.service";



@Injectable()
export class PublicResolver implements Resolve<any>{
    constructor(private httpService: CommonApiService){}

    resolve(route: ActivatedRouteSnapshot): Observable<any>{
        const pageName = route.params.tenant
        
        return this.httpService.get(`accountConfiguration?accountCode=${pageName}`, null, true).pipe(
            // return this.httpService.get(`accountConfiguration?accountCode=sfi`, null, true).pipe(
            map(res => { return res }),
            catchError(error => this.handleError(error))
        )
    }

    handleError(error){
        return observableThrowError(error)
    }
}