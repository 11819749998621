<div>
    <div class="modal-header">
        <h4 class="modal-title pull-left sfi-header-four-s" [ngStyle]="styling?.paragraphTitle" [innerHTML]='title'></h4>
    </div>
    <div class="modal-body p-0" style="height: 500px;">
        <!-- <pdf-viewer [src]="pdfFile" [render-text]="true" [original-size]="false" style="width: 100%; height: 100%;" 
        (on-progress)="progress($event)" (error)="onError($event)"
        (after-load-complete)="loadComplete($event)"></pdf-viewer> -->
        <!-- <canvas #displayThePDF></canvas> -->
        <ng2-pdfjs-viewer #pdfViewer id="pdfViewer" style="width: 100%; height: 100%;" 
        [externalWindow]="false" [downloadFileName]="'File.pdf'" [download]="true" [openFile]="false" [viewBookmark]="false"
        ></ng2-pdfjs-viewer>
    </div>
    <div class="modal-footer">
        <div class="text-right">
            <button type="button" (click)="onCancel()" class="btn btn-danger sfi-buttons m-r-10"
            [ngStyle]="styling?.button?.buttonNegative"
            >{{cancelBtnTxt}}</button>
            <!-- <button type="button" (click)="onConfirm()" class="btn btn-info sfi-buttons"
            [ngStyle]="styling?.button?.buttonPositive"
            >{{downloadBtnText}}</button> -->
        </div>
    </div>
</div>