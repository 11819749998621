<div class="row col-md-12" style="padding: 10px 40px;">
    <button class="btn btn-info" type="button" style="margin: auto;" (click)="submitForm('back')" [ngStyle]="styling?.button?.buttonBack">Back to Quote Details</button>
</div>
<div class="card thick-border find-agent-card-margin" style="font-family: Helvetica;margin: 20px 0 0 0;">
    <div class="text-left">
        <div class="row">
            <div class="col-md-4 user-actions-order agentbox-padding">
                <form class= "agent-search-border" [formGroup]="searchAgentForm" (ngSubmit)="searchAgents()">
                    <div class="col-md-12 p-t-10 bg-info text-white thick-bottom-border" *ngIf="agentData?.length" style="text-align:center;font-weight:bold;">We found
                        {{agentData?.length}} agents in your area!</div>
                    <div class="col-md-12" style="padding: 10px 20px 0 10px;">
                        <div class="row">
                            <div class="col-md-4">
                                <mat-form-field style="width: 100%">
                                    <input matInput numbersOnly maxlength="6" formControlName="zipCode" type="text"
                                        placeholder="Zip Code" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-4" style="padding-top: 8px;">
                                <!-- <mat-form-field style="width: 100%">
                                    <input matInput numbersOnly maxlength="6" formControlName="searchRadius" type="text"
                                        placeholder="Search Radius" />
                                </mat-form-field> -->
                                <ng-select id="searchRadius_1" [items]="searchRadiusList" appendTo="body" [hideSelected]="true"
                                    placeholder="Select Policy Term" formControlName="searchRadius"
                                    [clearable]="false"
                                    [searchable]="false" [ngStyle]="styling?.fieldDropDown">
                                </ng-select>
                            </div>
                            <div class="col-md-4 p-10">
                                <button type="submit" class="btn btn-info btn-block"
                                    [disabled]="isDisabled" [ngStyle]="styling?.button?.buttonPositive">Search</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" style="padding-bottom: 10px;">
                        <div class="row">
                            <div class="col-mob-6" style="padding-left: 15px;">
                                <a (click)="setMyLocation()" style="cursor: pointer;">
                                    <i class="fab fa-periscope" style="color: #1e88e5;"></i>
                                    <span class="hide-menu ml-2" style="color: #1e88e5;">Use my location</span>
                                </a>
                                <!-- <button class="btn btn-info" type="button" (click)="setMyLocation()" style="margin-bottom:10px;">Use my location</button> -->
                            </div>
                            <div class="col-mob-6">
                                <a (click)="resetLocation()" style="cursor: pointer;">
                                    <i class="fab fa-periscope" style="color: #1e88e5;"></i>
                                    <span class="hide-menu ml-2" style="color: #1e88e5;">Reset location</span>
                                </a>
                                <!-- <button class="btn btn-info" type="button" (click)="resetLocation()">Reset location</button> -->
                            </div>
                        </div>
                    </div>
                </form>
                <div class="agent-info-container" *ngIf="agentDataArray?.length">
                    <div class="card thick-border" [id]="'Location_'+agent?.agencyID" *ngFor="let agent of agentDataArray[currentPage-1]" (click)="openWindow(agent?.agencyID)"
                        [ngClass]="{'highlighted-card': selectedId === agent?.agencyID}" style="border-radius: 20px;">
                        <div class="card-body" style="padding: 12px">
                            <div class="d-flex" style="justify-content: space-between;">
                                <span class="font-weight-bold">{{agent?.agentFirstName}} {{agent?.agentLastName}}</span>
                                <span *ngIf="agent?.mileageMiles">{{agent?.mileageMiles}} mi</span>
                            </div>
                            <div class="address-font-card">{{agent?.agencyName}}</div>
                            <div class="address-font-card" *ngIf="findAgentMapFilter == 'Agency'">{{agent?.agencyLocationAddress?.street}}, {{agent?.agencyLocationAddress?.city}},
                                {{agent?.agencyLocationAddress?.stateCode}}, {{agent?.agencyLocationAddress?.zip}} <a (click)="navigateToGmap(agent)" style="cursor: pointer;" class="fas fa-globe-americas"></a></div>
                                <div class="address-font-card" *ngIf="findAgentMapFilter == 'Agent'">{{agent?.agentLocationAddress?.street}}, {{agent?.agentLocationAddress?.city}},
                                    {{agent?.agentLocationAddress?.stateCode}}, {{agent?.agentLocationAddress?.zip}} <a (click)="navigateToGmap(agent)" style="cursor: pointer;" class="fas fa-globe-americas"></a></div>
                            <div class="address-font-card"><a
                                    href="tel:{{agent?.agentPhoneNumbers?.PhoneNumber[0]?.number}}">{{utilsService.formatNumber(agent?.agentPhoneNumbers?.PhoneNumber[0]?.number)}}</a>
                            </div>
                            <div class="p-t-10">
                                <button class="btn btn-info btn-block address-font-card" type="button"
                                    (click)="sendQuote(agent)" [ngStyle]="styling?.button?.buttonPositive">Send
                                    {{agent?.agentFirstName}} your Quote</button>
                            </div>

                        </div>
                        <!-- {{agentDataArray[currentPage-1] | json}} --  -->
                    </div>
                </div>
                <div *ngIf="pageObj?.pages && pageObj?.pages?.length" class="text-center p-t-10">
                    <ul class="pagination">
                        <ng-container *ngIf="pageObj?.allPages > 5">
                            <li [ngClass]="{disabled: pageObj?.currentPage == 1}">
                                <a (click)="setPage(1, pageObj?.currentPage == 1)">&lt;&lt;</a>
                            </li>
                            <li [ngClass]="{disabled: pageObj?.currentPage == 1}">
                                <a (click)="setPage(pageObj?.currentPage - 1, pageObj?.currentPage == 1)">&lt;</a>
                            </li>
                        </ng-container>
                        <li *ngFor="let page of pageObj?.pages" [ngClass]="{active: pageObj?.currentPage == page}">
                            <a (click)="setPage(page)">{{page}}</a>
                        </li>
                        <ng-container *ngIf="pageObj?.allPages > 5">
                            <li [ngClass]="{disabled: pageObj?.currentPage == pageObj?.allPages}">
                                <a (click)="setPage(pageObj?.currentPage + 1, pageObj?.currentPage == pageObj?.allPages)">&gt;</a>
                            </li>
                            <li [ngClass]="{disabled: pageObj?.currentPage == pageObj?.allPages}">
                                <a (click)="setPage(pageObj?.allPages, pageObj?.currentPage == pageObj?.allPages)">&gt;&gt;</a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <br />

            <div class="col-md-8 login-box-order thick-left-border"  *ngIf="agentDataArray?.length; else noContent">
                <div class="button-hover-in-map" [ngStyle]="styling?.button?.buttonPositive"><button class="btn btn-info" (click)="findAgentsFromMap(true)"
                        [disabled]="isDisabled" [ngStyle]="styling?.button?.buttonPositive">Search this location</button></div>
                <agm-map (centerChange)="centerPositionChanged($event)" *ngIf="gotRiskAddress"
                    [latitude]="riskAddress.lat" [longitude]="riskAddress.lng" [fitBounds]="true">
                    <!-- <agm-marker *ngFor="let position of agentData" [latitude]="position.latFromGoogle"
                        [longitude]="position.lngFromGoogle" [agmFitBounds]="true"
                        (markerClick)="openWindow(position.agencyID)">
                        <agm-info-window [isOpen]="closeInfo(position.agencyID)"
                            [latitude]="position.latFromGoogle" [longitude]="position.lngFromGoogle"
                            (infoWindowClose)="closeInfo()">{{position?.agencyName}}</agm-info-window>
                    </agm-marker> -->
                    <agm-marker *ngFor="let position of agentDataArray[currentPage-1]" [latitude]="position.latFromGoogle"
                        [longitude]="position.lngFromGoogle" [agmFitBounds]="true"
                        (markerClick)="openWindow(position.agencyID)">
                        <agm-info-window [isOpen]="position.agencyID == selectedId"
                            (infoWindowClose)="closeInfo()">{{position?.agencyName}}</agm-info-window>
                    </agm-marker>
                    <agm-marker [latitude]="riskAddress.lat" [longitude]="riskAddress.lng" [agmFitBounds]="true"
                        [iconUrl]="urlProps">
                        <agm-info-window [isOpen]="true" [latitude]="riskAddress.lat" [longitude]="riskAddress.lng">My
                            Home Address: {{riskAddress?.zipCode}}</agm-info-window>
                    </agm-marker>
                    <agm-marker *ngIf="showLocation && myLocation" [latitude]="myLocation.lat" [longitude]="myLocation.lng"
                        [agmFitBounds]="true" [iconUrl]="myUrlProps">
                        <agm-info-window [isOpen]="true" [latitude]="myLocation.lat"
                            [longitude]="myLocation.lng">My Current Location</agm-info-window>
                    </agm-marker>
                </agm-map>
            </div>
            <ng-template #noContent>
                <div class="col-md-8 no-agent-found-body">
                    <span style="font-size: 60px;">No Agents Available</span>
                </div>
            </ng-template>
        </div>
    </div>
</div>