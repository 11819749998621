<!-- <div class="card get-quote-container zero-margin"> -->
<div>
    <div class="col-lg-12 outerbox-padding">
        <div class="col-md-12 " style="text-align: center;"   [innerHTML]="maintenancePageInfo">

        </div>   
        <!-- <div class="col-md-12 maintenance-container">
            <img src="assets/multiTenantImages/png/MaintenanceMode.png" class="maintenance-img">
            <div class="maintenance-description">
                <h3 class="maintenance-title">
                    We'll be back soon!
                </h3>
                <div class="maintenance-body">
                    <div>This system is currently down for maintenance.</div>
                    <div>We'll be back up shortly.</div>
                    <div class="maintenance-form-fill">
                        <span>To submit a quote in the meantime, </span><a target="_blank" href="https://form.jotform.com/SecurityFirst/online-quote-request">fill out this form</a>
                    </div>
                </div>

            </div>
        </div> -->
    </div>
</div>

<!-- class="col-md-4 offset-md-4" -->