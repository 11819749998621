<form [formGroup]="numberOfClaimsForm" >
    <div class="form-group row">
        <div class="col-md-4 offset-md-4 text-center" [ngStyle]="styling?.quoterWizard?.quoteSubField2">            
            <mat-form-field [ngStyle]="styling?.quoterWizard?.quoteSubField2">
                <input matInput [autofocus]="true" formControlName="claims" type="text" numbersOnly />
                <!-- maxlength="4" -->
            </mat-form-field>
            <p class="error-class mat-angular-error-text text-center" [ngStyle]="styling?.quoterWizard?.quoteSubField2" *ngIf="numberOfClaimsForm.get('claims').errors?.required && isSubmitted">
                {{'REQUIRED_FIELD' | translate: {value:'The number of claims'} }}</p>
            <p class="error-class mat-angular-error-text text-center" [ngStyle]="styling?.quoterWizard?.quoteSubField2" *ngIf="numberOfClaimsForm.get('claims').errors?.min && isSubmitted">The number of claims should be greater than 0</p>
        </div>
    </div>

	<app-summary-back-btn [styling]="styling" (summaryClickedData)="submitBackToSummary($event)"></app-summary-back-btn>
    <div class="text-center p-b-40" *ngIf="isSmallWindow">
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
            [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
        <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
            *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
            [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    </div>
    <div class="text-center p-b-40" *ngIf="!isSmallWindow">
    <button class="btn btn-info custom-button-info m-r-10 m-b-20"
        *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
        [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
    <button class="btn btn-info custom-button-info m-l-10 m-b-20"
        type="button" *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
        [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
</div>
</form>