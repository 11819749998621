import { Injectable } from '@angular/core';

@Injectable()
export class QuoteDataShare {
    globalDataQuote: any;
    globalPolicyObj: {
        policyType: any,
        capacityStatus: any
    }
    globalCoverageObj = {
        coverageAValue: '',
        coverageBValue: '',
        coverageCValue: '',
        coverageDValue: '',
        coverageEValue: '',
        coverageEPremisesLiabValue: '',
        coverageFValue: '',
        aopDeductible: '',
        deductibleAppliedForHurricaneCoverage: '',
        waterDeductible: '',
        coverageLValue: '',
        coverageMValue: '',
        CoverageDandE: '',
        sinkholeDeductible: ''
    };
    globalCoverageItemList = {
        coverageBValue: [],
        coverageCValue: [],
        coverageDValue: [],
        coverageEValue: [],
        coverageEPremisesLiabValue: [],
        coverageFValue: [],
        coverageLValue: [],
        coverageMValue: [],
        coverageDandE: [],
        aopDeductible: [],
        deductibleAppliedForHurricaneCoverage: [],
        waterDeductible: [],
        sinkholeDeductible: []
    }
    globalAddressObj: {
        stateCode: any,
        county: any,
        currentSelectedAddress: any
    }
    globalRiskAddressObj: any;
    globalAdditionalFeatures: any;
    globalEmailQuote: any;
    globalQuoteObj: any;
    globalEstimatedPremium: any;
    globalPolicyFees: any;
    globalQuoteID: any;
    globalCoverageNameObj: {
        curCoverageNames: any,
        curDeductibleNames: any,
        curInsuranceScore: any,
        curOptionalCoverages: any,
        curDiscount: any
    }
    globalBuildingObject: {
        buildingMaterials: any,
        buildingTypes: any,
        stories: any,
        roofMaterials: any
    }
    globalAcntConfig: {
        image: any,
        effectiveDate: any
    }
    globalMappingInfo: {
        roofMaterialsMapping: any,
        constructionTypeMapping: any,
        stories: any,
        coverageAPageCalculation: any,
        coverageCPageCalculation: any,
        coverageDefaultValue: any,
        defaultAgentID: any,
        homeStructureType: any,
        propertyUsageMapping: any,
        propertyOccupancyStatusMapping: any,
        rentalTermList: any,
        roofMaterialConvertValueForProductMapping: any,
        structureTypeConvertValueForRateQuote: any,
        insuranceTypeForRateAndSubmitQuote: any,
        policyTypeForGetConstrainedValue: any,
        coverageCOrCoverageCtoCoverageA: any,
        quoteSummaryMapping: any
        sendDocumentsMapping: any
    }
    globalPageConfig: {
        pageMessage: any,
        pageSubTitle: any
    }
    selectedRoofMaterial: any;
    insuranceTypePrefix: any;
    pagesListNavigated: any;
    propertyAddress: any;
    globalContactDetails: {
        first_name: any,
        last_name: any,
        phone_no: any,
        email: any,
        dontContact: any
    }
    globalAgencyData: {
        agency_email: any,
        quotePhone: any,
        companyShortName: any,
        company_Name: any,
        quoteForm: any,
        partner: any
    }
    globalInsuredName: {
        firstName: any,
        lastName: any
    }
    accountNumPreEnrollment: any
    globalProductMappingData: any
    globalSummaryBtnDisplay: boolean
    email: any
    phoneNumber: any
    policyNumber: any
    unitNumber: any
    effectiveDate: any
    premiumTax: any
    agentLoginName: any
    payrollDeduct: boolean
    agentID: any
    recoopCoverageValue: any

    getRecoopCoverageValue() {
        return this.recoopCoverageValue;
    }

    setRecoopCoverageValue(data) {
        this.recoopCoverageValue = data;
    }

    getAgentID() {
        return this.agentID;
    }

    setAgentID(data) {
        this.agentID = data;
    }

    getAgentLoginName() {
        return this.agentLoginName;
    }

    setAgentLoginName(data) {
        this.agentLoginName = data
    }

    getPayrollDeduct() {
        return this.payrollDeduct;
    }

    setPayrollDeduct(data) {
        this.payrollDeduct = data;
    }

    getPremiumTax() {
        return this.premiumTax;
    }

    setPremiumTax(data) {
        this.premiumTax = data
    }

    setProductMapping(data) {
        this.globalProductMappingData = data;
    }
    getProductMapping() { return this.globalProductMappingData }
    setConfiguration(config) {
        this.globalDataQuote = config;
    }

    getConfiguration() {
        return this.globalDataQuote;
    }

    setPolicyObjData(data) {
        this.globalPolicyObj = data
    }

    getPolicyObjData() {
        return this.globalPolicyObj;
    }

    setCoverageObj(data) {
        this.globalCoverageObj = data
    }

    getCoverageObj() {
        return this.globalCoverageObj;
    }

    setCoverageItemList(data) {
        this.globalCoverageItemList = data
    }

    getCoverageItemList() {
        return this.globalCoverageItemList;
    }

    setAddressObj(data) {
        this.globalAddressObj = data
    }

    getAddressObj() {
        return this.globalAddressObj;
    }

    setRiskAddressObj(data) {
        this.globalRiskAddressObj = data
    }

    getRiskAddressObj() {
        return this.globalRiskAddressObj;
    }

    setEmailQuote(data) {
        this.globalEmailQuote = data
    }

    getEmailQuote() {
        return this.globalEmailQuote;
    }

    setQuoteObjectStructure(data) {
        this.globalQuoteObj = data
    }

    getQuoteObjectStructure() {
        return this.globalQuoteObj
    }

    setEstimatedPremium(data) {
        this.globalEstimatedPremium = data
    }

    setInsuredName(data) {
        this.globalInsuredName = data;
    }

    getInsuredName() {
        return this.globalInsuredName;
    }

    setAccountNumberPreEnrollment(data) {
        this.accountNumPreEnrollment = data;
    }

    getAccountNumberPreEnrollment() {
        return this.accountNumPreEnrollment;
    }

    getEstimatedPremium() {
        return this.globalEstimatedPremium
    }

    setPolicyFees(data) {
        this.globalPolicyFees = data;
    }
    getPolicyFees() {
        return this.globalPolicyFees;
    }

    setQuoteID(data) {
        this.globalQuoteID = data
    }

    getQuoteID() {
        return this.globalQuoteID
    }

    setEmail(data) {
        this.email = data
    }

    getEmail() {
        return this.email
    }

    setPhoneNumber(data) {
        this.phoneNumber = data
    }

    setPolicyNumber(data) {
        this.policyNumber = data
    }

    getPolicyNumber() {
        return this.policyNumber
    }

    setUnitNumber(data) {
        this.unitNumber = data
    }

    getUnitNumber() {
        return this.unitNumber
    }

    getPhoneNumber() {
        return this.phoneNumber
    }

    setEffectiveDate(data) {
        this.effectiveDate = data
    }

    getEffectiveDate() {
        return this.effectiveDate
    }

    setCoverageNameObj(data) {
        this.globalCoverageNameObj = data
    }

    getCoverageNameObj() {
        return this.globalCoverageNameObj
    }

    setMappingInfoObj(data) {
        this.globalMappingInfo = data
    }

    setAdditionalFeatures(data) {
        this.globalAdditionalFeatures = data
    }

    getAdditionalFeatures() {
        return this.globalAdditionalFeatures
    }

    getMappingInfoObj() {
        return this.globalMappingInfo
    }

    setRoofMaterial(selectedRoofMaterial) {
        this.selectedRoofMaterial = selectedRoofMaterial;
    }

    getRoofMaterial() {
        return this.selectedRoofMaterial;
    }

    setBuildingObject(data) {
        this.globalBuildingObject = data
    }

    getBuildingObject() {
        return this.globalBuildingObject
    }

    setAcntConfig(config) {
        this.globalAcntConfig = config;
    }

    getAcntConfig() {
        return this.globalAcntConfig;
    }

    setPageTitle(pageObj) {
        this.globalPageConfig = pageObj;
    }

    getPageTitle() {
        return this.globalPageConfig;
    }

    setInsuranceTypePrefix(prefix) {
        this.insuranceTypePrefix = prefix;
    }

    getInsuranceTypePrefix() {
        return this.insuranceTypePrefix;
    }

    setVisitedPages(arr) {
        this.pagesListNavigated = arr
    }

    getVisitedPages() {
        return this.pagesListNavigated
    }

    setPropertyAddress(propertyAddress) {
        this.propertyAddress = propertyAddress
    }

    getPropertyAddress() {
        return this.propertyAddress;
    }

    setContactDetails(contactDetails) {
        this.globalContactDetails = contactDetails
    }

    getContactDetails() {
        return this.globalContactDetails
    }

    setAgencyConfig(data) {
        this.globalAgencyData = data
    }

    getAgencyConfig() {
        return this.globalAgencyData
    }

    setSummaryPageBtnDisplay(data: boolean) {
        this.globalSummaryBtnDisplay = data
    }

    getSummaryPageBtnDisplay() {
        return this.globalSummaryBtnDisplay
    }
}
