<form [formGroup]="premiumForm">
    <div *ngIf="!loading">
        <div class="row">
            <div class="col-md-12 text-center p-b-10" [ngStyle]="styling?.quoterWizard?.quoteTitle">
                {{pageMessage}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center p-b-10">
                <h4 [ngStyle]="styling?.quoterWizard?.quotePremiumTitle">
                </h4>
                <h2 [ngStyle]="styling?.quoterWizard?.quoteMonthlyPremium">
                    ${{this.premiumForm.value.premiumPerMonth}}/month
                </h2>
                <h4 class=" p-b-30" [ngStyle]="styling?.quoterWizard?.quoteAnnualPremium">
                    ${{this.premiumForm.value.premiumPerYear}}/yr
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center p-b-10" [ngStyle]="styling?.quoterWizard?.helpText">
                {{helpText}}
            </div>
        </div>
        <div class="row" style="display: flex; justify-content: center; align-items: center;">
            <div *ngFor="let coverage of coverageItemList">
                <ng-container *ngIf="mostPopularValue == coverage; else normalBtnContainer">
                    <div class="coverage-container">
                        <div class="coverage-inner-container">
                            <div class="popular-item-icon"><i class="fas fa-crown"></i></div>
                            <button class="btn btn-info w-100 coverage-btn" type="button" 
                            [ngStyle]="setStyles(coverage)" (click)="calculatePremium(coverage)"> {{ coverage }}</button>
                            <div class="popular-item-display">Most Popular</div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #normalBtnContainer>
                    <button class="btn btn-info coverage-btn m-r-10 m-b-40" type="button"
                        [ngStyle]="setStyles(coverage)" (click)="calculatePremium(coverage)">{{ coverage }}</button>
                </ng-template>
            </div>
        </div>
        <div class="text-center" *ngIf="!isSmallWindow">
            <button class="btn btn-info custom-button-info m-r-10 m-b-20"
                *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' |
                translate}}</button>
            <button class="btn btn-info custom-button-info m-l-10 m-b-20" type="button"
                *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{ 'NEXT' | translate}}</button>
        </div>
        <div class="text-center" *ngIf="isSmallWindow">
            <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6" type="button"
                *ngIf="quoteObj?.buttonOptions?.includes('Next')" (click)="submitForm('next')"
                [ngStyle]="styling?.button?.buttonPositive">{{'NEXT' | translate}}</button>
            <button class="btn btn-info custom-button-info m-r-20 m-b-20 col-lg-6"
                *ngIf="quoteObj?.buttonOptions?.includes('Back')" type="button" (click)="submitForm('back')"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
        </div>
        <div class="row p-b-40">
            <div class="col-md-12 text-center p-b-10" *ngIf="coverageDisclosure != null" [ngStyle]="styling?.quoterWizard?.coverageDisclosure">
                {{coverageDisclosure}}
            </div>
        </div>

    </div>
</form>
<div class="col-12" *ngIf="loading">
    <div class="p-b-20">
        <div class="row" style="justify-content: center;">
            <h4 class="card-title">
                <app-bot-message [message]="botMessage[0]" [showImageFlag]="true"></app-bot-message>
            </h4>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <div class="col-sm-8" style="margin-top: 100px;margin-bottom: 100px;">
            <div class="pipe-container">
                <div class="pipe-loader" [style.width.%]="progressNumber">
                    {{progressNumber}}%
                </div>
            </div>
        </div>
    </div>
</div>