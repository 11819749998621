import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/dataTransfer.service';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-roof-built-form',
  templateUrl: './roof-built-form.component.html',
  styleUrls: ['./roof-built-form.component.scss']
})
export class RoofBuiltFormComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  roofBuiltForm: any;
  // @Input() dataObj: any;
  // @Output() submitFormObj = new EventEmitter()
  isSubmitted = false;
  roofMaterials: any;
  quoteObj: any;
  
  isSmallWindow = window.innerWidth <= 768;
//   roofMaterials = [
//     "Shingles: Asphalt or Composition",
//     "Shingles: Wood or Wood Shake",
//     "Shingles: Metal",
//     "Shingles: Solar",
//     "Tile: Flat Slate",
//     "Tile: Flat Concrete, Clay or Composite",
//     "Tile: S Curve Concrete, Clay or Composite",
//     "Tile: Flat Metal",
//     "Metal: Light Panels Slate",
//     "Metal: Standing Seam",
//     "Built-up: Not Ballasted",
//     "Built-up: Roof Ballasted",
//     "Built-up: Single Ply membrane - Not ballasted/stabilized",
//     "Built-up: Single Ply membrane - Ballasted",
//     "Built-up: Concrete Fill",
//     "Thermoplastic Polyolefin (TPO)",
//     "Other",
//     "Asbestos"
// ]
  constructor(
    private formBuilder: FormBuilder, 
    private router: Router, 
    private utilsService: UtilsService,
    private quotedataService: QuoteDataShare,
    private dataTransferService: DataTransferService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.quoteObj = this.quotedataService.getConfiguration()
    this.initForm()
    this.fromAPI()
    // this.roofMaterials = this.quotedataService.getMappingInfoObj();
    // console.log(JSON.stringify(this.roofMaterials))
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
  }
  
  initForm(){
    this.roofBuiltForm = this.formBuilder.group({
      roofYear: ['', [Validators.required, Validators.min(1970), Validators.max((new Date()).getFullYear())]], 
    })
    //roofMaterial: ['', [Validators.required]]
  }

  fromAPI(){
    if(this.quoteObj.conversation && this.quoteObj.conversation['roof-age']) 
      this.roofBuiltForm.patchValue(this.quoteObj.conversation['roof-age'])
  }

  navigateToLink(page) {
    const tenant = this.utilsService.getTenantFromWindow()
    this.router.navigate([`${tenant}/${page}`]);
  }

  submitForm(userSelection){
    let temp;
    this.isSubmitted = true;
    if(this.roofBuiltForm.valid && userSelection == 'next'){
      temp = { 
        input: 'Next',
        pageID: 'roof-age',
        userInput: this.roofBuiltForm.value
      }
    } else if(userSelection == 'back'){
      temp = { input: 'Back', pageID: 'roof-age' }
    }
    if(temp){
      // console.log(temp)
      // this.submitFormObj.emit(temp)
      this.dataTransferService.sendData(temp)
    }
  }

  submitBackToSummary(eve){
    this.isSubmitted = true;
    if (this.roofBuiltForm.valid) {
      let temp = eve
      temp.userInput = this.roofBuiltForm.value
      temp.currentPageID = 'roof-age'
      this.dataTransferService.sendData(temp)
    }
  }
}
