import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthGuardMaintenance } from '../../shared/services/auth-guard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { map } from 'rxjs/internal/operators/map';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { CommonApiService } from 'src/app/shared/services/common-api.service';

@Component({
  selector: 'app-maintenance-view',
  templateUrl: './maintenance-view.component.html',
  styleUrls: ['./maintenance-view.component.scss'],
  providers: [CommonApiService]
})
export class MaintenanceViewComponent implements OnInit {
  maintenancePageInfo: any;
  maintenanceModeInfo: any;
  constructor(private sanitizer: DomSanitizer,
    private authGuardService: AuthGuardMaintenance,
    private router: Router,
    private http: CommonApiService,
    private utilsService: UtilsService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    // this.maintenanceModeInfo = sessionStorage.getItem('maintenanceMode')? JSON.parse(sessionStorage.getItem('maintenanceMode')) : null;;
    // if (this.maintenanceModeInfo != null && this.maintenanceModeInfo.maintenancePage != null) {
    //   this.maintenancePageInfo = this.sanitizer.bypassSecurityTrustHtml(this.maintenanceModeInfo.maintenancePage);
    // }
    this.getStatus();
  }

  // getStatus() {
  //   if (this.authGuardService.canActivate()) {
  //     const tenant = this.utilsService.getTenantFromWindow()
  //     console.log("finish the maintenance", tenant)
  //     this.router.navigate([`${tenant}`]);    
  //   }
  // }

  getStatus(){
    let maintenanceSettings = {
      maintenanceControl: false,
      timeSetting: new Date(),
    };;
    let currentDate = new Date();
    const tenant = this.utilsService.getTenantFromWindow()
    this.http.get(`accountConfiguration?accountCode=${tenant}`, null, true).subscribe(resp => {
      if(resp){
        let data = { ...resp.quoteMaintenanceMode }
        if (data != null && data.control != null && data.UTCTime != null) {
          maintenanceSettings.maintenanceControl = data.control;
          if (maintenanceSettings.maintenanceControl) {
            maintenanceSettings.timeSetting = new Date(data.UTCTime);
            if (maintenanceSettings.timeSetting <= currentDate) {
              this.maintenancePageInfo = this.sanitizer.bypassSecurityTrustHtml(data.maintenancePage);
                console.log("maintenance")
            } else{
              this.router.navigate([`${tenant}`])
            }
          } else{
            this.router.navigate([`${tenant}`])
          }
        } else{
          this.router.navigate(['/site-not-found'])
        }
      }
    })
  }

}
