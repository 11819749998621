import { Component, Input, OnInit } from '@angular/core';
import { QuoteDataShare } from 'src/app/shared/services/quoteDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-bot-message',
  templateUrl: './bot-message.component.html',
  styleUrls: ['./bot-message.component.scss']
})
export class BotMessageComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  @Input() message: any;
  @Input() subTitle: any;
  showImage = ''
  @Input() showImageFlag : any;
  // userDetails= {
  //   quoteUsername: String,
  //   quoteEmail: String
  // };
  displayMessage: any;
  displaySubTitle: any;
  constructor(private utilsService: UtilsService, private quotedataService: QuoteDataShare) { }

  ngOnInit(): void {
    this.showImage = this.quotedataService.getAcntConfig().image
    }

  ngOnChanges(changes: any): void {
    if(changes.hasOwnProperty('message') && changes.message.currentValue){
      this.modifyMsg()
    }
    this.displaySubTitle = this.subTitle;
  }
  
  modifyMsg(){
    let finalMsg;
    if(this.message.indexOf('${customer_name}') > -1){
      let temp = this.message
      finalMsg = temp.replace('${customer_name}', this.getFormValue('Personal-Info' , 'firstName'))
    } else {
      finalMsg = this.message
    }
    this.displayMessage = finalMsg
  }

  getFormValue(formName, value){
    let quoteObj = this.quotedataService.getConfiguration()
    if(quoteObj.conversation && quoteObj.conversation[formName]){
      return quoteObj.conversation[formName][value]
    } else{
      return 'Buddy'
    }
  }

}
