<div class="modal-header" style="background-color: '#EF8201';">
    <h4 class="modal-title pull-left text-white sfi-header-four-s"  [ngStyle]="styling?.paragraphTitle" [innerHTML]='title'></h4>
</div>
<div class="modal-body">
    <p  [ngStyle]="styling?.paragraphText" [innerHTML]='body' style="white-space: pre-line;"></p>
</div>
<div class="modal-footer">
    <div class="text-right">
        <button type="button" (click)="hideConfirmationModal()" class="btn btn-info sfi-buttons">{{buttonContent}}</button>
    </div>
</div>