import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilsService } from './shared/services/utils.service';


@Injectable({ 
    providedIn: 'root'
})

export class AppService {

    constructor(private utilsService: UtilsService, @Inject(DOCUMENT) private doc: any){}

    // googleAnalytics(){
		
    //     let gtmId = this.getGTMId()
	// 	// console.log("gtmid:", gtmId);
    //     if(gtmId) this.gAnalyticsScript(window,document,'script','dataLayer', gtmId);
    // }

    // gAnalyticsScript(w,d,s,l,i){
    //     w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
    //     var f=d.getElementsByTagName(s)[0],
          
    //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    //     j.async=true;
    //     j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
	// 	// console.log("j.src:", j.src);
    //     f.parentNode.insertBefore(j,f);    
    // }

    getGTMId(){
        let tenant = this.utilsService.getTenantFromWindow()
		// console.log("GTM tag to be used:", environment.gtmID[tenant]);
        if(environment.gtmID[tenant]) return environment.gtmID[tenant]
        else return null
    }


    newGoogleAnalytics(){
        let gtmId = this.getGTMId()
        if(gtmId) this.newGTMAnalyticsScript(gtmId);
    }

    newGTMAnalyticsScript(gtmId){
        const s = this.doc.createElement('script');
        s.type = 'text/javascript';
        s.innerHTML = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push'
        + '({\'gtm.start\':new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)'
        + '[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src='
        + '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})'
        + '(window,document,\'script\',\'dataLayer\',\'' + gtmId + '\');';
        const head = this.doc.getElementsByTagName('head')[0];
        head.appendChild(s);
    }
}