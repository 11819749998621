import { Injectable } from "@angular/core";
import { CanDeactivate, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { QuoteDataShare } from "./quoteDataShare.service";

export interface CanComponentDeactivate{
    canDeactivate: () => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
}

@Injectable()
export class PreventBrowserBackGuard implements CanDeactivate<CanComponentDeactivate>{
    constructor(private quoteDataShare: QuoteDataShare){}

    canDeactivate(component: CanComponentDeactivate){
        console.log(component, 'componenent')
        return component.canDeactivate() ? component.canDeactivate() : true
        // if(this.quoteDataShare.getBrowserNavClick()){
            console.log('any updates')
            // this.quoteDataShare.setBrowserNavClick(false)
            // history.pushState(null, null, location.href)
            // return false
        // }
        // return true
    }
}