import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { NotifytoastService } from '../services/notificationToast.service';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.scss']
})

export class PdfModalComponent implements OnInit {
  @ViewChild('pdfViewer') pdfViewer;
  public active = false;
  public pdfFile: any;
  public title: string;
  public onClose: Subject<boolean>;
  public styling =this.utilsService.getItemfromSession('themeObject')
  cancelBtnTxt: string;
  downloadBtnText: string;
  pdfUrl: any;
  constructor(
    private _bsModal: BsModalRef,
    private utilsService: UtilsService,
    private notifyToast: NotifytoastService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.onClose = new Subject()
  }

  public showConfirmationModal(title, pdfFile, cancelBtnTxt?, downloadBtnText?){
    this.title = title
    // this.pdfFile = this.sanitizer.bypassSecurityTrustHtml(pdfFile)
    // this.pdfFile = pdfFile
    setTimeout(() => {
      this.pdfViewer.pdfSrc = pdfFile
      this.pdfViewer.refresh()
    }, 500);
    this.cancelBtnTxt = cancelBtnTxt ? cancelBtnTxt : 'No'
    this.downloadBtnText = downloadBtnText ? downloadBtnText : 'Yes'
    this.active = true
  }

  public onConfirm(){
    this.active = false
    this.onClose.next(true)
    this._bsModal.hide()
  }

  public onCancel(){
    this.active = false
    this.onClose.next(false)
    this._bsModal.hide()
  }

  public hideConfirmationModal(){
    this.active = false
    this.onClose.next(false)
    this._bsModal.hide()
  }

}
