import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { throwError as observableThrowError, Observable } from "rxjs";
import { CommonApiService } from "../../shared/services/common-api.service";
import { Guid } from "guid-typescript"
import { catchError, map } from 'rxjs/operators'
import { UtilsService } from "src/app/shared/services/utils.service";


@Injectable()
export class LatestGetQuoteResolver implements Resolve<any>{
    constructor(private httpService: CommonApiService, private utilsService: UtilsService){}

    resolve(route: ActivatedRouteSnapshot): Observable<any>{
        let guID = this.getGUID()
        let tenant = this.utilsService.getTenantFromWindow()
        let data = {"accountCode":tenant, guID}
        const flowstart = route.queryParamMap.get('webpage');
        if(flowstart != null){
            data["pageID"] = flowstart;
            data["input"] = "flowstart";
            return this.httpService.post('getQuoteInfo', data, true, true).pipe(
                map(res => {
                    res.guID = guID
                    return res
                }),
                catchError(error => this.handleError(error))
            )
        }
        return this.httpService.post('getQuoteInfo', data, true, true).pipe(
            map(res => {
                res.guID = guID
                return res
            }),
            catchError(error => this.handleError(error))
        )
    }

    private getGUID(){
        let id;
        id = Guid.create().toString()
        return id
    }

    handleError(error){
        return observableThrowError(error)
    }
}