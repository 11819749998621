<div>
    <h4 class="col-md-12 text-center sfi-header-four-s" [ngStyle]="styling?.paragraphTitle" [innerHTML]='header'></h4>
    <div class="col-md-12 text-center" [ngStyle]="styling?.paragraphText" [innerHTML]='content'></div>
    <div class="col-md-12" style="text-align: center">
        <button class="btn btn-info custom-button-info sfi-buttons" type="button" (click)="hideUserWarning()"
            [ngStyle]="styling?.button?.buttonNegative" style="margin: 10px;"> {{'GO_BACK' | translate }}
        </button>
        <button class="btn btn-info custom-button-info sfi-buttons" type="button" (click)="navigateToPage(page)"
            [ngStyle]="styling?.button?.buttonPositive" style="margin: 10px;">{{'CANCEL_CLAIM' | translate }}
        </button>
    </div>
</div>